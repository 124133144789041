// File path: src/components/Stats.js

import React, { useState } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { BagXFill, BarChartFill, GraphDown, GraphUp } from 'react-bootstrap-icons';
import useStats from '../../../../hooks/useStats';

const Stats = () => {
  const [filter, setFilter] = useState('mes');
  const { stats, loading } = useStats(filter);

  //console.log("Datos recibidos en Stats.js:", stats);

  if (loading) {
    return <div>Cargando...</div>;
  }

  const nombreMesActual = new Date().toLocaleString('en-US', { month: 'long' });
  const mesActual = stats?.meses.find(mes => mes.nombre === nombreMesActual) || {};

  if (Object.keys(mesActual).length === 0) {
    return <div>No hay datos disponibles para el mes actual.</div>;
  }

  // Función para formatear valores como moneda COP
  const formatCurrency = (value) => {
    return value?.toLocaleString('es-CO', { 
      style: 'currency', 
      currency: 'COP',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0 
    }) || '$0';
  };

  return (
    <Row>
      <Col md={6}>
        <Card bg='light' className='shadow-none border-0 rounded-4'>
          <Card.Body>
            <div className="d-md-none d-flex align-items-center justify-content-center">
              <BarChartFill className='text-primary me-4' size={24} />
              <div>
                <small className="text-muted">Ventas</small>
                <div className="fw-bold">{formatCurrency(mesActual.ventas)}</div>
              </div>
            </div>
            <div className="d-none d-md-block text-center">
              <Card.Title>
                <BarChartFill className='text-primary' size={24} />
              </Card.Title>
              <small className="text-muted">Ventas</small>
              <div className="fw-bold">{formatCurrency(mesActual.ventas)}</div>
            </div>
          </Card.Body>
        </Card>
        <Card bg='light' className='mt-3 shadow-none border-0 rounded-4'>
          <Card.Body>
            <div className="d-md-none d-flex align-items-center justify-content-center">
              <GraphUp className='text-success me-4' size={24} />
              <div>
                <small className="text-muted">Ingresos</small>
                <div className="fw-bold">{formatCurrency(mesActual.ingresos)}</div>
              </div>
            </div>
            <div className="d-none d-md-block text-center">
              <Card.Title>
                <GraphUp className='text-success' size={24} />
              </Card.Title>
              <small className="text-muted">Ingresos</small>
              <div className="fw-bold">{formatCurrency(mesActual.ingresos)}</div>
            </div>
          </Card.Body>
        </Card>
      </Col>
      <Col md={6} className='mt-3 mt-md-0'>
        <Card bg='light' className='shadow-none border-0 rounded-4'>
          <Card.Body>
            <div className="d-md-none d-flex align-items-center justify-content-center">
              <BagXFill className='text-warning me-4' size={24} />
              <div>
                <small className="text-muted">Compras</small>
                <div className="fw-bold">{formatCurrency(mesActual.compras)}</div>
              </div>
            </div>
            <div className="d-none d-md-block text-center">
              <Card.Title>
                <BagXFill className='text-warning' size={24} />
              </Card.Title>
              <small className="text-muted">Compras</small>
              <div className="fw-bold">{formatCurrency(mesActual.compras)}</div>
            </div>
          </Card.Body>
        </Card>
        <Card bg='light' className='mt-3 shadow-none border-0 rounded-4'>
          <Card.Body>
            <div className="d-md-none d-flex align-items-center justify-content-center">
              <GraphDown className='text-danger me-4' size={24} />
              <div>
                <small className="text-muted">Gastos</small>
                <div className="fw-bold">{formatCurrency(mesActual.gastos)}</div>
              </div>
            </div>
            <div className="d-none d-md-block text-center">
              <Card.Title>
                <GraphDown className='text-danger' size={24} />
              </Card.Title>
              <small className="text-muted">Gastos</small>
              <div className="fw-bold">{formatCurrency(mesActual.gastos)}</div>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default Stats;
