// ../Utils/AlertsBancos.js
import Swal from 'sweetalert2';
import APIserver from '../../../../api/axiosConfig';

export const showAlertSuccess = (message) => {
  Swal.fire({
    icon: 'success',
    title: '¡Éxito!',
    text: message,
    showConfirmButton: false,
    timer: 2000,
    iconColor: '#2fb380',
confirmButtonColor: "#2c4cc4"
  }).then(() => {
    window.location.reload();
  });
};

export const showAlertError = (message) => {
  Swal.fire({
    icon: 'error',
    title: 'Error',
    text: message,
    iconColor: '#da292e',
confirmButtonColor: "#da292e"
  });
};

export const handleUpdateBanco = async (idBanco, nomBanco, tipoCuenta, numeroCuenta, saldoBanco, onUpdate, handleClose) => {
  try {
    // Realizar la solicitud PUT para actualizar el banco, incluyendo saldoBanco
    const response = await APIserver.put(`/api/bancos/${idBanco}`, {
      nomBanco,
      tipoCuenta,
      numeroCuenta,
      saldoBanco // Incluimos el saldo en la actualización
    });

    // Mostrar alerta de éxito
    showAlertSuccess('El banco se ha actualizado correctamente.');

    // Llamar a la función onUpdate para actualizar la lista de bancos en TablaBancos
    onUpdate();
    handleClose();
  } catch (error) {
    console.error('Error al actualizar el banco:', error);
    // Mostrar alerta de error
    showAlertError('Error al actualizar el banco. Por favor, inténtalo de nuevo.');
  }
};

export const handleDeleteBanco = async (idBanco, onUpdate) => {
  try {
    const confirmDelete = await Swal.fire({
      title: '¿Estás seguro?',
      text: 'Esta acción no se puede revertir. ¿Quieres continuar?',
      icon: 'warning',
      showCancelButton: true,
      iconColor: '#f4bd61',
      confirmButtonColor: '#2c4cc4',
      cancelButtonColor: '#da292e',
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar'
    });

    if (confirmDelete.isConfirmed) {
      const response = await APIserver.delete(`/api/bancos/${idBanco}`);

      console.log('Banco eliminado:', response.data);
      showAlertSuccess('El banco se ha eliminado correctamente.');
      onUpdate(); // Actualiza la lista de bancos en TablaBancos
    }
  } catch (error) {
    console.error('Error al eliminar el banco:', error);

    // Verificamos si el error es el que indica pagos asociados
    if (error.response && error.response.status === 400 && error.response.data.message) {
      // Si el backend devuelve el mensaje específico, lo mostramos en la alerta
      if (error.response.data.message === 'No se puede eliminar este banco porque hay pagos asociados a él.') {
        showAlertError('No se puede eliminar este banco porque hay pagos asociados a él.');
      } else {
        showAlertError('Error al eliminar el banco. Por favor, inténtalo de nuevo.');
      }
    } else {
      // Otros errores genéricos
      showAlertError('Error al eliminar el banco. Por favor, inténtalo de nuevo.');
    }
  }
};