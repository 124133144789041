// src/components/AdminLogin.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import FormLogin from './componentes/FormLogin';
import DecoLogin from './componentes/DecoLogin';
import Footer from '../common/Footer';

function AdminLogin() {
    return (
        <Container fluid className="p-0">
            <Row className="m-0">
                <Col md={7} className="p-0">
                    {/* Formulario de ingreso */}
                    <FormLogin />
                </Col>
                <Col md={4} className='d-none d-md-flex flex-column justify-content-center p-0'>
                    {/* Decoracion */}
                    <DecoLogin />
                </Col>
            </Row>
            <div className='mt-3'>
                <Footer />
            </div>
        </Container>
    );
}

export default AdminLogin;
