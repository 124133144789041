import React, { useState, useEffect } from 'react';
import { Card, Button, ButtonGroup } from 'react-bootstrap';
import { SeccionModal, EditSeccionModal, CategoriaModal, EditCategoriaModal } from './componentes/Modals';
import CategoriaCard from './componentes/categoriaCard';
import { fetchSecciones, fetchCategorias, deleteCategoria, deleteSeccion } from './Utils/ApiCategoria';
import { PencilFill, PlusCircleFill, EyeFill, EyeSlashFill, TrashFill } from 'react-bootstrap-icons';
import { handleDeleteClick, handleDeleteSeccionClick } from './Utils/AlertsCategorias';

function AdminCategorias() {
  const [secciones, setSecciones] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [expandedSecciones, setExpandedSecciones] = useState({});
  const [modalState, setModalState] = useState({
    showSeccionModal: false,
    showEditSeccionModal: false,
    showCategoriaModal: false,
    showEditModal: false,
    currentSeccion: { idSeccion: '', nomSeccion: '' },
    currentCategoria: { idCategoria: null, nomCategoria: '', idSeccion: '', imagenCategoria: null }
  });

  useEffect(() => {
    const loadData = async () => {
      const seccionesData = await fetchSecciones();
      const categoriasData = await fetchCategorias();
      setSecciones(seccionesData);
      setCategorias(categoriasData);
      const initialExpandedState = seccionesData.reduce((acc, seccion) => {
        acc[seccion.idSeccion] = false;
        return acc;
      }, {});
      setExpandedSecciones(initialExpandedState);
    };
    loadData();
  }, []);

  const handleOpenModal = (type, data = {}) => {
    if (type === 'showEditModal') {
      //console.log('Current categoria:', data.currentCategoria); // Verifica el objeto categoría completo
    }
    setModalState(prev => ({ ...prev, [type]: true, ...data }));
  };

  const handleCloseModal = (type) => setModalState(prev => ({ ...prev, [type]: false }));

  const toggleExpandSeccion = (idSeccion) => {
    setExpandedSecciones(prevState => ({
      ...prevState,
      [idSeccion]: !prevState[idSeccion]
    }));
  };

  return (
    <div className="px-4">
      <div className="mt-4">
        <div className='d-flex flex-column flex-md-row align-items-center justify-content-between mb-4'>
          <p className='mb-2 mb-md-0 text-muted'>Agrupa las categorías en secciones para tener todos tus productos ordenados.</p>
          <div className='d-flex mt-2 mt-md-0'>
            <Button variant="primary" onClick={() => handleOpenModal('showSeccionModal')} className="d-flex align-items-center me-2 shadow-sm">
              <PlusCircleFill className="me-2" /> Crear Sección
            </Button>
            <Button variant="secondary" onClick={() => handleOpenModal('showCategoriaModal')} className="d-flex align-items-center shadow-sm">
              <PlusCircleFill className="me-2" /> Crear Categoría
            </Button>
          </div>
        </div>
      </div>

      {secciones.map(seccion => (
        <Card className="mb-4 shadow-sm" key={seccion.idSeccion}>
          <Card.Header className="d-flex justify-content-between align-items-center">
            <div>
              <h4 className='text-primary'>{seccion.nomSeccion}</h4>
              <small className='text-muted'>Categorías: {seccion.totalCategorias}</small>
            </div>
            <ButtonGroup className="d-flex">
              <Button
                variant="link"
                className="me-2 d-flex align-items-center text-warning"
                onClick={() => handleOpenModal('showEditSeccionModal', { currentSeccion: seccion })}
              >
                <PencilFill size={18} />
              </Button>
              <Button
                variant="link"
                className="me-2 d-flex align-items-center text-danger"
                onClick={() => handleDeleteSeccionClick(seccion)}
              >
                <TrashFill size={18} />
              </Button>
              <Button
                variant="link"
                className="d-flex align-items-center text-success"
                onClick={() => toggleExpandSeccion(seccion.idSeccion)}
              >
                {expandedSecciones[seccion.idSeccion] ? <EyeFill size={18} /> : <EyeSlashFill size={18} />}
              </Button>
            </ButtonGroup>
          </Card.Header>
          {expandedSecciones[seccion.idSeccion] && (
            <Card.Body>
              <div className="row justify-content-center">
                {categorias
                  .filter(categoria => categoria.idSeccion === seccion.idSeccion)
                  .map(categoria => (
                    <CategoriaCard
                      key={categoria.idCategoria}
                      categoria={categoria}
                      handleEditClick={() => handleOpenModal('showEditModal', { currentCategoria: categoria })}
                      handleDeleteClick={() => handleDeleteClick(categoria)}
                    />
                  ))}
              </div>
            </Card.Body>
          )}
        </Card>
      ))}

      {/* Modales de Crear y Editar Sección/Categoría siguen igual */}
      <SeccionModal
        show={modalState.showSeccionModal}
        handleClose={() => handleCloseModal('showSeccionModal')}
        refreshSecciones={() => fetchSecciones().then(setSecciones)}
      />
      <EditSeccionModal
        show={modalState.showEditSeccionModal}
        handleClose={() => handleCloseModal('showEditSeccionModal')}
        seccion={modalState.currentSeccion}
        refreshSecciones={() => fetchSecciones().then(setSecciones)}
      />
      <CategoriaModal
        show={modalState.showCategoriaModal}
        handleClose={() => handleCloseModal('showCategoriaModal')}
        secciones={secciones}
        refreshCategorias={() => fetchCategorias().then(setCategorias)}
      />
      <EditCategoriaModal
        show={modalState.showEditModal}
        handleClose={() => handleCloseModal('showEditModal')}
        categoria={modalState.currentCategoria}
        secciones={secciones}
        refreshCategorias={() => fetchCategorias().then(setCategorias)}
      />
    </div>
  );
}

export default AdminCategorias;