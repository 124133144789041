import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { ArrowLeftCircleFill } from 'react-bootstrap-icons';
import NavbarUni from '../componentes/headUni';
import FooterPublic from '../FooterPublic';
import FloatingButton from '../FloatingButton';
import CategoriasUni from './componenetes/categoriasUni';
import Marquee from '../componentes/marquee';
import { useSucursal } from '../../../contexts/SucursalContext';

function AdminUnidad() {
    const { idSucursal: contextIdSucursal, loading: loadingSucursal } = useSucursal(); // Obtener idSucursal del contexto
    const { idSucursal: paramIdSucursal } = useParams(); // Obtener idSucursal desde los parámetros de la URL
    const [sucursalData, setSucursalData] = useState(null);
    const navigate = useNavigate();

    // Si el idSucursal en la URL es diferente al del contexto, actualizar el contexto o usar el de la URL
    const idSucursal = paramIdSucursal || contextIdSucursal;

    // Verificar el valor de idSucursal
    useEffect(() => {
     }, [idSucursal]); // Solo se ejecuta cuando idSucursal cambia

    return (
        <>
            <Marquee />

            {/* Contenido de la página */}
            <div>
                <NavbarUni />
                <Button variant="link" className="text1-cr text-decoration-none mt-3 mb-0 fw-bold" onClick={() => navigate(`/${idSucursal}`)}>
                    <ArrowLeftCircleFill className='me-2' size={18} /> Volver al inicio
                </Button>
                <div>
                    <CategoriasUni />
                </div>
                <FloatingButton />
                <FooterPublic />
            </div>
        </>
    );
}

export default AdminUnidad;
