// src/components/admin/ResetPassword.js
import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Button, Container, Card } from 'react-bootstrap';
import Swal from 'sweetalert2';  // Importamos SweetAlert2
import { TextField } from '@mui/material';
import { API } from '../../api/URL_API';

const ResetPassword = () => {
    const { token } = useParams();  // Capturamos el token de la URL
    const [newPassword, setNewPassword] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(`${API}/api/auth/reset-password`, {
                token,
                newPassword
            });

            // Reemplazamos el alert por SweetAlert2
            Swal.fire({
                title: '¡Éxito!',
                text: response.data.message,
                icon: 'success',
                confirmButtonText: 'Aceptar',
                iconColor: '#2fb380',
                confirmButtonColor: "#2c4cc4"
            }).then(() => {
                navigate('/login');  // Redirigir al login después de cerrar la alerta
            });
        } catch (error) {
            // Reemplazamos el alert por SweetAlert2
            Swal.fire({
                title: 'Error',
                text: error.response?.data?.message || 'Failed to reset password',
                icon: 'error',
                confirmButtonText: 'Aceptar',
                iconColor: '#da292e',
                confirmButtonColor: "#da292e"
            });
        }
    };

    return (
        <Container className="d-flex justify-content-center align-items-center min-vh-100">
            <Card className='rounded-4 border-0' style={{ maxWidth: '500px', width: '100%' }}>
                <Card.Body className='mx-4 mt-3'>
                    <h2 className='text-center fw-bold'>Restablecer contraseña</h2>
                    <small>Ingrese su nueva contraseña, esta se actualizará automaticamente.</small>
                    <form className='my-3' onSubmit={handleSubmit}>
                        <TextField
                            label="Nueva contraseña"
                            type='password'
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            fullWidth
                            variant='standard'
                            required
                        />
                        <Button type="submit" variant='primary' className='w-100 mt-4'>Restablecer Contraseña</Button>
                    </form>
                </Card.Body>
            </Card>
        </Container>
    );
};

export default ResetPassword;
