import React, { useState, useEffect } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { Boxes, Coin, Tags } from 'react-bootstrap-icons';
import APIserver from '../../../../api/axiosConfig';

function CardProducto() {
    const [totalItems, setTotalItems] = useState(0);
    const [totalSum, setTotalSum] = useState(0);
    const [totalRef, setTotalRef] = useState(0);
    const [totalSumM, setTotalSumM] = useState(0);

    useEffect(() => {
        fetchProductStats();
    }, []);

    const fetchProductStats = () => {
        APIserver.get(`/api/productosEstadisticas/stats`)
            .then(response => {
                setTotalItems(response.data.totalItems ?? 0);  // Asegura que nunca sea null
                setTotalSum(response.data.totalSum ?? 0);
                setTotalRef(response.data.totalRef ?? 0);
                setTotalSumM(response.data.totalSumM ?? 0);
            })
            .catch(error => {
                console.error('Error al obtener estadísticas de productos:', error);
            });
    };

    const formatNumber = (number) => {
        return (number ?? 0).toLocaleString('es-ES');  // Proporciona valor seguro
    };

    const formatCurrency = (number) => {
        return (number ?? 0).toLocaleString('es-ES', { style: 'currency', currency: 'COP' });
    };

    return (
        <div className='d-flex justify-content-center'>
            <Row className='text-center'>
                <Col xs={12} md={4} className='mb-4 d-flex justify-content-center'>
                    <Card bg='primary' text='white' style={{ width: '18rem' }}>
                        <Card.Body>
                            <Card.Text>
                                <span><Tags className='mx-2' />Total de referencias: <b>{formatNumber(totalRef)}</b></span>
                                <span><Boxes className='mx-2' />Total de items: <b>{formatNumber(totalItems)}</b></span>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>

                <Col xs={12} md={4} className='mb-4 d-flex justify-content-center'>
                    <Card bg='success' text='white' style={{ width: '18rem' }}>
                        <Card.Body>
                            <Card.Text>
                                <Coin className='mx-2' />Productos Unidad: <b>{formatCurrency(totalSum)}</b>
                            </Card.Text>  
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={4} className='mb-4 d-flex justify-content-center'>
                    <Card bg='primary' text='white' style={{ width: '18rem' }}>
                        <Card.Body>
                            <Card.Text>
                                <Coin className='mx-2' />Productos Al Por Mayor: <b>{formatCurrency(totalSumM)}</b>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default CardProducto;
