// src/hooks/useTiendaConfig.js
import { useState, useEffect } from 'react';
import { fetchSocialLinks, sendSocialLinkToBackend, updateSocialLinkInBackend, deleteSocialLinkFromBackend } from '../api/tiendaConfigAPI';
import axios from 'axios';
import Swal from 'sweetalert2';
import { API } from '../api/URL_API';

export const useTiendaConfig = () => {
    const [socialLinks, setSocialLinks] = useState([]);
    const [newLink, setNewLink] = useState({ title: '', url: '' });
    const [editIndex, setEditIndex] = useState(-1);
    const [companyInfo, setCompanyInfo] = useState({});
    const [storeInfo, setStoreInfo] = useState({});

    useEffect(() => {
        const getLinks = async () => {
            try {
                const links = await fetchSocialLinks();
                setSocialLinks(links);
            } catch (error) {
                console.error('Error al obtener los enlaces:', error);
            }
        };

        const fetchCompanyInfo = async () => {
            try {
                const response = await axios.get(`${API}/api/company`);
                setCompanyInfo(response.data);
            } catch (error) {
                console.error('Error al obtener la información de la empresa:', error);
            }
        };

        const fetchStoreInfo = async () => {
            try {
                const response = await axios.get(`${API}/api/store`);
                setStoreInfo(response.data);
            } catch (error) {
                console.error('Error al obtener la información de la tienda:', error);
            }
        };

        getLinks();
        fetchCompanyInfo();
        fetchStoreInfo();
    }, []);

    const addLink = async () => {
        if (socialLinks.length < 5 && newLink.title && newLink.url) {
            try {
                await sendSocialLinkToBackend(newLink);
                // Volver a cargar los enlaces después de añadir uno nuevo
                await fetchSocialLinks().then((links) => {
                    setSocialLinks(links);
                });
                setNewLink({ title: '', url: '' });
                Swal.fire({
                    title: 'Éxito',
                    text: 'La red social se ha añadido correctamente.',
                    icon: 'success',
                    confirmButtonText: 'Aceptar',
                    iconColor: '#2fb380',
                    confirmButtonColor: "#2c4cc4"
                });
            } catch (error) {
                console.error('Error al añadir el enlace:', error);
                Swal.fire({
                    title: 'Error',
                    text: 'Hubo un problema al añadir el enlace.',
                    icon: 'error',
                    confirmButtonText: "Aceptar",
                    iconColor: '#da292e',
                    confirmButtonColor: "#da292e"
                });
            }
        }
    };

    const editLink = (index) => {
        setNewLink(socialLinks[index]);
        setEditIndex(index);
    };

    const saveLink = async () => {
        if (editIndex !== -1) {
            const updatedLink = { ...newLink, id: socialLinks[editIndex].id };
            try {
                await updateSocialLinkInBackend(updatedLink);
                // Volver a cargar los enlaces después de actualizar uno
                await fetchSocialLinks().then((links) => {
                    setSocialLinks(links);
                });
                setNewLink({ title: '', url: '' });
                setEditIndex(-1);
                Swal.fire({
                    title: 'Éxito',
                    text: 'El enlace se ha guardado correctamente.',
                    icon: 'success',
                    confirmButtonText: "Aceptar",
                    iconColor: '#2fb380',
                    confirmButtonColor: "#2c4cc4"
                });
            } catch (error) {
                console.error('Error al guardar el enlace:', error);
                Swal.fire({
                    title: 'Error',
                    text: 'Hubo un problema al guardar el enlace.',
                    icon: 'error',
                    confirmButtonText: 'Aceptar',
                    iconColor: '#da292e',
                    confirmButtonColor: "#da292e"
                });
            }
        }
    };

    const cancelEdit = () => {
        setNewLink({ title: '', url: '' });
        setEditIndex(-1);
    };

    const deleteLink = async (id) => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: "Esta acción no se puede deshacer.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2c4cc4',
            cancelButtonColor: '#da292e',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar',
            iconColor: "#f4bd61"
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await deleteSocialLinkFromBackend(id);
                    // Volver a cargar los enlaces después de eliminar uno
                    await fetchSocialLinks().then((links) => {
                        setSocialLinks(links);
                    });
                    Swal.fire({
                        title: 'Eliminado',
                        text: 'El enlace ha sido eliminado.',
                        icon: 'success',
                        iconColor: '#2fb380',
                        confirmButtonColor: "#2c4cc4",
                        confirmButtonText: "Aceptar"
                    });
                } catch (error) {
                    console.error('Error al eliminar el enlace:', error);
                    Swal.fire({
                        title: 'Error',
                        text: 'Hubo un problema al eliminar el enlace.',
                        icon: 'error',
                        iconColor: '#da292e',
                        confirmButtonColor: "#da292e",
                        confirmButtonText: "Aceptar"
                    });
                }
            }
        });
    };

    const updateBannerImage = async (imageFile) => {
        const formData = new FormData();
        formData.append('image', imageFile);

        try {
            const response = await axios.post(`${API}/api/store/banner`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            Swal.fire({
                title: 'Éxito',
                text: 'La imagen del banner se ha actualizado correctamente.',
                icon: 'success',
                confirmButtonText: "Aceptar",
                iconColor: '#2fb380',
                confirmButtonColor: "#2c4cc4"
            });
            return response.data.imageUrl;
        } catch (error) {
            console.error('Error al actualizar la imagen de banner:', error);
            Swal.fire({
                title: 'Error',
                text: 'Hubo un problema al actualizar la imagen del banner.',
                icon: 'error',
                confirmButtonText: 'Aceptar',
                iconColor: '#da292e',
                confirmButtonColor: "#da292e"
            });
            throw error;
        }
    };

    const updateProfileImage = async (imageFile) => {
        const formData = new FormData();
        formData.append('image', imageFile);

        try {
            const response = await axios.post(`${API}/api/store/profile`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            Swal.fire({
                title: 'Éxito',
                text: 'La imagen de perfil se ha actualizado correctamente.',
                icon: 'success',
                confirmButtonText: 'Aceptar',
                iconColor: '#2fb380',
                confirmButtonColor: "#2c4cc4"
            });
            return response.data.imageUrl;
        } catch (error) {
            console.error('Error al actualizar la imagen de perfil:', error);
            Swal.fire({
                title: 'Error',
                text: 'Hubo un problema al actualizar la imagen de perfil.',
                icon: 'error',
                confirmButtonText: 'Aceptar',
                iconColor: '#da292e',
                confirmButtonColor: "#da292e"
            });
            throw error;
        }
    };

    const updateDescription = async (newDescription) => {
        try {
            await axios.put(`${API}/api/store/description`, { descripcionTienda: newDescription });
            setStoreInfo({ ...storeInfo, descripcionTienda: newDescription });
            Swal.fire({
                title: 'Éxito',
                text: 'La descripción de la tienda se ha actualizado correctamente.',
                icon: 'success',
                confirmButtonText: 'Acpetar',
                iconColor: '#2fb380',
                confirmButtonColor: "#2c4cc4"
            });
        } catch (error) {
            console.error('Error al actualizar la descripción:', error);
            Swal.fire({
                title: 'Error',
                text: 'Hubo un problema al actualizar la descripción de la tienda.',
                icon: 'error',
                confirmButtonText: 'Aceptar',
                iconColor: '#da292e',
                confirmButtonColor: "#da292e"
            });
        }
    };

    return {
        socialLinks,
        newLink,
        editIndex,
        companyInfo,
        storeInfo,
        setNewLink,
        addLink,
        editLink,
        saveLink,
        cancelEdit,
        deleteLink,
        updateBannerImage,
        updateProfileImage,
        updateDescription,
        setStoreInfo
    };
};
