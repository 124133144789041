import React, { useState, useCallback, useEffect } from 'react';
import { Card, Table, Form, Button, InputGroup, Modal } from 'react-bootstrap';
import { PlusCircleFill, Search, TrashFill } from 'react-bootstrap-icons';
import AlertsFacturas from '../Utils/AlertsFacturas';
import APIserver from '../../../../api/axiosConfig';

const ProductoRow = React.memo(({ producto, onChange, onRemove, onSearch, tipoFactura, onReferenciaEnter }) => {
    const handleChange = (e) => {
        const { name, value } = e.target;
        onChange(producto.idDetallesFacV, name, value);
    };

    const handleSearchClick = () => {
        onSearch(producto.idDetallesFacV);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            onReferenciaEnter(producto.idDetallesFacV, producto.referencia);
        }
    };

    const calculateTotal = () => {
        const cantidad = parseFloat(producto.cantidad) || 0;
        const valorUnitario = parseFloat(producto.valorUnitario) || 0;
        const total = (cantidad * valorUnitario).toFixed(2);
        return new Intl.NumberFormat('es-CO', {
            style: 'currency',
            currency: 'COP',
            maximumFractionDigits: 0, // Mostrar sin decimales
        }).format(total);
    };

    return (
        <tr>
            <td>
                <Form.Control
                    type="text"
                    name="referencia"
                    value={producto.referencia}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    placeholder="Referencia"
                />
            </td>
            <td>
                <InputGroup>
                    <Form.Control
                        type="text"
                        name="nomProducto"
                        value={producto.nomProducto}
                        onChange={handleChange}
                        placeholder="Nombre"
                        readOnly
                    />
                    <Button variant='outline-success' size='sm' title='Buscar producto' onClick={handleSearchClick}>
                        <Search />
                    </Button>
                </InputGroup>
            </td>
            <td>
                <Form.Control
                    type="number"
                    name="cantidad"
                    value={producto.cantidad}
                    onChange={handleChange}
                    placeholder="Cantidad"
                />
            </td>
            <td>
                <Form.Control
                    type="number"
                    name="valorUnitario"
                    value={producto.valorUnitario}
                    onChange={handleChange}
                    placeholder="Valor Unitario"
                    readOnly
                />
            </td>
            <td>
                <Form.Control
                    type="text"
                    value={calculateTotal()}
                    placeholder="Total"
                    disabled
                />
            </td>
            <td>
                <Button variant='danger' onClick={() => onRemove(producto.idDetallesFacV)}>
                    <TrashFill />
                </Button>
            </td>
        </tr>
    );
});

const EditarProductoFactura = ({ idFactura, setProductos, tipoFactura, setCosEnvio, setDescuento, updateTotalfacv }) => {
    const [localProductos, setLocalProductos] = useState([]);
    const [cosEnvio, setEnvio] = useState(0);
    const [descuento, setDescuentoLocal] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentProductId, setCurrentProductId] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            if (!idFactura) {
                console.error('ID de factura no definido.');
                return;
            }

            try {
                const response = await APIserver.get(`/api/actualizarv/datos/facturas/${idFactura}`);
                const factura = response.data;

                const productosConDatos = factura.productos.map(detalle => ({
                    idDetallesFacV: detalle.idDetallesFacV,
                    referencia: detalle.referencia || '',
                    nomProducto: detalle.nombreProducto || '',
                    cantidad: detalle.cantidad || 0,
                    cantidadOriginal: detalle.cantidad || 0, // Almacena la cantidad original
                    valorUnitario: tipoFactura === 'distribucion' ? detalle.precioDistribucion : detalle.precioUnitario,
                    idProducto: detalle.idProducto,
                    idSucursal: factura.idSucursal
                }));

                setLocalProductos(productosConDatos);
                setEnvio(factura.cosEnvio || 0);
                setCosEnvio(factura.cosEnvio || 0);
                setDescuentoLocal(factura.descuento || 0);
                setDescuento(factura.descuento || 0);
            } catch (error) {
                console.error('Error al obtener los datos:', error);
            }
        };

        fetchData();
    }, [idFactura, tipoFactura, setCosEnvio, setDescuento]);


    useEffect(() => {
        setProductos(localProductos);
        updateTotalfacv(calculateTotal()); // Actualiza el total cuando cambian los productos
    }, [localProductos, setProductos, updateTotalfacv]);

    const handleAddRow = () => {
        setLocalProductos(prevProductos => [
            ...prevProductos,
            {
                referencia: '',
                nomProducto: '',
                cantidad: 1,
                valorUnitario: 0,
                idProducto: '',
                idSucursal: ''
            }
        ]);
    };

    const handleRemoveRow = (idDetallesFacV) => {
        setLocalProductos(prevProductos => prevProductos.filter(p => p.idDetallesFacV !== idDetallesFacV));
    };

    const handleChange = useCallback(async (idDetallesFacV, name, value) => {
        setLocalProductos(prevProductos => {
            const updatedProductos = prevProductos.map(p =>
                p.idDetallesFacV === idDetallesFacV ? { ...p, [name]: value } : p
            );

            if (name === 'cantidad') {
                const producto = updatedProductos.find(p => p.idDetallesFacV === idDetallesFacV);
                if (producto && producto.idProducto) {
                    (async () => {
                        try {
                            const response = await APIserver.get(`/api/productos/${producto.idProducto}`);
                            const cantidadDisponible = response.data.stock;

                            // Usa la cantidad original para calcular la diferencia
                            const productoOriginal = prevProductos.find(p => p.idDetallesFacV === idDetallesFacV);
                            const cantidadOriginal = parseInt(productoOriginal.cantidadOriginal, 10) || 0;
                            const cantidadNueva = parseInt(value, 10) || 0;

                            const diferencia = cantidadNueva - cantidadOriginal;

                            if (diferencia > cantidadDisponible) {
                                AlertsFacturas.showStockAlert(cantidadDisponible);
                                setLocalProductos(prevProductos.map(p =>
                                    p.idDetallesFacV === idDetallesFacV ? { ...p, cantidad: cantidadOriginal } : p
                                ));
                            }
                        } catch (error) {
                            console.error('Error al obtener el stock del producto:', error);
                        }
                    })();
                }
            }

            return updatedProductos;
        });
    }, []);

    const handleReferenciaEnter = async (id, referencia) => {
        try {
            const productoResponse = await APIserver.get(`/api/referencia/${referencia}`);
            const producto = productoResponse.data;

            if (!producto) {
                AlertsFacturas.showErrorEncontrar('Producto no encontrado. Verifique la referencia.');
                return;
            }

            const stockResponse = await APIserver.get(`/api/productos/${producto.idProducto}`);
            const cantidadDisponible = stockResponse.data.stock;

            setLocalProductos(prevProductos => {
                const indexProductoExistente = prevProductos.findIndex(p => p.referencia === referencia && p.idDetallesFacV !== id);

                if (indexProductoExistente !== -1) {
                    const productosActualizados = prevProductos.map(p => {
                        if (p.idDetallesFacV === prevProductos[indexProductoExistente].idDetallesFacV) {
                            const nuevaCantidad = parseInt(p.cantidad) + 1;
                            const cantidadActualTotal = prevProductos.reduce((total, prod) => {
                                return total + (prod.idDetallesFacV === p.idDetallesFacV ? parseInt(prod.cantidad) : 0);
                            }, 0);

                            if (cantidadActualTotal <= cantidadDisponible) {
                                return {
                                    ...p,
                                    cantidad: nuevaCantidad
                                };
                            } else {
                                AlertsFacturas.showStockAlert(cantidadDisponible);
                                return p;
                            }
                        } else if (p.idDetallesFacV === id) {
                            return {
                                ...p,
                                referencia: '',
                                nomProducto: '',
                                cantidad: 1,
                                valorUnitario: 0,
                                idProducto: '',
                                idSucursal: ''
                            };
                        }
                        return p;
                    });
                    return productosActualizados;
                } else {
                    const updatedProductos = prevProductos.map(p => p.idDetallesFacV === id ? {
                        ...p,
                        referencia: producto.referencia,
                        nomProducto: producto.nomProducto,
                        valorUnitario: tipoFactura === 'distribucion' ? producto.precioM : producto.precioU,
                        idProducto: producto.idProducto,
                        idSucursal: producto.idSucursal
                    } : p);

                    if (producto.referencia) {
                        return [
                            ...updatedProductos,
                            {
                                referencia: '',
                                nomProducto: '',
                                cantidad: 1,
                                valorUnitario: 0,
                                idProducto: '',
                                idSucursal: '',
                                idDetallesFacV: Date.now()
                            }
                        ];
                    } else {
                        return updatedProductos;
                    }
                }
            });
        } catch (error) {
            AlertsFacturas.showErrorEncontrar();
            console.error('Error al buscar producto por referencia:', error);
        }
    };

    const handleSearch = (idDetallesFacV) => {
        setCurrentProductId(idDetallesFacV);
        setShowModal(true);
    };

    const handleSearchQueryChange = async (e) => {
        const query = e.target.value;
        setSearchQuery(query);
        try {
            const response = await APIserver.get(`/api/info/productos`, {
                params: { nombre: query, tipoFactura }
            });

            setSearchResults(response.data);
        } catch (error) {
            console.error('Error al buscar productos:', error);
        }
    };

    const handleSelectProduct = async (product) => {
        try {
            const stockResponse = await APIserver.get(`/api/productos/${product.idProducto}`);
            const cantidadDisponible = stockResponse.data.stock;

            setLocalProductos(prevProductos => {
                const productoExistente = prevProductos.find(p => p.referencia === product.referencia && p.idDetallesFacV !== currentProductId);

                if (productoExistente) {
                    const updatedProductos = prevProductos.map(p => {
                        if (p.idDetallesFacV === productoExistente.idDetallesFacV) {
                            const nuevaCantidad = (parseInt(p.cantidad, 10) || 0) + 1;
                            const cantidadActualTotal = prevProductos.reduce((total, prod) => {
                                return total + (prod.idDetallesFacV === productoExistente.idDetallesFacV ? parseInt(prod.cantidad, 10) : 0);
                            }, 0);

                            if (cantidadActualTotal <= cantidadDisponible) {
                                return {
                                    ...p,
                                    cantidad: nuevaCantidad
                                };
                            } else {
                                AlertsFacturas.showStockAlert(cantidadDisponible);
                                return p;
                            }
                        } else if (p.idDetallesFacV === currentProductId) {
                            return {
                                ...p,
                                referencia: '',
                                nomProducto: '',
                                valorUnitario: 0,
                                idProducto: '',
                                idSucursal: '',
                                cantidad: 1
                            };
                        }
                        return p;
                    });
                    return updatedProductos;
                } else {
                    const updatedProductos = prevProductos.map(p => p.idDetallesFacV === currentProductId ? {
                        ...p,
                        referencia: product.referencia,
                        nomProducto: product.nomProducto,
                        valorUnitario: tipoFactura === 'distribucion' ? product.precioM : product.precioU,
                        idProducto: product.idProducto,
                        idSucursal: product.idSucursal,
                        cantidad: 1
                    } : p);
                    return [
                        ...updatedProductos,
                        {
                            referencia: '',
                            nomProducto: '',
                            cantidad: 1,
                            valorUnitario: 0,
                            idProducto: '',
                            idSucursal: '',
                            idDetallesFacV: Date.now()
                        }
                    ];
                }
            });
            setShowModal(false);
        } catch (error) {
            console.error('Error al verificar el stock del producto:', error);
        }
    };

    const handleEnvioChange = (e) => {
        const nuevoEnvio = parseFloat(e.target.value) || 0;
        setEnvio(nuevoEnvio);
        setCosEnvio(nuevoEnvio);
    };

    const handleDescuentoChange = (e) => {
        const nuevoDescuento = parseFloat(e.target.value) || 0;
        setDescuentoLocal(nuevoDescuento);
        setDescuento(nuevoDescuento);
    };

    const calculateSubtotal = () => {
        const subtotal = localProductos.reduce((total, producto) => {
            const cantidad = parseFloat(producto.cantidad) || 0;
            const valorUnitario = parseFloat(producto.valorUnitario) || 0;
            const productoTotal = cantidad * valorUnitario;
            return total + productoTotal;
        }, 0).toFixed(2);
        return subtotal;
    };

    const formatCurrency = (value) => new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        maximumFractionDigits: 0,
    }).format(value);

    const calculateTotalItems = () => {
        return localProductos.reduce((total, producto) => total + (parseInt(producto.cantidad) || 0), 0);
    };

    const calculateTotal = useCallback(() => {
        const subtotal = parseFloat(calculateSubtotal());
        const descuentoTotal = subtotal * (descuento / 100);
        const total = (subtotal + cosEnvio - descuentoTotal).toFixed(2);
        return total;
    }, [calculateSubtotal, cosEnvio, descuento]);

    return (
        <>
            <Card className='mt-2 shadow-none'>
                <Card.Header className='fw-bold'>Productos</Card.Header>
                <Table responsive className='shadow-none text-center'>
                    <thead className='bg-primary-subtle'>
                        <tr>
                            <th>Referencia</th>
                            <th>Nombre</th>
                            <th>Cant.</th>
                            <th>Valor Unitario</th>
                            <th>Total</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {localProductos.map(producto => (
                            <ProductoRow
                                key={producto.idDetallesFacV}
                                producto={producto}
                                onChange={handleChange}
                                onRemove={handleRemoveRow}
                                onSearch={handleSearch}
                                tipoFactura={tipoFactura}
                                onReferenciaEnter={handleReferenciaEnter}
                            />
                        ))}
                        <tr>
                            <td colSpan="6" className='text-start'>
                                <Button variant='outline-success' onClick={handleAddRow}>
                                    <PlusCircleFill size={18} className='me-2' /> Agregar Línea
                                </Button>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="5" className='text-end fw-bold'>Subtotal:</td>
                            <td colSpan="2" className='text-start'>{formatCurrency(calculateSubtotal())}</td>
                        </tr>
                        <tr>
                            <td colSpan="5" className='text-end fw-bold'>Descuento:</td>
                            <td colSpan="2" className='text-start'>
                                <InputGroup size='sm'>
                                    <InputGroup.Text>%</InputGroup.Text>
                                    <Form.Control
                                        type="number"
                                        value={descuento}
                                        onChange={handleDescuentoChange}
                                        placeholder="Descuento"
                                    />
                                </InputGroup>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="5" className='text-end fw-bold'>Items:</td>
                            <td colSpan="2" className='text-start'>{calculateTotalItems()}</td>
                        </tr>
                        <tr>
                            <td colSpan="5" className='text-end fw-bold'>Envio:</td>
                            <td colSpan="2" className='text-start'>
                                <InputGroup size='sm'>
                                    <InputGroup.Text>$</InputGroup.Text>
                                    <Form.Control
                                        type="number"
                                        value={cosEnvio}
                                        onChange={handleEnvioChange}
                                        placeholder="Envio"
                                    />
                                </InputGroup>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="5" className='text-end fw-bold'>Total:</td>
                            <td colSpan="2" className='text-start'>{formatCurrency(calculateTotal())}</td>
                        </tr>
                    </tbody>
                </Table>

                <Modal show={showModal} size='lg' onHide={() => setShowModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Buscar Producto</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Control
                            type="text"
                            value={searchQuery}
                            onChange={handleSearchQueryChange}
                            placeholder="Escriba el nombre del producto"
                        />
                        <Table responsive className='shadow-none text-center mt-3'>
                            <thead className='bg-primary-subtle'>
                                <tr>
                                    <th>Referencia</th>
                                    <th>Nombre</th>
                                    <th>Stock</th>
                                    <th>Valor Unitario</th>
                                    <th>Valor Distribución</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {searchResults.length ? searchResults.map(producto => (
                                    <tr key={producto.idProducto}>
                                        <td>{producto.referencia}</td>
                                        <td>{producto.nomProducto}</td>
                                        <td>{producto.stock}</td>
                                        <td>{formatCurrency(producto.precioU)}</td>
                                        <td>{formatCurrency(producto.precioM)}</td>
                                        <td>
                                            <Button variant='outline-success' size='sm' onClick={() => handleSelectProduct(producto)}
                                                disabled={producto.stock <= 0}>
                                                Seleccionar
                                            </Button>
                                        </td>
                                    </tr>
                                )) : (
                                    <tr>
                                        <td colSpan="5">No se encontraron resultados</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowModal(false)}>
                            Cerrar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Card>
        </>
    );
};

export default EditarProductoFactura;
