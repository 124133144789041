import React, { useState } from 'react';
import { FormControl, Button, ButtonGroup } from 'react-bootstrap';
import { Search, XSquare } from 'react-bootstrap-icons';

function FiltrosBancos({ onSearch, onClear }) {
    const [filters, setFilters] = useState({
        nomBanco: '',
        tipoCuenta: '',
        numeroCuenta: '',
        saldoBanco: '',
        ingresosBanco: '',
        egresosBanco: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFilters({
            ...filters,
            [name]: value
        });
    };


    const handleSearch = () => {
        onSearch(filters);
    };

    const handleClear = () => {
        setFilters({
            nomBanco: '',
            tipoCuenta: '',
            numeroCuenta: '',
            saldoBanco: '',
            ingresosBanco: '',
            egresosBanco: ''
        });
        onClear();
    };

    return (
        <div className='d-flex align-items-center m-3'>
            <FormControl
                name='nomBanco'
                value={filters.nomBanco}
                onChange={handleInputChange}
                placeholder='Busca por Nombre'
                className='me-2'
            />
            <FormControl
                name='tipoCuenta'
                value={filters.tipoCuenta}
                onChange={handleInputChange}
                placeholder='Filtra por Cuenta'
                className='me-2'
            />
            <FormControl
                name='numeroCuenta'
                value={filters.numeroCuenta}
                onChange={handleInputChange}
                placeholder='Filtra por Numero'
                className='me-2'
            />
            <FormControl
                name='saldoBanco'
                value={filters.saldoBanco}
                onChange={handleInputChange}
                placeholder='Filtra por Saldo'
                className='me-2'
            />
            <FormControl
                name='ingresosBanco'
                value={filters.ingresosBanco}
                onChange={handleInputChange}
                placeholder='Filtra por Ingresos'
                className='me-2'
            />
            <FormControl
                name='egresosBanco'
                value={filters.egresosBanco}
                onChange={handleInputChange}
                placeholder='Filtra por Egresos'
                className='me-2'
            />
            <ButtonGroup>
                <Button variant='primary' title='Buscar' onClick={handleSearch}>
                    <Search />
                </Button>
                <Button variant='danger' title='Limpiar' onClick={handleClear}>
                    <XSquare />
                </Button>
            </ButtonGroup>
        </div>
    );
}

export default FiltrosBancos;
