import React, { useState, useEffect } from 'react';
import { Form, Row, Col, InputGroup, Button, Modal, Table } from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons';
import Select from 'react-select';
import axios from 'axios';
import APIserver from '../../../../api/axiosConfig';

function ClienteFactura({ setClienteID, setVenceDate, setTipoFactura, setAsesor, setCreacionDate, pedidoId }) {
    const [idCliente, setIdCliente] = useState('');
    const [clienteID, setClienteIDs] = useState('');
    const [nomCliente, setNomCliente] = useState('');
    const [creacionDate, setCreacionDateState] = useState('');
    const [venceDate, setVenceDateState] = useState('');
    const [tipoFactura, setTipoFacturaState] = useState('');
    const [clientes, setClientes] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [selectedCliente, setSelectedCliente] = useState(null);
    const [asesores, setAsesores] = useState([]);
    const [selectedAsesor, setSelectedAsesor] = useState(null);

    useEffect(() => {
        // Establecer la fecha actual al cargar el componente
        const today = new Date();
        const yyyy = today.getFullYear();
        const mm = String(today.getMonth() + 1).padStart(2, '0');
        const dd = String(today.getDate()).padStart(2, '0');
        const todayDate = `${yyyy}-${mm}-${dd}`;
        
        setCreacionDateState(todayDate); // Fecha de elaboración
        setVenceDateState(todayDate);    // Fecha de vencimiento

        // Cargar asesores al iniciar
        fetchAsesores();
    }, []);

    useEffect(() => {
        setTipoFactura(tipoFactura); // Actualiza tipoFactura en el componente principal
    }, [tipoFactura, setTipoFactura]);

    useEffect(() => {
        if (pedidoId) {
            fetchPedidoData(pedidoId);
        }
    }, [pedidoId]);

    const fetchPedidoData = async (id) => {
        try {
            const response = await APIserver.get(`/api/facturarPedidos/pedidos/${id}`);
            if (response.status === 200) {
                const pedido = response.data;
                setIdCliente(pedido.idCliente);
                setClienteIDs(pedido.clienteID);
                setNomCliente(pedido.nomCliente);
                setTipoFacturaState(pedido.tipo);
                setSelectedAsesor({ value: pedido.idSucursal, label: pedido.nomSucursal });
                setAsesor(pedido.idSucursal);
            }
        } catch (error) {
            console.error('Error al cargar el pedido:', error);
        }
    };

    const optionsPedido = [
        { value: 'distribucion', label: 'Distribución' },
        { value: 'unidad', label: 'Unidad' }
    ];

    const searchClientes = async (nombre) => {
        try {
            const response = await APIserver.get(`/api/nombre/buscar?nombre=${nombre}`);
            setSearchResults(response.data);
        } catch (error) {
            console.error('Error al buscar clientes:', error);
        }
    };

    const handleIdClienteChange = async (e) => {
        const id = e.target.value;
        setIdCliente(id);
        setClienteID(id);

        if (id.length === 10) {
            try {
                const response = await APIserver.get(`/api/buscarClientes/${id}`);
                if (response.status === 200) {
                    setNomCliente(response.data.nomCliente || '');
                }
            } catch (error) {
                if (error.response && error.response.status === 404) {
                    setNomCliente('Cliente no encontrado');
                } else {
                    console.error('Error al buscar el cliente:', error);
                }
            }
        } else {
            setNomCliente('');
        }
    };

    const handleNomClienteChange = (selectedOption) => {
        if (selectedOption) {
            setIdCliente(selectedOption.value);
            setNomCliente(selectedOption.label);
            setShowModal(false);
            setClienteID(selectedOption.value);
        }
    };

    const fetchAsesores = async () => {
        try {
            const response = await APIserver.get(`/api/nombre/asesores`);
            const options = response.data.map(asesor => ({
                value: asesor.idSucursal,
                label: asesor.nomSucursal
            }));
            setAsesores(options);
        } catch (error) {
            console.error('Error al obtener asesores:', error);
        }
    };

    const handleAsesorChange = (selectedOption) => {
        setSelectedAsesor(selectedOption);
        setAsesor(selectedOption ? selectedOption.value : '');
    };

    const handleSearchClick = () => {
        searchClientes(nomCliente);
        setShowModal(true);
    };

    const handleSelectCliente = (cliente) => {
        setIdCliente(cliente.idCliente);
        setClienteIDs(cliente.clienteID);
        setNomCliente(cliente.nomCliente);
        setShowModal(false);
        setClienteID(cliente.idCliente);
    };

    const handleTipoFacturaChange = (selectedOption) => {
        const selectedValue = selectedOption ? selectedOption.value : '';
        setTipoFacturaState(selectedValue);
        setTipoFactura(selectedValue); // Actualiza tipoFactura en el componente principal
    };

    return (
        <>
            <Form>
                <Row>
                    <Col>
                        <Form.Group className='mt-0' controlId='idCliente'>
                            <Form.Label className='w-100'>NIT
                                <Form.Control
                                    type='number'
                                    name='idCliente'
                                    placeholder='Identificación del cliente'
                                    className='mt-2'
                                    value={clienteID}
                                    onChange={handleIdClienteChange}
                                    required
                                />
                            </Form.Label>
                        </Form.Group>
                        <Form.Group className='mt-2' controlId='venceFactura'>
                            <Form.Label className='w-100'>Vence
                                <Form.Control 
                                    type='date' 
                                    name='venceFactura' 
                                    className='mt-2' 
                                    value={venceDate} 
                                    required 
                                    onChange={(e) => {
                                        const selectedDate = e.target.value;
                                        setVenceDateState(selectedDate);
                                        setVenceDate(selectedDate);
                                    }} 
                                />
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mt-0" controlId="nomCliente">
                            <Form.Label>Cliente</Form.Label>
                            <InputGroup className="mb-3">
                                <Form.Control
                                    type='text'
                                    placeholder='Nombre del cliente'
                                    value={nomCliente}
                                    onChange={(e) => setNomCliente(e.target.value)}
                                />
                                <Button variant='outline-success' title='Buscar cliente' onClick={handleSearchClick}>
                                    <Search />
                                </Button>
                            </InputGroup>
                        </Form.Group>

                        <Form.Group className='mt-2' controlId='tipoFactura'>
                            <Form.Label className='w-100'>Tipo
                                <Select
                                    placeholder='Tipo de Factura'
                                    name='tipoFactura'
                                    className='mt-2'
                                    options={optionsPedido}
                                    value={optionsPedido.find(option => option.value === tipoFactura)}
                                    onChange={handleTipoFacturaChange}
                                    isDisabled 
                                    required
                                />
                            </Form.Label>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className='mt-0' controlId='creacionFactura'>
                            <Form.Label className='w-100'>Elaboración
                                <Form.Control 
                                    type='date' 
                                    name='creacionFactura' 
                                    className='mt-2' 
                                    value={creacionDate} 
                                    required 
                                    onChange={(e) => {
                                        const selectedDate = e.target.value;
                                        setCreacionDateState(selectedDate);
                                        setCreacionDate(selectedDate);
                                    }} 
                                />
                            </Form.Label>
                        </Form.Group>
                        <Form.Group className='mt-2' controlId='nomSucursal'>
                            <Form.Label className='w-100'>Vendedor
                                <Select
                                    placeholder='Seleccione Asesor'
                                    name='nomSucursal'
                                    className='mt-2'
                                    options={asesores}
                                    value={selectedAsesor}
                                    onChange={handleAsesorChange}
                                    required
                                />
                            </Form.Label>
                        </Form.Group>
                    </Col>
                </Row>
            </Form>

            {/* Modal de búsqueda de clientes */}
            <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Seleccionar Cliente</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InputGroup className="mb-3">
                        <Form.Control
                            type='text'
                            placeholder='Buscar cliente por nombre'
                            value={nomCliente}
                            onChange={(e) => {
                                setNomCliente(e.target.value);
                                searchClientes(e.target.value);
                            }}
                        />
                    </InputGroup>
                    <Table responsive className='shadow-none text-center mt-3'>
                        <thead className='bg-primary-subtle'>
                            <tr>
                                <th>NIT</th>
                                <th>Nombre</th>
                                <th>Acción</th>
                            </tr>
                        </thead>
                        <tbody>
                            {searchResults.length === 0 ? (
                                <tr>
                                    <td colSpan="3">Cliente no encontrado</td>
                                </tr>
                            ) : (
                                searchResults.map(cliente => (
                                    <tr key={cliente.idCliente}>
                                        <td>{cliente.clienteID}</td>
                                        <td>{cliente.nomCliente}</td>
                                        <td>
                                            <Button variant='outline-success' size='sm' onClick={() => handleSelectCliente(cliente)}>
                                                Seleccionar
                                            </Button>
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ClienteFactura;
