// src/api/companyConfigApi.js
import axios from 'axios';
import { API } from './URL_API';

export const fetchCompanyInfo = async () => {
    const response = await axios.get(`${API}/api/companyConfig`);
    return response.data;
};

export const updateCompanyInfo = async (companyData) => {
    const response = await axios.put(`${API}/api/companyConfig`, companyData);
    return response;  // Devuelve toda la respuesta en lugar de solo response.data
};

export const updateCompanyLogo = async (logoFile) => {
    const formData = new FormData();
    formData.append('logoEmpresa', logoFile);  // Solo envía la imagen, no el filename

    try {
        const response = await axios.post(`${API}/api/companyConfig/logo`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        return response.data;
    } catch (error) {
        console.error('Error al actualizar el logo de la empresa:', error);
        throw error;
    }
};