import React from 'react';
import { Table, Row, Col } from 'react-bootstrap';
import convertirMontoALetras from '../../../Pagos/componentes/comprobante/convertirMontoALetras'; // Importa la función personalizada

function InfoDetallesPagoRes({ pago }) {
    if (!pago) return null;

    const { monto, detalle } = pago;

    // Formato de moneda COP
    // Formato de moneda COP sin decimales
    const formatoCOP = new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });
    const montoEnLetras = convertirMontoALetras(monto);

    return (
        <div>
            <Row>
                <Col>
                    <Table bordered size='sm' className='shadow-none'>
                        <tbody>
                            <tr>
                                <th className='bg-primary-subtle'>El valor de</th>
                                <td>{montoEnLetras || 'N/A'}</td>
                                {/* Formateamos el monto en formato de moneda COP */}
                                <th>{monto !== undefined ? formatoCOP.format(monto) : 'N/A'}</th>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Row className='text-end'>
                <Col>
                    <h5 className='text-body-emphasis fw-bold'>Total COP</h5>
                    {/* Formateamos el monto en formato de moneda COP */}
                    <h5>{monto !== undefined ? formatoCOP.format(monto) : 'N/A'}</h5>
                </Col>
            </Row>
            <Row className='mt-4'>
                <Col className='border'>
                    <div className='m-2'>
                        <h6>Observaciones:</h6>
                        <p>{detalle || ''}</p>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default InfoDetallesPagoRes;
