// src/UsersRolesPermisos.js
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Users from './ususarios/users';
import RolesPermisos from './ususarios/rolesPermisos';

function UsersRolesPermisos({ loggedUser }) {
    return (
        <div className='m-4'>
            <Row>
                <Col>
                <Users loggedUser={loggedUser} />  {/* Pasa loggedUser al componente Users */}
                </Col>
            </Row>
            <Row>
                <Col>
                    {/* <RolesPermisos /> */}  {/* Comentar el componente RolesPermisos */}
                    </Col>
            </Row>
        </div>
    );
}

export default UsersRolesPermisos;
