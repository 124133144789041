import React, { useState, useRef } from 'react';
import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import Select from 'react-select';
import Departamentos from '../../../utils/places-colombia';
import axios from 'axios';
import Swal from 'sweetalert2';
import ImageCropperModal from './ImageCropperModal';
import { Pencil } from 'react-bootstrap-icons';
import { API } from '../../../api/URL_API';

function InfoEmpresa({ onNext }) {
    const [selectedDepartamento, setSelectedDepartamento] = useState(null);
    const [selectedCiudad, setSelectedCiudad] = useState(null);
    const [errors, setErrors] = useState({});
    const [showCropperModal, setShowCropperModal] = useState(false);
    const [tempImageFile, setTempImageFile] = useState(null);
    const [formData, setFormData] = useState({
        companyName: '',
        companyNit: '',
        companyEmail: '',
        companyPhone: '',
        companyAddress: '',
        companyDep: '',
        companyCity: '',
        companyLogo: null
    });
    const [cropperError, setCropperError] = useState('');
    const [ciudadesOptions, setCiudadesOptions] = useState([]);
    const fileInputRef = useRef(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
        const { files } = e.target;
        if (files && files[0]) {
            setTempImageFile(files[0]);
            setShowCropperModal(true);
        }
    };

    const handleDepartamentoChange = (selectedOption) => {
        setSelectedDepartamento(selectedOption);
        setFormData({ ...formData, companyDep: selectedOption.value });
        const departamento = Departamentos.find(dep => dep.departamento === selectedOption.value);
        const ciudades = departamento ? departamento.ciudades.map(ciudad => ({ value: ciudad, label: ciudad })) : [];
        setCiudadesOptions(ciudades);
        setSelectedCiudad(null);
    };

    const handleCiudadChange = (selectedOption) => {
        setSelectedCiudad(selectedOption);
        setFormData({ ...formData, companyCity: selectedOption.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (cropperError) {
            return;
        }

        try {
            const dataToSave = new FormData();
            Object.keys(formData).forEach(key => {
                dataToSave.append(key, formData[key]);
            });

            const response = await axios.post(`${API}/api/empresa`, dataToSave, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            Swal.fire({
                icon: 'success',
                title: 'Registro Exitoso',
                text: 'La empresa se ha registrado correctamente',
                confirmButtonText: 'Continuar'
            });

            const previousData = JSON.parse(localStorage.getItem('userFormData')) || {};
            localStorage.setItem('companyFormData', JSON.stringify({ ...previousData, ...response.data }));
            onNext();
        } catch (error) {
            const errorMessage = error.response && error.response.data && error.response.data.message;
            if (errorMessage) {
                const newErrors = {};
                if (errorMessage.includes('correo')) {
                    newErrors.companyEmail = errorMessage;
                }
                if (errorMessage.includes('NIT')) {
                    newErrors.companyNit = errorMessage;
                }
                setErrors(newErrors);
            } else {
                console.error('Error al enviar el formulario', error);
            }
        }
    };

    const handleCropComplete = (croppedImageFile) => {
        if (croppedImageFile) {
            setFormData({ ...formData, companyLogo: croppedImageFile });
            setCropperError('');
        }
        setShowCropperModal(false);
    };

    const handleCropCancel = (action) => {
        if (action === 'cancel') {
            setCropperError('El usuario no ha seleccionado una imagen.');
        }
        setShowCropperModal(false);
    };

    const departamentosOptions = Departamentos.map(departamento => ({ value: departamento.departamento, label: departamento.departamento }));

    return (
        <Container className='mt-4'>
            <h2>Registro de Empresa</h2>
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col>
                        <Form.Group controlId='companyNit' className='m-3'>
                            <Form.Label>NIT</Form.Label>
                            <Form.Control
                                type='text'
                                name='companyNit'
                                value={formData.companyNit}
                                onChange={handleChange}
                                required
                                isInvalid={!!errors.companyNit}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.companyNit}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId='companyPhone' className='m-3'>
                            <Form.Label>Teléfono</Form.Label>
                            <Form.Control
                                type='number'
                                name='companyPhone'
                                value={formData.companyPhone}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId='companyDep' className='m-3'>
                            <Form.Label>Departamento</Form.Label>
                            <Select
                                options={departamentosOptions}
                                value={selectedDepartamento}
                                onChange={handleDepartamentoChange}
                                placeholder='Seleccione su departamento'
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId='companyAddress' className='m-3'>
                            <Form.Label>Dirección</Form.Label>
                            <Form.Control
                                type='text'
                                name='companyAddress'
                                value={formData.companyAddress}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId='companyName' className='m-3'>
                            <Form.Label>Nombre de la Empresa</Form.Label>
                            <Form.Control
                                type='text'
                                name='companyName'
                                value={formData.companyName}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId='companyEmail' className='m-3'>
                            <Form.Label>Correo Electrónico</Form.Label>
                            <Form.Control
                                type='email'
                                name='companyEmail'
                                value={formData.companyEmail}
                                onChange={handleChange}
                                required
                                isInvalid={!!errors.companyEmail}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.companyEmail}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId='companyCity' className='m-3'>
                            <Form.Label>Ciudad</Form.Label>
                            <Select
                                options={ciudadesOptions}
                                value={selectedCiudad}
                                onChange={handleCiudadChange}
                                placeholder='Seleccione su ciudad'
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId='companyLogo' className='m-3'>
                            <Form.Label style={{ textAlign: 'center', display: 'block' }}>Logo de la Empresa</Form.Label>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleFileChange}
                                ref={fileInputRef}
                                style={{
                                    display: 'none', // Oculta el input de archivo
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    width: '100%',
                                    height: '100%',
                                    cursor: 'pointer',
                                }}
                            />
                            <div
                                className="position-relative"
                                style={{
                                    width: '150px',
                                    height: '150px',
                                    border: formData.companyLogo ? 'none' : '2px dashed #007bff', // Borde azul del recuadro de previsualización
                                    borderRadius: '5px',
                                    cursor: 'pointer',
                                    backgroundColor: formData.companyLogo ? 'transparent' : '#f8f9fa',
                                    overflow: 'hidden',
                                    margin: '0 auto',
                                    position: 'relative', // Para que el input se posicione correctamente
                                }}
                                onClick={() => fileInputRef.current.click()}
                            >
                                {formData.companyLogo ? (
                                    <>
                                        <img
                                            src={URL.createObjectURL(formData.companyLogo)}
                                            alt="Logo de la empresa"
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover',
                                            }}
                                        />
                                        <button
                                            type="button"
                                            className="btn position-absolute top-50 start-50 translate-middle p-2"
                                            style={{
                                                borderRadius: '50%',
                                                backgroundColor: 'white', // Fondo blanco para el botón circular
                                                border: 'none', // Sin borde
                                                cursor: 'pointer',
                                                width: '40px', // Tamaño del botón
                                                height: '40px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <Pencil color="black" size={20} />
                                        </button>
                                    </>
                                ) : (
                                    <span
                                        style={{
                                            fontSize: '24px',
                                            color: '#007bff', // Color del símbolo +
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            borderRadius: '50%',
                                            width: '40px', // Tamaño del botón
                                            height: '40px',
                                            lineHeight: '40px',
                                            textAlign: 'center',
                                            backgroundColor: 'white',
                                            border: '2px solid #007bff', // Borde azul del círculo
                                            cursor: 'pointer',
                                            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)', // Sombra para el círculo
                                            transition: 'background-color 0.3s, color 0.3s, border-color 0.3s', // Transiciones para el cambio de color
                                        }}
                                        onMouseEnter={(e) => {
                                            e.currentTarget.style.backgroundColor = '#007bff'; // Color de fondo al pasar el ratón
                                            e.currentTarget.style.color = 'white'; // Cambia el color del texto
                                            e.currentTarget.style.borderColor = '#007bff'; // Cambia el color del borde
                                        }}
                                        onMouseLeave={(e) => {
                                            e.currentTarget.style.backgroundColor = 'white'; // Color de fondo original
                                            e.currentTarget.style.color = '#007bff'; // Color del texto original
                                            e.currentTarget.style.borderColor = '#007bff'; // Color del borde original
                                        }}
                                    >
                                        +
                                    </span>
                                )}
                            </div>
                            {cropperError && <p className='text-danger'>{cropperError}</p>}
                        </Form.Group>

                    </Col>
                </Row>
                <Button type='submit' className='btn btn-primary'>Siguiente</Button>
            </Form>
            {showCropperModal && (
                <ImageCropperModal
                    show={showCropperModal}
                    imageFile={tempImageFile}
                    onSave={handleCropComplete}
                    onClose={handleCropCancel}
                />
            )}
        </Container>
    );
}

export default InfoEmpresa;
