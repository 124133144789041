import React, { useState } from 'react';
import { Dropdown, Button } from 'react-bootstrap';
import { Download, NutFill, PersonPlusFill, Upload } from 'react-bootstrap-icons';
import CrearProveedorModal from './componentes/crearProveedorModal';
import '../styles/tables.css';
import CustomToggle from '../styles/customToggle'; 
import CardProveedor from './componentes/cardProveedor';
import TablaProveedor from './componentes/tablaProveedor';

function AdminProveedores() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className='mx-2 mt-3'>
      <div className='d-flex flex-column flex-md-row align-items-center justify-content-between'>
        <p className='mb-2 mb-md-0'>
          Administra tus proveedores, gestiona su informacion y sus facturas.
        </p>
        <div className='d-flex mt-2 mt-md-0'>
          <Button variant='primary' onClick={handleShow}>
            <PersonPlusFill /> Nuevo Proveedor
          </Button>
          <Dropdown>
            <Dropdown.Toggle as={CustomToggle} variant='secondary' className='ms-2'>
              <NutFill />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item><Download className='text-success mx-1' /> Importar</Dropdown.Item>
              <Dropdown.Item><Upload className='text-danger mx-1' /> Exportar</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      {/* MODAL CREAR PROVEEDORES */}
      <CrearProveedorModal show={show} handleClose={handleClose} />

      {/* Cards Proveedores */}
      <div className='mt-4'>
        <CardProveedor />
      </div>

      {/* TABLA DE PROVEEDORES */}
      <div className='my-2'>
        <TablaProveedor />
      </div>
    </div>

  );
}

export default AdminProveedores;
