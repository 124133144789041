import React, { useState, useEffect } from 'react';
import { Modal, Form, Row, Col, Button, InputGroup } from 'react-bootstrap';
import Select from 'react-select';
import CrearProveedorModal from '../../Proveedores/componentes/crearProveedorModal';
import AlertsCompras from '../Utils/AlertsCompras';
import APIserver from '../../../../api/axiosConfig';

function CrearCompra({ show, handleClose }) {
  const [currentDate, setCurrentDate] = useState('');
  const [showProveedorModal, setShowProveedorModal] = useState(false);
  const [formData, setFormData] = useState({
    numCompra: '',
    idProveedor: null,
    creacion: '',
    vence: '',
    totalCompra: '',
    pagado: '',
    pendiente: '',
    estado: 'pendiente'
  });
  const [proveedores, setProveedores] = useState([]);
  const [numCompraExistente, setNumCompraExistente] = useState(false);
  const [errors, setErrors] = useState({}); // Objeto para almacenar errores de validación

  useEffect(() => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const dd = String(today.getDate()).padStart(2, '0');
    setCurrentDate(`${yyyy}-${mm}-${dd}`);
    setFormData((prevFormData) => ({
      ...prevFormData,
      creacion: `${yyyy}-${mm}-${dd}`,
      vence: `${yyyy}-${mm}-${dd}`
    }));

    const fetchProveedores = async () => {
      try {
        const response = await APIserver.get(`/api/proveedores`);
        if (response.data && response.data.proveedores) {
          setProveedores(response.data.proveedores.map(proveedor => ({
            value: proveedor.idProveedor,
            label: proveedor.nomProveedor
          })));
        } else {
          console.error('La respuesta no contiene el array de proveedores:', response.data);
        }
      } catch (error) {
        console.error('Error al obtener los proveedores:', error);
      }
    };

    fetchProveedores();
  }, []);

  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });

    if (name === 'numCompra') {
      try {
        const response = await APIserver.get(`/api/compras/verificar/${value}`);
        setNumCompraExistente(response.data.existe);
        setErrors((prevErrors) => ({ ...prevErrors, numCompra: response.data.existe }));
      } catch (error) {
        console.error('Error al verificar el número de compra:', error);
      }
    }
  };

  // Función para validar los campos
  const validateForm = () => {
    const newErrors = {};
    
    if (!formData.numCompra) {
      newErrors.numCompra = 'El número de compra es obligatorio';
    }
    if (numCompraExistente) {
      newErrors.numCompra = 'El número de compra ya está en uso.';
    }
    if (!formData.idProveedor) {
      newErrors.idProveedor = 'El proveedor es obligatorio';
    }
    if (!formData.creacion) {
      newErrors.creacion = 'La fecha de elaboración es obligatoria';
    }
    if (!formData.vence) {
      newErrors.vence = 'La fecha de vencimiento es obligatoria';
    }
    if (!formData.totalCompra || parseFloat(formData.totalCompra) <= 0) {
      newErrors.totalCompra = 'El monto debe ser mayor a 0';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Si no hay errores, retorna true
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      AlertsCompras.showErrorMessage('Por favor complete todos los campos correctamente.');
      return;
    }
    try {
      const response = await APIserver.post(`/api/compras`, formData);
      AlertsCompras.showSuccessMessage('Compra creada exitosamente');
      handleClose();
    } catch (error) {
      console.error('Error al crear la compra:', error);
      AlertsCompras.showErrorMessage('Error al crear la compra');
    }
  };

  const handleProveedorModalClose = () => setShowProveedorModal(false);
  const handleProveedorModalShow = () => setShowProveedorModal(true);

  return (
    <>
      <Modal size='lg' show={show} onHide={handleClose} aria-labelledby='create-new-comra'>
        <Modal.Header closeButton>
          <Modal.Title>Nueva Compra</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <Form.Group className='m-3 mt-0' controlId='numCompra'>
                  <Form.Label>Numero Compra</Form.Label>
                  <Form.Control
                    type='text'
                    name='numCompra'
                    placeholder='Numero de Factura'
                    onChange={handleInputChange}
                    isInvalid={!!errors.numCompra} // Si hay error, marca el campo como inválido
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.numCompra}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className='m-3' controlId='creacion'>
                  <Form.Label>Elaboracion</Form.Label>
                  <Form.Control
                    type='date'
                    name='creacion'
                    value={formData.creacion}
                    onChange={handleInputChange}
                    isInvalid={!!errors.creacion}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.creacion}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='m-3 mt-0' controlId='idProveedor'>
                  <Form.Label>Proveedor</Form.Label>
                  <div className='d-flex align-items-center'>
                    <Select
                      placeholder='Seleccione Proveedor'
                      className='flex-grow-1'
                      options={proveedores}
                      onChange={(selectedOption) =>
                        setFormData({ ...formData, idProveedor: selectedOption.value })
                      }
                      isInvalid={!!errors.idProveedor}
                    />
                  </div>
                  {errors.idProveedor && (
                    <div className="text-danger mt-1">{errors.idProveedor}</div>
                  )}
                </Form.Group>
                <Form.Group className='m-3' controlId='vence'>
                  <Form.Label>Vence</Form.Label>
                  <Form.Control
                    type='date'
                    name='vence'
                    value={formData.vence}
                    onChange={handleInputChange}
                    isInvalid={!!errors.vence}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.vence}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className='m-3 mt-0' controlId='totalCompra'>
              <Form.Label>Monto</Form.Label>
              <InputGroup>
                <InputGroup.Text>$</InputGroup.Text>
                <Form.Control
                  type='number'
                  name='totalCompra'
                  placeholder='Valor total de la compra'
                  onChange={handleInputChange}
                  isInvalid={!!errors.totalCompra}
                />
                <InputGroup.Text>COP</InputGroup.Text>
                <Form.Control.Feedback type="invalid">
                  {errors.totalCompra}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Modal.Footer>
              <Button variant='secondary' onClick={handleClose}>
                Cancelar
              </Button>
              <Button variant='primary' type='submit'>
                Guardar
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
      <CrearProveedorModal show={showProveedorModal} handleClose={handleProveedorModalClose} />
    </>
  );
}

export default CrearCompra;