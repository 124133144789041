import React, { useEffect, useState, useCallback } from 'react';
import { Button } from 'react-bootstrap';
import EditarClienteFactura from './componentes/editClienteFac';
import EditarProductoFactura from './componentes/editProductosFac';
import { useParams, useNavigate } from 'react-router-dom';
import AlertsFacturas from './Utils/AlertsFacturas';
import APIserver from '../../../api/axiosConfig';

function EditarFactura() {
    const { idFactura } = useParams();
    const [factura, setFactura] = useState(null);
    const [localProductos, setLocalProductos] = useState([]);
    const [cosEnvio, setCosEnvio] = useState(0);
    const [descuento, setDescuento] = useState(0);
    const navigate = useNavigate();

    // Fetch factura data on mount
    useEffect(() => {
        const fetchFactura = async () => {
            try {
                const response = await APIserver.get(`/api/actualizarv/datos/facturas/${idFactura}`);
                setFactura(response.data);
                setLocalProductos(response.data.productos || []);
                setCosEnvio(response.data.cosEnvio || 0);
                setDescuento(response.data.descuento || 0);
            } catch (error) {
                console.error('Error al cargar la factura:', error);
            }
        };

        fetchFactura();
    }, [idFactura]);

    // Update products in state
    const handleChange = useCallback(async (idDetallesFacV, name, value) => {
        setLocalProductos(prevProductos => {
            const updatedProductos = prevProductos.map(p =>
                p.idDetallesFacV === idDetallesFacV ? { ...p, [name]: value } : p
            );

            if (name === 'cantidad') {
                const producto = updatedProductos.find(p => p.idDetallesFacV === idDetallesFacV);
                if (producto && producto.idProducto) {
                    APIserver.get(`/api/productos/${producto.idProducto}`)
                        .then(response => {
                            const cantidadDisponible = response.data.stock;
                            if (parseInt(value, 10) > cantidadDisponible) {
                                AlertsFacturas.showStockAlert(cantidadDisponible);
                            }
                        })
                        .catch(error => {
                            console.error('Error al obtener el stock del producto:', error);
                        });
                }
            }

            return updatedProductos;
        });
    }, []);

    // Calculate total for the invoice
    const calculateTotal = useCallback(() => {
        const subtotal = localProductos.reduce((total, producto) =>
            total + (parseFloat(producto.cantidad) || 0) * (parseFloat(producto.valorUnitario) || 0), 0
        ).toFixed(2);
        const descuentoTotal = subtotal * (descuento / 100);
        const totalFacV = (parseFloat(subtotal) + parseFloat(cosEnvio) - descuentoTotal).toFixed(2);
        return totalFacV;
    }, [localProductos, cosEnvio, descuento]);

    // Validation: Ensure there are valid products before saving
    const validateFacturaData = () => {
        const productosValidos = localProductos.filter(
            (producto) => producto.referencia && producto.cantidad > 0
        );

        if (productosValidos.length === 0) {
            AlertsFacturas.showErrorMessage('Debe agregar al menos un producto válido antes de guardar.');
            return false;
        }

        return true;
    };

    // Handle save action
    const handleSave = async () => {
        try {
            // Validate the products before proceeding
            if (!validateFacturaData()) return;

            if (!factura || !localProductos || !idFactura) {
                console.error('Datos incompletos para actualizar la factura.');
                return;
            }

            // Identify details to delete
            const detallesActuales = factura.productos.map(detalle => detalle.idDetallesFacV);
            const detallesEnBaseDeDatos = localProductos.map(producto => producto.idDetallesFacV);
            const detallesParaEliminar = detallesActuales.filter(id => !detallesEnBaseDeDatos.includes(id));

            await Promise.all(detallesParaEliminar.map(id =>
                APIserver.delete(`/api/actualizarv/detalles-eliminar/${id}`)
            ));

            // Prepare data for update
            const updatedFactura = {
                idCliente: factura.idCliente,
                idSucursal: factura.idSucursal,
                creacion: factura.fechaCreacion ? factura.fechaCreacion.split('T')[0] : '',
                vence: factura.fechaVencimiento ? factura.fechaVencimiento.split('T')[0] : '',
                totalFacV: calculateTotal(),  // Recalculate total
                subtotal: localProductos.reduce((total, producto) =>
                    total + (parseFloat(producto.cantidad) || 0) * (parseFloat(producto.valorUnitario) || 0), 0
                ).toFixed(2),
                cantItem: localProductos.reduce((total, producto) => total + (parseInt(producto.cantidad) || 0), 0),
                cosEnvio: cosEnvio,
                descuento: descuento,
                tipoFacV: factura.tipoFactura,
                detalles: localProductos.map(producto => ({
                    idFacV: idFactura,
                    idProducto: producto.idProducto || null,
                    idDetallesFacV: producto.idDetallesFacV || null,
                    cantidad: producto.cantidad || 0
                }))
            };

            await APIserver.put(`/api/actualizarv/facturasactualizar/${idFactura}`, updatedFactura);
            AlertsFacturas.showactualizacionSuccessMessage();
            navigate('/admin/IngresosFacturas/');
        } catch (error) {
            AlertsFacturas.showactualizacionErrorMessage();
            console.error('Error al actualizar la factura:', error);
        }
    };

    if (!factura) {
        return <div>Cargando...</div>;
    }

    return (
        <div className='mx-2 mt-4 mt-md-0'>
            <div className='d-flex flex-column flex-md-row align-items-center justify-content-between'>
                <h4 className='mb-2 mb-md-0 text-center'>
                    <span className='fw-bold text-black'>Editar Factura No.</span> {factura.numeroFactura}
                </h4>
                <div className='d-flex mt-2 mt-md-0'>
                    <Button variant='primary' className='me-2' onClick={handleSave}>Guardar</Button>
                    <Button variant='secondary' className='me-2' onClick={() => navigate('/admin/IngresosFacturas/')}>Cancelar</Button>
                </div>
            </div>
            <div className='mt-4'>
                <EditarClienteFactura factura={factura} setFactura={setFactura} />
            </div>
            <div className='mt-2'>
                <EditarProductoFactura
                    idFactura={idFactura}
                    setProductos={setLocalProductos}
                    tipoFactura={factura.tipoFactura}
                    setCosEnvio={setCosEnvio}
                    setDescuento={setDescuento}  // Check that `setDescuento` is used correctly
                    updateTotalfacv={calculateTotal} // Make sure `calculateTotal` is used if needed
                />
            </div>
        </div>
    );
}

export default EditarFactura;
