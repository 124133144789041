import React, { useState } from 'react';
import { Nav } from 'react-bootstrap';
import ReporteVentas from './componentes/reporteVentas';
import ReporteCompras from './componentes/reporteCompras';
import ReporteFinanciero from './componentes/reporteFinanciero';
import Reporteinventario from './componentes/reporteInventario';
import ReporteClientes from './componentes/reporteClientes';
import ReporteDescuentosEnvios from './componentes/reporteDescuentosEnvios';

function AdminReportes() {
    const [activeKey, setActiveKey] = useState('/active');

    const renderContent = () => {
        switch (activeKey) {
            case '/active':
                return <ReporteVentas />;
            case 'link-compras':
                return <ReporteCompras />;
            case 'link-financiero':
                return <ReporteFinanciero />
            case 'link-inventario':
                return <Reporteinventario />
            case 'link-clientes':
                return <ReporteClientes />
            case 'link-DescuentosEnvios':
                return <ReporteDescuentosEnvios />
            default:
                return null;
        }
    };

    return (
        <>
            <div>
                <Nav variant="tabs" fill defaultActiveKey="/active" onSelect={(selectedKey) => setActiveKey(selectedKey)}>
                    <Nav.Item>
                        <Nav.Link eventKey="/active" className='text-center w-100 d-flex justify-content-center align-items-center'>Ventas</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="link-compras" className='text-center w-100 d-flex justify-content-center align-items-center'>Compras</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="link-financiero" className='text-center w-100 d-flex justify-content-center align-items-center'>Financiero</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="link-inventario" className='text-center w-100 d-flex justify-content-center align-items-center'>Inventario</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="link-clientes" className='text-center w-100 d-flex justify-content-center align-items-center'>Clientes</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="link-DescuentosEnvios" className='text-center w-100 d-flex justify-content-center align-items-center'>Descuentos y Envios</Nav.Link>
                    </Nav.Item>
                </Nav>
                <div>
                    {renderContent()}
                </div>
            </div>
        </>
    )
}

export default AdminReportes;