import Swal from 'sweetalert2';

const AlertsPagos = {
  showSuccessMessage: () => {
    Swal.fire({
      icon: 'success',
      iconColor: '#2fb380',
      title: '¡Éxito!',
      text: 'El pago se ha registrado correctamente.',
      showConfirmButton: false,
      timer: 2000
    }).then(() => {
      window.location.reload(); // Recarga la página
    });
  },

  showErrorMessage: (message) => {
    Swal.fire({
      icon: 'error',
      iconColor: '#da292e',
      title: 'Error',
      html: `${message}`, // Usamos HTML para mostrar el mensaje en negrita o más claro
    });
  },
};

const AlertsPagosActualizar = {
  showSuccessMessage: () => {
    Swal.fire({
      icon: 'success',
      iconColor: '#2fb380',
      title: '¡Éxito!',
      text: 'El pago se ha Actualizado correctamente.',
      showConfirmButton: false,
      timer: 2000
    });
  },

  showErrorMessage: (message) => {
    Swal.fire({
      icon: 'error',
      iconColor: '#da292e',
      title: 'Error',
      text: message || 'Ha ocurrido un error.',
    });
  },
};

const AlertsPagosEliminar = {
  showConfirmDelete: (confirmCallback) => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: 'Esta acción eliminará el pago. ¿Deseas continuar?',
      icon: 'warning',
      showCancelButton: true,
      iconColor: '#f4bd61',
      confirmButtonColor: '#2c4cc4',
      cancelButtonColor: '#da292e',
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        confirmCallback(); // Llama a la función de confirmación pasada como argumento
      }
    });
  },

  showSuccessMessage: () => {
    Swal.fire({
      icon: 'success',
      iconColor: '#2fb380',
      title: '¡Éxito!',
      text: 'El pago se ha Eliminado correctamente.',
      showConfirmButton: false,
      timer: 2000
    });
  },

  showErrorMessage: (message) => {
    Swal.fire({
      icon: 'error',
      iconColor: '#da292e',
      title: 'Error',
      text: message || 'Ha ocurrido un error.',
    });
  },
};

export { AlertsPagos, AlertsPagosActualizar, AlertsPagosEliminar };
