import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import Departamentos from '../../../../../utils/places-colombia';

function EditUserModal({ show, handleClose, user, onSave }) {
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        role: '',
        userNit: '',
        lastName: '',
        userPhone: '',
        userAdress: '',
        userPassword: '',
        userDep: '',
        userCity: ''
    });
    const [selectedDepartamento, setSelectedDepartamento] = useState(null);
    const [selectedCiudad, setSelectedCiudad] = useState(null);
    const [ciudadesOptions, setCiudadesOptions] = useState([]);
    const [isFormReady, setIsFormReady] = useState(false);

    const departamentosOptions = Departamentos.map(departamento => ({
        value: departamento.departamento,
        label: departamento.departamento
    }));

    const roleOptions = [
        { value: 'Administrador', label: 'Administrador' },
        { value: 'Estandar', label: 'Estandar' }
    ];

    useEffect(() => {
        if (user) {
            setFormData({
                username: user.nameInq,
                email: user.emailInq,
                role: user.role,
                userNit: user.NitInq,
                lastName: user.apeInq,
                userPhone: user.teleInq,
                userAdress: user.dirInq,
                userDep: user.depInq,
                userCity: user.ciudadInq,
                userPassword: '' // Mantenerlo vacío para no mostrar la contraseña actual
            });

            const departamento = Departamentos.find(dep => dep.departamento === user.depInq);
            if (departamento) {
                setSelectedDepartamento({ value: departamento.departamento, label: departamento.departamento });
                setCiudadesOptions(departamento.ciudades.map(city => ({ value: city, label: city })));
            }
            setSelectedCiudad({ value: user.ciudadInq, label: user.ciudadInq });
            setIsFormReady(true);
        }
    }, [user]);

    const handleDepartamentoChange = (selectedOption) => {
        setSelectedDepartamento(selectedOption);
        setFormData({ ...formData, userDep: selectedOption.value });
        const departamento = Departamentos.find(dep => dep.departamento === selectedOption.value);
        setCiudadesOptions(departamento ? departamento.ciudades.map(ciudad => ({ value: ciudad, label: ciudad })) : []);
        setSelectedCiudad(null);
    };

    const handleCiudadChange = (selectedOption) => {
        setSelectedCiudad(selectedOption);
        setFormData({ ...formData, userCity: selectedOption.value });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        console.log("Estado del formulario actualizado:", {
            ...formData,
            [name]: value,
        }); // Agrega este console.log
    };
    

    const handleSave = () => {
        console.log("Datos a enviar al backend desde el modal:", {
            ...formData,
            idInq: user.idInq,
        }); // Agrega este console.log
        onSave({
            ...formData,
            idInq: user.idInq,
        });
    };
    

    if (!isFormReady) return null;

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Editar Usuario</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId='userNitEdit' className='m-3'>
                        <Form.Label>No. Identificación</Form.Label>
                        <Form.Control
                            type='text'
                            name='userNit'
                            value={formData.userNit}
                            onChange={handleChange}
                            required
                            autoComplete="off"
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Nombre de Usuario</Form.Label>
                        <Form.Control
                            type="text"
                            name="username"
                            value={formData.username}
                            onChange={handleChange}
                            placeholder="Ingrese el nombre de usuario"
                            autoComplete="off"
                        />
                    </Form.Group>
                    <Form.Group controlId='lastNameEdit' className='m-3'>
                        <Form.Label>Apellidos</Form.Label>
                        <Form.Control
                            type='text'
                            name='lastName'
                            value={formData.lastName}
                            onChange={handleChange}
                            required
                            autoComplete="off"
                        />
                    </Form.Group>
                    <Form.Group controlId='userPhoneEdit' className='m-3'>
                        <Form.Label>Teléfono</Form.Label>
                        <Form.Control
                            type='number'
                            name='userPhone'
                            value={formData.userPhone}
                            onChange={handleChange}
                            required
                            autoComplete="off"
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="Ingrese el email"
                            autoComplete="off"
                        />
                    </Form.Group>
                    <Form.Group controlId='userDepEdit' className='m-3'>
                        <Form.Label>Departamento</Form.Label>
                        <Select
                            value={selectedDepartamento}
                            onChange={handleDepartamentoChange}
                            options={departamentosOptions}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId='userCityEdit' className='m-3'>
                        <Form.Label>Ciudad</Form.Label>
                        <Select
                            value={selectedCiudad}
                            onChange={handleCiudadChange}
                            options={ciudadesOptions}
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId='userAdressEdit' className='m-3'>
                        <Form.Label>Dirección</Form.Label>
                        <Form.Control
                            type='text'
                            name='userAdress'
                            value={formData.userAdress}
                            onChange={handleChange}
                            required
                            autoComplete="off"
                        />
                    </Form.Group>
                    <Form.Group className='m-3'>
                        <Form.Label>Contraseña</Form.Label>
                        <Form.Control
                            type='password'
                            name='userPassword'
                            value={formData.userPassword}
                            onChange={handleChange}
                            placeholder="Ingrese nueva contraseña (dejar vacío si no desea cambiar)"
                            autoComplete="off"
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Rol</Form.Label>
                        <Select
                            name="role"
                            value={roleOptions.find(option => option.value === formData.role)}
                            onChange={selectedOption => setFormData({ ...formData, role: selectedOption ? selectedOption.value : '' })}
                            options={roleOptions}
                            placeholder="Seleccione un rol"
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Cancelar</Button>
                <Button variant="primary" onClick={handleSave}>Guardar cambios</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default EditUserModal;
