import Swal from 'sweetalert2';

const AlertsUsuarios = {
  showSuccessMessage: (message) => {
    Swal.fire({
      icon: 'success',
      iconColor: '#2fb380',
      title: '¡Éxito!',
      text: message || 'El usuario se ha creado correctamente.',
      showConfirmButton: false,
      timer: 3000
    })
  },

  showErrorMessage: (message) => {
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: message || 'Hubo un error al crear el usuario.',
      confirmButtonText: 'Aceptar',
      iconColor: '#da292e',
      confirmButtonColor: '#da292e'
    });
  },

  showUpdateSuccessMessage: (message) => {
    Swal.fire({
      icon: 'success',
      title: '¡Éxito!',
      text: message || 'El usuario se ha actualizado correctamente.',
      showConfirmButton: false,
      timer: 2000,
      iconColor: '#2fb380'
    });
  },

  showUpdateErrorMessage: () => {
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'Hubo un error al actualizar el usuario.',
      confirmButtonText: 'Aceptar',
      iconColor: '#da292e',
      confirmButtonColor: '#da292e'
    });
  },

  showDeleteConfirmation: (onDelete) => {
    Swal.fire({
      icon: 'warning',
      title: '¿Estás seguro?',
      text: '¿Quieres eliminar este usuario?',
      showCancelButton: true,
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar',
      iconColor: '#f4bd61',
      confirmButtonColor: '#2c4cc4',
      cancelButtonColor: '#da292e'
    }).then((result) => {
      if (result.isConfirmed) {
        onDelete(); // Llama a la función onDelete para realizar la eliminación
        Swal.fire({
          icon: 'success',
          title: '¡Eliminado!',
          text: 'El usuario ha sido eliminado exitosamente.',
          iconColor: '#2fb380',
          showConfirmButton: false,
          timer: 2000
        });
      } // Si selecciona "Cancelar", no se ejecuta nada adicional
    });
  },
  showErrorMessageDelete: (message) => {
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: message || 'Hubo un error al eliminar el usuario.',
      confirmButtonText: 'Aceptar',
      iconColor: '#da292e',
      confirmButtonColor: '#da292e'
    });
  }
  
};

export default AlertsUsuarios;
