import React, { useState, useEffect } from 'react';
import { Table, Pagination, Button, Form, ButtonGroup } from 'react-bootstrap';
import { Filter, EyeFill, PencilFill, TrashFill } from 'react-bootstrap-icons';
import { Link, useParams } from 'react-router-dom';
import FiltrosPagos from './filtroPagos';
import EditarPago from './editarPago';
import CrearPagoModal from './crearPagoModal';
import { AlertsPagosEliminar } from '../Utils/AlertsPagos';
import APIserver from '../../../../api/axiosConfig';

function TablaPagos() {
  const [showFilters, setShowFilters] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [editShow, setShowEdit] = useState(false);
  const [crearPagoShow, setCrearPagoShow] = useState(false);
  const [pagos, setPagos] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [editPagoId, setEditPagoId] = useState(null);
  const [filters, setFilters] = useState({});
  const { idProveedor } = useParams();

  useEffect(() => {
    fetchPagos(currentPage, rowsPerPage, filters);
  }, [currentPage, rowsPerPage, filters]);

  // Función para cargar los pagos con paginación
  const fetchPagos = async (page, limit, filters = {}) => {
    try {
      const response = await APIserver.get(`/api/api/getPagos`, {
        params: {
          page,
          limit,
          ...filters,
        },
      });

      const pagosFormateados = response.data.pagos.map((pago) => ({
        ...pago,
        fecha: new Date(pago.fecha).toLocaleDateString('es-ES', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        }),
        monto: new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 0 }).format(pago.monto),
      }));

      setPagos(pagosFormateados);
      setTotalPages(response.data.totalPages || 1); // Evita que el total de páginas sea cero

    } catch (error) {
      console.error('Error al cargar los pagos:', error);
    }
  };

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value, 10));
    setCurrentPage(1);
  };

  const handleEditClose = () => {
    setShowEdit(false);
    setEditPagoId(null);
    fetchPagos(currentPage, rowsPerPage, filters);
  };

  const handleEditShow = (pagoId) => {
    setShowEdit(true);
    setEditPagoId(pagoId);
  };

  const handleCrearPagoClose = () => setCrearPagoShow(false);
  const handleCrearPagoShow = () => setCrearPagoShow(true);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleEliminarPago = async (idPago) => {
    AlertsPagosEliminar.showConfirmDelete(() => {
      APIserver.delete(`/api/pagos/${idPago}`)
        .then(response => {
          if (response.status === 200) {
            AlertsPagosEliminar.showSuccessMessage('El pago ha sido eliminado correctamente');
            const updatedPagos = pagos.filter((pago) => pago.idPago !== idPago);
            setPagos(updatedPagos);
          } else {
            AlertsPagosEliminar.showErrorMessage('Error al eliminar el pago');
          }
        })
        .catch(error => {
          console.error('Error al eliminar el pago:', error);
          AlertsPagosEliminar.showErrorMessage('Error al eliminar el pago');
        });
    });
  };

  const applyFilters = (filters) => {
    setFilters(filters);
    setCurrentPage(1);
  };

  // Función para renderizar los botones de paginación
  const renderPaginationItems = () => {
    const items = [];
    const pageRangeDisplayed = 5; // Mostrar 5 páginas a la vez
    let startPage = Math.max(1, currentPage - Math.floor(pageRangeDisplayed / 2));
    let endPage = Math.min(totalPages, startPage + pageRangeDisplayed - 1);

    if (endPage - startPage < pageRangeDisplayed - 1) {
      startPage = Math.max(1, endPage - pageRangeDisplayed + 1);
    }

    if (startPage > 1) {
      items.push(
        <Pagination.First key="first" onClick={() => handlePageChange(1)} />
      );
    }

    if (currentPage > 1) {
      items.push(
        <Pagination.Prev key="prev" onClick={() => handlePageChange(currentPage - 1)} />
      );
    }

    for (let number = startPage; number <= endPage; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => handlePageChange(number)}
        >
          {number}
        </Pagination.Item>
      );
    }

    if (currentPage < totalPages) {
      items.push(
        <Pagination.Next key="next" onClick={() => handlePageChange(currentPage + 1)} />
      );
    }

    if (endPage < totalPages) {
      items.push(
        <Pagination.Last key="last" onClick={() => handlePageChange(totalPages)} />
      );
    }

    return items;
  };

  return (
    <div style={{ boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)' }}>
      <Table responsive className='text-center align-middle border'>
        <thead>
          <tr>
            <th colSpan={8}>
              <div className='d-flex justify-content-between align-items-center'>
                <div className='d-flex flex-wrap'>
                  <Button variant='link' size='sm' onClick={toggleFilters} title='Filtrar'>
                    <Filter className='m-0' size={20} />
                  </Button>
                </div>
                <div>
                  <Form.Select className='ms-2' value={rowsPerPage} onChange={handleChangeRowsPerPage}>
                    <option value='5'>5</option>
                    <option value='10'>10</option>
                    <option value='20'>20</option>
                    <option value='50'>50</option>
                  </Form.Select>
                </div>
              </div>
              <div className={`filter-options ${showFilters ? 'show' : ''}`}>
                <FiltrosPagos updateTable={applyFilters} />
              </div>
            </th>
          </tr>
          <tr>
            <th>Pago</th>
            <th>Factura</th>
            <th>Proveedor</th>
            <th>Concepto</th>
            <th>Creación</th>
            <th>Cuenta</th>
            <th>Monto</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {pagos.map((pago) => (
            <tr key={pago.numPagoHe}>
              <td>
                <Link to={`/admin/PagosRealizados/DetallesPago/${pago.idPago}/`} className='text-decoration-none'>
                  {pago.numPagoHe}
                </Link>
              </td>
              <td>
                <Link to={`/admin/Compras/DetallesCompra/${pago.idCompra}/`} className='text-decoration-none'>
                  {pago.numCompra}
                </Link>
              </td>
              <td>
                <Link to={`/admin/Proveedores/DetallesProveedores/${pago.idProveedor}/`} className='text-decoration-none'>
                  {pago.nomProveedor}
                </Link>
              </td>
              <td>{pago.concepto}</td>
              <td>{pago.fecha}</td>
              <td>
                <Link to={`/admin/Bancos/DetallesBancos/${pago.idBanco}/`} className='text-decoration-none'>
                  {pago.nombreBanco}
                </Link>
              </td>
              <td>{pago.monto}</td>
              <td>
                <ButtonGroup aria-label='action-button'>
                  <Link to={`/admin/PagosRealizados/DetallesPago/${pago.idPago}/`}>
                    <Button variant='link' className='text-primary' title='Ver Pago'>
                      <EyeFill size={18} />
                    </Button>
                  </Link>
                  <Button variant='link' className='text-warning' title='Editar Pago' onClick={() => handleEditShow(pago.idPago)}>
                    <PencilFill size={18} />
                  </Button>
                  <Button variant='link' className='text-danger' title='Eliminar Pago' onClick={() => handleEliminarPago(pago.idPago)}>
                    <TrashFill size={18} />
                  </Button>
                </ButtonGroup>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={8}>
              <div className='d-flex justify-content-center align-items-center'>
                <Pagination>
                  {renderPaginationItems()}
                </Pagination>
              </div>
            </td>
          </tr>
        </tfoot>
      </Table>

      <CrearPagoModal show={crearPagoShow} handleClose={handleCrearPagoClose} />
      <EditarPago show={editShow} handleClose={handleEditClose} pagoId={editPagoId} updatePagos={() => fetchPagos(currentPage, rowsPerPage, filters)} />
    </div>
  );
}

export default TablaPagos;
