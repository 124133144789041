import Swal from 'sweetalert2';

const AlertsClientes = {
  showSuccessMessage: (message) => {
    Swal.fire({
      icon: 'success',
      iconColor: '#2fb380',
      title: '¡Éxito!',
      text: message,
      showConfirmButton: false,
      timer: 2000
    });
  },

  showErrorMessage: (message) => {
    Swal.fire({
      icon: 'error',
      iconColor: '#da292e',
      title: 'Error',
      text: message,
    });
  },

  showSuccessMessage: (message) => {
    Swal.fire({
      icon: 'success',
      iconColor: '#2fb380',
      title: '¡Éxito!',
      text: message || 'Los datos se han actualizado correctamente.',
      showConfirmButton: false,
      timer: 1500
    }).then(() => {
      window.location.reload(); // Recarga la página
    });
  },

  showUpdateErrorMessage: () => {
    Swal.fire({
      icon: 'error',
      iconColor: '#da292e',
      title: 'Error',
      text: 'Hubo un error al actualizar los datos.',
    });
  },

  showDeleteConfirmation: (clienteNombre, onDelete) => {
    Swal.fire({
      icon: 'warning',
      title: '¿Estás seguro?',
      text: `¿Quieres eliminar al cliente ${clienteNombre}?`,
      showCancelButton: true,
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar',
      iconColor: '#f4bd61',
      confirmButtonColor: '#2c4cc4',
      cancelButtonColor: '#da292e'
    }).then((result) => {
      if (result.isConfirmed) {
        onDelete(); // Llama a la función onDelete cuando se confirma la eliminación
      }
    });
  }
};

export default AlertsClientes;
