import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import APIserver from '../../../../api/axiosConfig';

const ReporteDescuentosEnvios = () => {
    const [descuentosPromedioPorCantidadData, setDescuentosPromedioPorCantidadData] = useState([]);
    const [descuentosPromedioPorTotalData, setDescuentosPromedioPorTotalData] = useState([]);
    const [fechaInicio, setFechaInicio] = useState('');
    const [fechaFin, setFechaFin] = useState('');
    const [enviosPromedioPorCantidadData, setEnviosPromedioPorCantidadData] = useState([]);
    const [enviosPromedioPorTotalData, setEnviosPromedioPorTotalData] = useState([]);
    const [rankingDescuentosData, setRankingDescuentosData] = useState([]);
    const [ahorroPromedioData, setAhorroPromedioData] = useState([]);

    useEffect(() => {
        const today = new Date();

        // Fecha actual formateada
        const fechaActual = today.toISOString().split('T')[0]; // Formato YYYY-MM-DD

        // Fecha de inicio del mes (primer día)
        const primerDiaMes = new Date(today.getFullYear(), today.getMonth(), 1);
        const fechaInicioMes = primerDiaMes.toISOString().split('T')[0]; // Formato YYYY-MM-DD

        // Fecha de fin del mes (último día, limitada al día actual si es necesario)
        const ultimoDiaMes = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        const fechaFinMes = ultimoDiaMes > today ? fechaActual : ultimoDiaMes.toISOString().split('T')[0];

        // Configurar los valores iniciales
        setFechaInicio(fechaInicioMes);
        setFechaFin(fechaFinMes);
    }, []);

    // Descuentos promedio por cantidad
    useEffect(() => {
        const fetchDescuentosPromedio = async () => {
            if (!fechaInicio || !fechaFin) return;

            try {
                const response = await APIserver.get('/api/descuentosEnvios/descuentos-promedio-cantidad', {
                    params: { fechaInicio, fechaFin }
                });

                const sortedData = response.data.descuentosPromedioPorCantidadData.sort((a, b) => {
                    const ordenRango = ['1-5 productos', '6-10 productos', '11+ productos'];
                    return ordenRango.indexOf(a.rango) - ordenRango.indexOf(b.rango);
                });

                setDescuentosPromedioPorCantidadData(sortedData);
            } catch (error) {
                console.error('Error al cargar los datos de descuentos promedio por cantidad:', error);
            }
        };

        fetchDescuentosPromedio();
    }, [fechaInicio, fechaFin]);

    // Descuentos promedio por total
    useEffect(() => {
        const fetchDescuentosPromedioPorTotal = async () => {
            if (!fechaInicio || !fechaFin) return;

            try {
                const response = await APIserver.get('/api/descuentosEnvios/descuentos-promedio-total', {
                    params: { fechaInicio, fechaFin }
                });

                // Ajustar orden si se conocen los rangos esperados
                const ordenRango = []; // Define aquí los rangos esperados si existen
                const sortedData = ordenRango.length > 0
                    ? response.data.descuentosPromedioPorTotalData.sort((a, b) => ordenRango.indexOf(a.rango) - ordenRango.indexOf(b.rango))
                    : response.data.descuentosPromedioPorTotalData;

                setDescuentosPromedioPorTotalData(sortedData);
            } catch (error) {
                console.error('Error al cargar los datos de descuentos promedio por total:', error);
            }
        };

        fetchDescuentosPromedioPorTotal();
    }, [fechaInicio, fechaFin]);

    // Envíos promedio por cantidad
    useEffect(() => {
        const fetchEnviosPromedioPorCantidad = async () => {
            if (!fechaInicio || !fechaFin) return;

            try {
                const response = await APIserver.get('/api/descuentosEnvios/envios-promedio-cantidad', {
                    params: { fechaInicio, fechaFin }
                });

                const sortedData = response.data.enviosPromedioPorCantidadData.sort((a, b) => {
                    const ordenRango = ['1-5 productos', '6-10 productos', '11+ productos'];
                    return ordenRango.indexOf(a.rango) - ordenRango.indexOf(b.rango);
                });

                setEnviosPromedioPorCantidadData(sortedData);
            } catch (error) {
                console.error('Error al cargar los datos de envíos promedio por cantidad:', error);
            }
        };

        fetchEnviosPromedioPorCantidad();
    }, [fechaInicio, fechaFin]);

    // Envíos promedio por total
    useEffect(() => {
        const fetchEnviosPromedioPorTotal = async () => {
            if (!fechaInicio || !fechaFin) return;

            try {
                const response = await APIserver.get('/api/descuentosEnvios/envios-promedio-por-total', {
                    params: { fechaInicio, fechaFin }
                });

                setEnviosPromedioPorTotalData(response.data.enviosPromedioPorTotalData);
            } catch (error) {
                console.error('Error al cargar los datos de envíos promedio por total:', error);
            }
        };

        fetchEnviosPromedioPorTotal();
    }, [fechaInicio, fechaFin]);

    useEffect(() => {
        const fetchRankingDescuentos = async () => {
            try {
                const response = await APIserver.get('/api/descuentosEnvios/ranking-descuentos', {
                    params: { fechaInicio, fechaFin },
                });
                setRankingDescuentosData(response.data.rankingDescuentosData);
            } catch (error) {
                console.error('Error al cargar ranking de descuentos:', error);
            }
        };

        if (fechaInicio && fechaFin) {
            fetchRankingDescuentos();
        }
    }, [fechaInicio, fechaFin]);
    useEffect(() => {
        const fetchAhorroPromedio = async () => {
            try {
                const response = await APIserver.get('/api/descuentosEnvios/ahorro-promedio', {
                    params: { fechaInicio, fechaFin },
                });
                setAhorroPromedioData(response.data.ahorroPromedioData);
            } catch (error) {
                console.error('Error al cargar ahorro promedio:', error);
            }
        };

        if (fechaInicio && fechaFin) {
            fetchAhorroPromedio();
        }
    }, [fechaInicio, fechaFin]);

    const COLORS = ['#3459e6', '#2fb380', '#f4bd61', '#da292e'];

    // Función para formatear valores en COP
    const formatCOP = (value) => value?.toLocaleString('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    }) || '$0';

    return (
        <Container fluid className="py-3">
            {/* Filtros de Reporte */}
            <div className='d-flex flex-column flex-md-row align-items-center align-items-md-end justify-content-center mb-3 text-center'>
                <div className='d-flex flex-column me-md-3 mb-3 mb-md-0'>
                    <Form.Label>Desde</Form.Label>
                    <Form.Control
                        type='date'
                        value={fechaInicio}
                        onChange={(e) => {
                            const selectedDate = e.target.value;
                            if (new Date(selectedDate) <= new Date(fechaFin || new Date())) {
                                setFechaInicio(selectedDate);
                            }
                        }}
                        max={new Date().toISOString().split('T')[0]} // Fecha máxima: Hoy
                        style={{ minWidth: '200px' }}
                    />
                </div>
                <div className='d-flex flex-column me-md-3 mb-3 mb-md-0'>
                    <Form.Label>Hasta</Form.Label>
                    <Form.Control
                        type='date'
                        value={fechaFin}
                        onChange={(e) => {
                            const selectedDate = e.target.value;
                            if (new Date(selectedDate) >= new Date(fechaInicio) && new Date(selectedDate) <= new Date()) {
                                setFechaFin(selectedDate);
                            }
                        }}
                        max={new Date().toISOString().split('T')[0]} // Fecha máxima: Hoy
                        style={{ minWidth: '200px' }}
                    />
                </div>
            </div>

            {/* Descuento Promedio por Rango de Cantidad de Productos */}
            <Row className="mb-4">
                <Col md={6}>
                    <h5 className='fw-bold'>Descuento Promedio por Productos</h5>
                    <ResponsiveContainer width="100%" height={300}>
                        <BarChart data={descuentosPromedioPorCantidadData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="rango" />
                            <YAxis />
                            <Tooltip
                                formatter={(value) => {
                                    return [`${value.toFixed(2)}%`, 'Descuento Promedio']; // Directamente en el formatter
                                }}
                            />

                            <Legend />
                            <Bar dataKey="descuentoPromedio" fill="#2fb380" name="Descuento Promedio" />
                        </BarChart>
                    </ResponsiveContainer>
                </Col>

                {/* Descuento Promedio por Rango de Total de Factura */}
                <Col md={6}>
                    <h5 className='fw-bold'>Descuento Promedio por Total de Factura</h5>
                    <ResponsiveContainer width="100%" height={300}>
                        <BarChart data={descuentosPromedioPorTotalData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="rango" />
                            <Tooltip
                                formatter={(value) => {
                                    return [`${value.toFixed(2)}%`, 'Descuento Promedio']; // Directamente en el formatter
                                }}
                            />

                            <Legend />
                            <Bar dataKey="descuentoPromedio" fill="#3459e6" name="Descuento Promedio" />
                        </BarChart>
                    </ResponsiveContainer>
                </Col>

            </Row>

            {/* Análisis de Costo Promedio de Envío por Cantidad*/}
            <Row className="mb-4">
                <Col md={6}>
                    <h5 className='fw-bold'>Envío Promedio por Productos</h5>
                    <ResponsiveContainer width="100%" height={300}>
                        <BarChart data={enviosPromedioPorCantidadData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            {/* Eje X mostrando el rango */}
                            <XAxis dataKey="rango" />
                            {/* Eje Y mostrando valores en pesos colombianos */}
                            <YAxis tickFormatter={(value) => `COP $${value.toLocaleString('es-CO')}`} />
                            {/* Tooltip mostrando valores en pesos colombianos */}
                            <Tooltip
                                formatter={(value, name) => {
                                    return [`$${value.toLocaleString('es-CO')}`, name]; // Formateo en pesos colombianos
                                }}
                            />
                            <Legend />
                            {/* Barras con datos */}
                            <Bar dataKey="costoPromedioEnvio" fill="#2fb380" name="Envío Promedio" />
                        </BarChart>
                    </ResponsiveContainer>
                </Col>

                {/* Análisis de Descuento Promedio Aplicado por Valor de Factura y Cantidad de Ítems */}
                <Col md={6}>
                    <h5 className='fw-bold'>Envio Promedio por Total de Facturas</h5>
                    <ResponsiveContainer width="100%" height={300}>
                        <BarChart data={enviosPromedioPorTotalData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="rango" />
                            <YAxis tickFormatter={(value) => `$${value.toLocaleString('es-CO')}`} />
                            <Tooltip
                                formatter={(value, name) => {
                                    return [`$${value.toLocaleString('es-CO')}`, name]; // Formateo en pesos colombianos
                                }}
                            />
                            <Legend />
                            <Bar dataKey="envioPromedioPorValor" fill="#3459e6" name="Descuento Promedio por Valor" />
                        </BarChart>
                    </ResponsiveContainer>
                </Col>
            </Row>

            {/* Ranking de Descuentos Más Aplicados */}
            <Row className="mb-4">
                <Col md={6}>
                    <h5 className='fw-bold'>Descuentos Más Aplicados en Facturas</h5>
                    <ResponsiveContainer width="100%" height={300}>
                        <PieChart>
                            <Pie
                                data={rankingDescuentosData}
                                dataKey="aplicaciones"
                                nameKey="tipoDescuento"
                                cx="50%"
                                cy="50%"
                                outerRadius={100}
                            >
                                {rankingDescuentosData.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Pie>
                            <Tooltip />
                        </PieChart>
                    </ResponsiveContainer>
                </Col>
                {/* Análisis de Ahorro Total por Tipo de Descuento */}
                <Col md={6}>
                    <h5 className='fw-bold'>Ahorro Promedio por Factura</h5>
                    <ResponsiveContainer width="100%" height={300}>
                        <BarChart data={ahorroPromedioData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="nombre" />
                            <YAxis
                                tickFormatter={(value) => `COP $${value.toLocaleString('es-CO')}`}
                            />
                            <Tooltip
                                formatter={(value, name) => [
                                    `COP $${Math.round(value).toLocaleString('es-CO')}`, // Valor redondeado y formateado
                                    name, // Nombre de la serie
                                ]}
                            />
                            <Legend />
                            <Bar dataKey="valorTotal" fill="#3459e6" name="Promedio Valor Total" />
                            <Bar dataKey="valorConDescuento" fill="#2fb380" name="Promedio Valor con Descuento" />
                        </BarChart>
                    </ResponsiveContainer>
                </Col>

            </Row>
        </Container>
    );
};

export default ReporteDescuentosEnvios;
