import React, { useState } from 'react';
import { FormControl, Button, ButtonGroup } from 'react-bootstrap';
import { Search, XSquare } from 'react-bootstrap-icons';

function FiltrosSucursal({ onSearch }) {
  const [asesor, setAsesor] = useState('');
  const [numero, setNumero] = useState('');

  const handleSearch = () => {
    onSearch({ asesor, numero });
  };

  const handleClear = () => {
    setAsesor('');
    setNumero('');
    onSearch({ asesor: '', numero: '' });
  };

  return (
    <div className='d-flex align-items-center m-3'>
      <FormControl
        placeholder='Filtra por Asesor'
        className='me-2'
        value={asesor}
        onChange={(e) => setAsesor(e.target.value)}
      />
      <FormControl
        placeholder='Filtra por Numero'
        className='me-2'
        value={numero}
        onChange={(e) => setNumero(e.target.value)}
      />
      <ButtonGroup>
        <Button variant='primary' title='Buscar' onClick={handleSearch}>
          <Search />
        </Button>
        <Button variant='danger' title='Limpiar' onClick={handleClear}>
          <XSquare />
        </Button>
      </ButtonGroup>
    </div>
  );
}

export default FiltrosSucursal;
