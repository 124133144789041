import React, { useState } from 'react';
import { Modal, Form, Row, Col, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { showAlertSuccess, showAlertError } from '../Utils/AlertsProveedores';
import Departamentos from '../../../../utils/places-colombia';
import Paises from '../../../../utils/paises';
import Select from 'react-select';
import { QuestionCircle } from 'react-bootstrap-icons';
import APIserver from '../../../../api/axiosConfig';

const CrearProveedorModal = ({ show, handleClose }) => {
    const [selectedDepartamento, setSelectedDepartamento] = useState(null);
    const [selectedCiudad, setSelectedCiudad] = useState(null);
    const [selectedPais, setSelectedPais] = useState({ value: 'Colombia', label: 'Colombia' });

    const [ProveedorID, setProveedorID] = useState('');
    const [nomProveedor, setNomProveedor] = useState('');
    const [numProveedor, setNumProveedor] = useState('');
    const [razonSocial, setRazonSocial] = useState('');
    const [direccion, setDireccion] = useState('');

    const [ciudadesOptions, setCiudadesOptions] = useState([]);

    const handleDepartamentoChange = (selectedOption) => {
        if (selectedOption) {
            const departamento = Departamentos.find(dep => dep.departamento === selectedOption.value);
            const ciudades = departamento ? departamento.ciudades.map(ciudad => ({ value: ciudad, label: ciudad })) : [];
            setCiudadesOptions(ciudades);
            setSelectedCiudad(null); // Limpiar la ciudad al cambiar de departamento
        } else {
            setCiudadesOptions([]); // Reiniciar opciones de ciudad si se elimina el departamento
            setSelectedCiudad(null);
        }
        setSelectedDepartamento(selectedOption); // Puede ser null cuando se limpia el Select
    };

    const handleCiudadChange = (selectedOption) => {
        setSelectedCiudad(selectedOption);
    };

    const handlePaisChange = (selectedOption) => {
        setSelectedPais(selectedOption);
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        const proveedorData = {
            ProveedorID,
            nomProveedor,
            numProveedor,
            razonSocial,
            direccion,
            ciudad: selectedCiudad ? selectedCiudad.value : '',
            departamento: selectedDepartamento ? selectedDepartamento.value : '',
            pais: selectedPais.value
        };

        try {
            const response = await APIserver.post(`/api/proveedores`, proveedorData);

            if (response.status === 201) {
                showAlertSuccess('Proveedor guardado correctamente');
                handleClose();
            } else {
                throw new Error('Error al guardar el proveedor');
            }
        } catch (error) {
            console.error('Error al crear el proveedor:', error);
            showAlertError('Error al procesar la solicitud del proveedor');
        }
    };

    const departamentosOptions = Departamentos.map(departamento => ({ value: departamento.departamento, label: departamento.departamento }));
    const paisesOptions = Paises.map(pais => ({ value: pais.es_name, label: pais.es_name }));

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Este campo siempre tendrá como valor predefinido "Colombia".
            Si no es necesario se sugiere no modificarlo.
        </Tooltip>
    );

    return (
        <Modal size='lg' show={show} onHide={handleClose} aria-labelledby='create-new-prov-modal'>
            <Modal.Header closeButton>
                <Modal.Title>Crear Nuevo Proveedor</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleFormSubmit}>
                    <Row>
                        <Col xs={12} md={6}>
                            <Form.Group className='m-3 mt-0' controlId='ProveedorID'>
                                <Form.Label>Identificación</Form.Label>
                                <Form.Control type='number' name='ProveedorID' placeholder='Numero de CC o RUT' value={ProveedorID} onChange={(e) => setProveedorID(e.target.value)} required />
                            </Form.Group>
                            <Form.Group className='m-3' controlId='nomProveedor'>
                                <Form.Label>Nombre Completo</Form.Label>
                                <Form.Control type='text' name='nomProveedor' placeholder='Nombre Completo' value={nomProveedor} onChange={(e) => setNomProveedor(e.target.value)} required />
                            </Form.Group>
                            <Form.Group className='m-3' controlId='direccion'>
                                <Form.Label>Direccion</Form.Label>
                                <Form.Control type='text' placeholder='Direccion' value={direccion} onChange={(e) => setDireccion(e.target.value)} required />
                            </Form.Group>
                            <Form.Group className='m-3' controlId='departamento'>
                                <Form.Label>Departamento</Form.Label>
                                <Select options={departamentosOptions} value={selectedDepartamento} onChange={handleDepartamentoChange} placeholder='Seleccione' isClearable required/>
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                        <Form.Group className='m-3 mt-0' controlId='ciudad'>
                                <Form.Label>Ciudad</Form.Label>
                                <Select options={ciudadesOptions} value={selectedCiudad} onChange={handleCiudadChange} placeholder='Seleccione' isClearable required />
                            </Form.Group>
                            <Form.Group className='m-3' controlId='numProveedor'>
                                <Form.Label>Celular</Form.Label>
                                <Form.Control type='number' name='numProveedor' placeholder='Numero de Contacto' value={numProveedor} onChange={(e) => setNumProveedor(e.target.value)} required />
                            </Form.Group>
                            <Form.Group className='m-3' controlId='razonSocial'>
                                <Form.Label>Razón Social</Form.Label>
                                <Form.Control type='text' name='razonSocial' placeholder='Nombre de la empresa' value={razonSocial} onChange={(e) => setRazonSocial(e.target.value)} required />
                            </Form.Group>
                            <Form.Group className='m-3' controlId='pais'>
                                <Form.Label>Pais</Form.Label>
                                <OverlayTrigger placement='right' delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
                                    <QuestionCircle className='ms-2' />
                                </OverlayTrigger>
                                <Select options={paisesOptions} value={selectedPais} onChange={handlePaisChange} placeholder='Seleccione un Pais' />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Modal.Footer>
                        <Button variant='secondary' onClick={handleClose}>
                            Cancelar
                        </Button>
                        <Button variant='primary' type='submit'>
                            Guardar
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default CrearProveedorModal;
