import React, { useState, useEffect } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import APIserver from '../../../../api/axiosConfig';

function CardDetallesBanco({ nombreBanco, idBanco }) {
    const [estadisticas, setEstadisticas] = useState({
        totalMovimientos: 0,
        sumaMovimientos: 0,
        totalIngresos: 0,
        sumaIngresos: 0,
        totalEgresos: 0,
        sumaEgresos: 0
    });

    useEffect(() => {
        const fetchEstadisticas = async () => {
            try {
                const response = await APIserver.get(`/api/bancos/${idBanco}/estadisticas`);
                setEstadisticas(response.data);
            } catch (error) {
                console.error('Error al obtener las estadisticas del banco: ', error);
            }
        };

        if (idBanco) {
            fetchEstadisticas();
        }
    }, [idBanco]);

     // Formateador de moneda sin decimales
     const formatter = new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });

    return (
        <div className='mx-2 mt-3'>
            <div className='text-center my-4'>
                <h2 className='fw-bold'>{nombreBanco}</h2>
            </div>
            <div>
                <div className='d-flex justify-content-center'>
                    <Row className='text-center'>
                        <Col xs={12} md={4} className='mb-4 d-flex justify-content-center'>
                            <Card bg='info' text='white' style={{ width: '18rem' }}>
                                <Card.Body>
                                    <Card.Text>Movimientos: <b>{estadisticas.totalMovimientos}</b></Card.Text>
                                    <Card.Text><b>{formatter.format(estadisticas.sumaMovimientos)}</b></Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} md={4} className='mb-4 d-flex justify-content-center'>
                            <Card bg='success' text='white' style={{ width: '18rem' }}>
                                <Card.Body>
                                    <Card.Text>Ingresos: <b>{estadisticas.totalIngresos}</b></Card.Text>
                                    <Card.Text><b>{formatter.format(estadisticas.sumaIngresos)}</b></Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={12} md={4} className='mb-4 d-flex justify-content-center'>
                            <Card bg='danger' text='white' style={{ width: '18rem' }}>
                                <Card.Body>
                                    <Card.Text>Egresos: <b>{estadisticas.totalEgresos}</b></Card.Text>
                                    <Card.Text><b>{formatter.format(estadisticas.sumaEgresos)}</b></Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
}

export default CardDetallesBanco;
