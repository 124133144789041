import React from 'react';
import '../styles/tables.css';
import TablaPedidos from './componentes/tablaPedidos';

function AdminPedidos() {
  return (
    <div className='mx-2 mt-3'>
      <div className='d-flex flex-column flex-md-row align-items-center justify-content-between'>
        <p className='mb-2 mb-md-0 text-center'>
          Historial de pedidos realizados por tu tienda o por los asesores
        </p>
      </div>
      {/* Tabla de Pedidos */}
      <div className='mt-4'>
        <TablaPedidos />
      </div>
    </div>

  );
}

export default AdminPedidos;