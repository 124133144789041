import React from 'react';
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import { Whatsapp, EnvelopeAtFill, PinMapFill } from 'react-bootstrap-icons';
import { useCompanyInfo } from '../../hooks/useCompanyInfo';

export default function FloatingButton() {
    const { companyData } = useCompanyInfo();

    const encodedAddress = encodeURIComponent(`${companyData.direccion} ${companyData.ciudad} - ${companyData.departamento}`)

    const actions = [
        { icon: <Whatsapp style={{ color: '#25D366' }} />, name: 'WhatsApp', onClick: () => window.open(`https://wa.me/${companyData.telefono}`, '_blank') },
        { icon: <EnvelopeAtFill style={{ color: '#FF5733' }} />, name: 'Correo', onClick: () => window.open(`mailto:${companyData.email}`) },
        {
            icon: <PinMapFill style={{ color: '#FFC107' }} />,
            name: 'Ubicación',
            onClick: () => window.open(`https://www.google.com/maps/search/?api=1&query=${encodedAddress}`, '_blank')
        },
    ];
    return (
        <div className="position-fixed bottom-0 end-0 mb-3 me-3 z-3">
            <Box sx={{ position: 'fixed', bottom: 16, right: 16 }} >
                <SpeedDial
                    ariaLabel="Opciones de contacto"
                    icon={<SpeedDialIcon />}
                    direction="up"
                    FabProps={{
                        sx: {
                            bgcolor: '#e2304a',
                            color: '#ffffff',
                            '&:hover': {
                                bgcolor: '#d12641',
                            },
                        },
                    }}
                >
                    {actions.map((action) => (
                        <SpeedDialAction
                            key={action.name}
                            icon={action.icon}
                            tooltipTitle={action.name}
                            onClick={action.onClick}
                        />
                    ))}
                </SpeedDial>
            </Box>
        </div>
    );
}
