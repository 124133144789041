import React, { useRef, useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import imageCompression from 'browser-image-compression';
import Swal from 'sweetalert2';

const ImageCropperModal = ({ show, imageFile, onSave, onClose }) => {
    const cropperRef = useRef(null);
    const [imageSrc, setImageSrc] = useState(null);
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        if (imageFile) {
            const reader = new FileReader();
            reader.onload = () => setImageSrc(reader.result);
            reader.readAsDataURL(imageFile);
        } else {
            setImageSrc(null);
        }
    }, [imageFile]);

    const handleSave = async () => {
        if (!cropperRef.current || !cropperRef.current.cropper) return;

        setIsSaving(true);
        try {
            const croppedCanvas = cropperRef.current.cropper.getCroppedCanvas();
            if (!croppedCanvas) throw new Error('No se pudo obtener el canvas recortado');

            const croppedBlob = await new Promise((resolve) => croppedCanvas.toBlob(resolve, 'image/webp'));
            if (!croppedBlob) throw new Error('No se pudo convertir el canvas a un blob');

            const compressedBlob = await imageCompression(croppedBlob, {
                maxSizeMB: 1,
                useWebWorker: true,
            });

            const fileName = 'recorte.webp';
            const compressedFile = new File([compressedBlob], fileName, { type: 'image/webp' });
            onSave(compressedFile);
        } catch (error) {
            console.error('Error al comprimir la imagen:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Hubo un problema al procesar la imagen.',
            });
        } finally {
            setIsSaving(false);
            onClose();  // Cerrar el modal después de intentar guardar
        }
    };

    const handleCancel = () => {
        setImageSrc(null);
        setIsSaving(false);  // Asegurarse de que no esté guardando
        onClose();  // Cerrar el modal
    };

    return (
        <Modal show={show} onHide={handleCancel} backdrop='static' centered size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Recortar Imagen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {imageSrc ? (
                    <Cropper
                        ref={cropperRef}
                        src={imageSrc}
                        style={{ height: 400, width: '100%' }}
                        viewMode={1}          // Permitir ajustes de vista y recorte sin restricciones
                        guides={true}         // Mostrar guías de ayuda al recortar
                        movable={true}        // Permitir mover el área de recorte
                        scalable={true}       // Permitir cambiar el tamaño del área de recorte libremente
                        zoomable={true}       // Permitir hacer zoom en la imagen
                        background={false}    // Ocultar fondo detrás de la imagen
                        responsive={true}     // Ajustar el recorte al tamaño de contenedor
                        checkOrientation={false}
                    />
                ) : (
                    <p>No se ha seleccionado ninguna imagen.</p>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCancel} disabled={isSaving}>
                    Cancelar
                </Button>
                <Button variant="primary" onClick={handleSave} disabled={isSaving || !imageSrc}>
                    {isSaving ? 'Guardando...' : 'Guardar'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ImageCropperModal;
