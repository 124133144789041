import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';

function CardDetallesClientes({ detalles }) {
    // Función para formatear el monto con un valor por defecto si es null
    const formatAmount = (amount) => {
        if (amount === null) return '$ 0.00'; // Retorna un valor por defecto si amount es null
        return `$ ${amount.toLocaleString('es-ES')}`; // Formatear el monto
    };

    if (!detalles) {
        return <div>Cargando detalles...</div>; // Mostrar un mensaje de carga si no hay detalles
    }

    return (
        <div className='d-flex justify-content-center'>
            <Row className='text-center'>
                <Col xs={12} md={3} className='mb-4 d-flex justify-content-center'>
                    <Card bg='info' text='white' style={{ width: '18rem' }}>
                        <Card.Body>
                            <Card.Text>Facturas: <b>{detalles.total_pedidos?.count || 0}</b></Card.Text>
                            <Card.Text><b>{formatAmount(detalles.total_pedidos?.amount)}</b></Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={3} className='mb-4 d-flex justify-content-center'>
                    <Card bg='success' text='white' style={{ width: '18rem' }}>
                        <Card.Body>
                            <Card.Text>Facturas Pagadas: <b>{detalles.total_pagados?.count || 0}</b></Card.Text>
                            <Card.Text><b>{formatAmount(detalles.total_pagados?.amount)}</b></Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={3} className='mb-4 d-flex justify-content-center'>
                    <Card bg='warning' text='white' style={{ width: '18rem' }}>
                        <Card.Body>
                            <Card.Text>Facturas Pendientes: <b>{detalles.total_por_cobrar?.count || 0}</b></Card.Text>
                            <Card.Text><b>{formatAmount(detalles.total_por_cobrar?.amount)}</b></Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={3} className='mb-4 d-flex justify-content-center'>
                    <Card bg='danger' text='white' style={{ width: '18rem' }}>
                        <Card.Body>
                            <Card.Text>Facturas Vencidas: <b>{detalles.total_vencidos?.count || 0}</b></Card.Text>
                            <Card.Text><b>{formatAmount(detalles.total_vencidos?.amount)}</b></Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default CardDetallesClientes;
