import React, { useState, useEffect, useRef } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { Link, useParams, useNavigate } from 'react-router-dom'; // Importar useParams y useNavigate
import { PencilFill, PrinterFill, ThreeDotsVertical, TrashFill } from 'react-bootstrap-icons';
import { useReactToPrint } from 'react-to-print';
import CustomToggle from '../styles/customToggle';
import ComprobantePagoRes from './componentes/compPagoRes';
import EditarPagoRes from './componentes/editarPagoRes';
import AlertsPagosRecibidos from './componentes/Utils/AlertsPagosRecibidos';
import axios from 'axios';
import { API } from '../../../api/URL_API';
import APIserver from '../../../api/axiosConfig';

function DetallesPagoRes() {
    const [pago, setPago] = useState(null);
    const [editShow, setShowEdit] = useState(false); 
    const componentRef = useRef();
    const { idPago } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPagoDetails = async () => {
            try {
                const response = await APIserver.get(`/api/pagosrecibidos/pagosR/${idPago}`);
                
                const pagoData = response.data;

                // Asegurar el formato correcto para la fecha y el banco
                const formattedPago = {
                    ...pagoData,
                    fechaPago: new Date(pagoData.fechaPago).toISOString().split('T')[0], // Convertir la fecha a YYYY-MM-DD
                };

                setPago(formattedPago);
            } catch (error) {
                console.error('Error al obtener los detalles del pago:', error);
            }
        };

        if (idPago) {
            fetchPagoDetails();
        }
    }, [idPago]);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const handleEditShow = () => {
        setShowEdit(true);
    };

    const handleEditClose = () => {
        setShowEdit(false);
    };

    const handleDelete = () => {
        AlertsPagosRecibidos.showDeleteConfirmation(idPago, async () => {
            try {
                await APIserver.delete(`/api/pagosrecibidos/pagos/${idPago}`);
                AlertsPagosRecibidos.showDeleteConfirmationserver();
                navigate('/admin/PagosRecibidos/');
            } catch (error) {
                AlertsPagosRecibidos.showDeleteErrorMessage();
                console.error('Error al eliminar el pago:', error);
            }
        });
    };

    return (
        <div className='mx-2 mt-3 mt-md-0'>
            <div className='d-flex flex-column flex-md-row align-items-center justify-content-between'>
                <h4 className='mb-2 mb-md-0 text-center'>
                    Comprobante de pago recibido: <b>{pago ? `${pago.numPagoRe}` : 'Cargando...'}</b>
                </h4>
                <div className='d-flex mt-2 mt-md-0'>
                    <Button variant='primary' onClick={handlePrint} disabled={!pago}>
                        <PrinterFill className='me-2' /> Imprimir
                    </Button>
                    <Link to='../PagosRecibidos'>
                        <Button variant='secondary' className='ms-2'>
                            Volver
                        </Button>
                    </Link>
                    <Dropdown>
                        <Dropdown.Toggle as={CustomToggle} variant='secondary' className='ms-2'>
                            <ThreeDotsVertical />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={handleEditShow}>
                                <PencilFill className='me-2 text-warning' /> Editar
                            </Dropdown.Item>
                            <Dropdown.Item onClick={handleDelete}>
                                <TrashFill className='me-2 text-danger' /> Eliminar
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
            <div>
                {/* Card de comprobante */}
                <div ref={componentRef}>
                    <ComprobantePagoRes pago={pago} numPagoRe={pago ? pago.numPagoRe : ''} />
                </div>
            </div>

            {/* Modal para editar */}
            {pago && (
                <EditarPagoRes
                    show={editShow}
                    handleClose={handleEditClose}
                    pago={{
                        ...pago,
                        fecha: pago.fechaPago
                    }} 
                    fetchPagos={() => {
                        setShowEdit(false);
                        navigate('/admin/PagosRecibidos/');
                    }}
                />
            )}
        </div>
    );
}

export default DetallesPagoRes;