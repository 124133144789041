import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Table } from 'react-bootstrap';
import TablaPagosDetalles from './componentes/tablaPagosDetalles';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import APIserver from '../../../api/axiosConfig';

function DetallesCompra() {
  const { idCompra } = useParams();
  const [compra, setCompra] = useState(null);
  const [pagos, setPagos] = useState([]);

  useEffect(() => {
    const fetchCompraDetails = async () => {
      try {
        const response = await APIserver.get(`/api/compras/${idCompra}`);
        setCompra(response.data.compra); // Asegúrate de acceder a response.data.compra si es necesario
        setPagos(response.data.pagos || []); // Asegúrate de que los pagos estén en response.data.pagos
      } catch (error) {
        console.error('Error al obtener los detalles de la compra:', error);
      }
    };

    fetchCompraDetails();
  }, [idCompra]);

  if (!compra) {
    return <div>Cargando detalles de la compra...</div>;
  }

  return (
    <div className='m-3'>
      <Row>
        <Col xs={12} md={6} lg={4} className='mb-4 mb-md-0'>
          <Card>
            <Card.Header className='bg-primary-subtle'>Información General</Card.Header>
            <Table>
              <thead className='align-middle'>
                <tr>
                  <th className='text-end'>Factura</th>
                  <td className='text-black-50'>{compra.numCompra}</td>
                </tr>
                <tr>
                  <th className='text-end'>Proveedor</th>
                  <td>
                    <Link to={`/admin/Proveedores/DetallesProveedores/${compra.idProveedor}/`} className='text-decoration-none'>
                      {compra.nombreProveedor}
                    </Link>
                  </td>
                </tr>
                <tr>
                  <th className='text-end'>Creación</th>
                  <td className='text-black-50'>{new Date(compra.creacion).toLocaleDateString()}</td>
                </tr>
                <tr>
                  <th className='text-end'>Vence</th>
                  <td className='text-black-50'>{new Date(compra.vence).toLocaleDateString()}</td>
                </tr>
                <tr>
                  <th className='text-end'>Total</th>
                  <td className='text-black-50'>
                    {new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 0 }).format(compra.totalCompra)}
                  </td>
                </tr>
                <tr>
                  <th className='text-end'>Estado</th>
                  <td className={`text-${compra.estado === 'pagada' ? 'success' : compra.estado === 'vencida' ? 'danger' : 'warning'}`}>
                    {compra.estado === 'pagada' ? 'Pagada' : compra.estado === 'vencida' ? <span style={{ color: 'red' }}>Vencida</span> : 'Pendiente'}
                  </td>
                </tr>
              </thead>
            </Table>
          </Card>
        </Col>
        <Col xs={12} md={6} lg={8}>
          <Card>
            <Card.Header className='bg-primary-subtle'>Pagos Realizados</Card.Header>
            <TablaPagosDetalles pagos={pagos} idProveedor={compra.idProveedor} />
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default DetallesCompra;
