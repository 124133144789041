import * as React from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import { Card, Button } from '@mui/material';
import InfoUsuario from './componenetes/infoUsuario';
import InfoEmpresa from './componenetes/infoEmpresa';
import InfoTienda from './componenetes/infoTienda';

const steps = ['Registro de usuario', 'Registro de empresa', 'Personaliza tu tienda'];

export default function RegisterPage() {
    const { token } = useParams();
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());

    const isStepSkipped = (step) => skipped.has(step);

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return <InfoUsuario token={token} onNext={handleNext} />;
            case 1:
                return <InfoEmpresa onNext={handleNext} />;
            case 2:
                return <InfoTienda onNext={handleNext} />;
            default:
                return 'Unknown step';
        }
    };

    return (
        <Card className='m-4'>
            <Box className='m-4'>
                <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => {
                        const stepProps = {};
                        const labelProps = {};
                        if (isStepSkipped(index)) {
                            stepProps.completed = false;
                        }
                        return (
                            <Step key={label} {...stepProps}>
                                <StepLabel {...labelProps}>{label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
                {activeStep === steps.length ? (
                    <React.Fragment>
                        <Typography sx={{ mt: 2, mb: 1 }}>
                            Todos los pasos completados - has terminado
                        </Typography>
                        <Button onClick={handleReset}>Reset</Button>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {getStepContent(activeStep)}
                    </React.Fragment>
                )}
            </Box>
        </Card>
    );
}
