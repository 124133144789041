import React, { useState, useEffect, useRef } from 'react';
import { Modal, Row, Col, Button, ButtonGroup, Form, InputGroup, Toast } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Pagination, Autoplay } from 'swiper/modules'; // Agregar Autoplay
import '../../styles/PaginationDetalles.css';
import { useCarrito } from './CarritoContext'; // Importar el contexto del carrito
import { ArrowCounterclockwise, BoxSeamFill, CaretDownFill, CartFill, DashLg, PlusLg, Truck } from 'react-bootstrap-icons';
import { Accordion, AccordionDetails, AccordionSummary, Alert, Snackbar, Typography } from '@mui/material';

function DetallesProductoUni({
    idProducto,
    images = [],
    name,
    price,
    descripcion,
    showModal,
    handleCloseModal,
    quantity,
    setQuantity,
    incrementQuantity,
    decrementQuantity,
    colors = [],
    selectedColor: initialSelectedColor,
    sizes = [],
    selectedSize: initialSelectedSize,
    wholesalePrice,
    stock
}) {
    const [localSelectedColor, setLocalSelectedColor] = useState(initialSelectedColor || null);
    const [localSelectedSize, setLocalSelectedSize] = useState(initialSelectedSize || null);
    const [showToast, setShowToast] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [selectedThumbnail, setSelectedThumbnail] = useState(images[0]);
    const swiperRef = useRef(null);
    const [expanded, setExpanded] = React.useState(false);

    const handleExpansion = () => {
        setExpanded((prevExpanded) => !prevExpanded);
    };

    const selectedColorObj = colors.find(color => color.idColor === localSelectedColor);
    const selectedSizeObj = sizes.find(size => size.idTalla === localSelectedSize);
    // Obtener la función addToCarrito del contexto
    const { addToCarrito } = useCarrito();

    useEffect(() => {
        setLocalSelectedColor(initialSelectedColor);
        setLocalSelectedSize(initialSelectedSize);
    }, [initialSelectedColor, initialSelectedSize]);

    // Resetear selección al cerrar el modal
    const resetSelections = () => {
        setLocalSelectedColor(null);
        setLocalSelectedSize(null);
        setQuantity(1); // Resetea la cantidad a 1 al cerrar el modal
    };

    const handleAddProductToCart = () => {
        // Validación de selección
        if ((colors.length > 0 && !localSelectedColor) || (sizes.length > 0 && !localSelectedSize)) {
            setErrorMessage('Por favor selecciona una opción antes de agregar al carrito.');
            return;
        }

        // Limpiar el mensaje de error si se seleccionó correctamente
        setErrorMessage('');

        // Crear el objeto de datos del producto
        const productData = {
            idProducto: idProducto,
            nomProducto: name,
            precioU: price,
            precioM: wholesalePrice,
            descripcion,
            imgProducto: images[0],
            stock: stock,
            quantity: quantity,
            selectedColor: selectedColorObj ? {
                id: selectedColorObj.idColor,
                hexCode: selectedColorObj.hexCode
            } : null,
            selectedSize: selectedSizeObj ? {
                id: selectedSizeObj.idTalla,
                nombre: selectedSizeObj.nombreTalla
            } : null
        };

        addToCarrito(productData);
        setShowToast(true);
        resetSelections();
    };

    // Incrementa la cantidad, pero no permite que supere el stock
    const handleIncrementQuantity = () => {
        if (quantity < stock) {
            setQuantity(quantity + 1);
        }
    };

    // Decrementa la cantidad, pero no permite que sea menor que 1
    const handleDecrementQuantity = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    };

    // Filtrar las imágenes que no sean null
    const filteredImages = images.filter((img) => img !== null);

    // Función para formatear valores como moneda COP
    const formatCop = (value) => {
        return value?.toLocaleString('es-CO', {
            style: 'currency',
            currency: 'COP',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        }) || '$0';
    };

    return (
        <>
            <Modal size='xl' show={showModal} onHide={() => {
                handleCloseModal();
                resetSelections();
            }}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={6}>
                            <Swiper
                                modules={[Pagination, Autoplay]}
                                onSwiper={(swiper) => {
                                    swiperRef.current = swiper;
                                }}
                                spaceBetween={50}
                                slidesPerView={1}
                                pagination={{ clickable: true }}
                                autoplay={{ delay: 3000, disableOnInteraction: false }}
                                onSlideChange={(swiper) => setSelectedThumbnail(images[swiper.realIndex])}
                                className="custom-swiper"
                            >
                                {images.map((image, idx) => (
                                    <SwiperSlide key={idx}>
                                        <img
                                            src={image}
                                            alt={`slide-${idx}`}
                                            className="img-fluid mb-3"
                                            style={{
                                                maxHeight: '400px',
                                                width: '100%',
                                                objectFit: 'contain'
                                            }}
                                        />
                                    </SwiperSlide>
                                ))}
                            </Swiper>

                            <Row className="mt-3 d-flex justify-content-center">
                                {images.map((image, idx) => (
                                    <Col xs={3} key={idx} className="p-1">
                                        <img
                                            src={image}
                                            alt={`thumbnail-${idx}`}
                                            className={`img-fluid small-thumbnail ${selectedThumbnail === image ? 'selected-thumbnail' : ''}`}
                                            style={{
                                                maxHeight: '80px',
                                                objectFit: 'cover',
                                                borderRadius: '5px'
                                            }}
                                            onClick={() => {
                                                setSelectedThumbnail(image);
                                                swiperRef.current.slideTo(idx); // Mueve a la imagen en Swiper al hacer clic en miniatura
                                            }}
                                        />
                                    </Col>
                                ))}
                            </Row>
                        </Col>
                        <Col md={6} className="p-4 rounded text-center">
                            <h3 className='fw-bold text1-cr mb-3 fst-italic'>{name}</h3>
                            {/*<p><strong>Cantidad disponible:</strong> <span className="text-muted">{stock}</span></p>*/}
                            <h5 className='fw-bold'><strong className='text1-cr'>Precio</strong> <span className="text-light title-seccion">{formatCop(price)}</span></h5>
                            <h6 className='fw-bold'><strong className='text1-cr'>Gran Mayorista {formatCop(wholesalePrice)}</strong></h6>
                            <p className="text-muted mt-2">{descripcion}</p>

                            <div
                                className='d-flex flex-column flex-sm-row justify-content-between justify-content-sm-center align-items-center mb-3'
                                style={{ overflowX: 'auto', flexWrap: 'wrap', gap: '0.5rem' }}
                            >
                                {colors.length > 0 && (
                                    <div className="text-center text-sm-left">
                                        <strong className='text1-cr'>Color</strong>
                                        <ButtonGroup className="d-flex justify-content-center">
                                            {colors.map((color) => (
                                                <Button
                                                    key={color.idColor}
                                                    variant="outline-secondary"
                                                    className={`color-button ${localSelectedColor === color.idColor ? 'selected' : ''}`}
                                                    style={{
                                                        backgroundColor: localSelectedColor === color.idColor ? color.hexCode : 'transparent',
                                                        borderColor: color.hexCode,
                                                        width: '2.5rem',
                                                        height: '2.5rem',
                                                        borderRadius: '50%',
                                                        margin: '0 0.5rem',
                                                        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                                        boxShadow: localSelectedColor === color.idColor ? '0 0 10px rgba(0, 0, 0, 0.2)' : 'none'
                                                    }}
                                                    onClick={() => setLocalSelectedColor(color.idColor)}
                                                />
                                            ))}
                                        </ButtonGroup>
                                    </div>
                                )}

                                {sizes.length > 0 && (
                                    <div className="text-center text-sm-left">
                                        <strong className='text1-cr'>Motivos</strong>
                                        <ButtonGroup className="d-flex justify-content-center">
                                            {sizes.map((size) => (
                                                <Button
                                                    key={size.idTalla}
                                                    variant={localSelectedSize === size.idTalla ? "primary" : "outline-primary"}
                                                    className={`size-button ${localSelectedSize === size.idTalla ? 'selected' : ''}`}
                                                    style={{
                                                        margin: '0 0.5rem',
                                                        padding: '0.5rem 1rem',
                                                        borderRadius: '0.25rem',
                                                        transition: 'background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease',
                                                        backgroundColor: localSelectedSize === size.idTalla ? '#2b235e' : 'transparent',
                                                        color: localSelectedSize === size.idTalla ? '#fff' : '#2b235e',
                                                        fontWeight: localSelectedSize === size.idTalla ? 'bold' : 'normal',
                                                        boxShadow: localSelectedSize === size.idTalla ? '0 0 10px rgba(0, 0, 0, 0.2)' : 'none',
                                                        borderColor: '#2b235e'
                                                    }}
                                                    onClick={() => setLocalSelectedSize(size.idTalla)}
                                                >
                                                    {size.nombreTalla}
                                                </Button>
                                            ))}
                                        </ButtonGroup>
                                    </div>
                                )}
                            </div>

                            {errorMessage && (
                                <p className="text-danger text-center small">{errorMessage}</p>
                            )}

                            <Form.Group controlId="quantity" className="text-center">
                                <Row>
                                    <Col xs={6} className="d-flex justify-content-center align-items-center">
                                        <InputGroup className='mt-2 w-100'>
                                            <Button variant="danger" className='button-decrement' onClick={handleDecrementQuantity}><DashLg size={18} /></Button>
                                            <Form.Control
                                                className="text-center"
                                                type="number"
                                                value={quantity}
                                                onChange={(e) => setQuantity(Math.min(Number(e.target.value), stock))}
                                                min="1"
                                                max={stock}
                                            />
                                            <Button variant='success' className='button-increment' onClick={handleIncrementQuantity}><PlusLg size={18} /></Button>
                                        </InputGroup>
                                    </Col>

                                    <Col xs={6} className="d-flex justify-content-center align-items-center">
                                        <button
                                            onClick={handleAddProductToCart}
                                            className="mt-2 w-100 button-car d-flex justify-content-center align-items-center"
                                        >
                                            <CartFill className="me-2" />
                                            <span className="d-inline d-sm-none">Añadir</span>
                                            <span className="d-none d-sm-inline">Añadir al carrito</span>
                                        </button>
                                    </Col>
                                </Row>
                            </Form.Group>

                            <div className="mt-3 mt-md-5">

                                <Accordion sx={{ backgroundColor: '#2b235e', boxShadow: 3, borderRadius: '8px', mb: 2 }}>
                                    <AccordionSummary
                                        expandIcon={<CaretDownFill style={{ color: '#f8f9fa' }} />}
                                        aria-controls="panel2-content"
                                        id="panel2-header"
                                        sx={{ '& .MuiAccordionSummary-content': { alignItems: 'center' } }}
                                        className='text-light'
                                    >
                                        <Typography sx={{ fontWeight: 'bold', fontSize: '1rem' }}>
                                            <Truck style={{ marginRight: 8, color: '#f8f9fa' }} /> Envío Rápido y Seguro, ¡Estés donde estes!
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography sx={{ color: '#f8f9fa', lineHeight: 1.7 }} className='text-md-start'>
                                            Nuestros envíos son rápidos y confiables:
                                            <ul>
                                                <li><strong>Tiempo de entrega</strong>: De 2 a 5 días hábiles para destinos nacionales.</li>
                                                <li><strong>Seguimiento en tiempo real</strong>: Recibirás un código, foto real de la guía de rastreo junto con evidencias de envió para tu tranquilidad, de esta manera podrás rastrear tu paquete y saber en qué punto de la ruta se encuentra.</li>
                                                <li><strong>Embalaje seguro</strong>: Nos aseguramos de que el producto llegue en perfectas condiciones.</li>
                                                <li><strong>Costo de envío</strong>: El costo varía dependiendo la ciudad destino (sujeto a costos de la transportadora).</li>
                                            </ul>
                                            <strong>¿Urgente?</strong> Tenemos opciones de envío exprés para entregas prioritarias en la ciudad de Bogotá.
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>

                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <button className='button-cr' onClick={() => {
                        handleCloseModal();
                        resetSelections();
                    }}>
                        Cerrar
                    </button>
                </Modal.Footer>
            </Modal>

            {/* TOAST alerta al agregar producto al carrito*/}
            <Snackbar
                open={showToast}
                autoHideDuration={3000}
                onClose={() => setShowToast(false)}
            >
                <Alert
                    onClose={() => setShowToast(false)}
                    severity='success'
                    variant='filed'
                    sx={{ position: 'fixed', top: '20px', right: '30px', zIndex: 9999, backgroundColor: '#1b4621' }}
                    className='text-light'
                >
                    Producto agregado al carrito correctamente.
                </Alert>
            </Snackbar>

        </>
    );
}

export default DetallesProductoUni;
