import React, { useState, useEffect } from 'react';
import { Table, Pagination, Button, Form, ButtonGroup } from 'react-bootstrap';
import { Filter, EyeFill, PencilFill, TrashFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import FiltrosBancos from './filtrosBancos';
import EditarBanco from './editarBancoModal';
import { handleDeleteBanco } from '../Utils/AlertsBancos';
import APIserver from '../../../../api/axiosConfig';

function TablaBancos() {
    const [bancos, setBancos] = useState([]);
    const [totalBancos, setTotalBancos] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [showFilters, setShowFilters] = useState(false);
    const [editShow, setShowEdit] = useState(false);
    const [bancoId, setBancoId] = useState(null);

    const formatCurrency = (value) => {
        return `$ ${value.toLocaleString('es-CO')}`;
    };

    // Función para cargar los bancos con paginación y filtros
    const fetchBancos = async (page, limit, filters = {}) => {
        try {
            const response = await APIserver.get(`/api/bancos`, {
                params: {
                    page,
                    limit,
                    nomBanco: filters.nomBanco,
                    tipoCuenta: filters.tipoCuenta,
                    numeroCuenta: filters.numeroCuenta,
                    saldoBanco: filters.saldoBanco,
                    ingresosBanco: filters.ingresosBanco,
                    egresosBanco: filters.egresosBanco
                }
            });
            setBancos(response.data.bancos);
            setTotalBancos(response.data.total);
        } catch (error) {
            console.error('Error al obtener los bancos:', error);
        }
    };

    useEffect(() => {
        fetchBancos(currentPage, rowsPerPage, { nomBanco: '', tipoCuenta: '', numeroCuenta: '', saldoBanco: '', ingresosBanco: '', egresosBanco: '' });
    }, [currentPage, rowsPerPage]);

    const loadBancos = () => {
        fetchBancos(currentPage, rowsPerPage, { nomBanco: '', tipoCuenta: '', numeroCuenta: '', saldoBanco: '', ingresosBanco: '', egresosBanco: '' });
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleSearch = (filters) => {
        fetchBancos(currentPage, rowsPerPage, filters);
    };

    const handleClear = () => {
        fetchBancos(currentPage, rowsPerPage, { nomBanco: '', tipoCuenta: '', numeroCuenta: '', saldoBanco: '', ingresosBanco: '', egresosBanco: '' });
    };

    const toggleFilters = () => {
        setShowFilters(!showFilters);
    };

    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setCurrentPage(1);
    };

    const handleEditClose = () => {
        setShowEdit(false);
        setBancoId(null);
    };

    const handleEditShow = (bancoId) => {
        setShowEdit(true);
        setBancoId(bancoId);
    };

    const handleDelete = async (idBanco) => {
        handleDeleteBanco(idBanco, loadBancos);
    };

    // Función para renderizar los botones de paginación
    const renderPaginationItems = () => {
        const items = [];
        const totalPages = Math.ceil(totalBancos / rowsPerPage); // Total de páginas calculado
        const pageRangeDisplayed = 5; // Número de páginas visibles
        let startPage = Math.max(1, currentPage - Math.floor(pageRangeDisplayed / 2));
        let endPage = Math.min(totalPages, startPage + pageRangeDisplayed - 1);

        if (endPage - startPage < pageRangeDisplayed - 1) {
            startPage = Math.max(1, endPage - pageRangeDisplayed + 1);
        }

        if (startPage > 1) {
            items.push(<Pagination.First key="first" onClick={() => handlePageChange(1)} />);
        }

        if (currentPage > 1) {
            items.push(<Pagination.Prev key="prev" onClick={() => handlePageChange(currentPage - 1)} />);
        }

        for (let number = startPage; number <= endPage; number++) {
            items.push(
                <Pagination.Item
                    key={number}
                    active={number === currentPage}
                    onClick={() => handlePageChange(number)}
                >
                    {number}
                </Pagination.Item>
            );
        }

        if (currentPage < totalPages) {
            items.push(<Pagination.Next key="next" onClick={() => handlePageChange(currentPage + 1)} />);
        }

        if (endPage < totalPages) {
            items.push(<Pagination.Last key="last" onClick={() => handlePageChange(totalPages)} />);
        }

        return items;
    };

    return (
        <div style={{ boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)" }}>
            <Table responsive className='text-center align-middle border'>
                <thead>
                    <tr>
                        <th colSpan={7}>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='d-flex flex-wrap'>
                                    <Button variant='link' size='sm' onClick={toggleFilters} title='filtrar'>
                                        <Filter className='m-0' size={20} />
                                    </Button>
                                </div>
                                <div>
                                    <Form.Select
                                        className='ms-2'
                                        value={rowsPerPage}
                                        onChange={handleChangeRowsPerPage}
                                    >
                                        <option value='5'>5</option>
                                        <option value='10'>10</option>
                                        <option value='20'>20</option>
                                        <option value='50'>50</option>
                                    </Form.Select>
                                </div>
                            </div>
                            <div className={`filter-options ${showFilters ? 'show' : ''}`}>
                                <FiltrosBancos onSearch={handleSearch} onClear={handleClear} />
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <th>Nombre</th>
                        <th>Cuenta</th>
                        <th>Número de Cuenta</th>
                        <th>Saldo</th>
                        <th>Ingresos</th>
                        <th>Egresos</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {bancos.map((banco) => (
                        <tr key={banco.idBanco}>
                            <td><Link to={`/admin/Bancos/DetallesBancos/${banco.idBanco}/`} className='text-decoration-none'>{banco.nomBanco}</Link></td>
                            <td>{banco.tipoCuenta}</td>
                            <td>{banco.numeroCuenta}</td>
                            <td>{formatCurrency(banco.saldoFinal)}</td>
                            <td>{formatCurrency(banco.ingresosBanco)}</td>
                            <td>{formatCurrency(banco.egresosBanco)}</td>
                            <td>
                                <ButtonGroup aria-label='action-button'>
                                    <Link to={`/admin/Bancos/DetallesBancos/${banco.idBanco}/`}>
                                        <Button variant='link' className='text-primary' title='ver banco'>
                                            <EyeFill size={18} />
                                        </Button>
                                    </Link>
                                    <Button variant='link' className='text-warning' title='editar banco' onClick={() => handleEditShow(banco.idBanco)}>
                                        <PencilFill size={18} />
                                    </Button>
                                    <Button variant='link' className='text-danger' title='eliminar banco' onClick={() => handleDelete(banco.idBanco)}>
                                        <TrashFill size={18} />
                                    </Button>
                                </ButtonGroup>
                            </td>
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan={7}>
                            <div className='d-flex justify-content-center align-items-center'>
                                <Pagination>
                                    {renderPaginationItems()}
                                </Pagination>
                            </div>
                        </td>
                    </tr>
                </tfoot>
            </Table>
            <EditarBanco show={editShow} handleClose={handleEditClose} bancoId={bancoId} onUpdate={loadBancos} />
        </div>
    );
}

export default TablaBancos;
