// src/components/DecoLogin.js
import React from 'react';
import { Container } from 'react-bootstrap';

function DecoLogin() {
    return (
        <Container className="text-center d-flex flex-column justify-content-center">
            <h1 className="my-5 display-3 fw-bold ls-tight px-3">
                La mejor oferta <br />
                <span className="text-primary">para tu negocio.</span>
            </h1>
            <p className='px-3' style={{ color: 'hsl(217, 10%, 50.8%)' }}>
                <b>TuBisnez</b> combina las propiedades de un software
                administrativo con las de una tienda e-commerce permitiéndote tener control
                absoluto de todas tus ventas en puntos físicos y virtuales.
            </p>
        </Container>
    );
}

export default DecoLogin;