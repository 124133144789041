import React, { useState } from 'react';
import { FormControl, Button, ButtonGroup } from 'react-bootstrap';
import { Search, XSquare } from 'react-bootstrap-icons';

function FiltrosPagos({ updateTable }) {
  const [filtroPago, setFiltroPago] = useState('');
  const [filtroFactura, setFiltroFactura] = useState('');
  const [filtroProveedor, setFiltroProveedor] = useState('');
  const [filtroCreacion, setFiltroCreacion] = useState('');
  const [filtroCuenta, setFiltroCuenta] = useState('');
  const [filtroMonto, setFiltroMonto] = useState('');

  const handleSearch = () => {
    // Llamar a la función para actualizar la tabla con los filtros
    updateTable({
      filtroPago,
      filtroFactura,
      filtroProveedor,
      filtroCreacion,
      filtroCuenta,
      filtroMonto
    });
  };

  const handleClear = () => {
    // Limpiar los estados de los filtros
    setFiltroPago('');
    setFiltroFactura('');
    setFiltroProveedor('');
    setFiltroCreacion('');
    setFiltroCuenta('');
    setFiltroMonto('');

    // Actualizar la tabla sin filtros
    updateTable({
      filtroPago: '',
      filtroFactura: '',
      filtroProveedor: '',
      filtroCreacion: '',
      filtroCuenta: '',
      filtroMonto: ''
    });
  };

  return (
    <div className='d-flex align-items-center m-3'>
      <FormControl
        placeholder='Busca por Pago'
        className='me-2'
        value={filtroPago}
        onChange={(e) => setFiltroPago(e.target.value)}
      />
      <FormControl
        placeholder='Busca por Factura'
        className='me-2'
        value={filtroFactura}
        onChange={(e) => setFiltroFactura(e.target.value)}
      />
      <FormControl
        placeholder='Busca por Proveedor'
        className='me-2'
        value={filtroProveedor}
        onChange={(e) => setFiltroProveedor(e.target.value)}
      />
      <FormControl
        type='date'
        className='me-2'
        value={filtroCreacion}
        onChange={(e) => setFiltroCreacion(e.target.value)}
      />
      <FormControl
        placeholder='Busca por Cuenta'
        className='me-2'
        value={filtroCuenta}
        onChange={(e) => setFiltroCuenta(e.target.value)}
      />
      <FormControl
        placeholder='Busca por Monto'
        className='me-2'
        value={filtroMonto}
        onChange={(e) => setFiltroMonto(e.target.value)}
      />
      <ButtonGroup>
        <Button variant='primary' title='Buscar' onClick={handleSearch}>
          <Search />
        </Button>
        <Button variant='danger' title='Limpiar' onClick={handleClear}>
          <XSquare />
        </Button>
      </ButtonGroup>
    </div>
  );
}

export default FiltrosPagos;
