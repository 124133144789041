// File path: src/pages/Admin/Dashboard/componentes/Welcome.js
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import useAuth from '../../../../hooks/useAuth';

const Welcome = () => {
    const { auth } = useAuth();

    //Dividir nombre completo por espacios y solo tomar el primero
    const firstName = auth.user.name.split(' ')[0];
    return (
        <div className="p-4 bg-light rounded-4">
            <Row>
                <Col>
                    <h1>Bienvenido {firstName}!! 👋🏼</h1>
                </Col>
            </Row>
        </div>
    );
};

export default Welcome;
