import React, { useEffect, useState } from 'react';
import { Navbar, Button, Container, Modal, Nav, Offcanvas } from 'react-bootstrap';
import { TextField } from '@mui/material';
import { CartFill, List, Search, ArrowLeft, ChevronRight } from 'react-bootstrap-icons';
import { useSearch } from '../ventaUnidad/componenetes/SearchContext';
import { useCarrito } from '../ventaUnidad/componenetes/CarritoContext';
import { useNavigate, useLocation, Link, useParams } from 'react-router-dom';
import CarUni from '../ventaUnidad/componenetes/carUni';
import axios from 'axios';
import { X } from 'react-bootstrap-icons';
import { API } from '../../../api/URL_API';
import { useTiendaConfig } from '../../../hooks/useTiendaConfig';
import logo from '../../../utils/cr1-logo.png';
import icon from '../../../utils/icon.png'
import getIcon from '../../../utils/getIcon';
import { useSucursal } from '../../../contexts/SucursalContext';

function NavbarUni() {
    const { searchTerm, setSearchTerm } = useSearch();
    const [show, setShow] = useState(false);
    const [searchVisible, setSearchVisible] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [secciones, setSecciones] = useState([]);
    const [isColeccionOpen, setIsColeccionOpen] = useState(false);
    const [selectedSeccion, setSelectedSeccion] = useState(null);
    const { carrito } = useCarrito();
    const navigate = useNavigate();
    const { idSucursal: contextIdSucursal } = useSucursal(); // Obtenemos idSucursal desde el contexto
    const { idSucursal: paramIdSucursal } = useParams(); // Obtenemos idSucursal desde los parámetros de la URL
    const location = useLocation();
    const { socialLinks, storeInfo } = useTiendaConfig();
    const [aspectRatio, setAspectRatio] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);

    // Lógica para decidir el idSucursal final (URL > Contexto)
    const idSucursal = paramIdSucursal || contextIdSucursal;

    // Verificar el idSucursal que estamos obteniendo
    useEffect(() => {
     }, [idSucursal]);

    const closeCar = () => setShow(false);
    const showCar = () => setShow(true);

    const totalItems = carrito.reduce((total, item) => total + item.quantity, 0);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSearch = () => {
        if (searchTerm.trim() !== '') {
            navigate(`/Collections/${idSucursal}/Products/all?searchTerm=${encodeURIComponent(searchTerm)}`);
            setSearchVisible(false); // Cierra el modal de búsqueda al buscar
        }
    };

    const redirectToRoot = () => {
        navigate(`/${idSucursal}`);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    const handleSearchIconClick = () => {
        setSearchVisible(true);
        setMenuOpen(false); // Cierra el menú hamburguesa al abrir la búsqueda
    };

    const handleCloseSearch = () => {
        setSearchVisible(false);
        setSearchTerm('');
    };

    // Efecto para cargar las secciones
    useEffect(() => {
        axios.get(`${API}/api/mostrarCategorias/secciones-categorias`)
            .then(response => {
                setSecciones(response.data);
            })
            .catch(error => {
                console.error('Error al obtener las secciones', error);
            });
    }, []);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
        if (!menuOpen) {
            setIsColeccionOpen(false);
            setSelectedSeccion(null);
        }
    };

    const openColeccion = () => {
        setIsColeccionOpen(true);
    };

    const closeColeccion = () => {
        setIsColeccionOpen(false);
        setSelectedSeccion(null);
    };

    const selectSeccion = (seccion) => {
        setSelectedSeccion(seccion);
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const searchTermFromURL = queryParams.get('searchTerm');
        if (!searchTermFromURL) {
            setSearchTerm('');
        }
    }, [location.search, setSearchTerm]);

    const handleImageLoad = (e) => {
        const { naturalWidth, naturalHeight } = e.target;
        setAspectRatio(naturalWidth / naturalHeight);
        setIsLoaded(true); // Cambiar estado a "cargado" cuando la imagen termina de cargar
    };
    return (
        <>
            <Navbar expand='lg' className="navbar-cr mt-3 mx-2 mx-md-4 py-3">
                <Container fluid>
                    <Navbar.Brand className='d-lg-none text-start' style={{ marginLeft: '15px' }}>
                        <img
                            src={logo}
                            width='110'
                            alt='crishaCaps'
                            style={{ objectFit: 'cover' }} 
                        />
                    </Navbar.Brand>
                    <div className='d-none d-lg-block position-absolute start-50 translate-middle-x'>
                        <img
                            src={logo}
                            width='150'
                            alt='crishaCaps'
                            style={{ objectFit: 'cover' }}
                        />
                    </div>
                    <Nav className="me-auto d-none d-lg-flex ms-4">

                        <Button
                            variant='link'
                            className='nav-link text-light fw-semibold fs-6'
                            onClick={redirectToRoot}
                        >
                            <img
                                src={icon}
                                alt='logo'
                                width='20'
                                height='20'
                                style={{ marginRight: '8px' }}
                            />
                            Home
                        </Button>

                        <div
                            className="position-relative"
                            onMouseEnter={() => setIsColeccionOpen(true)}
                            onMouseLeave={() => setIsColeccionOpen(false)}
                        >
                        <Link to={`/Collections/${idSucursal}`} className='nav-link d-flex align-items-center text-light fw-semibold fs-6'>
                            Colección
                        </Link>


                        </div>

                        <Button
                            onClick={() => {
                                window.location.href = `/Collections/${idSucursal}/Products/All`;
                            }}
                            className='nav-link text-light fw-semibold fs-6'
                        >
                            Gorras
                        </Button>

                    </Nav>

                    <div className='d-flex align-items-center ms-auto d-none d-lg-flex me-4'>
                        <Button variant='link' onClick={handleSearchIconClick} title='Buscar' className='me-2 link-light'>
                            <Search size={20} />
                        </Button>

                        <button
                            onClick={showCar}
                            title='Carrito de compra'
                            className='d-flex align-items-center button'
                            style={{ marginLeft: '10px' }}
                        >
                            <span>{totalItems}</span>
                            <CartFill className="ms-1 d-lg-none" size={14} />
                            <CartFill className="ms-2 d-none d-lg-inline" size={20} />
                            <span className='d-none d-lg-inline fs-6'> Carrito</span>
                        </button>
                    </div>

                    <div className='d-flex align-items-center ms-auto d-lg-none'>
                        <Button variant='link' onClick={toggleMenu} aria-controls="offcanvasNavbar">
                            <List size={24} className='text-light' />
                        </Button>

                        <button variant='outline-primary' onClick={showCar} title='Carrito de compra' className='d-flex align-items-center ms-2 button'>
                            <CartFill size={20} />
                            <span className='ms-1'>{totalItems}</span>
                        </button>
                    </div>
                </Container>
            </Navbar>

            {/* Agregamos aquí el menú desplegable */}
            {isColeccionOpen && (
                <div
                    className="dropdown-menu-custom bg-white shadow rounded p-3 position-absolute show d-none d-lg-block"
                    style={{ top: '110px', left: '0', zIndex: '100', width: '100%' }}
                    onMouseEnter={() => setIsColeccionOpen(true)}
                    onMouseLeave={() => setIsColeccionOpen(false)}
                >
                    <div className="row">
                        {secciones.map((seccion, index) => (
                            <div key={index} className="col-md-4">
  <Button as={Link} to={`/Collections/${idSucursal}/${seccion.nomSeccion}`} variant='link' className='text-decoration-none'>
    <h5 className="fw-bold d-flex align-items-center text1-cr">
        {seccion.nomSeccion}
    </h5>
</Button>

                                <Nav className="flex-column">
                                    {seccion.categorias.map((categoria) => (
                                        <Button
                                            key={categoria.nomCategoria}
                                            onClick={() => {
                                                window.location.href = `/Collections/${idSucursal}/Products/${categoria.nomCategoria}`;
                                            }}
                                            className="dropdown-item py-1 d-flex align-items-center text1-cr"
                                        >
                                            {categoria.nomCategoria}
                                        </Button>
                                    ))}
                                </Nav>
                            </div>
                        ))}
                    </div>
                </div>
            )}

            <Offcanvas show={menuOpen} onHide={toggleMenu} placement="start" className='bg-light'>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className='text1-cr'>Menú</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className={`menu-section ${!isColeccionOpen && !selectedSeccion ? 'active' : ''}`}>
                        <Nav className="flex-column">
                            <Button
                                variant='link'
                                className='nav-link text1-cr'
                                onClick={redirectToRoot}
                            >
                                Home
                            </Button>
                            <Button variant='link' className='nav-link text-start d-flex align-items-center text1-cr' onClick={openColeccion}>
                                Colección <ChevronRight className="ms-auto " />
                            </Button>
                            <Button
                                variant='link'
                                onClick={() => {
                                    window.location.href = `/Collections/${idSucursal}/Products/All`;
                                }}
                                className='nav-link text1-cr'
                            >
                                Gorras
                            </Button>

                            <Button variant='link' className='nav-link text-start d-flex align-items-center text1-cr' onClick={handleSearchIconClick}>
                                Buscar
                            </Button>
                        </Nav>
                    </div>

                    <div className={`text1-cr menu-section ${isColeccionOpen && !selectedSeccion ? 'active' : ''}`}>
                        <div className="d-flex align-items-center mb-3 justify-content-between">
                            <Button variant='link' onClick={closeColeccion} className='d-flex align-items-center me-2'>
                                <ArrowLeft size={24} className='text1-cr' />
                            </Button>
                            <h5 className="fw-bold mb-0 button fst-italic" style={{ marginRight: '90px' }}>Colecciones</h5>
                        </div>

                        <Nav className="flex-column">
                            {secciones.map((seccion) => (
                                <Button
                                    key={seccion.idSeccion}
                                    variant='link'
                                    className='nav-link text-start d-flex align-items-center text1-cr'
                                    onClick={() => selectSeccion(seccion)}
                                >
                                    {seccion.nomSeccion} <ChevronRight className="ms-auto text1-cr" />
                                </Button>
                            ))}
                        </Nav>
                    </div>

                    <div className={`text1-cr menu-section ${selectedSeccion ? 'active' : ''}`}>
                        <div className="d-flex align-items-center mb-3 justify-content-start" style={{ width: '80%' }}>
                            <Button variant='link' onClick={() => setSelectedSeccion(null)} className='d-flex align-items-center me-2'>
                                <ArrowLeft size={24} className='text1-cr' />
                            </Button>
                            <h5 className="fw-bold mb-0 ms-4 button">
                                {selectedSeccion?.nomSeccion}
                            </h5>
                        </div>
                        <Nav className="flex-column">
                            {selectedSeccion?.categorias.map((categoria) => (
                                <Button
                                    variant='link'
                                    key={categoria.nomCategoria}
                                    onClick={() => {
                                        window.location.href = `/Collections/${idSucursal}/Products/${categoria.nomCategoria}`;
                                    }}
                                    className="nav-link d-flex align-items-center text1-cr"
                                >
                                    {categoria.nomCategoria} <ChevronRight className="ms-auto text1-cr" />
                                </Button>
                            ))}
                        </Nav>

                    </div>

                    <hr className='mx-2 text1-cr' />

                    <div>
                        <div className="d-flex justify-content-center" >
                            {socialLinks.map((link, index) => (
                                <a key={index} href={link.url} target='_balank' title={link.title}>
                                    <button
                                        className='m-1 button'
                                    >
                                        {getIcon(link.url)}
                                    </button>
                                </a>
                            ))}
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>

            <CarUni show={show} handleClose={closeCar} />

            <Modal show={searchVisible} onHide={handleCloseSearch} size='md' dialogClassName='custom-search-modal'>
                <Modal.Body className='p-3'>
                    <div className='d-flex align-items-center position-relative'>
                        <TextField
                            className='w-100'
                            size='small'
                            id='searchProducto'
                            label='Buscar Producto'
                            value={searchTerm}
                            onChange={handleSearchChange}
                            onKeyDown={handleKeyDown}
                            autoFocus
                        />

                        <Button variant='link' className='position-absolute top-0 end-0' onClick={handleCloseSearch} aria-label='Cerrar búsqueda'>
                            <X size={24} />
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default NavbarUni;
