// src/context/SucursalContext.js

import React, { createContext, useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { API } from '../api/URL_API';

// Crear el contexto
const SucursalContext = createContext();

// Proveedor del contexto
export const SucursalProvider = ({ children }) => {
    const { idSucursal: paramIdSucursal } = useParams(); // Obtener idSucursal desde los parámetros de la URL
    const [idSucursal, setIdSucursal] = useState(paramIdSucursal); // Estado local para almacenar el idSucursal
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchSucursal = async () => {
            try {
                let id = paramIdSucursal;

                // Si no hay idSucursal en los parámetros, obtener el primer idSucursal desde la API
                if (!id) {
                    const response = await axios.get(`${API}/api/sucursales-poner/primer`);
                    id = response.data.idSucursal;
                    setIdSucursal(id); // Establecer el idSucursal al primero disponible
                } else {
                    setIdSucursal(id); // Si ya existe idSucursal en los parámetros, lo usamos
                }
            } catch (error) {
                console.error('Error al obtener el id de la sucursal:', error);
            } finally {
                setLoading(false); // Dejar de mostrar la pantalla de carga
            }
        };

        fetchSucursal();
    }, [paramIdSucursal]); // Ejecutar el efecto solo cuando el paramIdSucursal cambie

    return (
        <SucursalContext.Provider value={{ idSucursal, setIdSucursal, loading }}>
            {children}
        </SucursalContext.Provider>
    );
};

// Hook para acceder al contexto
export const useSucursal = () => {
    const context = useContext(SucursalContext);
    if (!context) {
        throw new Error('useSucursal debe ser usado dentro de un SucursalProvider');
    }
    return context;
};
