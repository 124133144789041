import React, { useState, useEffect } from 'react';
import { Table, Form, Pagination } from 'react-bootstrap';
import APIserver from '../../../../api/axiosConfig';

function TablaMarketing() {
    const [data, setData] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);

    const fetchData = async (page, rowsPerPage) => {
        try {
            const response = await APIserver.get(`/api/marketingMostrar/datos`, {
                params: { page, rowsPerPage }
            });
            setData(response.data.data);
            setTotalRows(response.data.total);
        } catch (error) {
            console.error('Error fetching marketing data:', error);
        }
    };

    useEffect(() => {
        fetchData(currentPage, rowsPerPage);
    }, [currentPage, rowsPerPage]);

    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setCurrentPage(1); // Reset to first page when rows per page changes
    };

    const totalPages = Math.ceil(totalRows / rowsPerPage);

    return (
        <div style={{ boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)" }}>
            <Table responsive className='text-center align-middle border'>
                <thead>
                    <tr>
                        <th colSpan='3'>
                            <div className='d-flex justify-content-between align-items-center'>
                            <div className='d-flex flex-wrap text-black'>

                                <Form.Select
                                    className='ms-2'
                                    value={rowsPerPage}
                                    onChange={handleChangeRowsPerPage}
                                >
                                    <option value='5'>5</option>
                                    <option value='10'>10</option>
                                    <option value='20'>20</option>
                                    <option value='50'>50</option>
                                </Form.Select>
                            </div>
                            </div>

                        </th>
                    </tr>
                    <tr>
                        <th>Nombre</th>
                        <th>Correo Electrónico</th>
                        <th>Fecha de Suscripcion</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item) => (
                        <tr key={item.idMark}>
                            <td>{item.nameuser}</td>
                            <td>{item.email}</td>
                            <td>{new Date(item.createdAt).toLocaleDateString()}</td>
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan='3'>
                            <Pagination className='d-flex justify-content-center'>
                                {[...Array(totalPages).keys()].map(page => (
                                    <Pagination.Item
                                        key={page + 1}
                                        active={page + 1 === currentPage}
                                        onClick={() => setCurrentPage(page + 1)}
                                    >
                                        {page + 1}
                                    </Pagination.Item>
                                ))}
                            </Pagination>
                        </td>
                    </tr>
                </tfoot>
            </Table>
        </div>
    );
}

export default TablaMarketing;
