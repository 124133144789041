import React, { useState } from 'react';
import NewProduct from './newProduct';
import PopularProduct from './popularProduc';
import CategoriasHome from './categoriasHome';

function BodyUni() {
    const [selectedCategoryId, setSelectedCategoryId] = useState(null); // Estado para el idCategoria

    return (
        <div className='mt-4'>
            <div>
                <CategoriasHome />
            </div>
            <div className='mb-2'>
                <NewProduct selectedCategoryId={selectedCategoryId} />
            </div>
            <div className='mt-4'>
                <PopularProduct selectedCategoryId={selectedCategoryId} /> 
            </div>
        </div>
    );
}

export default BodyUni;
