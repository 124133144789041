import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Button, InputGroup, Card, Modal, Table } from 'react-bootstrap';
import Select from 'react-select';
import { Search } from 'react-bootstrap-icons';
import APIserver from '../../../../api/axiosConfig';

// Función para convertir fecha ISO a formato YYYY-MM-DD
const formatDate = (date) => {
    if (!date) return '';
    const d = new Date(date);
    const yyyy = d.getFullYear();
    const mm = String(d.getMonth() + 1).padStart(2, '0');
    const dd = String(d.getDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
};

// Función para convertir fecha en formato YYYY-MM-DD a fecha ISO
const parseDate = (date) => {
    if (!date) return '';
    const [yyyy, mm, dd] = date.split('-');
    return new Date(`${yyyy}-${mm}-${dd}`).toISOString();
};

function EditarClienteFactura({ factura, setFactura }) {
    const [sucursales, setSucursales] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [clienteInput, setClienteInput] = useState(factura.nombreCliente || '');

    useEffect(() => {
        const fetchSucursales = async () => {
            try {
                const response = await APIserver.get(`/api/nombre/asesores`);
                const options = response.data.map(sucursal => ({
                    value: sucursal.idSucursal,
                    label: sucursal.nomSucursal
                }));
                setSucursales(options);
            } catch (error) {
                console.error('Error al obtener las sucursales:', error);
            }
        };

        fetchSucursales();
    }, []);

    useEffect(() => {
        setClienteInput(factura.nombreCliente || '');
    }, [factura.nombreCliente]);

    const optionsTipoFactura = [
        { value: 'distribucion', label: 'Distribución' },
        { value: 'unidad', label: 'Unidad' }
    ];

    // Función para buscar clientes por nombre
    const searchClientes = async (nombre) => {
        if (nombre.trim() === '') {
            setSearchResults([]);
            return;
        }
        try {
            const response = await APIserver.get(`/api/nombre/buscar?nombre=${nombre}`);
            setSearchResults(response.data);
        } catch (error) {
            console.error('Error al buscar clientes:', error);
        }
    };

    // Función para buscar cliente por clienteID
    const searchClienteById = async (clienteID) => {
        if (!clienteID) return;
        try {
            const response = await APIserver.get(`/api/buscarClientes/${clienteID}`);
            const cliente = response.data;

            if (cliente) {
                setFactura(prevFactura => ({
                    ...prevFactura,
                    idCliente: cliente.idCliente,
                    clienteID: cliente.clienteID,
                    nombreCliente: cliente.nomCliente
                }));
                setClienteInput(cliente.nomCliente);  // Actualiza el nombre del cliente
            }
        } catch (error) {
            console.error('Error al buscar cliente por ID:', error);
        }
    };

    // Función que maneja la búsqueda al presionar Enter en el campo clienteID
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            searchClienteById(e.target.value);  // Llama a la función si se presiona Enter
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFactura(prevFactura => ({
            ...prevFactura,
            [name]: name.includes('fecha') ? parseDate(value) : value
        }));
    };

    const handleSelectChange = (selectedOption, { name }) => {
        setFactura(prevFactura => ({
            ...prevFactura,
            [name]: selectedOption ? selectedOption.value : ''
        }));
    };

    const handleSearchClick = () => {
        searchClientes(clienteInput);
        setShowModal(true);
    };

    const handleSelectCliente = (cliente) => {
        setFactura(prevFactura => ({
            ...prevFactura,
            idCliente: cliente.idCliente,
            clienteID: cliente.clienteID,
            nombreCliente: cliente.nomCliente
        }));
        setClienteInput(cliente.nomCliente);
        setShowModal(false);
    };

    return (
        <>
            <Card className='shadow-none'>
                <Card.Header className='fw-bold'>Detalles Cliente</Card.Header>
                <Card.Body>
                    <Form>
                        <Row>
                            <Col>
                                <Form.Group className='mt-0' controlId='clienteID'>
                                    <Form.Label className='w-100'>Identificación
                                        <Form.Control
                                            type='number'
                                            name='clienteID'
                                            value={factura.clienteID || ''}
                                            onChange={handleChange}
                                            onKeyPress={handleKeyPress} // Añadir el evento para capturar la tecla Enter
                                            className='mt-2'
                                        />
                                    </Form.Label>
                                </Form.Group>
                                <Form.Group className='mt-2' controlId='fechaVencimiento'>
                                    <Form.Label className='w-100'>Vence
                                        <Form.Control
                                            type='date'
                                            name='fechaVencimiento'
                                            className='mt-2'
                                            value={formatDate(factura.fechaVencimiento)}
                                            onChange={handleChange}
                                        />
                                    </Form.Label>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mt-0" controlId="nomCliente">
                                    <Form.Label>Cliente</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            type='text'
                                            name='nombreCliente'
                                            value={clienteInput}
                                            onChange={(e) => {
                                                setClienteInput(e.target.value);
                                                handleChange(e);
                                                searchClientes(e.target.value);
                                            }}
                                        />
                                        <Button variant='outline-success' title='Buscar cliente' onClick={handleSearchClick}>
                                            <Search />
                                        </Button>
                                    </InputGroup>
                                </Form.Group>

                                <Form.Group className='mt-2' controlId='tipoFactura'>
                                    <Form.Label className='w-100'>Tipo
                                        <Select
                                            name='tipoFactura'
                                            className='mt-2'
                                            value={optionsTipoFactura.find(option => option.value === factura.tipoFactura)}
                                            options={optionsTipoFactura}
                                            onChange={(option) => handleSelectChange(option, { name: 'tipoFactura' })}
                                        />
                                    </Form.Label>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className='mt-0' controlId='fechaCreacion'>
                                    <Form.Label className='w-100'>Elaboración
                                        <Form.Control
                                            type='date'
                                            name='fechaCreacion'
                                            className='mt-2'
                                            value={formatDate(factura.fechaCreacion)}
                                            onChange={handleChange}
                                        />
                                    </Form.Label>
                                </Form.Group>
                                <Form.Group className='mt-2' controlId='asesor'>
                                    <Form.Label className='w-100'>Asesor
                                        <Select
                                            placeholder='Seleccione Asesor'
                                            name='idSucursal'
                                            className='mt-2'
                                            value={sucursales.find(option => option.value === factura.idSucursal)}
                                            options={sucursales}
                                            onChange={(option) => handleSelectChange(option, { name: 'idSucursal' })}
                                        />
                                    </Form.Label>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
            </Card>

            {/* Modal de búsqueda de clientes */}
            <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Seleccionar Cliente</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InputGroup className="mb-3">
                        <Form.Control
                            type='text'
                            placeholder='Buscar cliente por nombre'
                            value={clienteInput}
                            onChange={(e) => {
                                setClienteInput(e.target.value);
                                searchClientes(e.target.value);
                            }}
                        />
                    </InputGroup>
                    <Table responsive className='shadow-none text-center mt-3'>
                        <thead className='bg-primary-subtle'>
                            <tr>
                                <th>Identificación</th>
                                <th>Nombre</th>
                                <th>Acción</th>
                            </tr>
                        </thead>
                        <tbody>
                            {searchResults.length === 0 ? (
                                <tr>
                                    <td colSpan="3">Cliente no encontrado</td>
                                </tr>
                            ) : (
                                searchResults.map(cliente => (
                                    <tr key={cliente.idCliente}>
                                        <td>{cliente.clienteID}</td>
                                        <td>{cliente.nomCliente}</td>
                                        <td>
                                            <Button variant='outline-success' size='sm' onClick={() => handleSelectCliente(cliente)}>
                                                Seleccionar
                                            </Button>
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default EditarClienteFactura;
