import React, { useState } from 'react';
import { FormControl, Button, ButtonGroup } from 'react-bootstrap';
import { Search, XSquare } from 'react-bootstrap-icons';
import Select from 'react-select'; // Importar react-select

function FiltrosDetallesProveedor({ onSearch, onClear }) {
  // Estados para cada filtro
  const [compra, setCompra] = useState('');
  const [elaboracion, setElaboracion] = useState('');
  const [vencimiento, setVencimiento] = useState('');
  const [total, setTotal] = useState('');
  const [estado, setEstado] = useState(null); // Cambiar a null para compatibilidad con react-select

  // Opciones del select para el estado
  const estadoOptions = [
    { value: 'pendiente', label: 'Pendiente' },
    { value: 'pagada', label: 'Pagado' },
    { value: 'vencida', label: 'Vencido' }
  ];

  // Función para manejar el cambio en los campos de filtro
  const handleInputChange = (setter) => (event) => {
    setter(event.target.value);
  };

  // Función para manejar la búsqueda
  const handleSearch = () => {
    if (onSearch) {
      onSearch({
        compra,
        elaboracion,
        vencimiento,
        total,
        estado: estado ? estado.value : '' // Obtener el valor seleccionado del select
      });
    }
  };

  // Función para manejar la limpieza de los filtros
  const handleClear = () => {
    setCompra('');
    setElaboracion('');
    setVencimiento('');
    setTotal('');
    setEstado(null); // Restablecer el select a su valor inicial
    if (onClear) {
      onClear(); // Llamar a la función de limpieza pasada por props
    }
  };

  return (
    <div className='d-flex align-items-center m-3'>
      <FormControl
        value={compra}
        onChange={handleInputChange(setCompra)}
        placeholder='Busca por Compra'
        className='me-2'
      />
      <FormControl
        value={elaboracion}
        onChange={handleInputChange(setElaboracion)}
        placeholder='Busca por Elaboracion'
        className='me-2'
        type='date'
      />
      <FormControl
        value={vencimiento}
        onChange={handleInputChange(setVencimiento)}
        placeholder='Busca por Vencimiento'
        className='me-2'
        type='date'
      />
      <FormControl
        value={total}
        onChange={handleInputChange(setTotal)}
        placeholder='Busca por Total'
        className='me-2'
      />

      <Select
        value={estado} // Asignar el valor del estado
        onChange={setEstado} // Actualizar el estado cuando se selecciona una opción
        options={estadoOptions} // Opciones del select
        placeholder='Estado'
        className='me-2 w-100'
        menuPlacement="auto"  // Ajusta el menú según el espacio disponible
        menuPortalTarget={document.body}  // Renderiza el menú dentro del body para evitar problemas de superposición
        styles={{
          menuPortal: base => ({ ...base, zIndex: 9999 }),  // Asegura que el menú esté siempre al frente
          container: base => ({ ...base, position: 'relative', zIndex: 1050 })
        }}
      />

      <ButtonGroup>
        <Button variant='primary' title='Buscar' onClick={handleSearch}>
          <Search />
        </Button>
        <Button variant='danger' title='Limpiar' onClick={handleClear}>
          <XSquare />
        </Button>
      </ButtonGroup>
    </div>
  );
}

export default FiltrosDetallesProveedor;
