import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Card, CardActionArea, CardContent, CardMedia } from '@mui/material';
import { ArrowRightCircleFill } from 'react-bootstrap-icons';
import '../styles/CardProductos.css';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { API } from '../../../api/URL_API';
import { useSucursal } from '../../../contexts/SucursalContext';

function PopularProduct() {
    const [products, setProducts] = useState([]);
    const [hoveredImages, setHoveredImages] = useState({}); // Estado individual para cada producto
    const [idSucursal, setIdSucursal] = useState(null); // Estado para almacenar idSucursal
    const navigate = useNavigate();
    const { paramIdSucursal } = useParams(); // Usamos el parámetro de la URL

    // Efecto para obtener el idSucursal
    useEffect(() => {
        const fetchSucursal = async () => {
            try {
                let id = paramIdSucursal;

                // Si no hay idSucursal en los parámetros, obtener el primer idSucursal desde la API
                if (!id) {
                    const response = await axios.get(`${API}/api/sucursales-poner/primer`);
                    id = response.data.idSucursal;
                }

                setIdSucursal(id); // Establece el idSucursal actual
            } catch (error) {
                console.error('Error al obtener el id de la sucursal:', error);
            }
        };

        fetchSucursal();
    }, [paramIdSucursal]); // Dependemos solo de paramIdSucursal

    // Efecto para obtener los productos populares
    useEffect(() => {
        if (idSucursal) {  // Solo buscar productos si ya tenemos el idSucursal
            axios.get(`${API}/api/mostrarCategorias/productos-sales`, { params: { limit: 4, idSucursal } })
                .then(response => {
                    setProducts(response.data);
                })
                .catch(error => {
                    console.error('Error fetching popular products:', error);
                });
        }
    }, [idSucursal]); // Dependemos solo de idSucursal

    const formatCop = (value) => {
        return value?.toLocaleString('es-CO', {
            style: 'currency',
            currency: 'COP',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        }) || '$0';
    };

    // Redirigir a la página de productos más vendidos de la sucursal actual
    const handleViewMores = () => {
        if (idSucursal) {
            navigate(`/Collections/${idSucursal}/Products/MoreSales`);
        }
    };

    const handleMouseEnter = (productId, img1) => {
        if (img1) {
            setHoveredImages(prev => ({ ...prev, [productId]: img1 }));
        }
    };

    const handleMouseLeave = (productId) => {
        setHoveredImages(prev => ({ ...prev, [productId]: null }));
    };

    return (
        <Container fluid>
            <Row className='mb-2'>
                <div className='d-flex align-items-center justify-content-between w-100'>
                    <h4 className='ms-2 text1-cr fw-bold fst-italic'>Lo más vendido 🔥</h4>
                    <button
                        variant='link'
                        className='button-cr fst-italic'
                        onClick={handleViewMores}
                    >
                        Ver más <ArrowRightCircleFill size={18} className='text-liht ms-2' />
                    </button>
                </div>
            </Row>
            <Row>
                {products.map((product) => (
                    <Col key={product.idProducto} xs={6} sm={6} md={3} className='d-flex justify-content-center mb-3'>
                        <Card
                            className="product-card mt-2"
                            onClick={handleViewMores}
                            style={{ boxShadow: '0 4px 8px rgba(43, 35, 94, 0.3), 0 6px 20px rgba(43, 35, 94, 0.3)', borderRadius: '15px' }}
                        >
                            <CardActionArea
                                onMouseEnter={() => handleMouseEnter(product.idProducto, product.img1)} // Cambia a img1 en hover solo para este producto
                                onMouseLeave={() => handleMouseLeave(product.idProducto)} // Vuelve a la imagen original solo para este producto
                            >
                                <CardMedia
                                    component='img'
                                    height='300'
                                    image={hoveredImages[product.idProducto]
                                        ? `${hoveredImages[product.idProducto]}` // Muestra img1 si está en hover
                                        : `${product.imgProducto}`} // Muestra imgProducto por defecto
                                    alt={`product-${product.nomProducto}`}
                                    className='card-media-image'
                                />
                                <CardContent className='text-center text1-cr'>
                                    <h4 className='fw-bold'>{product.nomProducto}</h4>
                                    <h5>
                                        {formatCop(product.precioU)}
                                    </h5>
                                    <h6 className='mb-3 fs-6 text-muted'>
                                        Gran Mayorista {formatCop(product.precioM)}
                                    </h6>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    );
}

export default PopularProduct;
