import React, { useState, useEffect } from 'react';
import { API } from '../../../api/URL_API';
import { Container, Row } from 'react-bootstrap';
import { ArrowRightCircleFill } from 'react-bootstrap-icons';
import { Card, CardContent, CardMedia } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import Skeleton from '@mui/material/Skeleton';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import '../styles/PaginationDetalles.css';
import { useSucursal } from '../../../contexts/SucursalContext';

function CategoriasHome() {
    const [categorias, setCategorias] = useState([]);
    const { idSucursal } = useSucursal();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        fetch(`${API}/api/mostrarCategorias/categorias`)
            .then(response => response.json())
            .then(data => {
                setCategorias(data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error al cargar las categorias', error);
                setIsLoading(false);
            });
    }, []);

    const handleMoreCollection = () => {
        if (idSucursal) {
            navigate(`/Collections/${idSucursal}`);
        } else {
            console.error('idSucursal no disponible');
        }
    };

    return (
        <Container fluid>
            <Row className='mb-2'>
                <div className='d-flex align-items-center justify-content-between w-100'>
                    <h4 className='ms-2 text1-cr fw-bold fst-italic'>Colecciones ✨</h4>
                    <button className='button-cr fst-italic' onClick={handleMoreCollection}>
                        Ver más <ArrowRightCircleFill size={18} className='text-light ms-2' />
                    </button>
                </div>
            </Row>
            <Swiper
                slidesPerView={4}
                spaceBetween={20}
                loop={true} // Activa el loop
                pagination={{
                    clickable: true,
                    dynamicBullets: true // Activa los bullets dinámicos
                }}
                autoplay={{ delay: 3000 }}
                modules={[Pagination, Autoplay]}
                breakpoints={{
                    0: { slidesPerView: 2 },
                    768: { slidesPerView: 4 }
                }}
                className="mySwiper"
            >
                {isLoading
                    ? Array.from({ length: 4 }).map((_, idx) => (
                        <SwiperSlide key={idx} className="d-flex h-100 mb-5">
                            <Card
                                className='product-card mt-2 mx-2 h-100 w-100'
                                style={{
                                    boxShadow: '0 4px 8px rgba(43, 35, 94, 0.3), 0 6px 20px rgba(43, 35, 94, 0.3)',
                                    borderRadius: '15px'
                                }}
                            >
                                <Skeleton variant="rectangular" height={300} animation="wave" />
                                <CardContent className='text-center text1-cr'>
                                    <Skeleton variant="text" width="80%" height={30} />
                                </CardContent>
                            </Card>
                        </SwiperSlide>
                    ))
                    : categorias.map((categoria, idx) => (
                        <SwiperSlide key={idx} className="d-flex">
                            <Card
                                className='mt-2 mx-2 h-100 w-100'
                                style={{
                                    boxShadow: '0 4px 8px rgba(43, 35, 94, 0.3), 0 6px 20px rgba(43, 35, 94, 0.3)',
                                    borderRadius: '15px'
                                }}
                            >
                                <Link
                                    to={`/Collections/${idSucursal}/Products/${categoria.nomCategoria}`}
                                    className="text-decoration-none d-flex flex-column"
                                >
                                    <CardMedia
                                        component='img'
                                        height='300'
                                        image={`${categoria.imgCategoria}`}
                                        alt={`collection-${categoria.nomCategoria}`}
                                        className='card-media-image'
                                    />
                                    <CardContent className='text-center text1-cr'>
                                        <h4 className='fw-bold'>{categoria.nomCategoria}</h4>
                                    </CardContent>
                                </Link>
                            </Card>
                        </SwiperSlide>
                    ))}
            </Swiper>
        </Container>
    );
}

export default CategoriasHome;
