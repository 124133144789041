import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import '../../styles/printComprobante.css';
import InfoCompPagoHe from './comprobante/infoCompPagoHe';
import InfoProveedorPagoHe from './comprobante/infoProveedorPagoHe';
import InfoDetallesPagoHe from './comprobante/infoDetallesPagoHe';

function ComprobantePagoHe({ pagoData, numPago }) {
    return (
        <div className='m-4'>
            <Card className='table-responsive comprobante-container shadow-none'>
                <Card.Body>
                    {/* Informacion de empresa */}
                    <InfoCompPagoHe pagoData={pagoData} numPago={numPago}/>
                    {/* Informacion de proveedor */}
                    <InfoProveedorPagoHe pagoData={pagoData} />
                    {/* Informacion de detalles del pago */}
                    <InfoDetallesPagoHe pagoData={pagoData}/>
                    <Row className='mt-4 text-center'>
                        <Col className='mt-5'>
                            <hr className='mt-5 mx-5 border border-black' />
                            <p>Firma elaborado</p>
                        </Col>
                        <Col className='mt-5'>
                            <hr className='mt-5 mx-5 border border-black' />
                            <p>Firma recibido</p>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </div>
    );
}

export default ComprobantePagoHe;
