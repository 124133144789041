import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Form, Row, Col, InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { PlusCircle, TrashFill, QuestionCircle } from 'react-bootstrap-icons';
import Select from 'react-select';
import ImageCropperModal from './ImageCropperModal';
import { handleSubmit } from '../utils/AlertsProductos';
import APIserver from '../../../../api/axiosConfig';
import '../components/editProductoModal.css';

function CrearProductoModal({ show, handleClose, fetchProductos }) {
  const [formData, setFormData] = useState({
    referencia: '',
    nombre: '',
    stock: '',
    precioUnidad: '',
    precioMayor: '',
    idCategoria: '',
  });

  const [categorias, setCategorias] = useState([]);
  const [selectedImages, setSelectedImages] = useState([null, null, null, null]);
  const [croppedImages, setCroppedImages] = useState([null, null, null, null]);
  const [showCropper, setShowCropper] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(null);
  const [tallas, setTallas] = useState([]);
  const [colores, setColores] = useState([]);
  const [selectedTallas, setSelectedTallas] = useState([]);
  const [selectedColores, setSelectedColores] = useState([]);
  const [referenciaError, setReferenciaError] = useState('');
  const [imagenError, setImagenError] = useState(false);

  useEffect(() => {
    fetchCategorias();
    fetchTallas();
    fetchColores();
  }, []);

  const fetchTallas = async () => {
    try {
      const response = await APIserver.get(`/api/atributos-product/tallas`);
      setTallas(response.data);
    } catch (error) {
      console.error('Error al obtener tallas:', error);
    }
  };

  const fetchColores = async () => {
    try {
      const response = await APIserver.get(`/api/atributos-product/colores`);
      setColores(response.data);
    } catch (error) {
      console.error('Error al obtener colores:', error);
    }
  };

  // Refs para los inputs de archivo
  const fileInputRefs = useRef([]);

  useEffect(() => {
    fetchCategorias();
  }, []);

  const fetchCategorias = async () => {
    try {
      const response = await APIserver.get(`/api/categorias`);
      setCategorias(response.data);
    } catch (error) {
      console.error('Error al obtener categorías:', error);
    }
  };

  const handleImageChange = (e, index) => {
    const file = e.target.files[0];
    if (file) {
      const updatedImages = [...selectedImages];
      updatedImages[index] = file;
      setSelectedImages(updatedImages);
      setCurrentImageIndex(index);
      setShowCropper(true);
      if (index === 0) setImagenError(false); // Si se selecciona una imagen para la primera imagen, se limpia el error
    }
  };

  const handleCategoriaChange = (selectedOption) => {
    setFormData({
      ...formData,
      idCategoria: selectedOption.value,
    });
  };

  const checkReferenciaExists = async (referencia) => {
    try {
      const response = await APIserver.post(`/api/productos/verificar-referencia`, { referencia });
      return response.data.exists;
    } catch (error) {
      console.error('Error al verificar la referencia:', error);
      return false;
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    // Verificar si la primera imagen está presente
    if (!croppedImages[0]) {
      setImagenError(true); // Mostrar error si la primera imagen no está seleccionada
      return;
    }

    const referenciaExists = await checkReferenciaExists(formData.referencia);
    if (referenciaExists) {
      setReferenciaError('La referencia ya existe. Elige otra referencia.');
      return;
    } else {
      setReferenciaError('');  // Limpiar el error si no hay problema
    }

    const formDataToSend = new FormData();
    formDataToSend.append('referencia', formData.referencia);
    formDataToSend.append('nombre', formData.nombre);
    formDataToSend.append('stock', parseInt(formData.stock) || 0);
    formDataToSend.append('precioUnidad', parseFloat(formData.precioUnidad) || 0);
    formDataToSend.append('precioMayor', parseFloat(formData.precioMayor) || 0);
    formDataToSend.append('idCategoria', formData.idCategoria);
    formDataToSend.append('descripcion', formData.descripcion);
    formDataToSend.append('tallas', JSON.stringify(selectedTallas));
    formDataToSend.append('colores', JSON.stringify(selectedColores));

    croppedImages.forEach((image) => {
      if (image) {
        formDataToSend.append('imagenProducto', image);
      }
    });

    try {
      await handleSubmit(formDataToSend, handleClose, fetchProductos);
      window.location.reload();
    } catch (error) {
      console.error('Error al crear el producto:', error);
    }
  };

  const handleCropperSave = (croppedFile) => {
    if (currentImageIndex !== null) {
      const updatedCroppedImages = [...croppedImages];
      const updatedSelectedImages = [...selectedImages];

      // Actualiza la imagen recortada
      updatedCroppedImages[currentImageIndex] = croppedFile;
      updatedSelectedImages[currentImageIndex] = croppedFile; // También actualiza el input con la imagen recortada

      setCroppedImages(updatedCroppedImages);
      setSelectedImages(updatedSelectedImages); // Asegura que se muestre la imagen recortada en el input
      setShowCropper(false);
    }
  };

  const handleCancelCropper = () => {
    const updatedSelectedImages = [...selectedImages];
    updatedSelectedImages[currentImageIndex] = null; // Limpiar la imagen seleccionada
    setSelectedImages(updatedSelectedImages);
    setShowCropper(false);
  };

  const handleDeleteImage = (index) => {
    const updatedImages = [...selectedImages];
    updatedImages[index] = null;
    setSelectedImages(updatedImages);
    const updatedCroppedImages = [...croppedImages];
    updatedCroppedImages[index] = null;
    setCroppedImages(updatedCroppedImages);
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Este valor es el menor en el que puede venderse este producto
    </Tooltip>
  );

  const getObjectURL = (file) => {
    return file ? URL.createObjectURL(file) : '';
  };

  const triggerFileInput = (index) => {
    if (fileInputRefs.current[index]) {
      fileInputRefs.current[index].click();
    }
  };

  return (
    <>
      <Modal size="lg" show={show} onHide={handleClose} aria-labelledby="example-modal-sizes-title-lg" dialogClassName="custom-modal-width">
        <Modal.Header closeButton>
          <Modal.Title>Crear Nuevo Producto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={onSubmit}>
            <Row>
              <Col xs={12} md={4}>
                <Form.Group className="m-3 mt-0">
                  <Form.Label>Referencia</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="example-2133"
                    name="referencia"
                    value={formData.referencia}
                    onChange={(e) => setFormData({ ...formData, referencia: e.target.value })}
                    isInvalid={!!referenciaError}  // Usamos isInvalid de Bootstrap para el borde rojo
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {referenciaError}  {/* Mostrar mensaje de error debajo del input */}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="m-3">
                  <Form.Label>Stock</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Cantidad disponible"
                    name="stock"
                    value={formData.stock}
                    onChange={(e) => setFormData({ ...formData, stock: e.target.value })}
                    required
                  />
                </Form.Group>
                <Form.Group className="m-3">
                  <Form.Label>
                    Precio al por Mayor
                    <OverlayTrigger
                      placement="right"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderTooltip}
                    >
                      <QuestionCircle className='ms-2' />
                    </OverlayTrigger>
                  </Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control
                      type="number"
                      placeholder="Precio distribución (Opcional)"
                      name="precioMayor"
                      value={formData.precioMayor}
                      onChange={(e) => setFormData({ ...formData, precioMayor: e.target.value })}
                      required
                    />
                    <InputGroup.Text>COP</InputGroup.Text>
                  </InputGroup>
                </Form.Group>
                <Form.Group className="m-3" style={{ zIndex: 9999 }}>
                  <Form.Label>Motivos (Opcional)</Form.Label>
                  <Select
                    isMulti
                    options={tallas.map(talla => ({
                      value: talla.idTalla,
                      label: talla.nombreTalla,
                    }))}
                    onChange={(selectedOptions) => {
                      setSelectedTallas(selectedOptions.map(option => option.value));
                    }}
                    value={tallas.filter(talla => selectedTallas.includes(talla.idTalla)).map(talla => ({
                      value: talla.idTalla,
                      label: talla.nombreTalla,
                    }))}
                    menuPortalTarget={document.body} // Esto hace que el menú se monte en el body
                    styles={{
                      menuPortal: base => ({ ...base, zIndex: 9999 }) // Asegura que el menú tenga un alto z-index
                    }}
                  />
                </Form.Group>
                <Form.Group className="m-3 form-group-descripcion" style={{ width: "200%", zIndex: "10", position: "relative" }}>
                  <Form.Label>Descripción (Opcional)</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    style={{ cursor: "text" }}
                    placeholder="Descripción del producto"
                    name="descripcion"
                    value={formData.descripcion}
                    onChange={(e) => setFormData({ ...formData, descripcion: e.target.value })}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={4}>
                <Form.Group className="m-3 mt-0">
                  <Form.Label>Nombre</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Nombre del producto"
                    name="nombre"
                    value={formData.nombre}
                    onChange={(e) => setFormData({ ...formData, nombre: e.target.value })}
                    required
                  />
                </Form.Group>
                <Form.Group className="m-3">
                  <Form.Label>Precio Unidad</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control
                      type="number"
                      placeholder="Precio de Venta al público"
                      name="precioUnidad"
                      value={formData.precioUnidad}
                      onChange={(e) => setFormData({ ...formData, precioUnidad: e.target.value })}
                      required
                    />
                    <InputGroup.Text>COP</InputGroup.Text>
                  </InputGroup>
                </Form.Group>
                <Form.Group className="m-3" style={{ zIndex: "20", position: "relative" }}>
                  <Form.Label>Categoría</Form.Label>
                  <Select
                    options={categorias.map(cat => ({
                      value: cat.idCategoria,
                      label: cat.label,
                    }))}
                    onChange={handleCategoriaChange}
                    value={categorias.find(cat => cat.idCategoria === formData.idCategoria) ? {
                      value: formData.idCategoria,
                      label: categorias.find(cat => cat.idCategoria === formData.idCategoria).label
                    } : null}
                    required
                  />
                </Form.Group>
                <Form.Group className="m-3" style={{ zIndex: 9999 }}>
                  <Form.Label>Colores (Opcional)</Form.Label>
                  <Select
                    isMulti
                    options={colores.map(color => ({
                      value: color.idColor,
                      label: color.nombreColor,
                    }))}
                    onChange={(selectedOptions) => {
                      setSelectedColores(selectedOptions.map(option => option.value));
                    }}
                    value={colores.filter(color => selectedColores.includes(color.idColor)).map(color => ({
                      value: color.idColor,
                      label: color.nombreColor,
                    }))}
                    menuPortalTarget={document.body} // Hace que el menú se muestre sobre cualquier otro div
                    styles={{
                      menuPortal: base => ({ ...base, zIndex: 9999 }) // Asegura que el menú tenga un alto z-index
                    }}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={4}>
                <Form.Group className="m-3 mt-0">
                  <Form.Label className="text-center d-block">Imágenes del Producto</Form.Label>

                  {/* Primera imagen en su propia fila */}
                  <Row className="d-flex justify-content-center mb-2">
                    <Col className="text-center mx-auto position-relative">
                      <div className="image-container large-image mx-auto">
                        {croppedImages[0] ? (
                          <>
                            <img
                              src={getObjectURL(croppedImages[0])}
                              alt="Producto 1"
                              className="img-fluid rounded mb-2 centered-image"
                            />
                            <div className="position-absolute top-50 start-50 translate-middle">
                              <Button
                                variant="light"
                                size="sm"
                                className="btn-circle shadow-sm"
                                onClick={() => handleDeleteImage(0)}
                              >
                                <TrashFill size={16} color="#265CB6" />
                              </Button>
                            </div>
                          </>
                        ) : selectedImages[0] ? (
                          <>
                            <img
                              src={getObjectURL(selectedImages[0])}
                              alt="Producto 1"
                              className="img-fluid rounded mb-2 centered-image"
                            />
                            <div className="position-absolute top-50 start-50 translate-middle">
                              <Button
                                variant="light"
                                size="sm"
                                className="btn-circle shadow-sm"
                                onClick={() => handleDeleteImage(0)}
                              >
                                <TrashFill size={16} color="#265CB6" />
                              </Button>
                            </div>
                          </>
                        ) : (
                          <div className="image-placeholder" onClick={() => triggerFileInput(0)}>
                            <PlusCircle size={24} />
                            <input
                              ref={el => fileInputRefs.current[0] = el}
                              type="file"
                              accept="image/*"
                              onChange={(e) => handleImageChange(e, 0)}
                              style={{ display: 'none' }}
                            />
                          </div>
                        )}
                      </div>
                      {imagenError && (
                        <Form.Text className="text-danger">
                          Por favor, selecciona una imagen para este producto.
                        </Form.Text>
                      )}
                    </Col>
                  </Row>

                  {/* Fila con las otras 3 imágenes */}
                  <Row className="d-flex justify-content-center">
                    {[1, 2, 3].map((index) => (
                      <Col key={index} xs={12} md={4} className="text-center position-relative">
                        <div className="image-container mx-auto small-image">
                          {croppedImages[index] ? (
                            <>
                              <img
                                src={getObjectURL(croppedImages[index])}
                                alt={`Producto ${index + 1}`}
                                className="img-fluid rounded mb-2 centered-image"
                              />
                              <div className="position-absolute top-50 start-50 translate-middle">
                                <Button
                                  variant="light"
                                  size="sm"
                                  className="btn-circle shadow-sm"
                                  onClick={() => handleDeleteImage(index)}
                                >
                                  <TrashFill size={16} color="#265CB6" />
                                </Button>
                              </div>
                            </>
                          ) : selectedImages[index] ? (
                            <>
                              <img
                                src={getObjectURL(selectedImages[index])}
                                alt={`Producto ${index + 1}`}
                                className="img-fluid rounded mb-2 centered-image"
                              />
                              <div className="position-absolute top-50 start-50 translate-middle">
                                <Button
                                  variant="light"
                                  size="sm"
                                  className="btn-circle shadow-sm"
                                  onClick={() => handleDeleteImage(index)}
                                >
                                  <TrashFill size={16} color="#265CB6" />
                                </Button>
                              </div>
                            </>
                          ) : (
                            <div className="image-placeholder" onClick={() => triggerFileInput(index)}>
                              <PlusCircle size={24} />
                              <input
                                ref={el => fileInputRefs.current[index] = el}
                                type="file"
                                accept="image/*"
                                onChange={(e) => handleImageChange(e, index)}
                                style={{ display: 'none' }}
                              />
                            </div>
                          )}
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Form.Group>
              </Col>
            </Row>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Cancelar
              </Button>
              <Button variant="primary" type="submit">
                Crear Producto
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
      {showCropper && (
        <ImageCropperModal
          show={showCropper}
          imageFile={selectedImages[currentImageIndex]}
          onSave={handleCropperSave}
          onClose={handleCancelCropper}
        />
      )}
    </>
  );
}

export default CrearProductoModal;
