import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import NavbarUni from '../componentes/headUni';
import BodyProductUni from './componenetes/bodyProduct';
import { API } from '../../../api/URL_API';
import Marquee from '../componentes/marquee';

function VerProductUni() {
    const { idCategoria } = useParams(); // Obtener idCategoria de la URL
    const [searchTerm, setSearchTerm] = useState('');
    const [productosData, setProductosData] = useState(null); // Estado para almacenar los datos de productos

    const handleSearch = (term) => {
        setSearchTerm(term); // Actualiza el término de búsqueda
    };

    // Cargar datos de la API
    useEffect(() => {
        const fetchProductos = async () => {
            try {
                let url;
                if (idCategoria === 'All') {
                    url = `${API}/api/mostrarCategorias/productos-todos`;
                } else if (idCategoria) {
                    url = `${API}/api/mostrarCategorias/productos/${idCategoria}`;
                } else if (idCategoria === 'NewProductos') {
                    url = `${API}/api/mostrarCategorias/productos/nuevos`;
                } else if (idCategoria === 'MoreSales') {
                    url = `${API}/api/mostrarCategorias/productos/mas-vendidos`;
                }


            } catch (error) {
                console.error('Error al obtener los datos de los productos:', error);
            } 
        };

        fetchProductos();
    }, [idCategoria]);


    return (
        <>
            <Marquee />

            {/* Contenido de la página */}
            <div>
                <NavbarUni onSearch={handleSearch} />
                <div className=''>
                    <BodyProductUni idCategoria={idCategoria} searchTerm={searchTerm} productosData={productosData} />
                </div>
            </div>
        </>
    );
}

export default VerProductUni;
