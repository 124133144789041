// src/components/AlertsDropdown.js
import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { InfoCircle } from 'react-bootstrap-icons';
import { Badge } from '@mui/material';
import ContentAlert from "./contentAlerts";

const AlertsDropdown = () => {
  const [alertsCount, setAlertsCount] = useState(0);

  useEffect(() => {
    const today = new Date();
    const currentDay = today.getDate();
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();

    // Calcula la fecha del próximo día 1
    const nextMonth = currentMonth === 11 ? 0 : currentMonth + 1;
    const nextYear = currentMonth === 11 ? currentYear + 1 : currentYear;
    const alertStartDate = new Date(currentYear, currentMonth, 26); // 5 o 6 dias antes del dia 1
    const alertEndDate = new Date(nextYear, nextMonth, 1); // Día 1 del próximo mes

    // Verifica si hoy está dentro del rango
    if (today >= alertStartDate && today <= alertEndDate) {
      setAlertsCount(1);
    } else {
      setAlertsCount(0);
    }
  }, []);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <button
      type="button"
      className="btn btn-link ms-2 text-dark"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </button>
  ));

  return (
    <Dropdown align="start">
      <Dropdown.Toggle as={CustomToggle} variant="light" id="dropdown-alerts">
        <Badge badgeContent={alertsCount} color="primary">
          <InfoCircle size={15} />
        </Badge>
      </Dropdown.Toggle>
      <Dropdown.Menu
        className="p-0 dropdown-menu"
        style={{ width: '300px', position: 'absolute', left: '-140px' }}
      >
        <ContentAlert showAlert={alertsCount > 0} />
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default AlertsDropdown;
