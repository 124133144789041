import Swal from 'sweetalert2';

const AlertsPagosRecibidos = {

  showDeleteConfirmation: (numPagoRe, onDelete) => {
    Swal.fire({
      icon: 'warning',
      title: '¿Estás seguro?',
      text: `Esta acción no se puede deshacer`,
      showCancelButton: true,
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar',
      iconColor: '#f4bd61',
      confirmButtonColor: '#2c4cc4',
      cancelButtonColor: '#da292e'
    }).then((result) => {
      if (result.isConfirmed) {
        onDelete(); // Llama a la función onDelete cuando se confirma la eliminación
      }
    });
  },

  showDeleteConfirmationserver: (message) => {
    Swal.fire({
      icon: 'success',
      iconColor: '#2fb380',
      title: '¡Éxito!',
      text: message || 'El pago se ha eliminado  exitosamente.',
      showConfirmButton: false,
      timer: 2000
    });
  },

  showDeleteErrorMessage: () => {
    Swal.fire({
      icon: 'error',
      iconColor: '#da292e',
      title: 'Error',
      text: 'Hubo un error al eliminar el pago.',
    });
  },

  showUpdateConfirmation: (message) => {
    Swal.fire({
      icon: 'success',
      iconColor: '#2fb380',
      title: '¡Éxito!',
      text: message || 'El pago se ha actualizado exitosamente.',
      showConfirmButton: false,
      timer: 2000
    });
  },

  showUpdateErrorMessage: () => {
    Swal.fire({
      icon: 'error',
      iconColor: '#da292e',
      title: 'Error',
      text: 'Hubo un error al actualizar el pago.',
    });
  },

  showErrorMessage: (message) => {
    Swal.fire({
      icon: 'error',
      iconColor: '#da292e',
      title: 'Error',
      text: message || 'Se ha producido un error.',
    });
  }
};

export default AlertsPagosRecibidos;
