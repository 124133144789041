// src/components/AdminSucursales.js

import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Whatsapp } from 'react-bootstrap-icons';
import '../styles/tables.css';
import CrearSucursalModal from './componentes/crearSucursalModal';
import TablaSucursal from './componentes/tablaSucursales';

function AdminSucursales() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className='mx-2 mt-3'>
      <div className='d-flex flex-column flex-md-row align-items-center justify-content-between'>
        <p className='mb-2 mb-md-0 text-center'>
          Administra tus líneas de atención al Cliente por medio de WhatsApp
        </p>
        <div className='d-flex mt-2 mt-md-0'>
          <Button variant='primary' onClick={handleShow}>
            <Whatsapp /> Nueva Sucursal
          </Button>
        </div>
      </div>
      {/* MODAL PARA CREAR SUCURSALE */}
      <CrearSucursalModal show={show} handleClose={handleClose} />
      {/* Tabla de Sucursales */}
      <div className='mb-2 mt-4'>
        <TablaSucursal />
      </div>
    </div>
  );
}

export default AdminSucursales;
