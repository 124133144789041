import React from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import convertirMontoALetras from './convertirMontoALetras'; // Importa la función personalizada

function InfoDetallesPagoHe({ pagoData = {} }) {
    // Valores predeterminados
    const monto = pagoData.monto || 0;
    const concepto = pagoData.concepto || 'Sin concepto';
    const observaciones = pagoData.detalle || 'Sin observaciones';
    const montoEnLetras = convertirMontoALetras(monto);

    return (
        <div>
            <Row>
                <Col>
                    <Table bordered size='sm' className='shadow-none'>
                        <tbody>
                            <tr>
                                <th className='bg-primary-subtle'>El valor de</th>
                                <td>{montoEnLetras}</td>
                                <th>$ {monto.toLocaleString()}</th>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Table size='sm' bordered className='shadow-none'>
                        <thead>
                            <tr>
                                <th className='bg-primary-subtle'>Concepto</th>
                                <th className='bg-primary-subtle'>Valor</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{concepto}</td>
                                <td>$ {monto.toLocaleString()}</td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Row className='text-end'>
                <Col>
                    <h5 className='text-body-emphasis fw-bold'>Total COP</h5>
                    <p>$ {monto.toLocaleString()}</p>
                </Col>
            </Row>
            <Row className='mt-4'>
                <Col className='border'>
                    <div className='m-2'>
                        <h6>Observaciones:</h6>
                        <p>{observaciones}</p>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default InfoDetallesPagoHe;
