import React, { useState, useEffect } from 'react';
import { Table, Pagination, Button, Form, ButtonGroup } from 'react-bootstrap';
import { Filter, CashCoin, EyeFill, PencilFill, TrashFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import FiltrosCompra from './filtrosCopras';
import EditarCompra from './editarCompra';
import AlertsCompras from '../Utils/AlertsCompras';
import CrearPagoModal from '../../Pagos/componentes/crearPagoModal';
import APIserver from '../../../../api/axiosConfig';

function TablaCompra() {
    const [showFilters, setShowFilters] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [editShow, setEditShow] = useState(false);
    const [crearShow, setCrearShow] = useState(false);
    const [compras, setCompras] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [selectedCompra, setSelectedCompra] = useState(null);

    // Función para actualizar la tabla con los datos recibidos
    const updateTable = (comprasData) => {
        setCompras(comprasData);
    };

    // Función para cargar las compras desde el backend
    const fetchCompras = async () => {
        try {
            const response = await APIserver.get(`/api/compras`, {
                params: {
                    page: currentPage,
                    rowsPerPage,
                    // Aquí puedes agregar los filtros si los necesitas
                }
            });
            const comprasData = response.data.compras.map(compra => ({
                ...compra,
                creacion: formatDate(compra.creacion),
                vence: formatDate(compra.vence)
            }));
            setCompras(comprasData);
            setTotalPages(response.data.totalPages || 1); // Evita que el total de páginas sea cero
        } catch (error) {
            console.error('Error al obtener las compras:', error);
        }
    };

    // Función para formatear la fecha en formato DD/MM/YYYY
    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        const date = new Date(dateString);
        return date.toLocaleDateString('es-AR', options);
    };

    // Función para formatear números como moneda
    const formatCurrency = (value) => {
        return new Intl.NumberFormat('es-CO', {
            style: 'currency',
            currency: 'COP',
            minimumFractionDigits: 0
        }).format(value);
    };

    // Llamada inicial para cargar las compras al cargar el componente
    useEffect(() => {
        fetchCompras();
    }, [currentPage, rowsPerPage]);

    const toggleFilters = () => {
        setShowFilters(!showFilters);
    };

    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setCurrentPage(1);
    };

    const handleEditClose = () => setEditShow(false);
    const handleCrearClose = () => setCrearShow(false);

    const handleEditShow = (compraId) => {
        const compra = compras.find(compra => compra.idCompra === compraId);
        if (compra.estado !== 'pagada') {
            setSelectedCompra(compra);
            setEditShow(true);
        }
    };

    const handleCrearShow = (compraId, numCompra) => {
        const compra = compras.find(compra => compra.idCompra === compraId);
        if (compra.estado !== 'pagada') {
            setSelectedCompra(compra);
            setCrearShow(true);
        }
    };

    const handleEliminarCompra = async (compraId) => {
        try {
            await AlertsCompras.handleEliminarCompra(compraId, fetchCompras);
        } catch (error) {
            console.error('Error al eliminar la compra:', error);
        }
    };

    // Función para renderizar los botones de paginación
    const renderPaginationItems = () => {
        const items = [];
        const pageRangeDisplayed = 5; // Número de páginas visibles
        let startPage = Math.max(1, currentPage - Math.floor(pageRangeDisplayed / 2));
        let endPage = Math.min(totalPages, startPage + pageRangeDisplayed - 1);

        if (endPage - startPage < pageRangeDisplayed - 1) {
            startPage = Math.max(1, endPage - pageRangeDisplayed + 1);
        }

        if (startPage > 1) {
            items.push(
                <Pagination.First key="first" onClick={() => setCurrentPage(1)} />
            );
        }

        if (currentPage > 1) {
            items.push(
                <Pagination.Prev key="prev" onClick={() => setCurrentPage(currentPage - 1)} />
            );
        }

        for (let number = startPage; number <= endPage; number++) {
            items.push(
                <Pagination.Item
                    key={number}
                    active={number === currentPage}
                    onClick={() => setCurrentPage(number)}
                >
                    {number}
                </Pagination.Item>
            );
        }

        if (currentPage < totalPages) {
            items.push(
                <Pagination.Next key="next" onClick={() => setCurrentPage(currentPage + 1)} />
            );
        }

        if (endPage < totalPages) {
            items.push(
                <Pagination.Last key="last" onClick={() => setCurrentPage(totalPages)} />
            );
        }

        return items;
    };

    return (
        <div style={{ boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)" }}>
            <Table responsive className='text-center align-middle border'>
                <thead>
                    <tr>
                        <th colSpan={9}>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='d-flex flex-wrap'>
                                    <Button variant='link' size='sm' onClick={toggleFilters} title='filtrar'>
                                        <Filter className='m-0' size={20} />
                                    </Button>
                                </div>
                                <div>
                                    <Form.Select
                                        className='ms-2'
                                        value={rowsPerPage}
                                        onChange={handleChangeRowsPerPage}
                                    >
                                        <option value='5'>5</option>
                                        <option value='10'>10</option>
                                        <option value='20'>20</option>
                                        <option value='50'>50</option>
                                    </Form.Select>
                                </div>
                            </div>
                            <div className={`filter-options ${showFilters ? 'show' : ''}`}>
                                {/* FILTROS DE TABLA */}
                                <FiltrosCompra updateTable={updateTable} />
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <th>Factura</th>
                        <th>Proveedor</th>
                        <th>Creacion</th>
                        <th>Vence</th>
                        <th>Total</th>
                        <th>Pagado</th>
                        <th>Pendiente</th>
                        <th>Estado</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {compras.map((compra) => (
                        <tr key={compra.idCompra}>
                            <td>
                                <Link to={`/admin/Compras/DetallesCompra/${compra.idCompra}/`} className='text-decoration-none'>
                                    {compra.numCompra}
                                </Link>
                            </td>
                            <td>
                                <Link to={`/admin/Proveedores/DetallesProveedores/${compra.idProveedor}/`} className='text-decoration-none'>
                                    {compra.nombreProveedor}
                                </Link>
                            </td>
                            <td>{compra.creacion}</td>
                            <td>{compra.vence}</td>
                            <td>{formatCurrency(compra.totalCompra)}</td>
                            <td>{formatCurrency(compra.pagado)}</td>
                            <td>{formatCurrency(compra.pendiente)}</td>
                            <td className={`text-${compra.estado === 'pagada' ? 'success' : compra.estado === 'vencida' ? 'danger' : 'warning'}`}>
                                {compra.estado === 'pagada' ? 'Pagada' : compra.estado === 'vencida' ? <span style={{ color: 'red' }}>Vencida</span> : 'Pendiente'}
                            </td>
                            <td>
                                <ButtonGroup aria-label='action-button'>
                                    <Link to={`/admin/Compras/DetallesCompra/${compra.idCompra}/`}>
                                        <Button variant='link' className='text-primary' title='Ver detalles'>
                                            <EyeFill size={18} />
                                        </Button>
                                    </Link>
                                    <Button
                                        variant='link'
                                        className='text-success'
                                        title='Agregar Pago'
                                        onClick={() => handleCrearShow(compra.idCompra, compra.numCompra)}
                                        disabled={compra.estado === 'pagada'}
                                    >
                                        <CashCoin size={18} />
                                    </Button>
                                    <Button
                                        variant='link'
                                        className='text-warning'
                                        title='Editar compra'
                                        onClick={() => handleEditShow(compra.idCompra)}
                                        disabled={compra.estado === 'pagada'}
                                    >
                                        <PencilFill size={18} />
                                    </Button>
                                    <Button
                                        variant='link'
                                        className='text-danger'
                                        title='Eliminar compra'
                                        onClick={() => handleEliminarCompra(compra.idCompra)}
                                    >
                                        <TrashFill size={18} />
                                    </Button>
                                </ButtonGroup>
                            </td>
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan={9}>
                            <div className='d-flex justify-content-center align-items-center'>
                                <Pagination>
                                    {renderPaginationItems()}
                                </Pagination>
                            </div>
                        </td>
                    </tr>
                </tfoot>
            </Table>
            {selectedCompra && <EditarCompra show={editShow} handleClose={handleEditClose} compraId={selectedCompra.idCompra} />}
            {selectedCompra && <CrearPagoModal show={crearShow} handleClose={handleCrearClose} compraId={selectedCompra.idCompra} numCompra={selectedCompra.numCompra} saldo={selectedCompra.pendiente} />}
        </div>
    );
}

export default TablaCompra;