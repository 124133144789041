import React, { useState, useEffect } from 'react';
import { Nav } from 'react-bootstrap';
import IngresosTabla from './componentes/verBanco/ingresosTabla.js';
import EgresoTable from './componentes/verBanco/egresoTable.js';
import CardDetallesBanco from './componentes/cardDetallesBanco.js';
import { useParams } from 'react-router-dom'; // Importa useParams para obtener los parámetros de la URL
import APIserver from '../../../api/axiosConfig.js';

function DetallesBanco() {
    const { id } = useParams(); // Captura el parámetro ID de la URL

    const [nombreBanco, setNombreBanco] = useState('');

    useEffect(() => {
        // Función asincrónica para obtener el nombre del banco por su ID
        const fetchBancoDetails = async () => {
            try {
                const response = await APIserver.get(`/api/bancos/${id}`);
                setNombreBanco(response.data.nomBanco); // Establece el nombre del banco desde la respuesta de la API
            } catch (error) {
                console.error('Error al obtener detalles del banco:', error);
            }
        };

        if (id) {
            fetchBancoDetails(); // Llama a la función solo si id está definido
        }
    }, [id]);

    const [activeKey, setActiveKey] = useState('/active');

    const renderContent = () => {
        switch (activeKey) {
            case '/active':
                return <IngresosTabla bancoId={id} />; // Pasa el id del banco a IngresosTabla
            case 'link-1':
                return <EgresoTable bancoId={id} />; // Si también necesitas filtrar los egresos
            default:
                return null;
        }
    };

    return (
        <div>
            {/* Card detalles banco */}
            <CardDetallesBanco nombreBanco={nombreBanco} idBanco={id} /> {/* Pasa el nombre del banco como prop a CardDetallesBanco */}

            <Nav variant="tabs" defaultActiveKey="/active" onSelect={(selectedKey) => setActiveKey(selectedKey)}>
                <Nav.Item>
                    <Nav.Link eventKey="/active">Ingresos</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="link-1">Egresos</Nav.Link>
                </Nav.Item>
            </Nav>
            <div>
                {renderContent()}
            </div>
        </div>
    );
}

export default DetallesBanco;
