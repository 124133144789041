import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useCompanyInfo } from '../../../../../hooks/useCompanyInfo';

function InfoCompPagoRes({ numPagoRe  }) {
    const { companyData, isLoading, error } = useCompanyInfo();

    if (isLoading) {
        return <p>Cargando informacion de la empresa...</p>;
    }

    if (error) {
        return <p>Error: {error}</p>;
    }

    return (
        <Row className='text-center mb-4'>
            <Col className='my-auto'>
                <img
                    src={`${companyData.logoEmpresa}` || "https://via.placeholder.com/60"}
                    alt="logoEmpresa"
                    width="150"
                />
            </Col>
            <Col>
                <h3 className='fw-bold text-body-emphasis'>{companyData.nombreEmpresa}</h3>
                <p>NIT: {companyData.nit}</p>
                <p>{companyData.direccion}, {companyData.ciudad} - {companyData.departamento}</p>
                <p>Tel: {companyData.telefono}</p>
            </Col>
            <Col className='text-end my-auto'>
                <h3 className='fw-bold text-body-emphasis'>C.INGRESO</h3>
                <p>No. {numPagoRe}</p>
            </Col>
        </Row>
    );
}

export default InfoCompPagoRes;