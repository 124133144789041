import React, { useRef, useEffect, useState } from 'react';
import { Button, Dropdown, ButtonGroup, Card, Row, Col } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { Download, PrinterFill, PencilFill, XCircleFill, CashStack } from 'react-bootstrap-icons';
import DetallesClienteFactura from './componentes/detallesClienteFac';
import DetallesProductoFac from './componentes/detallesProductoFac';
import InfoCompanyFactura from './componentes/infoCompanyFac';
import '../styles/printFactura.css';
import { useReactToPrint } from 'react-to-print';
import InfoGeneralFactura from './componentes/infoGeneralFac';
import TablaPagosFactura from './componentes/tablaPagosFactura';
import Swal from 'sweetalert2'; // Importamos SweetAlert2
import CrearPagoResModal from '../PagosResibidos/componentes/crearPagoResModal';
import APIserver from '../../../api/axiosConfig';

function DetallesFactura() {
    const { idFactura } = useParams();
    const [factura, setFactura] = useState(null);
    const [productos, setProductos] = useState([]);
    const [cosEnvio, setCosEnvio] = useState(0);
    const [descuento, setDescuento] = useState(0);
    const [cliente, setCliente] = useState({});
    const [deudaCliente, setDeudaCliente] = useState(0);
    const [facturasRelacionadas, setFacturasRelacionadas] = useState(0);
    const [tipoFactura, setTipoFactura] = useState('');
    const [pagos, setPagos] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    const anularFactura = async () => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: `Estás a punto de anular la factura ${factura.numFacV}. Esta acción no se puede deshacer.`,
            icon: 'warning',
            showCancelButton: true,
            iconColor: '#f4bd61',
            confirmButtonColor: '#2c4cc4',
            cancelButtonColor: '#da292e',
            confirmButtonText: 'Sí, anular',
            cancelButtonText: 'Cancelar'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await APIserver.post(`/api/actualizarv/facturasVenta/anular`, {
                        idFacV: factura.idFactura
                    });

                    if (response.status === 200) {
                        setFactura({ ...factura, estado: 'anulado' });
                        Swal.fire({
                            title: 'Anulada',
                            text: `La factura ${factura.numFacV} ha sido anulada.`,
                            icon: 'success',
                            confirmButtonText: 'Aceptar',
                            iconColor: '#2fb380',
                            confirmButtonColor: "#2c4cc4"
                        });
                    } else {
                        console.error(`Error al anular factura: ${response.data.message}`);
                    }
                } catch (error) {
                    console.error('Error al anular factura:', error);
                    Swal.fire({
                        title: 'Error',
                        text: 'Hubo un problema al intentar anular la factura.',
                        icon: 'error',
                        confirmButtonText: 'Aceptar',
                        iconColor: '#da292e',
                        confirmButtonColor: "#da292e"
                    });
                }
            }
        });
    };

    useEffect(() => {
        const fetchFactura = async () => {
            try {
                const response = await APIserver.get(`/api/detallesFacturas/facturas/${idFactura}`);
                const data = response.data;
                setFactura(data);
                setCosEnvio(data.cosEnvio || 0);
                setTipoFactura(data.tipoFacV);
                setDescuento(data.descuento || 0);

                const clienteResponse = await APIserver.get(`/api/detallesFacturas/clientes/${data.idCliente}`);
                setCliente(clienteResponse.data);

                const deudaResponse = await APIserver.get(`/api/detallesFacturas/clientes/${data.idCliente}/deuda`);
                setDeudaCliente(deudaResponse.data.deudaTotal);

                const facturasResponse = await APIserver.get(`/api/detallesFacturas/clientes/${data.idCliente}/facturas`);
                setFacturasRelacionadas(facturasResponse.data.numeroFacturas);

                const pagosResponse = await APIserver.get(`/api/detallesFacturas/pagos/${idFactura}`);
                setPagos(pagosResponse.data);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        const fetchProductos = async () => {
            try {
                const response = await APIserver.get(`/api/detallesFacturas/facturas/${idFactura}/productos`);
                if (response.data && Array.isArray(response.data.productos)) {
                    setProductos(response.data.productos);
                } else {
                    console.warn('Datos de productos no válidos:', response.data);
                    setProductos([]);
                }
            } catch (error) {
                console.error('Error al obtener los productos de la factura:', error);
                setProductos([]);
            }
        };

        fetchFactura();
        fetchProductos();
    }, [idFactura]);

    if (!factura || !Array.isArray(productos) || productos.length === 0) {
        return <div>Loading...</div>;
    }

    return (
        <div className='mx-2 mt-4 mt-md-0'>
            <div className='d-flex flex-column flex-md-row align-items-center justify-content-between'>
                <h4 className='mb-2 mb-md-0 text-center'>
                    <span className='fw-bold text-black'>Factura No.</span> {factura.numFacV}
                </h4>
                <div className='m-2 d-flex justify-content-center align-items-center'>
                    <Button
                        variant='success'
                        disabled={factura.estado === 'anulado'}
                    >
                        Convertir en Factura Electrónica
                    </Button>
                    <Link to='/admin/IngresosFacturas/'>
                        <Button
                            variant='primary'
                            className='ms-2'
                        >
                            Volver
                        </Button>
                    </Link>
                    <Dropdown className='ms-2'>
                        <Dropdown.Toggle variant='secondary'>
                            Más
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <ButtonGroup>
                                <Button variant='link' className='text-primary' title='imprimir' onClick={handlePrint}>
                                    <PrinterFill />
                                </Button>
                                <Button variant='link' className='text-success' title='descargar'>
                                    <Download />
                                </Button>
                                <Button
                                    variant='link'
                                    className='text-success'
                                    title='agregar pago'
                                    onClick={handleShowModal}
                                    disabled={factura.estado === 'anulado' || factura.estado === 'pagado'}
                                >
                                    <CashStack size={18} />
                                </Button>
                                {/* Renderiza Link solo si la factura no está anulada o pagada */}
                                {factura.estado !== 'anulado' && factura.estado !== 'pagado' ? (
                                    <Link to={`/admin/Facturas/EditarFactura/${factura.idFacV}/`}>
                                        <Button
                                            variant='link'
                                            className='text-warning'
                                            title='editar factura'
                                        >
                                            <PencilFill size={18} />
                                        </Button>
                                    </Link>
                                ) : (
                                    <Button
                                        variant='link'
                                        className='text-warning'
                                        title='editar factura'
                                        disabled
                                    >
                                        <PencilFill size={18} />
                                    </Button>
                                )}
                                <Button
                                    variant='link'
                                    className='text-muted'
                                    title='anular pedido'
                                    onClick={anularFactura}
                                    disabled={factura.estado === 'anulado' || factura.estado === 'pagado'}
                                >
                                    <XCircleFill />
                                </Button>
                            </ButtonGroup>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
            <div className='mt-4'>
                <Card ref={componentRef} className='table-responsive comprobante-container shadow-none mt-4 card-con-tira'>
                    <div className={`franja-estado ${factura.estado.toLowerCase()}`}>{factura.estado.toUpperCase()}</div>
                    <Card.Body>
                        <div className='mt-2'>
                            <InfoCompanyFactura numFac={factura.numFacV} />
                        </div>
                        <div className='mt-2'>
                            <DetallesClienteFactura factura={factura} />
                        </div>
                        <div className='mt-0'>
                            <DetallesProductoFac productos={productos} envio={cosEnvio} descuento={descuento} />
                        </div>
                    </Card.Body>
                </Card>
                <Row className='mt-4 mb-4'>
                    <Col xs={12} md={6} lg={4}>
                        <InfoGeneralFactura
                            cliente={cliente.nomCliente}
                            idCliente={cliente.idCliente}
                            tipoFactura={tipoFactura}
                            deudaCliente={deudaCliente}
                            facturasRelacionadas={facturasRelacionadas}
                        />
                    </Col>
                    <Col xs={12} md={6} lg={8}>
                        <TablaPagosFactura pagos={pagos} />
                    </Col>
                </Row>
            </div>

            {/* Modal para agregar pago */}
            <CrearPagoResModal
                show={showModal}
                handleClose={handleCloseModal}
                factura={{
                    ...factura,
                    numeroFactura: factura.numFacV  // Pasa numFacV como numeroFactura
                }}
            />
        </div>
    );
}

export default DetallesFactura;
