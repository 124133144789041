import Swal from 'sweetalert2';
import APIserver from '../../../../api/axiosConfig';

// Función para manejar el envío de sección
export const handleSeccionSubmit = async (nombreSeccion) => {
  try {
    const response = await APIserver.post(`/api/seccion`, { nombreSeccion });

    if (response.status === 200) {
      Swal.fire({
        icon: 'success',
        iconColor: '#2fb380',
        title: '¡Éxito!',
        text: 'Sección guardada con éxito',
        showConfirmButton: false,
        timer: 2000
      });
      // Redirigir página
      setTimeout(() => {
        window.location.href = '/admin/Categorias/';
      }, 2000);

    } else {
      throw new Error('Error al guardar la sección');
    }
  } catch (error) {
    console.error('Error:', error);
    Swal.fire({
      icon: 'error',
      iconColor: '#da292e',
      title: 'Error',
      text: 'Error al procesar la solicitud de la sección',
      confirmButtonText: 'Aceptar',
      confirmButtonColor: "#da292e"
    });
  }
};

export const handleEditSeccion = async (idSeccion, nombreSeccion) => {
  try {
    // Realizar la solicitud PUT utilizando la instancia APIserver configurada
    const response = await APIserver.put(`/api/seccion/${idSeccion}`, { nombreSeccion });

    // Verificar si la respuesta no fue exitosa
    if (!response || response.status !== 200) {
      throw new Error('Error al actualizar la sección');
    }

    // Mensaje de éxito
    Swal.fire({
      title: '¡Éxito!',
      text: 'Sección actualizada correctamente',
      icon: 'success',
      confirmButtonText: 'Aceptar',
      iconColor: '#2fb380',
      confirmButtonColor: "#2c4cc4"
    });
  } catch (error) {
    console.error('Error:', error);

    // Mensaje de error
    Swal.fire({
      title: '¡Error!',
      text: 'Hubo un problema al actualizar la sección',
      icon: 'error',
      confirmButtonText: 'Aceptar',
      iconColor: '#da292e',
      confirmButtonColor: "#da292e"
    });
  }
};

// Función para crear una categoría
export const handleCategoriaSubmit = async (nombreCategoria, idSeccion, imagenCategoria) => {
  const formData = new FormData();
  formData.append('nomCategoria', nombreCategoria);
  formData.append('idSeccion', idSeccion);
  formData.append('imagenCategoria', imagenCategoria);

  try {
    const response = await APIserver.post('/api/categoria', formData);

    Swal.fire({
      title: '¡Éxito!',
      text: 'Categoría creada correctamente',
      icon: 'success',
      confirmButtonText: 'Aceptar',
      iconColor: '#2fb380',
      confirmButtonColor: "#2c4cc4"
    });
  } catch (error) {
    console.error('Error:', error);
    Swal.fire({
      title: '¡Error!',
      text: 'Hubo un problema al crear la categoría',
      icon: 'error',
      confirmButtonText: 'Aceptar',
      iconColor: '#da292e',
      confirmButtonColor: "#da292e"
    });
  }
};

// Función para actualizar una categoría
export const handleCategoriaUpdate = async (idCategoria, nombreCategoria, idSeccion, imagenCategoria) => {
  const formData = new FormData();
  formData.append('nomCategoria', nombreCategoria);
  formData.append('idSeccion', idSeccion);
  if (imagenCategoria) {
    formData.append('imagenCategoria', imagenCategoria);
  }

  try {
    const response = await APIserver.put(`/api/categoria/${idCategoria}`, formData);

    Swal.fire({
      title: '¡Éxito!',
      text: 'Categoría actualizada correctamente',
      icon: 'success',
      confirmButtonText: 'Aceptar',
      iconColor: '#2fb380',
      confirmButtonColor: "#2c4cc4"
    });
  } catch (error) {
    console.error('Error:', error);
    Swal.fire({
      title: '¡Error!',
      text: 'Hubo un problema al actualizar la categoría',
      icon: 'error',
      confirmButtonText: 'Aceptar',
      iconColor: '#da292e',
      confirmButtonColor: "#da292e"
    });
  }
};

// Función para eliminar una categoría
export const handleCategoriaDelete = async (idCategoria) => {
  try {
    const response = await APIserver.delete(`/api/categoria/${idCategoria}`);

    Swal.fire({
      title: '¡Éxito!',
      text: 'Categoría eliminada correctamente',
      icon: 'success',
      iconColor: '#2fb380',
      confirmButtonColor: "#2c4cc4"
    });
    setTimeout(() => {
      window.location.href = '/admin/Categorias/';
    }, 2000);
  } catch (error) {
    console.error('Error:', error);
    Swal.fire({
      title: '¡Error!',
      text: 'Hubo un problema al eliminar la categoría',
      icon: 'error',
      confirmButtonText: 'Aceptar',
      iconColor: '#da292e',
      confirmButtonColor: "#da292e"
    });
  }
};

// Función para confirmar y eliminar una categoría
export const handleDeleteClick = async (categoria) => {
  const result = await Swal.fire({
    title: '¿Estás seguro?',
    text: `¿Seguro que quieres eliminar la categoría ${categoria.nomCategoria}? Los productos dentro de esta categoria se moveran a la categoria "Otros"`,
    icon: 'warning',
    showCancelButton: true,
    iconColor: '#f4bd61',
    confirmButtonColor: '#2c4cc4',
    cancelButtonColor: '#da292e',
    confirmButtonText: 'Sí, eliminarla',
    cancelButtonText: 'Cancelar'
  });

  if (result.isConfirmed) {
    await handleCategoriaDelete(categoria.idCategoria);
  }
};

// Función para eliminar una sección
export const handleDeleteSeccion = async (idSeccion) => {
  try {
    const response = await APIserver.delete(`/api/seccion/${idSeccion}`);

    Swal.fire({
      title: '¡Éxito!',
      text: 'Sección eliminada correctamente',
      icon: 'success',
      confirmButtonText: 'Aceptar',
      iconColor: '#2fb380',
      confirmButtonColor: "#2c4cc4"
    });
    setTimeout(() => {
      window.location.href = '/admin/Categorias/';
    }, 2000);
  } catch (error) {
    console.error('Error:', error);
    Swal.fire({
      title: '¡Error!',
      text: 'Hubo un problema al eliminar la sección',
      icon: 'error',
      confirmButtonText: 'Aceptar',
      iconColor: '#da292e',
      confirmButtonColor: "#da292e"
    });
  }
};

export const handleDeleteSeccionClick = async (seccion) => {
  const result = await Swal.fire({
    title: '¿Estás seguro?',
    text: `¿Seguro que quieres eliminar la sección ${seccion.nomSeccion}? Las categorías dentro de esta sección se moverán a "Otros".`,
    icon: 'warning',
    showCancelButton: true,
    iconColor: '#f4bd61',
    confirmButtonColor: '#2c4cc4',
    cancelButtonColor: '#da292e',
    confirmButtonText: 'Sí, eliminarla',
    cancelButtonText: 'Cancelar'
  });

  if (result.isConfirmed) {
    await handleDeleteSeccion(seccion.idSeccion);
  }
};