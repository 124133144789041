// src/Profile.js
import React from 'react';
import InformacionUsuario from './personal/infoPersonal';
//import { Row, Col } from 'react-bootstrap';
//import NotificacionesFynDex from './personal/notificacionesFynDex';
//import NotificacionesCorreo from './personal/notificacionesCorreo';

function Profile() {


    return (
        <div className='m-4'>

            <InformacionUsuario />
            {/*
            <Row className='mt-3'>
                <Col>
                    <NotificacionesFynDex />
                </Col>
                <Col>
                    <NotificacionesCorreo />
                </Col>
            </Row>
            */}
        </div>
    );
}

export default Profile;
