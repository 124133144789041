import Swal from 'sweetalert2';

const AlertsFacturas = {
  showSuccessMessage: (message) => {
    Swal.fire({
      icon: 'success',
      iconColor: '#2fb380',
      title: '¡Éxito!',
      text: message || 'Pedido facturado exitosamente.',
      showConfirmButton: false,
      timer: 2000
    }).then(() => {
      window.location.href = '/admin/IngresosFacturas/';
    });
  },

  showErrorMessage: (message) => {
    Swal.fire({
      icon: 'error',
      iconColor: '#da292e',
      title: 'Error',
      text: message || 'Hubo un error al crear la factura.',
    });
  },

  showUpdateSuccessMessage: (message) => {
    Swal.fire({
      icon: 'success',
      iconColor: '#2fb380',
      title: '¡Éxito!',
      text: message || 'La factura se ha actualizado correctamente.',
      showConfirmButton: false,
      timer: 1500
    }).then(() => {
      window.location.reload(); // Recarga la página
    });
  },

  showUpdateErrorMessage: () => {
    Swal.fire({
      icon: 'error',
      iconColor: '#da292e',
      title: 'Error',
      text: 'Hubo un error al actualizar la factura.',
    });
  },

  showDeleteConfirmation: (facturaID, onDelete) => {
    Swal.fire({
      icon: 'warning',
      title: '¿Estás seguro?',
      text: `¿Quieres eliminar la factura con ID ${facturaID}?`,
      showCancelButton: true,
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar',
      iconColor: '#f4bd61',
      confirmButtonColor: '#2c4cc4',
      cancelButtonColor: '#da292e'
    }).then((result) => {
      if (result.isConfirmed) {
        onDelete(); // Llama a la función onDelete cuando se confirma la eliminación
      }
    });
  },

  showStockAlert: (cantidadDisponible) => {
    Swal.fire({
      icon: 'warning',
      iconColor: '#f4bd61',
      title: 'Stock insuficiente',
      text: `La cantidad solicitada excede el stock disponible. Solo hay ${cantidadDisponible} unidades disponibles.`,
      confirmButtonText: 'Aceptar'
    });
  },
  showStockAlerts: (stockDisponible) => {
    Swal.fire({
      icon: 'warning',
      iconColor: '#f4bd61',
      title: 'Stock insuficiente',
      text: `La cantidad solicitada, excede el stock disponible (${stockDisponible})`,
      confirmButtonText: 'Aceptar'
    });
  },
  showCancelConfirmation: (onConfirm) => {
    Swal.fire({
      icon: 'warning',
      title: '¿Estás seguro?',
      text: 'Si cancelas, el pedido no se facturara.',
      showCancelButton: true,
      confirmButtonText: 'Sí, cancelar',
      cancelButtonText: 'No, volver',
      iconColor: '#f4bd61',
      confirmButtonColor: '#2c4cc4',
      cancelButtonColor: '#da292e'
    }).then((result) => {
      if (result.isConfirmed) {
        onConfirm(); // Llama a la función onConfirm cuando se confirma la cancelación
      }
    });
  },

  // Nueva función para mostrar el mensaje de éxito al agregar un pago
  showPaymentSuccessMessage: (message) => {
    Swal.fire({
      icon: 'success',
      iconColor: '#2fb380',
      title: '¡Éxito!',
      text: message || 'El pago se ha agregado exitosamente.',
      showConfirmButton: false,
      timer: 2000
    }).then(() => {
      window.location.reload(); // Recarga la página
    });
  },

  showactualizacionSuccessMessage: (message) => {
    Swal.fire({
      icon: 'success',
      iconColor: '#2fb380',
      title: '¡Éxito!',
      text: message || 'Factura actualizada correctamente.',
      showConfirmButton: false,
      timer: 2000
    }).then(() => {
      window.location.href = '/admin/IngresosFacturas/';
    });
  },

  showactualizacionErrorMessage: () => {
    Swal.fire({
      icon: 'error',
      iconColor: '#da292e',
      title: 'Error',
      text: 'Hubo un error al actualizar la factura.',
    });
  },
  showFacAlert: () => {
    Swal.fire({
      icon: 'warning',
      iconColor: '#f4bd61',
      title: 'Error al facturar',
      text: `Este pedido ya esta facturado.`,
      confirmButtonText: 'Aceptar'
    });
  },
  showFacAlertAnular: () => {
    Swal.fire({
      icon: 'warning',
      iconColor: '#f4bd61',
      title: 'Error al anular',
      text: `Este pedido esta facturado y no se puede anular.`,
      confirmButtonText: 'Aceptar'
    });
  },
  showFacAlertAnularya: () => {
    Swal.fire({
      icon: 'warning',
      iconColor: '#f4bd61',
      title: 'Error al anular',
      text: `Este pedido ya esta anulado y no se puede anular.`,
      confirmButtonText: 'Aceptar'
    });
  },
  showFacsAlert: () => {
    Swal.fire({
      icon: 'warning',
      iconColor: '#f4bd61',
      title: 'Error al facturar',
      text: `Este pedido esta anulado y no se puede facturar.`,
      confirmButtonText: 'Aceptar'
    });
  },
  showAnularAlert: () => {
    Swal.fire({
      icon: 'success',
      iconColor: '#2fb380',
      title: '¡Éxito!',
      text: 'El pedido se ha anulado.',
      showConfirmButton: false,
      timer: 2000
    });
  },
  showConfirmAnularAlert: async (message, confirmText = 'Sí, anular', cancelText = 'Cancelar') => {
    const result = await Swal.fire({
      title: '¿Estás seguro?',
      text: message,
      icon: 'warning',
      showCancelButton: true,
      iconColor: '#f4bd61',
      confirmButtonColor: '#2c4cc4',
      cancelButtonColor: '#da292e',
      confirmButtonText: confirmText,
      cancelButtonText: cancelText,
    });

    return result.isConfirmed;
  }
};

export default AlertsFacturas;
