import React from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { Pagination, Autoplay, Navigation } from 'swiper/modules';
import APIserver from '../../../../api/axiosConfig';

function VerProductoModal({ show, handleClose, productoId }) {
    const [producto, setProducto] = React.useState(null);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        if (show && productoId) {
            obtenerDetallesProducto(productoId);
        }
    }, [show, productoId]);

    const obtenerDetallesProducto = async (productId) => {
        setLoading(true); // Iniciar carga
        try {
            const response = await APIserver.get(`/api/productos/${productId}`);
            setProducto(response.data);
        } catch (error) {
            console.error('Error al obtener detalles del producto:', error);
            await Swal.fire({
                title: 'Error',
                text: 'No se pudieron obtener los detalles del producto',
                icon: 'error',
                iconColor: '#da292e'
            });
        } finally {
            setLoading(false); // Finalizar carga
        }
    };

    if (!show) {
        return null;
    }

    return (
        <Modal size='lg' show={show} onHide={handleClose} aria-labelledby="modal-ver-producto">
            <Modal.Header closeButton>
                <Modal.Title>Detalles del Producto</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading ? (
                    <p>Cargando detalles del producto...</p>
                ) : producto ? (
                    <Row>
                        <Col md={6}>
                            {/* Verificar si hay imágenes y crear el slider */}
                            {producto.imagenes && producto.imagenes.length > 0 ? (
                                <Swiper
                                    spaceBetween={10}
                                    slidesPerView={1}
                                    pagination={{ clickable: true }}
                                    autoplay={{ delay: 3000 }}
                                    navigation
                                    modules={[Pagination, Autoplay, Navigation]}
                                >
                                    {producto.imagenes.map((imagen, index) => {
                                        return (
                                            <SwiperSlide key={index}>
                                                <img
                                                    src={imagen} // Usar la URL directa
                                                    alt={`Producto ${producto.nombre} - imagen ${index + 1}`}
                                                    style={{ width: '100%' }}
                                                />
                                            </SwiperSlide>
                                        );
                                    })}
                                </Swiper>
                            ) : (
                                <p>No hay imágenes disponibles para este producto.</p>
                            )}

                        </Col>
                        <Col md={6}>
                            <h4>{producto.nombre}</h4>
                            <p><strong>Referencia:</strong> {producto.referencia}</p>
                            <p><strong>Categoría:</strong> {producto.categoria}</p>
                            <p><strong>Stock:</strong> {producto.stock}</p>
                            <p><strong>Precio Unidad:</strong> ${producto.precioUnidad}</p>
                            <p><strong>Precio Mayor:</strong> ${producto.precioMayor}</p>
                            <p><strong>Descripción:</strong> {producto.descripcion}</p>

                            {/* Sección para mostrar colores */}
                            {producto.colores && producto.colores.length > 0 && (
                                <>
                                    <h5>Colores disponibles:</h5>
                                    <div style={{ display: 'flex', gap: '10px' }}>
                                        {producto.colores.map((color, index) => (
                                            <div key={index} style={{ textAlign: 'center' }}>
                                                <div
                                                    style={{
                                                        backgroundColor: color.hexCode,
                                                        width: '30px',
                                                        height: '30px',
                                                        borderRadius: '50%',
                                                        border: '1px solid #000'
                                                    }}
                                                />
                                                <p>{color.nombre}</p>
                                            </div>
                                        ))}
                                    </div>
                                </>
                            )}

                            {/* Sección para mostrar tallas */}
                            {producto.tallas && producto.tallas.length > 0 && (
                                <>
                                    <h5>Motivos disponibles:</h5>
                                    <div style={{ display: 'flex', gap: '10px' }}>
                                        {producto.tallas.map((talla, index) => (
                                            <span
                                                key={index}
                                                style={{
                                                    padding: '5px 10px',
                                                    border: '1px solid #000',
                                                    borderRadius: '5px'
                                                }}
                                            >
                                                {talla}
                                            </span>
                                        ))}
                                    </div>
                                </>
                            )}
                        </Col>
                    </Row>
                ) : (
                    <p>No se encontraron detalles del producto.</p>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant='secondary' onClick={handleClose}>
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default VerProductoModal;
