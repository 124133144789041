import React, { useState } from "react";
import { Nav, Card } from 'react-bootstrap';
import { HouseDoorFill, GraphUpArrow, GraphDownArrow, PersonLinesFill, Grid, Whatsapp, Bank, ChevronRight, ChevronDown, Clipboard2DataFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import './styles/Navigation.css';

const sections = [
  {
    key: 'ingresos',
    icon: GraphUpArrow,
    label: 'Ingresos',
    subItems: [
      { to: '/admin/IngresosFacturas/', label: 'Facturas' },
      { to: '/admin/PagosRecibidos/', label: 'Pagos Recibidos' },
      { to: '/admin/Pedidos/', label: 'Pedidos' },
    ],
  },
  {
    key: 'gastos',
    icon: GraphDownArrow,
    label: 'Gastos',
    subItems: [
      { to: '/admin/Compras/', label: 'Facturas de Compra' },
      { to: '/admin/PagosRealizados/', label: 'Pagos Realizados' },
    ],
  },
  {
    key: 'contactos',
    icon: PersonLinesFill,
    label: 'Contactos',
    subItems: [
      { to: '/admin/Clientes/', label: 'Clientes' },
      { to: '/admin/Proveedores/', label: 'Proveedores' },
      { to: '/admin/Marketing/', label: 'Marketing' }
    ],
  },
  {
    key: 'inventario',
    icon: Grid,
    label: 'Inventario',
    subItems: [
      { to: '/admin/Categorias/', label: 'Categorias' },
      { to: '/admin/Productos/', label: 'Productos' },
    ],
  },
];

const SidebarContent = ({ handleClose }) => {
  const [activeSection, setActiveSection] = useState(null);

  const handleSectionClick = (key) => {
    setActiveSection((prevKey) => (prevKey === key ? null : key));
  };

  return (
    <Nav defaultActiveKey="/admin/Dashboard/" className="flex-column">
      <Nav.Link as={Link} to="/admin/Dashboard/" className="text-white" onClick={handleClose}>
        <HouseDoorFill className="m-2" /> Dashboard
      </Nav.Link>
      {sections.map((section) => (
        <div key={section.key} className="nav-item">
          <Nav.Link
            onClick={() => handleSectionClick(section.key)}
            className="text-white d-flex align-items-center justify-content-between"
            id={`nav-link-${section.key}`}
          >
            <span><section.icon className="m-2" /> {section.label}</span>
            {activeSection === section.key ? <ChevronDown /> : <ChevronRight />}
          </Nav.Link>
          <div className={`collapsible ${activeSection === section.key ? 'open' : ''}`} style={{ overflow: 'hidden', maxHeight: activeSection === section.key ? '200px' : '0', transition: 'max-height 0.3s ease-out' }}>
            <Card className="bg-white text-dark border-0 rounded m-2">
              <Card.Body className="p-2">
                {section.subItems.map((subItem) => (
                  <Nav.Link as={Link} to={subItem.to} key={subItem.to} className="text-dark p-2" onClick={handleClose}>
                    {subItem.label}
                  </Nav.Link>
                ))}
              </Card.Body>
            </Card>
          </div>
        </div>
      ))}
      <Nav.Link as={Link} to="/admin/Sucursales/" className="text-white" onClick={handleClose}>
        <Whatsapp className="m-2" /> Sucursales
      </Nav.Link>
      <Nav.Link as={Link} to="/admin/Bancos/" className="text-white" onClick={handleClose}>
        <Bank className="m-2" /> Bancos
      </Nav.Link>
      <Nav.Link as={Link} to='/admin/Reportes/' className="text-white" onClick={handleClose}>
        <Clipboard2DataFill className="m-2" /> Reportes
      </Nav.Link>
    </Nav>
  );
};

export default SidebarContent;
