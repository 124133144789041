import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { handleUpdateBanco } from '../Utils/AlertsBancos'; // Importa la función handleUpdateBanco desde AlertsBancos
import { QuestionCircle } from 'react-bootstrap-icons';
import APIserver from '../../../../api/axiosConfig';

function EditarBanco({ show, handleClose, bancoId, onUpdate }) {
    const [nomBanco, setNomBanco] = useState('');
    const [tipoCuenta, setTipoCuenta] = useState('');
    const [numeroCuenta, setNumeroCuenta] = useState('');
    const [saldoBanco, setSaldoBanco] = useState(0);
    const rederTooltip = (props) => (
        <Tooltip id='button-tooltip' {...props}>
            Saldo incial es el valor con el cual registro el banco por primera vez.
        </Tooltip>
    );

    useEffect(() => {
        if (show && bancoId) {
            // Función para obtener los datos del banco por su ID
            const fetchBancoById = async () => {
                try {
                    const response = await APIserver.get(`/api/bancos/${bancoId}`);
                    const banco = response.data;

                    // Autocompletar los campos con los datos del banco
                    setNomBanco(banco.nomBanco);
                    setTipoCuenta(banco.tipoCuenta);
                    setNumeroCuenta(banco.numeroCuenta);
                    setSaldoBanco(banco.saldoBanco);
                } catch (error) {
                    console.error('Error al obtener el banco:', error);
                }
            };

            fetchBancoById();
        }
    }, [show, bancoId]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Llamar a la función handleUpdateBanco para manejar la actualización del banco
        handleUpdateBanco(bancoId, nomBanco, tipoCuenta, numeroCuenta, saldoBanco, onUpdate, handleClose);
    };

    return (
        <Modal show={show} onHide={handleClose} aria-labelledby="modal-editar-banco">
            <Modal.Header closeButton>
                <Modal.Title>Editar Banco</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className='m-3 mt-0' controlId='nomBancoEdit'>
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control type='text' name='nomBancoEdit' value={nomBanco} onChange={(e) => setNomBanco(e.target.value)} required />
                    </Form.Group>
                    <Form.Group className='m-3' controlId='tipoBancoEdit'>
                        <Form.Label>Tipo de Cuenta</Form.Label>
                        <Form.Control type='text' name='tipoBancoEdit' value={tipoCuenta} onChange={(e) => setTipoCuenta(e.target.value)} required />
                    </Form.Group>
                    <Form.Group className='m-3' controlId='numBancoEdit'>
                        <Form.Label>Número</Form.Label>
                        <Form.Control type='number' name='numBancoEdit' value={numeroCuenta} onChange={(e) => setNumeroCuenta(e.target.value)} required />
                    </Form.Group>
                    <Form.Group className='m-3' controlId='saldoBancoEdit'>
                        <Form.Label>Saldo Inicial
                            <OverlayTrigger
                                placement='right'
                                delay={{ show: 250, hide: 400 }}
                                overlay={rederTooltip}
                            >
                                <QuestionCircle className='ms-2'/>
                            </OverlayTrigger>
                        </Form.Label>
                        <Form.Control
                            type='number'
                            step='0.01'
                            name='saldoBancoEdit'
                            value={saldoBanco}
                            onChange={(e) => setSaldoBanco(e.target.value)} // Actualizar el saldo
                            disabled
                        />
                    </Form.Group>
                    <Modal.Footer>
                        <Button variant='secondary' onClick={handleClose}>Cancelar</Button>
                        <Button variant='primary' type='submit'>Guardar</Button>
                    </Modal.Footer>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default EditarBanco;
