import Swal from 'sweetalert2';

const AlertsFacturas = {
  showSuccessMessage: (message) => {
    Swal.fire({
      icon: 'success',
      iconColor: '#2fb380',
      title: '¡Éxito!',
      text: message || 'La factura se ha creado correctamente.',
      showConfirmButton: false,
      timer: 2000
    }).then(() => {
      Swal.fire({
        icon: 'question',
        title: 'Factura creada',
        text: '¿Desea volver a la página de facturas?',
        showCancelButton: true,
        confirmButtonText: 'Sí',
        cancelButtonText: 'No',
        iconColor: '#f4bd61',
        confirmButtonColor: '#2c4cc4',
        cancelButtonColor: '#da292e'
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.href = '/admin/IngresosFacturas/'; // Redirige a IngresosFacturas
        } else {
          window.location.reload(); // Recarga la página
        }
      });
    });
  },

  showErrorMessage: (message) => {
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: message || 'Hubo un error al crear la factura.',
      confirmButtonText: 'Aceptar',
      iconColor: '#da292e',
      confirmButtonColor: "#da292e"
    });
  },

  showUpdateSuccessMessage: (message) => {
    Swal.fire({
      icon: 'success',
      title: '¡Éxito!',
      text: message || 'La factura se ha actualizado correctamente.',
      showConfirmButton: false,
      timer: 1500,
      iconColor: '#2fb380'
    }).then(() => {
      window.location.reload(); // Recarga la página
    });
  },

  showUpdateErrorMessage: () => {
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'Hubo un error al actualizar la factura.',
      confirmButtonText: 'Aceptar',
      iconColor: '#da292e',
      confirmButtonColor: "#da292e"
    });
  },

  showDeleteConfirmation: (facturaID, onDelete) => {
    Swal.fire({
      icon: 'warning',
      title: '¿Estás seguro?',
      text: `¿Quieres eliminar la factura con ID ${facturaID}?`,
      showCancelButton: true,
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar',
      iconColor: '#f4bd61',
      confirmButtonColor: '#2c4cc4',
      cancelButtonColor: '#da292e'
    }).then((result) => {
      if (result.isConfirmed) {
        onDelete(); // Llama a la función onDelete cuando se confirma la eliminación
      }
    });
  },

  showStockAlert: (cantidadDisponible) => {
    Swal.fire({
      icon: 'warning',
      title: 'Stock insuficiente',
      text: `La cantidad solicitada excede el stock disponible. Solo hay ${cantidadDisponible} unidades disponibles.`,
      confirmButtonText: 'Aceptar',
      iconColor: '#f4bd61',
      confirmButtonColor: '#2c4cc4'
    });
  },

  showCancelConfirmation: (onConfirm) => {
    Swal.fire({
      icon: 'warning',
      title: '¿Estás seguro?',
      text: 'Si cancelas, los cambios no guardados se perderán.',
      showCancelButton: true,
      confirmButtonText: 'Sí, cancelar',
      cancelButtonText: 'No, volver',
      iconColor: '#f4bd61',
      confirmButtonColor: '#2c4cc4',
      cancelButtonColor: '#da292e'
    }).then((result) => {
      if (result.isConfirmed) {
        onConfirm(); // Llama a la función onConfirm cuando se confirma la cancelación
      }
    });
  },

  // Nueva función para mostrar el mensaje de éxito al agregar un pago
  showPaymentSuccessMessage: (message) => {
    Swal.fire({
      icon: 'success',
      iconColor: '#2fb380',
      title: '¡Éxito!',
      text: message || 'El pago se ha agregado exitosamente.',
      showConfirmButton: false,
      timer: 2000
    }).then(() => {
      window.location.reload(); // Recarga la página
    });
  },

  showactualizacionSuccessMessage: (message) => {
    Swal.fire({
      icon: 'success',
      iconColor: '#2fb380',
      title: '¡Éxito!',
      text: message || 'Factura actualizada correctamente.',
      showConfirmButton: false,
      timer: 2000
    }).then(() => {
      window.location.href = '/admin/IngresosFacturas/';
    });
  },

  showactualizacionErrorMessage: () => {
    Swal.fire({
      icon: 'error',
      iconColor: '#da292e',
      title: 'Error',
      text: 'Hubo un error al actualizar la factura.',
    });
  },

  showErrorEncontrar: () => {
    Swal.fire({
      icon: 'warning',
      iconColor: '#f4bd61',
      title: 'error',
      text: `Producto no encontrado. Verifique la referencia`
    });
  }
};

export default AlertsFacturas;
