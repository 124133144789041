import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Welcome from './componentes/bienvenida';
import Stats from './componentes/stats';
import Charts from './componentes/charts';
import Calendar from './componentes/calendar';
import Metas from './componentes/metas';

const AdminDashboard = () => {
  return (
    <Container fluid>
      <Row className="my-4">
        <Col md={8}>
          <Welcome />
          <Metas />
        </Col>
        <Col md={4}>
          <Calendar />
        </Col>
      </Row>
      <Row className="my-4">
        <Col md={4}>
          <Stats />
        </Col>
        <Col md={8}>
          <Charts />
        </Col>
      </Row>
    </Container>
  );
};

export default AdminDashboard;
