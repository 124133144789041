import React, { useState, useEffect } from 'react';
import { Button, Table, Form, ButtonGroup, Dropdown, Pagination } from 'react-bootstrap';
import { CashStack, PencilFill, EyeFill, Filter, XCircleFill, CheckCircleFill, CloudArrowUpFill, ThreeDotsVertical } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import FiltroFacturas from '../../facturas/componentes/filtrosFacturas';
import CustomToggle from '../../styles/customToggle';
import CrearPagoResModal from '../../PagosResibidos/componentes/crearPagoResModal';
import Swal from 'sweetalert2';  // Importar SweetAlert para las alertas
import APIserver from '../../../../api/axiosConfig';

function PedidosCliente({ clienteId }) {
    const [facturas, setFacturas] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [filters, setFilters] = useState({});
    const [showFilters, setShowFilters] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedFactura, setSelectedFactura] = useState(null);

    useEffect(() => {
        const fetchFacturas = async () => {
            try {
                const response = await APIserver.get(`/api/Mostrar/facturasClientes/${clienteId}`, {
                    params: {
                        page: currentPage,
                        rowsPerPage,
                        ...filters
                    }
                });

                setFacturas(response.data.facturas);

                if (Object.keys(filters).length === 0) {
                    setTotalPages(response.data.totalPages);
                } else {
                    setTotalPages(1); // Solo una página cuando hay filtros
                }

            } catch (error) {
                console.error('Error al obtener facturas:', error);
            }
        };

        fetchFacturas();
    }, [clienteId, currentPage, rowsPerPage, filters]);

    const toggleFilters = () => {
        setShowFilters(!showFilters);
    };

    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setCurrentPage(1); // Resetear a la primera página
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleFilterChange = (newFilters) => {
        setFilters(newFilters);
        setCurrentPage(1); // Resetear a la primera página cuando cambian los filtros
    };

    const handleShowModal = (factura) => {
        setSelectedFactura(factura);
        setShowModal(true);
    };

    const handleCloseModal = () => setShowModal(false);

    // Función para anular factura
    const anularFactura = async (factura) => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: `Estás a punto de anular la factura ${factura.numeroFactura}. Esta acción no se puede deshacer.`,
            icon: 'warning',
            showCancelButton: true,
            iconColor: '#f4bd61',
            confirmButtonColor: '#2c4cc4',
            cancelButtonColor: '#da292e',
            confirmButtonText: 'Sí, anular',
            cancelButtonText: 'Cancelar'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response = await APIserver.post(`/api/actualizarv/facturasVenta/anular`, {
                        idFacV: factura.idFactura
                    });

                    if (response.status === 200) {
                        // Actualizar el estado de la factura en el frontend
                        setFacturas(prevFacturas =>
                            prevFacturas.map(f =>
                                f.idFactura === factura.idFactura ? { ...f, estado: 'anulado' } : f
                            )
                        );
                        Swal.fire({
                            title: 'Anulada!',
                            text: `La factura ${factura.numeroFactura} ha sido anulada.`,
                            icon: 'success',
                            confirmButtonText: 'Aceptar',
                            iconColor: '#2fb380',
                            confirmButtonColor: "#2c4cc4"
                        });
                    } else {
                        console.error(`Error al anular factura: ${response.data.message}`);
                    }
                } catch (error) {
                    console.error('Error al anular factura:', error);
                    Swal.fire({
                        title: 'Error',
                        text: 'Hubo un problema al intentar anular la factura.',
                        icon: 'error',
                        confirmButtonText: 'Aceptar',
                        iconColor: '#da292e',
                        confirmButtonColor: "#da292e"
                    });
                }
            }
        });
    };

    const renderPaginationItems = () => {
        const items = [];
        for (let number = 1; number <= totalPages; number++) {
            items.push(
                <Pagination.Item
                    key={number}
                    active={number === currentPage}
                    onClick={() => handlePageChange(number)}
                >
                    {number}
                </Pagination.Item>
            );
        }
        return items;
    };

    return (
        <div style={{ boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)" }}>
            <Table responsive className='text-center align-middle border'>
                <thead>
                    <tr>
                        <th colSpan={10}>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='d-flex flex-wrap'>
                                    <Button variant='link' size='sm' title='Filtrar' onClick={toggleFilters}>
                                        <Filter size={20} />
                                    </Button>
                                </div>
                                <div>
                                    <Form.Select
                                        className='ms-2'
                                        value={rowsPerPage}
                                        onChange={handleChangeRowsPerPage}
                                    >
                                        <option value='5'>5</option>
                                        <option value='10'>10</option>
                                        <option value='20'>20</option>
                                        <option value='50'>50</option>
                                    </Form.Select>
                                </div>
                            </div>
                            <div className={`filter-options ${showFilters ? 'show' : ''}`}>
                                <FiltroFacturas onFilter={handleFilterChange} />
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <th>Factura</th>
                        <th>Cliente</th>
                        <th>Creacion</th>
                        <th>Vence</th>
                        <th>Total</th>
                        <th>Pagado</th>
                        <th>Pendiente</th>
                        <th>Estado</th>
                        <th>FRA.Electronica</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {facturas.length > 0 ? (
                        facturas.map(factura => (
                            <tr key={factura.idFactura}>
                                <td>
                                    <Link to={`/admin/Facturas/DetallesFactura/${factura.idFactura}/`} className='text-decoration-none'>
                                        {factura.numeroFactura}
                                    </Link>
                                </td>
                                <td>{factura.clienteNombre}</td>
                                <td>{new Date(factura.fechaCreacion).toLocaleDateString()}</td>
                                <td>{new Date(factura.fechaVencimiento).toLocaleDateString()}</td>
                                <td>${factura.total.toLocaleString()}</td>
                                <td>${factura.pagado.toLocaleString()}</td>
                                <td>${factura.pendiente.toLocaleString()}</td>
                                <td>
                                    <div className={factura.estado === 'pendiente' ? 'text-warning' : factura.estado === 'pagado' ? 'text-success' : factura.estado === 'vencido' ? 'text-danger' : 'text-muted'}>
                                        {factura.estado}
                                    </div>
                                </td>
                                <td>
                                    {factura.esFacturaElectronica ? (
                                        <XCircleFill className='text-danger' size={24} title="Factura No Electrónica" />
                                    ) : (
                                        <CheckCircleFill className='text-success' size={24} title="Factura Electrónica" />
                                    )}
                                </td>
                                <td>
                                    <Dropdown>
                                        <Dropdown.Toggle as={CustomToggle}>
                                            <ThreeDotsVertical />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <ButtonGroup>
                                                <Link to={`/admin/Facturas/DetallesFactura/${factura.idFactura}/`}>
                                                    <Button variant='link' className='text-primary' title='ver factura'>
                                                        <EyeFill size={18} />
                                                    </Button>
                                                </Link>
                                                <Button
                                                    variant='link'
                                                    className='text-success'
                                                    title='agregar pago'
                                                    onClick={() => handleShowModal(factura)}
                                                    disabled={factura.estado === 'anulado'|| factura.estado === 'pagado'}
                                                >
                                                    <CashStack size={18} />
                                                </Button>
                                                <Button
                                                    variant='link'
                                                    className='text-success'
                                                    title='factura electronica'
                                                    disabled={factura.estado === 'anulado'}
                                                >
                                                    <CloudArrowUpFill size={18} />
                                                </Button>

                                                {/* Deshabilitar el botón de edición si la factura está anulada */}
                                                {factura.estado !== 'anulado' && factura.estado !== 'pagado' ? (
                                                    <Link to={`/admin/Facturas/EditarFactura/${factura.idFactura}/`}>
                                                        <Button
                                                            variant='link'
                                                            className='text-warning'
                                                            title='editar factura'
                                                        >
                                                            <PencilFill size={18} />
                                                        </Button>
                                                    </Link>
                                                ) : (
                                                    <Button
                                                        variant='link'
                                                        className='text-warning'
                                                        title='editar factura'
                                                        disabled
                                                    >
                                                        <PencilFill size={18} />
                                                    </Button>
                                                )}

                                                <Button
                                                    variant='link'
                                                    className='text-muted'
                                                    title='anular factura'
                                                    onClick={() => anularFactura(factura)}
                                                    disabled={factura.estado === 'anulado'|| factura.estado === 'pagado'}
                                                >
                                                    <XCircleFill size={18} />
                                                </Button>
                                            </ButtonGroup>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={10}>No hay facturas para mostrar</td>
                        </tr>
                    )}
                </tbody>
                {Object.keys(filters).length === 0 && totalPages > 1 && (
                    <tfoot>
                        <tr>
                            <td colSpan={10}>
                                <div className='d-flex justify-content-center align-items-center'>
                                    <Pagination>
                                        <Pagination.Prev
                                            onClick={() => currentPage > 1 && handlePageChange(currentPage - 1)}
                                        />
                                        {renderPaginationItems()}
                                        <Pagination.Next
                                            onClick={() => currentPage < totalPages && handlePageChange(currentPage + 1)}
                                        />
                                    </Pagination>
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                )}
            </Table>

            {/* Modal para crear pago */}
            <CrearPagoResModal
                show={showModal}
                handleClose={handleCloseModal}
                factura={selectedFactura}
            />
        </div>
    );
}

export default PedidosCliente;
