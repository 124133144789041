import React, { useState, useEffect } from 'react';
import { Table, Button, Form, ButtonGroup, Pagination, Dropdown } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { Filter, FileEarmarkArrowUpFill, EyeFill, XCircleFill, ThreeDotsVertical } from 'react-bootstrap-icons';
import FiltrosPedidos from './filtrosPedido';
import CustomToggle from '../../styles/customToggle';
import AlertsFacturas from '../FacturarPedidos/Utils/AlertsFacturasPedidos'; // Importación del archivo para manejar las alertas
import APIserver from '../../../../api/axiosConfig';

function TablaPedidos() {
    const [pedidos, setPedidos] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [showFilters, setShowFilters] = useState(false);
    const [filters, setFilters] = useState({
        pedido: '',
        cliente: '',
        fecha: '',
        total: '',
        asesor: '',
        tipo: '',
        estado: ''
    });

    const navigate = useNavigate(); // Hook para redirigir al usuario a otra ruta

    const fetchData = async () => {
        try {
            const queryString = new URLSearchParams({
                page: currentPage,
                rowsPerPage,
                ...filters
            }).toString();
            
            // Realizar la solicitud usando APIserver.get
            const response = await APIserver.get(`/api/pedidos/pedidos/tabla?${queryString}`);
            
            // Axios retorna los datos en response.data directamente
            const data = response.data;
    
            if (Array.isArray(data.data)) {
                setPedidos(data.data);
                setTotalPages(data.totalPages || 1); // Evitar que el total de páginas sea cero
            } else {
                console.error('Datos recibidos no son válidos:', data);
            }
        } catch (error) {
            console.error('Error fetching pedidos:', error);
        }
    };
    
    useEffect(() => {
        fetchData();
    }, [currentPage, rowsPerPage, filters]);
    

    const toggleFilters = () => {
        setShowFilters(!showFilters);
    };
    
    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setCurrentPage(1); // Resetear a la primera página
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleSearch = (newFilters) => {
        setFilters(newFilters);
        setCurrentPage(1); // Reiniciar a la primera página con nuevos filtros
    };

    const handleClear = () => {
        setFilters({
            pedido: '',
            cliente: '',
            fecha: '',
            total: '',
            asesor: '',
            tipo: '',
            estado: ''
        });
        setCurrentPage(1); // Reiniciar a la primera página con filtros limpios
    };

    const handleFacturarPedido = async (idPedido, estado) => {
        if (estado === 'facturado') {
            AlertsFacturas.showFacAlert();
            return;
        }
    
        if (estado === 'anulado') {
            AlertsFacturas.showFacsAlert();
            return;
        }
    
        // Si el pedido no está facturado ni anulado, redirigir a la página de facturación
        navigate(`/admin/Pedidos/FacturarPedidos/CrearFacturarPedidos/${idPedido}/`);
    };
    
    const handleAnularPedido = async (idPedido, estado) => {
        if (estado === 'facturado') {
            AlertsFacturas.showFacAlertAnular("Este pedido ya está facturado y no se puede anular.");
            return;
        }
        if (estado === 'anulado') {
            AlertsFacturas.showFacAlertAnularya("Este pedido ya está anulado y no se puede anular nuevamente.");
            return;
        }
    
        const isConfirmed = await AlertsFacturas.showConfirmAnularAlert(
            "Esta acción anulará el pedido y no podrá deshacerse."
        );
    
        if (isConfirmed) {
            try {
                const response = await APIserver.put(`/api/facturarPedidos/anular/${idPedido}`, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                
                if (response.status === 200) {
                    AlertsFacturas.showAnularAlert('El pedido ha sido anulado.');
                    // Refrescar los datos de la tabla
                    fetchData();
                } else {
                    AlertsFacturas.showErrorMessage('Hubo un error al anular el pedido.');
                }
            } catch (error) {
                console.error('Error anulando el pedido:', error);
                AlertsFacturas.showErrorMessage('Hubo un error al anular el pedido.');
            }
        }
    };
    
     // Función para renderizar los botones de paginación
     const renderPaginationItems = () => {
        const items = [];
        const pageRangeDisplayed = 5; // Mostrar 5 páginas a la vez
        let startPage = Math.max(1, currentPage - Math.floor(pageRangeDisplayed / 2));
        let endPage = Math.min(totalPages, startPage + pageRangeDisplayed - 1);

        if (endPage - startPage < pageRangeDisplayed - 1) {
            startPage = Math.max(1, endPage - pageRangeDisplayed + 1);
        }

        if (startPage > 1) {
            items.push(
                <Pagination.First key="first" onClick={() => handlePageChange(1)} />
            );
        }

        if (currentPage > 1) {
            items.push(
                <Pagination.Prev key="prev" onClick={() => handlePageChange(currentPage - 1)} />
            );
        }

        for (let number = startPage; number <= endPage; number++) {
            items.push(
                <Pagination.Item
                    key={number}
                    active={number === currentPage}
                    onClick={() => handlePageChange(number)}
                >
                    {number}
                </Pagination.Item>
            );
        }

        if (currentPage < totalPages) {
            items.push(
                <Pagination.Next key="next" onClick={() => handlePageChange(currentPage + 1)} />
            );
        }

        if (endPage < totalPages) {
            items.push(
                <Pagination.Last key="last" onClick={() => handlePageChange(totalPages)} />
            );
        }

        return items;
    };

    return (
        <div style={{ boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)" }}>
            <Table responsive className='text-center align-middle border'>
                <thead>
                    <tr>
                        <th colSpan={10}>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='d-flex flex-wrap'>
                                    <Button variant='link' size='sm' title='Filtrar' onClick={toggleFilters}>
                                        <Filter size={20} />
                                    </Button>
                                </div>
                                <div>
                                    <Form.Select
                                        className='ms-2'
                                        value={rowsPerPage}
                                        onChange={handleChangeRowsPerPage}
                                    >
                                        <option value='5'>5</option>
                                        <option value='10'>10</option>
                                        <option value='20'>20</option>
                                        <option value='50'>50</option>
                                    </Form.Select>
                                </div>
                            </div>
                            <div className={`filter-options ${showFilters ? 'show' : ''}`}>
                                <FiltrosPedidos onSearch={handleSearch} onClear={handleClear} />
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <th>Pedido</th>
                        <th>Cliente</th>
                        <th>Fecha</th>
                        <th>Envio</th>
                        <th>Items</th>
                        <th>Total</th>
                        <th>Asesor</th>
                        <th>Tipo</th>
                        <th>Estado</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {pedidos.length > 0 ? (
                        pedidos.map((pedido) => (
                            <tr key={pedido.idPedido}>
                                <td>
                                    <Link to={`/admin/Pedidos/DetallesPedido/${pedido.idPedido}/`} className='text-decoration-none'>
                                        {pedido.numPedido}
                                    </Link>
                                </td>
                                <td>{pedido.nomCliente}</td>
                                <td>{new Date(pedido.creacion).toLocaleDateString()}</td>
                                <td>$ {pedido.costoEnvio.toLocaleString()}</td>
                                <td>{pedido.items}</td>
                                <td>$ {pedido.totalPedido.toLocaleString()}</td>
                                <td>{pedido.nomSucursal}</td>
                                <td>{pedido.tipo}</td>
                                <td>
                                    <div className={
                                        pedido.estado === 'pendiente' ? 'text-warning' :
                                        pedido.estado === 'facturado' ? 'text-success' :
                                        pedido.estado === 'anulado' ? 'text-muted' : ''
                                    }>
                                        {pedido.estado.charAt(0).toUpperCase() + pedido.estado.slice(1)}
                                    </div>
                                </td>

                                <td>
                                    <Dropdown>
                                        <Dropdown.Toggle as={CustomToggle}>
                                            <ThreeDotsVertical />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <ButtonGroup>
                                                <Link to={`/admin/Pedidos/DetallesPedido/${pedido.idPedido}/`}>
                                                    <Button variant='link' className='text-primary' title='Ver pedido'>
                                                        <EyeFill size={18} />
                                                    </Button>
                                                </Link>
                                                <Button 
                                                    variant='link' 
                                                    className='text-success' 
                                                    title='Facturar Pedido' 
                                                    onClick={() => handleFacturarPedido(pedido.idPedido, pedido.estado)}
                                                >
                                                    <FileEarmarkArrowUpFill size={18} />
                                                </Button>
                                                <Button 
                                                    variant='link' 
                                                    className='text-muted' 
                                                    title='Anular Pedido'
                                                    onClick={() => handleAnularPedido(pedido.idPedido, pedido.estado)}
                                                >
                                                    <XCircleFill size={18} />
                                                </Button>
                                            </ButtonGroup>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>

                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={10}>No hay pedidos disponibles</td>
                        </tr>
                    )}
                </tbody>
                {totalPages > 1 && (
                    <tfoot>
                        <tr>
                            <td colSpan={10}>
                                <div className='d-flex justify-content-center align-items-center'>
                                    <Pagination>
                                        {renderPaginationItems()}
                                    </Pagination>
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                )}
            </Table>
        </div>
    );
}

export default TablaPedidos;
