// src/components/common/componenter/UserOptions.js
import React from "react";
import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Avatar } from "@mui/material";
import useAuth from "../../../hooks/useAuth";

const ProfileDropdown = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const { auth } = useAuth();

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const NavigateToConfig = () => {
    navigate('/admin/Configuracion/');
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <button
      type="button"
      className="btn btn-link ms-2 text-decoration-none"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </button>
  ));

  const firstLetter = auth.user.name ? auth.user.name.charAt(0) : '';
  const firstName = auth.user.name.split(' ')[0];
  const avatarUrl = auth.user.avatar ? `${auth.user.avatar}` : null;

  return (
    <Dropdown align="end">
      <Dropdown.Toggle as={CustomToggle} variant="light" id="dropdown-profile">
        {avatarUrl ? (
          <Avatar
            alt="Avatar"
            src={avatarUrl}
            style={{ width: 40, height: 40 }}
          />
        ) : (
          <Avatar style={{ width: 40, height: 40 }}>
            {firstLetter}
          </Avatar>
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu
        className="p-0 dropdown-menu"
        style={{ position: 'absolute', zIndex: 99999 }}
      >
        <Dropdown.ItemText className="fw-bold">Hey, {firstName} 👋🏼</Dropdown.ItemText>
        <Dropdown.Item onClick={NavigateToConfig}>
          Configuración
        </Dropdown.Item>
        <Dropdown.Item onClick={handleLogout}>Cerrar sesión</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
