import React, { useState } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { Download, NutFill, PersonPlusFill, Upload } from 'react-bootstrap-icons';
import ClienteModal from './components/crearClienteModal.js';
import ContentCliente from './components/tablaCliente.js';
import CardClientes from './components/cardClientes.js';
import '../styles/tables.css';
import CustomToggle from '../styles/customToggle.js';

function AdminClientes() {
  //funciones Modales
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className='mx-2 mt-3'>
      <div className='d-flex flex-column flex-md-row align-items-center justify-content-between'>
        <p className='mb-2 mb-md-0'>
          Administra tus clientes, getiona su informacion y sus facturas.
        </p>
        <div className='d-flex mt-2 mt-md-0'>
          <Button variant='primary' onClick={handleShow}>
            <PersonPlusFill /> Nuevo Cliente
          </Button>
          <Dropdown>
            <Dropdown.Toggle as={CustomToggle} variant="secondary" className="ms-2">
              <NutFill />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item><Download className='text-success mx-1' /> Importar</Dropdown.Item>
              <Dropdown.Item><Upload className='text-danger mx-1' /> Exportar</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      {/* MODAL DE INGRESO DE CLIENTES */}
      <ClienteModal show={show} handleClose={handleClose} />

      {/* CARDS CLIENTES */}
      <div className='mt-4'>
        <CardClientes />
      </div>

      {/* TABLA DE CLIENTES */}
      <div className="my-2">
        <ContentCliente />
      </div>

    </div>
  );
}

export default AdminClientes;
