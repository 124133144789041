import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import { BarChart, Bar, PieChart, Pie, Cell, Tooltip, ResponsiveContainer, XAxis, YAxis, CartesianGrid, Legend } from 'recharts';
import APIserver from '../../../../api/axiosConfig';

const ReporteCompras = () => {
    const [fechaInicio, setFechaInicio] = useState('');
    const [fechaFin, setFechaFin] = useState('');
    const [totalCompras, setTotalCompras] = useState(0);
    const [totalComprasPagado, setTotalComprasPagado] = useState(0);
    const [totalComprasPendiente, setTotalComprasPendiente] = useState(0);
    const [totalComprasVencida, setTotalCompraVencida] = useState(0);
    const [comprasPorProveedorEstadoData, setComprasPorProveedorEstadoData] = useState([]);
    const [comprasPorProveedorData, setComprasPorProveedorData] = useState([]);

    useEffect(() => {
        const today = new Date();

        // Fecha de inicio del mes (primer día)
        const primerDiaMes = new Date(today.getFullYear(), today.getMonth(), 1);
        const fechaInicioMes = primerDiaMes.toISOString().split('T')[0]; // Formato YYYY-MM-DD

        // Fecha de fin del mes (último día)
        const ultimoDiaMes = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        const fechaFinMes = ultimoDiaMes.toISOString().split('T')[0];

        setFechaInicio(fechaInicioMes);
        setFechaFin(fechaFinMes);
    }, []);

    // Llamada a la API para obtener los datos de las compras
    const fetchComprasData = async () => {
        try {
            const [comprasRes, comprasPagadoRes, comprasPendienteRes, comprasVencidaRes] = await Promise.all([
                APIserver.get('/api/reportesCompras/total-compras', { params: { fechaInicio, fechaFin } }),
                APIserver.get('/api/reportesCompras/total-compras-pagado', { params: { fechaInicio, fechaFin } }),
                APIserver.get('/api/reportesCompras/total-compras-pendiente', { params: { fechaInicio, fechaFin } }),
                APIserver.get('/api/reportesCompras/total-compras-vencida', { params: { fechaInicio, fechaFin } }),

            ]);

            setTotalCompras(comprasRes.data || 0);
            setTotalComprasPagado(comprasPagadoRes.data || 0);
            setTotalComprasPendiente(comprasPendienteRes.data || 0);
            setTotalCompraVencida(comprasVencidaRes.data || 0);

        } catch (error) {
            console.error("Error fetching report data", error);
        }
    };

    const fetchComprasPorProveedorEstado = async () => {
        try {
            const response = await APIserver.get('/api/reportesCompras/compras-por-proveedor-estado', { params: { fechaInicio, fechaFin } });
            setComprasPorProveedorEstadoData(response.data || []);
        } catch (error) {
            console.error("Error fetching compras por proveedor estado data", error);
        }
    };

    useEffect(() => {
        if (fechaInicio && fechaFin) {
            fetchComprasData();
            fetchComprasPorProveedorEstado(); // Llama a la nueva función aquí
        }
    }, [fechaInicio, fechaFin]);

    // Efecto para obtener los datos de compras cuando el componente se monta
    useEffect(() => {
        fetchComprasData();
    }, []);

    // Colores para el gráfico de pastel
    const COLORS = ['#3459e6', '#2fb380', '#f4bd61', '#da292e'];

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            // Calcula la suma de los valores en payload
            const totalValue = payload.reduce((sum, entry) => sum + (entry.value || 0), 0);

            return (
                <div
                    className="custom-tooltip"
                    style={{
                        backgroundColor: '#fff',
                        padding: '10px',
                        border: '1px solid #ccc'
                    }}
                >
                    <p className="label">{`Proveedor: ${label}`}</p>
                    {payload.map((entry, index) => (
                        <p key={`item-${index}`} style={{ color: entry.color }}>
                            {`${entry.name}: ${formatCOP(entry.value ? entry.value : '0.00')}`}
                        </p>
                    ))}
                    {/* Nueva línea para mostrar la suma total */}
                    <p className="total" style={{ fontWeight: 'bold', marginTop: '10px' }}>
                        {`Total: ${formatCOP(totalValue)}`}
                    </p>
                </div>
            );
        }

        return null;
    };

    useEffect(() => {
        // Llamada a la API para obtener los datos de pagos por proveedor con el rango de fechas
        APIserver.get('/api/reportesCompras/pagos-por-proveedor', {
            params: { fechaInicio, fechaFin }
        })
            .then(response => {
                setComprasPorProveedorData(response.data);
            })
            .catch(error => {
                console.error("Error al obtener los pagos por proveedor", error);
            });
    }, [fechaInicio, fechaFin]);

    // Función para exportar el archivo Excel
    const exportarExcel = async () => {
        try {
            // Verificar si las fechas son válidas
            if (!fechaInicio || !fechaFin) {
                alert('Por favor, seleccione un rango de fechas válido.');
                return;
            }

            const response = await APIserver.get('/api/reportesCompras/exportar-excel', {
                params: { fechaInicio, fechaFin },
                responseType: 'arraybuffer',  // Recibimos los datos como un buffer de archivo
            });

            // Crear un Blob con la respuesta para que el navegador lo interprete como un archivo
            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            // Crear un enlace temporal para descargar el archivo
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `Compras_${fechaInicio}_a_${fechaFin}.xlsx`; // El nombre del archivo a descargar
            document.body.appendChild(link);
            link.click();
            link.remove();  // Elimina el enlace temporal después de hacer clic
        } catch (error) {
            console.error('Error al exportar el Excel:', error);
            alert('Hubo un error al generar el archivo Excel. Por favor, intente nuevamente.');
        }
    };

    // Función para formatear valores en COP
    const formatCOP = (value) => value?.toLocaleString('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    }) || '$0';

    return (
        <Container fluid className="py-3">
            {/* Filtros de Reporte */}
            <div className='d-flex flex-column flex-md-row align-items-center align-items-md-end justify-content-center mb-3 text-center'>
                <div className='d-flex flex-column me-md-3 mb-3 mb-md-0'>
                    <Form.Label>Desde</Form.Label>
                    <Form.Control
                        type='date'
                        value={fechaInicio}
                        onChange={(e) => {
                            const selectedDate = e.target.value;
                            if (new Date(selectedDate) <= new Date(fechaFin || new Date())) {
                                setFechaInicio(selectedDate);
                            }
                        }}
                        max={new Date().toISOString().split('T')[0]} // Fecha máxima: Hoy
                        style={{ minWidth: '200px' }}
                    />
                </div>
                <div className='d-flex flex-column me-md-3 mb-3 mb-md-0'>
                    <Form.Label>Hasta</Form.Label>
                    <Form.Control
                        type='date'
                        value={fechaFin}
                        onChange={(e) => {
                            const selectedDate = e.target.value;
                            if (new Date(selectedDate) >= new Date(fechaInicio) && new Date(selectedDate) <= new Date()) {
                                setFechaFin(selectedDate);
                            }
                        }}
                        max={new Date().toISOString().split('T')[0]} // Fecha máxima: Hoy
                        style={{ minWidth: '200px' }}
                    />
                </div>
                <div className='d-flex align-items-center justify-content-end mt-3 mt-md-0'>
                    <Button variant='success' onClick={exportarExcel}>
                        Exportar
                    </Button>
                </div>
            </div>

            {/* Resumen de Compras Totales */}
            <Row className="mb-4 text-center">
                <Col md={3}>
                    <Card bg='primary' className='text-light'>
                        <Card.Body>
                            <Card.Title>Total Compras ({totalCompras.totalFacturasCompras || 0})</Card.Title>
                            <h4>{formatCOP(totalCompras.totalCompras || 0)}</h4>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card bg='success' className='text-light'>
                        <Card.Body>
                            <Card.Title>Total Pagado ({totalComprasPagado.totalFacturasComprasPagado || 0})</Card.Title>
                            <h4>{formatCOP(totalComprasPagado.totalComprasPagado || 0)}</h4>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card bg='warning' className='text-light'>
                        <Card.Body>
                            <Card.Title>Total Pendiente ({totalComprasPendiente.totalFacturasComprasPendiente || 0})</Card.Title>
                            <h4>{formatCOP(totalComprasPendiente.totalComprasPendiente || 0)}</h4>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card bg='danger' className='text-light'>
                        <Card.Body>
                            <Card.Title>Total Vencido ({totalComprasVencida.totalFacturasComprasVencida || 0})</Card.Title>
                            <h4>{formatCOP(totalComprasVencida.totalComprasVencida || 0)}</h4>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {/* Compras por Proveedor */}
            <Row className="mb-4">
                <Col md={6}>
                    <div className='d-flex flex-column flex-md-row align-items-center justify-content-between mb-2'>
                        <h5 className='fw-bold'>Compras por Proveedor</h5>                    </div>
                    <ResponsiveContainer width="100%" height={400}>
                        <BarChart data={comprasPorProveedorEstadoData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip content={<CustomTooltip />} />
                            <Legend />
                            {/* Barras apiladas para cada estado */}
                            <Bar dataKey="pagadas" stackId="a" fill={COLORS[1]} name="Pagadas" />
                            <Bar dataKey="pendientes" stackId="a" fill={COLORS[2]} name="Pendientes" />
                            <Bar dataKey="vencidas" stackId="a" fill={COLORS[3]} name="Vencidas" />
                        </BarChart>
                    </ResponsiveContainer>
                </Col>


                {/* Distribución de Pagos Realizados */}
                <Col md={6}>
                    <h5 className='fw-bold'>Distribución de Pagos Realizados</h5>
                    <ResponsiveContainer width="100%" height={300}>
                        <PieChart>
                            <Pie
                                data={comprasPorProveedorData}
                                dataKey="compras"
                                nameKey="name"
                                cx="50%"
                                cy="50%"
                                outerRadius={100}
                                fill="#82ca9d"

                            >
                                {comprasPorProveedorData.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Pie>
                            <Tooltip />
                        </PieChart>
                    </ResponsiveContainer>
                </Col>
            </Row>
        </Container>
    );
};

export default ReporteCompras;
