import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Table, Form, Button } from 'react-bootstrap';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend, LineChart, Line } from 'recharts';
import APIserver from '../../../../api/axiosConfig';
import Swal from 'sweetalert2';

const ReporteInventario = () => {
    const [fechaInicio, setFechaInicio] = useState('');
    const [fechaFin, setFechaFin] = useState('');
    const [productosMasVendidos, setProductosMasVendidos] = useState([]);
    const [productosPorCategoria, setProductosPorCategoria] = useState([]);
    const [productosBajoStock, setProductosBajoStock] = useState([]);
    const [movimientosPorFecha, setMovimientosPorFecha] = useState([]);
    const [resumen, setResumen] = useState({
        valorTotalInventario: 0,
        valorTotalInventarioM: 0,
        totalProductosInventario: 0,
        productosAgotados: 0,
        productosBajoStock: 0,
    });

    useEffect(() => {
        const today = new Date();

        // Fecha de inicio del mes (primer día)
        const primerDiaMes = new Date(today.getFullYear(), today.getMonth(), 1);
        const fechaInicioMes = primerDiaMes.toISOString().split('T')[0]; // Formato YYYY-MM-DD

        // Fecha de fin del mes (último día)
        const ultimoDiaMes = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        const fechaFinMes = ultimoDiaMes.toISOString().split('T')[0];

        setFechaInicio(fechaInicioMes);
        setFechaFin(fechaFinMes);
    }, []);

    useEffect(() => {
        const fetchResumen = async () => {
            try {
                const response = await APIserver.get('/api/reportesInventario/resumen');
                setResumen(response.data);
            } catch (error) {
                console.error('Error al obtener el resumen del inventario:', error);
            }
        };

        fetchResumen();
    }, []);

    useEffect(() => {
        const fetchProductosMasVendidos = async () => {
            try {
                const response = await APIserver.get('/api/reportesInventario/productosMasVendidos', {
                    params: { fechaInicio, fechaFin }
                });
                setProductosMasVendidos(response.data);
            } catch (error) {
                console.error('Error al obtener los productos más vendidos:', error);
            }
        };
        if (fechaInicio && fechaFin) {
            fetchProductosMasVendidos();
        }
    }, [fechaInicio, fechaFin]);

    useEffect(() => {
        const fetchProductosPorCategoria = async () => {
            try {
                const response = await APIserver.get('/api/reportesInventario/productosPorCategoria');
                setProductosPorCategoria(response.data);
            } catch (error) {
                console.error('Error al obtener productos por categoría:', error);
            }
        };
        fetchProductosPorCategoria();
    }, []);

    useEffect(() => {
        const fetchProductosBajoStock = async () => {
            try {
                const response = await APIserver.get('/api/reportesInventario/productosBajoStocks');
                setProductosBajoStock(response.data);
            } catch (error) {
                console.error('Error al obtener productos con bajo stock:', error);
            }
        };
        fetchProductosBajoStock();
    }, []);

    useEffect(() => {
        if (fechaInicio && fechaFin) {
            const fetchMovimientos = async () => {
                try {
                    // Fetch data from API
                    const response = await APIserver.get('/api/reportesInventario/movimientosPorFecha', {
                        params: { fechaInicio, fechaFin },
                    });

                    // Convertimos cada fecha a "Semana X, mes"
                    const movimientosConSemanaYMes = response.data.map(item => {
                        return {
                            ...item,
                            fecha: obtenerSemanaDelMes(item.fecha), // Transformamos la fecha
                        };
                    });

                    // Ahora ordenamos los datos
                    const movimientosOrdenados = movimientosConSemanaYMes.sort((a, b) => {
                        const mesA = a.fecha.split(',')[1].trim();  // Obtener el mes
                        const mesB = b.fecha.split(',')[1].trim();
                        const semanaA = parseInt(a.fecha.split(',')[0].split(' ')[1]);
                        const semanaB = parseInt(b.fecha.split(',')[0].split(' ')[1]);

                        // Primero comparamos el mes
                        if (mesA === mesB) {
                            // Si son del mismo mes, comparamos las semanas
                            return semanaA - semanaB;
                        }
                        // Si son de meses diferentes, comparamos el mes
                        const meses = { "jan": 1, "feb": 2, "mar": 3, "apr": 4, "may": 5, "jun": 6, "jul": 7, "aug": 8, "sep": 9, "oct": 10, "nov": 11, "dec": 12 };
                        return meses[mesA] - meses[mesB];
                    });

                    // Guardamos los datos ordenados en el estado
                    setMovimientosPorFecha(movimientosOrdenados);

                } catch (error) {
                    console.error('Error al obtener los movimientos de inventario:', error);
                }
            };

            fetchMovimientos();
        }
    }, [fechaInicio, fechaFin]);

    // Función para formatear la fecha a "YYYY-MM-DD"
    const formatFecha = (fecha) => {
        const date = new Date(fecha);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Mes con dos dígitos
        const day = String(date.getDate()).padStart(2, '0'); // Día con dos dígitos
        return `${year}-${month}-${day}`;
    };

    const manejarExportar = async () => {
        try {
            const response = await APIserver.get('/api/reportesInventario/exportarPorFecha', {
                params: { fechaInicio, fechaFin },
                responseType: 'blob', // Para manejar el archivo como binario
            });

            // Verificar si la respuesta está vacía
            if (response.status === 204 || response.data.size === 0) { // status 204 o archivo vacío
                Swal.fire({
                    title: 'Sin Productos',
                    text: 'No hay productos disponibles en el rango de fechas seleccionado.',
                    icon: 'info',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                    iconColor: '#2fb380',
                    confirmButtonColor: "#2c4cc4"
                });
                return; // Detener el flujo
            }

            // Crear enlace para descarga
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Productos_Filtrados.xlsx');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } catch (error) {
            if (error.response && error.response.status === 404) {
                Swal.fire({
                    title: 'Sin Productos',
                    text: 'No hay productos disponibles en el rango de fechas seleccionado.',
                    icon: 'info',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                    iconColor: '#2fb380',
                    confirmButtonColor: "#2c4cc4"
                });
            } else {
                console.error('Error al exportar productos:', error);
                Swal.fire({
                    title: 'Error',
                    text: 'Ocurrió un error inesperado al exportar los productos.',
                    icon: 'error',
                    confirmButtonColor: "#2c4cc4"
                });
            }
        }
    };

    const manejarExportarTodo = async () => {
        try {
            const response = await APIserver.get('/api/reportesInventario/exportarTodos', {
                responseType: 'blob', // Para manejar el archivo como binario
            });

            // Crear enlace para descarga
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Todos_los_Productos.xlsx');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } catch (error) {
            console.error('Error al exportar todos los productos:', error);
        }
    };

    // Colores para el gráfico de pastel
    const COLORS = ['#3459e6', '#2fb380', '#f4bd61', '#da292e'];

    // Función para formatear valores en COP
    const formatCOP = (value) => value?.toLocaleString('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    }) || '$0';

    const obtenerSemanaDelMes = (fecha) => {
        const date = new Date(fecha);
      
        // Obtener el mes de la fecha (en formato corto, como 'oct' o 'nov')
        const mes = date.toLocaleString('es-ES', { month: 'short' });
      
        // Obtener el primer día del mes
        const primerDiaDelMes = new Date(date.getFullYear(), date.getMonth(), 1);
      
        // Calcular la diferencia de días entre la fecha y el primer día del mes
        const diferenciaDias = Math.floor((date - primerDiaDelMes) / (1000 * 60 * 60 * 24));
      
        // Calcular el número de semana dentro del mes
        const semanaDelMes = Math.floor(diferenciaDias / 7) + 1;
      
        // Devolver el formato "Semana X, mes"
        return `Semana ${semanaDelMes}, ${mes}`;
    };
 
    return (
        <Container fluid className="py-3">
            {/* Filtros de Reporte */}
            <div className='d-flex flex-column flex-md-row align-items-center align-items-md-end justify-content-center mb-3 text-center'>
                <div className='d-flex flex-column me-md-3 mb-3 mb-md-0'>
                    <Form.Label>Desde</Form.Label>
                    <Form.Control
                        type='date'
                        value={fechaInicio}
                        onChange={(e) => {
                            const selectedDate = e.target.value;
                            if (new Date(selectedDate) <= new Date(fechaFin || new Date())) {
                                setFechaInicio(selectedDate);
                            }
                        }}
                        max={new Date().toISOString().split('T')[0]} // Fecha máxima: Hoy
                        style={{ minWidth: '200px' }}
                    />
                </div>
                <div className='d-flex flex-column me-md-3 mb-3 mb-md-0'>
                    <Form.Label>Hasta</Form.Label>
                    <Form.Control
                        type='date'
                        value={fechaFin}
                        onChange={(e) => {
                            const selectedDate = e.target.value;
                            if (new Date(selectedDate) >= new Date(fechaInicio) && new Date(selectedDate) <= new Date()) {
                                setFechaFin(selectedDate);
                            }
                        }}
                        max={new Date().toISOString().split('T')[0]} // Fecha máxima: Hoy
                        style={{ minWidth: '200px' }}
                    />
                </div>
                <div className='d-flex align-items-center justify-content-end mt-3 mt-md-0'>
                    <Button variant='primary' className='me-2' onClick={manejarExportar}>
                        Exportar
                    </Button>
                    <Button variant='success' onClick={manejarExportarTodo}>
                        Exportar Todo
                    </Button>
                </div>
            </div>
            {/* Valor Total del Inventario */}
            <Row className="mb-4 text-center">
                <Col md={3}>
                    <Card bg="primary" text="white" className="text-center h-100">
                        <Card.Body>
                            <Card.Title>Valor del Inventario por Unidad</Card.Title>
                            <h4>{formatCOP(resumen.valorTotalInventario)}</h4>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card bg="success" text="white" className="text-center h-100">
                        <Card.Body>
                            <Card.Title>Valor del Inventario al por Mayor</Card.Title>
                            <h4>{formatCOP(resumen.valorTotalInventarioM)}</h4>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card bg="warning" text="white" className="text-center h-100">
                        <Card.Body>
                            <Card.Title>Cantidad de productos</Card.Title>
                            <h4>{resumen.totalProductosInventario.toLocaleString()}</h4>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card bg="danger" text="white" className="text-center h-100">
                        <Card.Body>
                            <Card.Title>Productos Agotados: {resumen.productosAgotados}</Card.Title>
                            <Card.Title>Productos con bajo stock: {resumen.productosBajoStock}</Card.Title>
                        </Card.Body>
                    </Card>
                </Col>

            </Row>

            {/* Productos Más Vendidos */}
            <Row className="mb-4">
                <Col md={6}>
                    <h5 className='fw-bold'>Productos Más Vendidos</h5>
                    <Table striped bordered hover responsive>
                        <thead>
                            <tr>
                                <th>Imagen</th>
                                <th>Producto</th>
                                <th>Cantidad Vendida</th>
                            </tr>
                        </thead>
                        <tbody>
                            {productosMasVendidos.map((producto, index) => (
                                <tr key={index}>
                                    <td><img src={producto.img} alt={producto.name} width="50" height="50" /></td>
                                    <td>{producto.name}</td>
                                    <td>{producto.cantidad}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>

                {/* Gráfico de pastel de Productos Más Vendidos */}
                <Col md={6}>
                    <ResponsiveContainer width="100%" height={300}>
                        <PieChart>
                            <Pie
                                data={productosMasVendidos}
                                dataKey="cantidad"
                                nameKey="name"
                                cx="50%"
                                cy="50%"
                                outerRadius={100}
                            >
                                {productosMasVendidos.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Pie>
                            <Tooltip />
                        </PieChart>
                    </ResponsiveContainer>
                </Col>
            </Row>

            {/* Productos por Categoría */}
            <Row className="mb-4">
                <Col md={6}>
                    <h5 className='fw-bold'>Productos por categoria</h5>
                    <ResponsiveContainer width="100%" height={400}>
                        <BarChart data={productosPorCategoria}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="categoria" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            {/* Barras apiladas para cada estado */}
                            <Bar dataKey="cantidad" stackId="a" fill='#3459e6' name="Cantidad" />
                        </BarChart>
                    </ResponsiveContainer>
                </Col>
                <Col md={6}>
                    <h5 className='fw-bold'>Productos con Bajo Stock</h5>
                    <Table striped bordered hover responsive>
                        <thead>
                            <tr>
                                <th>Imagen</th>
                                <th>Producto</th>
                                <th>Stock Actual</th>
                            </tr>
                        </thead>
                        <tbody>
                            {productosBajoStock.map((producto, index) => {
                                return (
                                    <tr key={index}>
                                        <td><img src={producto.img} alt={producto.name} width="50" height="50" /></td>
                                        <td>{producto.name}</td>
                                        <td>{producto.stock}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </Col>
            </Row>

            {/* Movimientos de Inventario por Fecha */}
            <Row className="mb-4">
                <Col>
                    <h5 className='fw-bold'>Movimientos de Inventario por Fecha</h5>
                    <ResponsiveContainer width="100%" height={300}>
                        <LineChart data={movimientosPorFecha}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="fecha" /> {/* Usamos "fecha" que tiene el formato "Semana X, mes" */}
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" dataKey="productosVendidos" stroke="#2fb380" name="Entradas" />
                            <Line type="monotone" dataKey="productosAgregados" stroke="#da292e" name="Salidas" />
                        </LineChart>
                    </ResponsiveContainer>
                </Col>
            </Row>

        </Container>
    );
};

export default ReporteInventario;
