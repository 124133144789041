// loader.js
import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import logo from '../../../../utils/logo512.png'; // Asegúrate de importar tu logo

// Estilo para la superposición del spinner
const spinnerOverlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999,
};

// Estilo y animación para el logo
const logoStyle = {
    width: '100px', // Ajusta el tamaño según tus necesidades
    marginBottom: '20px',
    animation: 'jump 1s ease-in-out infinite', // Añadimos la animación 'jump'
};

// Definición de la animación
const styles = `
@keyframes jump {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-20px); // Ajusta la altura del salto
    }
}
`;

const Loader = () => {
    return (
        <div style={spinnerOverlayStyle}>
            {/* Estilos de animación inyectados en el componente */}
            <style>{styles}</style>
            <img src={logo} alt="Logo" style={logoStyle} />
            <div className="d-flex justify-content-center">
            <Spinner
                    animation="grow"
                    className="spinner-delayed-1 me-2"
                    style={{ width: '1.5rem', height: '1.5rem' }} // Tamaño reducido
                />
                <Spinner
                    animation="grow"
                    className="spinner-delayed-2 me-2"
                    style={{ width: '1.5rem', height: '1.5rem' }} // Tamaño reducido
                />
                <Spinner
                    animation="grow"
                    className="spinner-delayed-3"
                    style={{ width: '1.5rem', height: '1.5rem' }} // Tamaño reducido
                />
            </div>
        </div>
    );
};

export default Loader;
