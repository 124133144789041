import { useState, useEffect } from 'react';
import APIserver from '../api/axiosConfig';

const useStats = (filter) => {
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await APIserver.get(`/api/stats`, {
          params: { filter },
        });
        setStats(response.data);
      } catch (error) {
        console.error('Error fetching stats:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, [filter]);

  return { stats, loading };
};

export default useStats;
