import React, { useState, useEffect } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import APIserver from '../../../../api/axiosConfig';

function CardClientes() {
    const [clientesRegistrados, setClientesRegistrados] = useState(0);
    const [clientesNuevos, setClientesNuevos] = useState(0);

    useEffect(() => {
        fetchClientesRegistrados();
        fetchClientesNuevos();
    }, []);

    const fetchClientesRegistrados = () => {
        APIserver.get(`/api/clientes/cards/registrados`)
            .then(response => {
                setClientesRegistrados(response.data.totalClientesRegistrados);
            })
            .catch(error => {
                console.error('Error al obtener clientes registrados:', error);
            });
    };

    const fetchClientesNuevos = () => {
        APIserver.get(`/api/clientes/cards/nuevos`)
            .then(response => {
                setClientesNuevos(response.data.nuevosClientesEsteMes);
            })
            .catch(error => {
                console.error('Error al obtener nuevos clientes este mes:', error);
            });
    };

    return (
        <div className='d-flex justify-content-center'>
            <Row className='text-center'>
                <Col xs={12} md={6} className='mb-4 d-flex justify-content-center'>
                    <Card bg='primary' text='white' style={{ width: '18rem' }}>
                        <Card.Body>
                            <Card.Text>Clientes Registrados: <b>{clientesRegistrados}</b></Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={6} className='mb-4 d-flex justify-content-center'>
                    <Card bg='success' text='white' style={{ width: '18rem' }}>
                        <Card.Body>
                            <Card.Text>Clientes Nuevos Este mes: <b>{clientesNuevos}</b></Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default CardClientes;
