// Importa los iconos necesarios desde react-bootstrap-icons
import { Tiktok, Instagram, Facebook, Whatsapp, Youtube, EnvelopeAtFill, Paperclip } from 'react-bootstrap-icons';

// Define y exporta la función getIcon
const getIcon = (url) => {
    if (url.includes('tiktok')) return <Tiktok size={18} />;
    if (url.includes('instagram')) return <Instagram size={18} />;
    if (url.includes('facebook')) return <Facebook size={18} />;
    if (url.includes('wa.me')) return <Whatsapp size={18} />;
    if (url.includes('youtube')) return <Youtube size={18} />;
    if (url.includes('mail.google')) return <EnvelopeAtFill size={18} />;
    return <Paperclip size={18} />;
};

export default getIcon;
