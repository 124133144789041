import React, { createContext, useContext, useState, useEffect } from 'react';
import { API } from '../../../../api/URL_API';
const CarritoContext = createContext();

export const useCarrito = () => useContext(CarritoContext);

export const CarritoProvider = ({ children }) => {
    const [carrito, setCarrito] = useState(() => {
        const savedCarrito = localStorage.getItem('carrito');
        return savedCarrito ? JSON.parse(savedCarrito) : [];
    });

    const [showAllProducts, setShowAllProducts] = useState(false);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [configEnvio, setConfigEnvio] = useState(null); // Configuración de envío
    const [reglasEnvio, setReglasEnvio] = useState([]); // Reglas de envío
    const [configDescuento, setConfigDescuento] = useState(null); // Configuración de descuentos
    const [reglasDescuento, setReglasDescuento] = useState([]); // Reglas de descuentos
    const [lastDiscount, setLastDiscount] = useState(0); // Estado para el último descuento aplicado

    // Formateador para mostrar precios en pesos colombianos
    const formatCurrency = (value) => {
        return new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP' }).format(value);
    };

    useEffect(() => {
        localStorage.setItem('carrito', JSON.stringify(carrito));
    }, [carrito]);

    useEffect(() => {
        const fetchConfigAndRules = async () => {
            try {
                const configEnvioResponse = await fetch(`${API}/api/envios/configEnvio/2`);
                const configEnvioData = await configEnvioResponse.json();
                setConfigEnvio(configEnvioData);
    
                const reglasEnvioResponse = await fetch(`${API}/api/envios`);
                const reglasEnvioData = await reglasEnvioResponse.json();
                setReglasEnvio(reglasEnvioData);
    
                const configDescuentoResponse = await fetch(`${API}/api/descuentos/configDescuento/1`);
                const configDescuentoData = await configDescuentoResponse.json();
                setConfigDescuento(configDescuentoData);
    
                const reglasDescuentoResponse = await fetch(`${API}/api/descuentos`);
                const reglasDescuentoData = await reglasDescuentoResponse.json();
                setReglasDescuento(reglasDescuentoData);
            } catch (error) {
                console.error('Error al obtener configuración o reglas:', error);
            }
        };
    
        fetchConfigAndRules();
    }, []);

    const calculateShippingCost = () => {
        const totalCantidad = carrito.reduce((total, product) => total + (product.quantity || 1), 0);
        const totalPrecio = carrito.reduce((total, product) => {
            const price = parseFloat(product.precioU?.toString().replace('$', '').replace(',', '') || '0');
            return total + (price * (product.quantity || 1));
        }, 0);

        if (configEnvio?.tipoConfig === 'desable') {
            return 0;
        }

        let envioCosto = 0;

        if (configEnvio?.tipoConfig === 'cantidad') {
            const regla = reglasEnvio
                .filter(rule => rule.tipoRegla === 'cantidad')
                .sort((a, b) => b.max_cantidad - a.max_cantidad)
                .find(rule => totalCantidad >= (rule.min_cantidad || 0));
            envioCosto = regla?.costoEnvio || 0;
        } else if (configEnvio?.tipoConfig === 'total') {
            const regla = reglasEnvio
                .filter(rule => rule.tipoRegla === 'total')
                .sort((a, b) => b.max_total - a.max_total)
                .find(rule => totalPrecio >= (rule.min_total || 0));
            envioCosto = regla?.costoEnvio || 0;
        }

        return envioCosto;
    };

    const calculateDiscount = () => {
        const totalCantidad = carrito.reduce((total, product) => total + (product.quantity || 1), 0);
        const totalPrecio = carrito.reduce((total, product) => {
            const price = parseFloat(product.precioU?.toString().replace('$', '').replace(',', '') || '0');
            return total + (price * (product.quantity || 1));
        }, 0);

        let descuento = 0;

        if (configDescuento?.tipoConfig === 'desable') {
            descuento = 0;
        } else if (configDescuento?.tipoConfig === 'cantidad') {
            const regla = reglasDescuento
                .filter(rule => rule.tipoRegla === 'cantidad')
                .sort((a, b) => b.max_cantidad - a.max_cantidad)
                .find(rule => totalCantidad >= (rule.min_cantidad || 0) && totalCantidad <= (rule.max_cantidad || Infinity));

            if (regla) {
                descuento = regla.porcentajeDescuento;
            }
        } else if (configDescuento?.tipoConfig === 'total') {
            const regla = reglasDescuento
                .filter(rule => rule.tipoRegla === 'total')
                .sort((a, b) => b.max_total - a.max_total)
                .find(rule => totalPrecio >= (rule.min_total || 0) && totalPrecio <= (rule.max_total || Infinity));

            if (regla) {
                descuento = regla.porcentajeDescuento;
            }
        }

        setLastDiscount(descuento);

        return descuento;
    };

    const calculateTotalWithDiscount = () => {
        const totalPrecio = carrito.reduce((total, product) => {
            const price = configDescuento?.tipoConfig === 'desable' 
                ? parseFloat(product.precioU.toString().replace('$', '').replace(',', '')) 
                : parseFloat((lastDiscount > 0 ? product.precioU : product.precioM)?.toString().replace('$', '').replace(',', '') || '0');
            return total + (price * (product.quantity || 1));
        }, 0);
    
        const totalConDescuento = totalPrecio - (totalPrecio * (lastDiscount / 100));
    
        return parseFloat(totalConDescuento.toFixed(0));
    };
    

    const addToCarrito = (product) => {
        setCarrito((prevCarrito) => {
            const existingProductIndex = prevCarrito.findIndex(p => 
                p.idProducto === product.idProducto &&
                p.selectedColor === product.selectedColor &&
                p.selectedSize === product.selectedSize
            );
            console.log("stock",product.stock);

            const currentQuantity = existingProductIndex > -1 ? prevCarrito[existingProductIndex].quantity : 0;
            const newQuantity = currentQuantity + product.quantity;
    
            if (newQuantity > product.stock) {
                alert(`Solo puedes añadir ${product.stock} unidades de este producto`);
                console.log("stock",product.stock);

                return prevCarrito;
                
            }
            if (existingProductIndex > -1) {
                const updatedCarrito = [...prevCarrito];
                updatedCarrito[existingProductIndex].quantity += product.quantity;
                return updatedCarrito;
            } else {
                return [...prevCarrito, product];
            }
        });
    };
    

    const removeFromCarrito = (idProducto, selectedColor, selectedSize) => {
        setCarrito((prevCarrito) => 
            prevCarrito.filter(product => 
                product.idProducto !== idProducto ||
                product.selectedColor !== selectedColor ||
                product.selectedSize !== selectedSize
            )
        );
    };

    const increaseQuantity = (idProducto, selectedColor, selectedSize) => {
        setCarrito((prevCarrito) => 
            prevCarrito.map(product => {
                if (product.idProducto === idProducto &&
                    product.selectedColor === selectedColor &&
                    product.selectedSize === selectedSize) {
    
                    // Verificar si al aumentar supera el stock disponible
                    if (product.quantity + 1 > product.stock) {
                        alert(`No hay suficiente stock. Solo puedes agregar ${product.stock} unidades de este producto.`);
                        return product; // Retorna sin cambiar la cantidad
                    }
    
                    return { ...product, quantity: product.quantity + 1 };
                }
                return product;
            })
        );
    };
    

    const decreaseQuantity = (idProducto, selectedColor, selectedSize) => {
        setCarrito((prevCarrito) => 
            prevCarrito.map(product => 
                product.idProducto === idProducto &&
                product.selectedColor === selectedColor &&
                product.selectedSize === selectedSize &&
                product.quantity > 1
                    ? { ...product, quantity: product.quantity - 1 }
                    : product
            )
        );
    };

    const clearCarrito = () => {
        setCarrito([]);
        localStorage.removeItem('carrito');
    };

    const updateSelectedCategories = (categories) => {
        setSelectedCategories(categories);
    };

    return (
        <CarritoContext.Provider value={{
            carrito,
            addToCarrito,
            removeFromCarrito,
            increaseQuantity,
            decreaseQuantity,
            clearCarrito,
            showAllProducts,
            setShowAllProducts,
            selectedCategories,
            updateSelectedCategories,
            calculateShippingCost,
            calculateDiscount,
            calculateTotalWithDiscount,
            formatCurrency,
            configEnvio,
            configDescuento
        }}>
            {children}
        </CarritoContext.Provider>
    );
};
