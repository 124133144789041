import APIserver from "../../../../api/axiosConfig";

// Función para obtener las secciones
export const fetchSecciones = async () => {
  try {
    const response = await APIserver.get('/api/seccion');
    return response.data;
  } catch (error) {
    console.error('Error al cargar las secciones:', error);
    return []; // Retorna un array vacío en caso de error
  }
};

// Función para obtener las categorías
export const fetchCategorias = async () => {
  try {
    const response = await APIserver.get('/api/categoria');
    return response.data;
  } catch (error) {
    console.error('Error al cargar las categorías:', error);
    return []; // Retorna un array vacío en caso de error
  }
};

// Función para eliminar una categoría
export const deleteCategoria = async (idCategoria) => {
  try {
    await APIserver.delete(`/api/categoria/${idCategoria}`);
    return true; // Retorna true si la eliminación fue exitosa
  } catch (error) {
    console.error('Error al eliminar la categoría:', error);
    return false; // Retorna false si hubo un error
  }
};

// Función para eliminar una sección
export const deleteSeccion = async (idSeccion) => {
  try {
    await APIserver.delete(`/api/seccion/${idSeccion}`);
    return true; // Retorna true si la eliminación fue exitosa
  } catch (error) {
    console.error('Error al eliminar la sección:', error);
    return false; // Retorna false si hubo un error
  }
};