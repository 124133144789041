import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, InputGroup, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import AlertsPagosRecibidos from './Utils/AlertsPagosRecibidos';
import APIserver from '../../../../api/axiosConfig';

function EditarPagoRes({ show, handleClose, pago, fetchPagos }) {

    const optionsPago = [
        { value: 'transferencia', label: 'Transferencias' },
        { value: 'efectivo', label: 'Efectivo' }
    ];

    const [bancos, setBancos] = useState([]);
    const [selectedBanco, setSelectedBanco] = useState(null);
    const [tipoPago, setTipoPago] = useState('');
    const [fechaPago, setFechaPago] = useState('');
    const [montoPago, setMontoPago] = useState('');
    const [detallePago, setDetallePago] = useState('');
    const [errors, setErrors] = useState({}); // Estado para manejar los errores

    // Función para convertir una fecha en formato Date al formato YYYY-MM-DD
    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        return `${year}-${month}-${day}`;
    };

    const fetchBancos = async () => {
        try {
            const response = await APIserver.get(`/api/pagosrecibidos/mostrar/bancos`);
            if (response.data && Array.isArray(response.data.bancos)) {
                setBancos(response.data.bancos);
            } else {
                setBancos([]);
            }
        } catch (error) {
            console.error('Error al obtener los bancos:', error);
            setBancos([]);
        }
    };

    useEffect(() => {
        fetchBancos();
    }, []);

    useEffect(() => {
        if (pago) {
            setSelectedBanco({ value: pago.idBanco, label: pago.nomBanco });
            setTipoPago(pago.tipoPago);
            setFechaPago(formatDate(pago.fecha));
            setMontoPago(pago.monto);
            setDetallePago(pago.detalle);
        }
    }, [pago]);

    const handleBancoChange = (selectedOption) => {
        setSelectedBanco(selectedOption);
    };

    // Función para validar el formulario
    const validateForm = () => {
        const newErrors = {};
        if (!tipoPago) newErrors.tipoPago = 'Seleccione un tipo de pago';
        if (!selectedBanco) newErrors.banco = 'Seleccione un banco';
        if (!fechaPago) newErrors.fecha = 'Seleccione una fecha';
        if (!montoPago || parseFloat(montoPago) <= 0) newErrors.monto = 'Ingrese un monto válido';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSave = async () => {
        if (!validateForm()) {
            return; // Si hay errores, no continuar
        }

        try {
            await APIserver.put(`/api/pagosrecibidos/pagos/${pago.idPago}`, {
                idBanco: selectedBanco.value,
                tipoPago: tipoPago,
                fecha: fechaPago,
                monto: montoPago,
                detalle: detallePago
            });

            AlertsPagosRecibidos.showUpdateConfirmation();
            fetchPagos(); // Refrescar la lista de pagos después de la actualización
            handleClose(); // Cerrar el modal después de guardar
        } catch (error) {
            if (error.response && error.response.status === 400) {
                AlertsPagosRecibidos.showErrorMessage(error.response.data.error);
            } else {
                AlertsPagosRecibidos.showUpdateErrorMessage();
            }
        }
    };

    return (
        <Modal size='lg' show={show} onHide={handleClose} aria-labelledby="modal-editar-sucursal">
            <Modal.Header closeButton>
                <Modal.Title>Editar Pago</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Col>
                            <Form.Group className='m-3 mt-0' controlId='editNumCompra'>
                                <Form.Label>Factura</Form.Label>
                                <Form.Control 
                                    type='text' 
                                    placeholder='Numero de factura de compra' 
                                    value={pago ? pago.numFacV : ''} 
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group className='m-3' controlId='editnomProveedor'>
                                <Form.Label>Cliente</Form.Label>
                                <Form.Control 
                                    type='text' 
                                    placeholder='Nombre del proveedor' 
                                    value={pago ? pago.nomCliente : ''} 
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group className='m-3' controlId='tipoPago'>
                                <Form.Label className='w-100'>Tipo</Form.Label>
                                <Select
                                    placeholder='Tipo de Pago'
                                    name='tipoPago'
                                    options={optionsPago}
                                    value={optionsPago.find(option => option.value === tipoPago)}
                                    onChange={(option) => setTipoPago(option.value)}
                                    isInvalid={!!errors.tipoPago}
                                    required
                                />
                                {errors.tipoPago && <div className="invalid-feedback d-block">{errors.tipoPago}</div>}
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className='m-3 mt-0' controlId='editfechaPago'>
                                <Form.Label>Fecha</Form.Label>
                                <Form.Control 
                                    type='date'
                                    value={fechaPago}
                                    onChange={(e) => setFechaPago(e.target.value)}
                                    isInvalid={!!errors.fecha}
                                    required 
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.fecha}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className='m-3' controlId='editbancoPago'>
                                <Form.Label>Banco</Form.Label>
                                <Select
                                    placeholder='Seleccione el Banco'
                                    options={bancos.map(banco => ({ value: banco.idBanco, label: banco.nomBanco }))}
                                    value={selectedBanco}
                                    onChange={handleBancoChange}
                                    isInvalid={!!errors.banco}
                                    required
                                />
                                {errors.banco && <div className="invalid-feedback d-block">{errors.banco}</div>}
                            </Form.Group>
                            <Form.Group className="m-3" controlId="editmontoPago">
                                <Form.Label>Monto</Form.Label>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>$</InputGroup.Text>
                                    <Form.Control 
                                        type='number' 
                                        placeholder='Pago total realizado' 
                                        value={montoPago}
                                        onChange={(e) => setMontoPago(e.target.value)} 
                                        isInvalid={!!errors.monto}
                                        required 
                                    />
                                    <InputGroup.Text>.00</InputGroup.Text>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.monto}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Form.Group className='m-3 mt-0' controlId='detallePago'>
                        <Form.Label>Observaciones</Form.Label>
                        <Form.Control 
                            as="textarea" 
                            placeholder='Observaciones sobre el pago (Opcional)' 
                            value={detallePago} 
                            onChange={(e) => setDetallePago(e.target.value)}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='secondary' onClick={handleClose}>Cancelar</Button>
                <Button variant='primary' onClick={handleSave}>Guardar</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default EditarPagoRes;
