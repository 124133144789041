import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import { BarChart, Bar, PieChart, Pie, Cell, Tooltip, ResponsiveContainer, XAxis, YAxis, CartesianGrid, Legend, LineChart, Line } from 'recharts';
import APIserver from '../../../../api/axiosConfig';

const ReporteVentas = () => {
    const [fechaInicio, setFechaInicio] = useState('');
    const [fechaFin, setFechaFin] = useState('');
    const [totalVentas, setTotalVentas] = useState(0);
    const [totalPagado, setTotalPagado] = useState(0);
    const [totalPendiente, setTotalPendiente] = useState(0);
    const [totalAnulado, setTotalAnulado] = useState(0);
    const [totalVencido, setTotalVencido] = useState(0);
    const [totalPedidos, setTotalPedidos] = useState(0);
    const [totalPedidosPendientes, setTotalPedidosPendientes] = useState(0);
    const [totalProductosVendidos, setTotalProductosVendidos] = useState(0);
    const [totalVentasPorCliente, setTotalVentasPorCliente] = useState([]);
    const [totalVentasPorSucursal, setVentasPorSucursal] = useState([]);
    const [totalFacturasPorDistribucion, setTotalFacturasPorDistribucion] = useState(0);
    const [totalFacturasPorUnidad, setTotalFacturasPorUnidad] = useState(0);


    const [filter, setFilter] = useState("mes");
    const [ventasPorFechaData, setVentasPorFechaData] = useState([]);
    useEffect(() => {
        const today = new Date();

        // Fecha de inicio del mes (primer día)
        const primerDiaMes = new Date(today.getFullYear(), today.getMonth(), 1);
        const fechaInicioMes = primerDiaMes.toISOString().split('T')[0]; // Formato YYYY-MM-DD

        // Fecha de fin del mes (último día)
        const ultimoDiaMes = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        const fechaFinMes = ultimoDiaMes.toISOString().split('T')[0];

        setFechaInicio(fechaInicioMes);
        setFechaFin(fechaFinMes);
    }, []);



    // Colores para el gráfico de pastel
    const COLORS = ['#3459e6', '#2fb380', '#f4bd61', '#da292e'];

    // Función para obtener los reportes de la API
    const fetchReportes = async () => {
        try {
            const [ventasRes, pagadoRes, pendienteRes, anuladoRes, vencidoRes, pedidosRes, pedidosPendientesRes, productosVendidosRes, ventasPorClientesRes, ventasPorSucursalRes, totalFacturasPorDistribucionRes, totalFacturasPorUnidadRes] = await Promise.all([
                APIserver.get('/api/reportes/total-ventas', { params: { fechaInicio, fechaFin } }),
                APIserver.get('/api/reportes/total-pagado', { params: { fechaInicio, fechaFin } }),
                APIserver.get('/api/reportes/total-pendiente', { params: { fechaInicio, fechaFin } }),
                APIserver.get('/api/reportes/total-anulado', { params: { fechaInicio, fechaFin } }),
                APIserver.get('/api/reportes/total-vencido', { params: { fechaInicio, fechaFin } }),
                APIserver.get('/api/reportes/total-pedidos', { params: { fechaInicio, fechaFin } }),
                APIserver.get('/api/reportes/total-pedidos-pendientes', { params: { fechaInicio, fechaFin } }),
                APIserver.get('/api/reportes/total-productos-vendidos', { params: { fechaInicio, fechaFin } }),
                APIserver.get('/api/reportes/total-ventas-por-cliente', { params: { fechaInicio, fechaFin } }),
                APIserver.get('/api/reportes/total-ventas-por-sucursal', { params: { fechaInicio, fechaFin } }),
                APIserver.get('/api/reportes/total-facturas-por-distribucion', { params: { fechaInicio, fechaFin } }),
                APIserver.get('/api/reportes/total-facturas-por-unidad', { params: { fechaInicio, fechaFin } }),
            ]);

            setTotalVentas(ventasRes.data || { totalFacturas: 0, totalVentas: 0 });
            setTotalPagado(pagadoRes.data || { totalPagado: 0, totalMontoPagado: 0 });
            setTotalPendiente(pendienteRes.data || { totalPendiente: 0, totalMontoPendiente: 0 });
            setTotalAnulado(anuladoRes.data || { totalAnulado: 0, totalMontoAnulado: 0 });
            setTotalVencido(vencidoRes.data || { totalVencido: 0, totalMontoVencido: 0 });
            setTotalPedidos(pedidosRes.data || { totalPedidos: 0 });
            setTotalPedidosPendientes(pedidosPendientesRes.data || { totalPedidosPendientes: 0 });
            setTotalProductosVendidos(productosVendidosRes.data || { totalProductosVendidos: 0 });
            setTotalVentasPorCliente(ventasPorClientesRes.data || []);
            setVentasPorSucursal(ventasPorSucursalRes.data || []);
            setTotalFacturasPorDistribucion(totalFacturasPorDistribucionRes.data || { totalFacturasPorDistribucion: 0 });
            setTotalFacturasPorUnidad(totalFacturasPorUnidadRes.data || { totalFacturasPorUnidad: 0 });

        } catch (error) {
            console.error("Error fetching report data", error);
        }
    };

    useEffect(() => {
        if (fechaInicio && fechaFin) { // Asegúrate de que ambas fechas estén seleccionadas
            fetchReportes();
        }
    }, [fechaInicio, fechaFin]);

    const handleFiltrar = () => {
        fetchReportes(); // Llama de nuevo a la API con las fechas de filtro actualizadas
    };

    // Función para formatear valores en COP
    const formatCOP = (value) => value?.toLocaleString('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    }) || '$0';

    useEffect(() => {
        APIserver.get('/api/reportes/total-obtener-ventas-por-filtro', {
            params: {
                filtro: filter
            }
        })
            .then(response => {
                // Procesar los datos de acuerdo al filtro
                const data = response.data.map(item => {
                    let dateLabel = '';
                    switch (filter) {
                        case 'dia':
                            // Asegurarse de mostrar solo la parte de la fecha (sin la hora)
                            dateLabel = item.date ? new Date(item.date).toISOString().split('T')[0] : '';
                            break;
                            case 'semana': {
                                const { year, week } = item;
                            
                                if (year && week) {
                                    // Calcular el primer lunes del año
                                    const firstDayOfYear = new Date(`${year}-01-01`);
                                    const firstDayOfWeek = firstDayOfYear.getDay(); // Día de la semana: 0 (domingo) a 6 (sábado)
                            
                                    // Ajustar al primer lunes del año
                                    const offsetDays = firstDayOfWeek === 0 ? -6 : 1 - firstDayOfWeek;
                                    const firstMonday = new Date(firstDayOfYear);
                                    firstMonday.setDate(firstMonday.getDate() + offsetDays);
                            
                                    // Calcular el inicio de la semana específica
                                    const startOfWeek = new Date(firstMonday);
                                    startOfWeek.setDate(firstMonday.getDate() + (week - 1) * 7);
                            
                                    // Limitar al mes actual
                                    const month = startOfWeek.getMonth(); // 0 = enero, 1 = febrero, etc.
                                    const firstDayOfMonth = new Date(startOfWeek.getFullYear(), month, 1);
                                    const lastDayOfMonth = new Date(startOfWeek.getFullYear(), month + 1, 0);
                            
                                    // Ajustar al rango del mes si está fuera
                                    let adjustedDate = startOfWeek;
                                    if (startOfWeek < firstDayOfMonth) {
                                        adjustedDate = firstDayOfMonth; // Si es antes del mes, usar el primer día del mes
                                    } else if (startOfWeek > lastDayOfMonth) {
                                        adjustedDate = lastDayOfMonth; // Si es después del mes, usar el último día del mes
                                    }
                            
                                    // Calcular el número de semana dentro del mes
                                    const weekOfMonth = Math.ceil(
                                        (adjustedDate.getDate() - firstDayOfMonth.getDate() + firstDayOfMonth.getDay()) / 7
                                    );
                            
                                    // Formatear el mes abreviado
                                    const monthShort = adjustedDate.toLocaleString('es-CO', { month: 'short' });
                            
                                    // Generar etiqueta
                                    dateLabel = `Semana ${weekOfMonth}, ${monthShort}`;
                                } else {
                                    console.warn(`Datos insuficientes para calcular la semana:`, item);
                                    dateLabel = `Semana ${item.week || 'N/A'}, Fecha inválida`;
                                }
                                break;
                            }
                            
                        case 'mes':
                            dateLabel = `${item.month}/${item.year}`;
                            break;
                        case 'ano':
                            dateLabel = `${item.year}`;
                            break;
                        default:
                            dateLabel = item.date;
                    }
    
                    return { date: dateLabel, ventas: item.ventas };
                });
    
                setVentasPorFechaData(data);
            })
            .catch(error => {
                console.error("Error fetching data", error);
            });
    }, [filter]); // Se vuelve a ejecutar si el filtro cambia
    
    const exportarExcel = async () => {
        try {
            // Verificar si las fechas son válidas
            if (!fechaInicio || !fechaFin) {
                alert('Por favor, seleccione un rango de fechas válido.');
                return;
            }

            const response = await APIserver.get('/api/reportes/exportar-excel', {
                params: { fechaInicio, fechaFin },
                responseType: 'arraybuffer',  // Recibimos los datos como un buffer de archivo
            });

            // Crear un Blob con la respuesta para que el navegador lo interprete como un archivo
            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            // Crear un enlace temporal para descargar el archivo
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `Facturas_${fechaInicio}_a_${fechaFin}.xlsx`; // El nombre del archivo a descargar
            document.body.appendChild(link);
             link.click();
            link.remove();  // Elimina el enlace temporal después de hacer clic
        } catch (error) {
            console.error('Error al exportar el Excel:', error);
            alert('Hubo un error al generar el archivo Excel. Por favor, intente nuevamente.');
        }
    };

    return (
        <Container fluid className="py-3">
            {/* Filtros de Reporte */}
            <div className="d-flex flex-column flex-md-row align-items-center align-items-md-end justify-content-center mb-3 text-center">
                <div className='d-flex flex-column me-md-3 mb-3 mb-md-0'>
                    <Form.Label>Desde</Form.Label>
                    <Form.Control
                        type='date'
                        value={fechaInicio}
                        onChange={(e) => {
                            const selectedDate = e.target.value;
                            if (new Date(selectedDate) <= new Date(fechaFin || new Date())) {
                                setFechaInicio(selectedDate);
                            }
                        }}
                        max={new Date().toISOString().split('T')[0]} // Fecha máxima: Hoy
                        style={{ minWidth: '200px' }}
                    />
                </div>
                <div className='d-flex flex-column me-md-3 mb-3 mb-md-0'>
                    <Form.Label>Hasta</Form.Label>
                    <Form.Control
                        type='date'
                        value={fechaFin}
                        onChange={(e) => {
                            const selectedDate = e.target.value;
                            if (new Date(selectedDate) >= new Date(fechaInicio) && new Date(selectedDate) <= new Date()) {
                                setFechaFin(selectedDate);
                            }
                        }}
                        max={new Date().toISOString().split('T')[0]} // Fecha máxima: Hoy
                        style={{ minWidth: '200px' }}
                    />
                </div>
                {/* Contenedor de botones ajustado */}
                <div className="d-flex align-items-center justify-content-end mt-3 mt-md-0">
                    <Button variant="success" onClick={exportarExcel}>
                        Exportar
                    </Button>

                </div>
            </div>

            {/* Resumen de Ventas Totales */}
            <Row className="mb-4 text-center g-4">
                {[
                    {
                        bg: 'primary',
                        title: `Total Facturas (${totalVentas.totalFacturas || 0})`,
                        value: formatCOP(totalVentas.totalVentas || 0),
                    },
                    {
                        bg: 'success',
                        title: `Total Pagado (${totalPagado.totalPagado || 0})`,
                        value: formatCOP(totalPagado.totalMontoPagado || 0),
                    },
                    {
                        bg: 'warning',
                        title: `Total Pendiente (${totalPendiente.totalPendiente || 0})`,
                        value: formatCOP(totalPendiente.totalMontoPendiente || 0),
                    },
                    {
                        bg: 'danger',
                        title: `Total Vencido (${totalVencido.totalVencido || 0})`,
                        value: formatCOP(totalVencido.totalMontoVencido || 0),
                    },
                ].map((item, idx) => (
                    <Col key={idx} xs={12} sm={6} md={3}>
                        <Card bg={item.bg} className="text-light shadow-sm h-100">
                            <Card.Body>
                                <Card.Title>{item.title}</Card.Title>
                                <h4>{item.value}</h4>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>

            {/* Estadísticas de Pedidos */}
            <Row className="mb-4 g-4">
                {[
                    {
                        title: `Total Pedidos: ${totalPedidos.totalPedidos || 0}`,
                        subtitle: `Pedidos Pendientes: ${totalPedidosPendientes.totalPedidosPendientes || 0}`,
                    },
                    {
                        title: `Fac. Distribución: ${totalFacturasPorDistribucion.totalFacturasPorDistribucion || 0}`,
                        subtitle: `Facturas Unidad: ${totalFacturasPorUnidad.totalFacturasPorUnidad}`,
                    },
                    {
                        title: 'Productos Vendidos',
                        value: totalProductosVendidos.totalProductosVendidos || 0,
                    },
                    {
                        title: `Facturas Anuladas (${totalAnulado.totalAnulado || 0})`,
                        value: formatCOP(totalAnulado.totalMontoAnulado || 0),
                    },
                ].map((item, idx) => (
                    <Col key={idx} xs={12} sm={6} md={3}>
                        <Card className="shadow-sm h-100">
                            <Card.Body>
                                <Card.Title>{item.title}</Card.Title>
                                {item.subtitle && <Card.Title>{item.subtitle}</Card.Title>}
                                {item.value && <h4>{item.value}</h4>}
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>

            {/* Ventas por Cliente */}
            <Row className="mb-4">
                <Col md={6}>
                    <div className='d-flex flex-column flex-md-row align-items-center justify-content-between mb-2'>
                        <h5 className='mb-2 mb-md-0 fw-bold'>Ventas por Cliente</h5>
                    </div>
                    <ResponsiveContainer width="100%" height={300}>
                        <BarChart data={totalVentasPorCliente}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis /> {/* Se elimina el dataKey para que los nombres no aparezcan en el eje X */}
                            <YAxis />
                            <Tooltip
                                formatter={(value) => formatCOP(value)}
                                labelFormatter={(index) => totalVentasPorCliente[index]?.name || ''} // Muestra el nombre del cliente en el tooltip
                            />
                            <Legend />
                            <Bar dataKey="ventas" fill="#3459e6" />
                        </BarChart>
                    </ResponsiveContainer>
                </Col>

                <Col md={6}>
                    <h5 className='fw-bold'>Ventas por Sucursal</h5>
                    <ResponsiveContainer width="100%" height={300}>
                        <PieChart>
                            <Pie
                                data={totalVentasPorSucursal}
                                dataKey="ventas"
                                nameKey="name"
                                cx="50%"
                                cy="50%"
                                outerRadius={100}
                                fill="#8884d8"
                            >
                                {totalVentasPorSucursal.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Pie>
                            <Tooltip formatter={(value) => formatCOP(value)} />
                        </PieChart>
                    </ResponsiveContainer>
                </Col>


            </Row>

            <div>
                <h5 className='fw-bold'>Evolución de Ventas</h5>

                <div className='d-flex justify-content-end w-100 mb-3 text-center text-md-center'>
                    <Form.Group controlId='filterSelect' className='d-flex align-items-center'>
                        <Form.Label className='me-2 mb-0'>Filtrar:</Form.Label>
                        <Form.Control
                            as='select'
                            value={filter}
                            onChange={(e) => setFilter(e.target.value)}
                            size='sm'
                        >
                            <option value="semana">Semana</option>
                            <option value="mes">Mes</option>
                            <option value="ano">Año</option>
                        </Form.Control>
                    </Form.Group>
                </div>
                <ResponsiveContainer width="100%" height={300}>
                    <LineChart data={ventasPorFechaData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="ventas" stroke="#8884d8" />
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </Container>
    );
};

export default ReporteVentas;
