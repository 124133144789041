import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import { Card, CardActionArea, CardContent, CardMedia, FormControl, InputLabel, Box, NativeSelect } from '@mui/material';
import '../../styles/CardProductos.css';
import axios from 'axios';
import DetallesProductoUni from './DetallesProductoUni';
import { useCarrito } from './CarritoContext';
import { useLocation } from 'react-router-dom';
import { API } from '../../../../api/URL_API';

function ProductosUni({ showNewProducts, showPopularProducts, idCategoria }) {
    const [allProducts, setAllProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [quantities, setQuantities] = useState([]);
    const [selectedColors, setSelectedColors] = useState([]);
    const [selectedSizes, setSelectedSizes] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [sortOrder, setSortOrder] = useState(5);
    const [currentPage, setCurrentPage] = useState(1);
    const [productsPerPage] = useState(12);
    const [showToast, setShowToast] = useState(false); // Estado del Toast
    const [totalProducts, setTotalProducts] = useState(0); // Estado para el total de productos
    const [hoveredImages, setHoveredImages] = useState({});
    const [isLoading, setIsLoading] = useState(false); //Estado de carga
    const [isLoadingMore, setIsLoadingMore] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0); // Mueve el scroll al inicio de la página
    }, []);

    const { addToCarrito, selectedCategories } = useCarrito();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const searchTerm = query.get('searchTerm') || '';

    const formatCurrency = (amount) => {
        const numberAmount = typeof amount === 'string'
            ? parseFloat(amount.replace(/[^0-9,-]+/g, ''))
            : amount;
        const formatted = numberAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return formatted;
    };

    // Función para obtener colores del producto desde el backend
    const fetchProductColors = async (productId) => {
        try {
            const response = await axios.get(`${API}/api/mostrarCategorias/colors/${productId}`);
            return response.data;
        } catch (error) {
            console.error('Error al obtener colores del producto:', error);
            return [];
        }
    };

    // Función para obtener tallas del producto desde el backend
    const fetchProductTallas = async (productId) => {
        try {
            const response = await axios.get(`${API}/api/mostrarCategorias/tallas/${productId}`);
            return response.data;
        } catch (error) {
            console.error('Error al obtener tallas del producto:', error);
            return [];
        }
    };

    // Función principal para obtener productos
    const fetchProducts = useCallback(async (page = 1) => {
        try {
            if (page === 1) setIsLoading(true); //Estado de carga
            let url;
            let params = {
                limit: productsPerPage,
                offset: (page - 1) * productsPerPage, // Ajuste para la paginación
            };

            // Determina la URL según las condiciones
            if (searchTerm) {
                url = `${API}/api/mostrarCategorias/buscar/productos-por-texto`;
                params.searchTerm = searchTerm;
            } else if (selectedCategories.length > 0) {
                url = `${API}/api/mostrarCategorias/productos-por-categorias`;
                params.categoryIds = selectedCategories.join(',');
            } else if (idCategoria === 'NewProductos') {
                url = `${API}/api/mostrarProductos/productos/nuevos`;
            } else if (idCategoria === 'MoreSales') {
                url = `${API}/api/mostrarProductos/productos/masvendidos`;
            } else if (idCategoria === 'All') {
                url = `${API}/api/mostrarCategorias/productos-todos`;
            } else if (idCategoria) {
                // Cambia el manejo de productos por categoría
                url = `${API}/api/mostrarCategorias/productos/${idCategoria}`;
                params.page = page; // Asegúrate de pasar la página actual
            }


            // Llamada a la API
            const response = await axios.get(url, { params });

            // Manejo de respuesta
            if (response.data) {
                let productsWithNumberPrices;

                // Manejo de diferentes formatos de respuesta
                if (Array.isArray(response.data)) {
                    productsWithNumberPrices = response.data.map(product => ({
                        ...product,
                        precioU: typeof product.precioU === 'string'
                            ? parseFloat(product.precioU.replace(/[^0-9,-]+/g, ''))
                            : product.precioU,
                        precioM: typeof product.precioM === 'string'
                            ? parseFloat(product.precioM.replace(/[^0-9,-]+/g, ''))
                            : product.precioM,
                    }));
                } else if (response.data.products && Array.isArray(response.data.products)) {
                    productsWithNumberPrices = response.data.products.map(product => ({
                        ...product,
                        precioU: typeof product.precioU === 'string'
                            ? parseFloat(product.precioU.replace(/[^0-9,-]+/g, ''))
                            : product.precioU,
                        precioM: typeof product.precioM === 'string'
                            ? parseFloat(product.precioM.replace(/[^0-9,-]+/g, ''))
                            : product.precioM,
                    }));
                } else {
                    setAllProducts([]);
                    setQuantities([]);

                    return; // Salimos si el formato no es el esperado
                }

                // Usamos un Set para evitar duplicados
                setAllProducts(prev => {
                    const existingIds = new Set(prev.map(p => p.idProducto)); // Crear un conjunto de IDs existentes
                    const uniqueProducts = productsWithNumberPrices.filter(product => !existingIds.has(product.idProducto)); // Filtrar productos únicos
                    return [...prev, ...uniqueProducts]; // Combina los productos únicos
                    setQuantities([]);

                });

                // Ajuste del total de productos
                setTotalProducts(response.data.total || productsWithNumberPrices.length);
            } else {
                setAllProducts([]);
            }
        } catch (error) {
            console.error('Error al cargar productos:', error);
        } finally {
            if (page === 1) setIsLoading(false); // Finaliza el estado de carga principal solo si es la carga inicial
        }
    }, [searchTerm, selectedCategories, showNewProducts, showPopularProducts, idCategoria]);

    const applyFilters = useCallback(() => {
        if (allProducts.length === 0) {
            setFilteredProducts([]); // O establecer un valor por defecto
            return; // Salir si no hay productos
        }

        let filtered = [...allProducts];

        if (searchTerm) {
            const lowercasedTerm = searchTerm.toLowerCase();
            filtered = filtered.filter(product =>
                product.nomProducto.toLowerCase().includes(lowercasedTerm)
            );
        } else {
            if (selectedCategories.length > 0) {
                filtered = filtered.filter(product =>
                    selectedCategories.includes(String(product.idCategoria))
                );
            }

            if (selectedColors.length > 0) {
                filtered = filtered.filter(product =>
                    selectedColors.includes(product.color)
                );
            }

            if (selectedSizes.length > 0) {
                filtered = filtered.filter(product =>
                    selectedSizes.includes(product.size)
                );
            }
        }

        filtered = sortProducts(filtered, sortOrder);

        setFilteredProducts(filtered);
    }, [allProducts, searchTerm, selectedCategories, selectedColors, selectedSizes, sortOrder]);

    // useEffect para aplicar filtros
    useEffect(() => {
        if (allProducts.length > 0) {
            applyFilters();
        }
    }, [applyFilters, allProducts, sortOrder]);

    // useEffect para fetchProducts
    useEffect(() => {
        fetchProducts(currentPage);
    }, [fetchProducts, currentPage]);

    // useEffect para applyFilters
    useEffect(() => {
        applyFilters();
    }, [applyFilters, allProducts, sortOrder]);

    const sortProducts = (products, sortOrder) => {
        switch (sortOrder) {
            case 1:
                return products.sort((a, b) => b.precioU - a.precioU);
            case 2:
                return products.sort((a, b) => a.precioU - b.precioU);
            case 3:
                return products.sort((a, b) => a.nomProducto.localeCompare(b.nomProducto));
            case 4:
                return products.sort((a, b) => b.nomProducto.localeCompare(a.nomProducto));
            default:
                return products;
        }
    };

    const handleAddToCart = (product, index, selection = {}) => {
        const { selectedColor, selectedSize } = selection;

        const quantityToCart = quantities[index] || 1;
        const imageToCart = product.imgProducto
            ? `${product.imgProducto}`
            : 'URL_DEFAULT_IMAGE'; // Maneja el caso cuando imgProducto es undefined
        const item = {
            idProducto: product.idProducto,
            nomProducto: product.nomProducto,
            precioU: product.precioU,
            precioM: product.precioM,
            image: imageToCart,
            quantity: quantityToCart, // Usar la cantidad validada
            selectedColor: selectedColor,
            selectedSize: selectedSize,
            stock: product.stock

        };

        console.log("cantidad ", item.quantity);
        addToCarrito(item);
        setShowToast(true);

    };

    const incrementQuantity = (index) => {
        setQuantities(prevQuantities => {
            const newQuantities = [...prevQuantities];
            newQuantities[index] = (newQuantities[index] || 1) + 1;
            return newQuantities;
        });
    };

    const decrementQuantity = (index) => {
        setQuantities(prevQuantities => {
            const newQuantities = [...prevQuantities];
            newQuantities[index] = Math.max((newQuantities[index] || 1) - 1, 1);
            return newQuantities;
        });
    };

    const handleShowModal = async (product) => {
        const productImages = [
            `${product.imgProducto}`,
            product.img1 ? `${product.img1}` : null,
            product.img2 ? `${product.img2}` : null,
            product.img3 ? `${product.img3}` : null,
        ].filter(Boolean); // Filtra imágenes no nulas

        // Consultar colores y tallas solo cuando se selecciona un producto
        const colors = await fetchProductColors(product.idProducto);
        const sizes = await fetchProductTallas(product.idProducto);

        setSelectedProduct({
            ...product,
            images: productImages,
            colors,
            sizes,
            selectedColor: selectedColors[allProducts.findIndex(p => p.idProducto === product.idProducto)] || null,
            selectedSize: selectedSizes[allProducts.findIndex(p => p.idProducto === product.idProducto)] || null
        });
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleLoadMore = async () => {
        setIsLoadingMore(true); // Solo el botón "Ver más" muestra el estado de carga
        await fetchProducts(currentPage + 1); // Llamamos a `fetchProducts` para cargar la siguiente página
        setCurrentPage(prevPage => prevPage + 1);
        setIsLoadingMore(false); // Finaliza el estado de carga del botón
    };

    const handleSetSelectedColor = (color) => {
        if (selectedProduct) {
            const updatedProduct = {
                ...selectedProduct,
                selectedColor: color,
            };
            setSelectedProduct(updatedProduct);  // Asegúrate de que esto actualice correctamente el estado de selectedProduct
        }
    };

    const handleSetSelectedSize = (size) => {
        if (selectedProduct) {
            const updatedProduct = {
                ...selectedProduct,
                selectedSize: size,
            };
            setSelectedProduct(updatedProduct);  // Asegúrate de que esto actualice correctamente el estado de selectedProduct
        }
    };

    const handleMouseEnter = (productId, img1) => {
        if (img1) {
            setHoveredImages(prev => ({ ...prev, [productId]: img1 }));
        }
    };

    const handleMouseLeave = (productId) => {
        setHoveredImages(prev => ({ ...prev, [productId]: null }));
    };


    return (
        <Container fluid className='mt-0'>
            <Row>
                <div className='d-flex align-items-center justify-content-between w-100 mb-4'>
                    <h4 className='text1-cr fw-bold fst-italic'>Productos</h4>
                    <Box>
                        <FormControl size='small'>
                            <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                Ordenar
                            </InputLabel>
                            <NativeSelect
                                value={sortOrder}
                                onChange={(e) => setSortOrder(Number(e.target.value))}
                                inputProps={{
                                    name: 'sortOrder',
                                    id: 'uncontrolled-native',
                                }}
                            >
                                <option value={5}>Por defecto</option>
                                <option value={1}>Mayor a Menor precio</option>
                                <option value={2}>Menor a mayor precio</option>
                                <option value={3}>A - Z</option>
                                <option value={4}>Z - A</option>
                            </NativeSelect>
                        </FormControl>
                    </Box>
                </div>
            </Row>
            <Row>
                {isLoading && !isLoadingMore ? (
                    <Col xs={12} className='text-center carga-productos'>
                        <Spinner animation='grow' className="spinner-delayed-1 me-1" style={{ width: '1.5rem', height: '1.5rem' }}/>
                        <Spinner animation='grow' className="spinner-delayed-2 me-1" style={{ width: '1.5rem', height: '1.5rem' }}/>
                        <Spinner animation='grow' className="spinner-delayed-3 me-1" style={{ width: '1.5rem', height: '1.5rem' }}/>
                        <p className='text1-cr fw-bold fst-italic'>Cargando productos...</p> {/* Indicador de carga */}
                    </Col>
                ) : filteredProducts.length === 0 ? (
                    <Col xs={12} className='text-center carga-productos'>
                        <p className='text1-cr fw-bold fst-italic'>No hay productos disponibles.</p> {/* Mensaje si no hay productos */}
                    </Col>
                ) : (
                    filteredProducts.slice(0, currentPage * productsPerPage).map((product, index) => (
                        <Col key={product.idProducto} xs={6} sm={6} md={3} className='d-flex justify-content-center mb-3'>
                            <Card
                                className='position-relative'
                                style={{ boxShadow: '0 4px 8px rgba(43, 35, 94, 0.3), 0 6px 20px rgba(43, 35, 94, 0.3)', borderRadius: '15px' }}
                            >
                                <CardActionArea
                                    onClick={() => handleShowModal(product)}
                                    onMouseEnter={() => handleMouseEnter(product.idProducto, product.img1)}
                                    onMouseLeave={() => handleMouseLeave(product.idProducto)}
                                >
                                    <CardMedia
                                        component='img'
                                        height='300'
                                        image={
                                            hoveredImages[product.idProducto]
                                                ? `${hoveredImages[product.idProducto]}`
                                                : `${product.imgProducto}`
                                        }
                                        alt={`product-${product.idProducto}`}
                                        className='card-media-image'
                                    />
                                </CardActionArea>
                                <CardContent className='text-center text1-cr'>
                                    <h4 className='fw-bold'>{product.nomProducto}</h4>
                                    <h5 className='mb-3'>
                                        $ {formatCurrency(product.precioU)}
                                    </h5>
                                    <h6 className='mb-3 fs-6 text-muted'>
                                        Gran Mayorista $ {formatCurrency(product.precioM)}
                                    </h6>
                                    <div className="d-flex justify-content-center mt-2">
                                        <button className='button' onClick={() => handleShowModal(product, index)}>
                                            Comprar
                                        </button>
                                    </div>
                                </CardContent>
                            </Card>
                        </Col>
                    ))
                )}
            </Row>
            {totalProducts > filteredProducts.length && (
                <Row className='mt-3'>
                    <Col className='text-center'>
                        <div className="d-flex justify-content-center mt-2">
                            <button onClick={handleLoadMore} className='button' disabled={isLoadingMore}>
                                {isLoadingMore ? (
                                    <Spinner animation="border" size="sm" /> // Spinner en vez de texto
                                ) : (
                                    "Ver más"
                                )}
                            </button>
                        </div>
                    </Col>
                </Row>
            )}

            {
                selectedProduct && (
                    <DetallesProductoUni
                        idProducto={selectedProduct.idProducto}
                        images={selectedProduct.images}
                        name={selectedProduct.nomProducto}
                        price={selectedProduct.precioU}
                        wholesalePrice={selectedProduct.precioM} // Añadir el precio mayorista
                        descripcion={selectedProduct.descripcion}
                        showModal={showModal}
                        handleCloseModal={handleCloseModal}
                        quantity={quantities[allProducts.findIndex(p => p.idProducto === selectedProduct.idProducto)] || 1}
                        setQuantity={(q) => {
                            const index = allProducts.findIndex(p => p.idProducto === selectedProduct.idProducto);
                            setQuantities(prev => {
                                const newQuantities = [...prev];
                                newQuantities[index] = q;
                                return newQuantities;
                            });
                        }}
                        incrementQuantity={() => {
                            const index = allProducts.findIndex(p => p.idProducto === selectedProduct.idProducto);
                            setQuantities(prev => {
                                const newQuantities = [...prev];
                                newQuantities[index] = (newQuantities[index] || 1) + 1;
                                return newQuantities;
                            });
                        }}
                        decrementQuantity={() => {
                            const index = allProducts.findIndex(p => p.idProducto === selectedProduct.idProducto);
                            setQuantities(prev => {
                                const newQuantities = [...prev];
                                newQuantities[index] = Math.max((newQuantities[index] || 1) - 1, 1);
                                return newQuantities;
                            });
                        }}
                        colors={selectedProduct.colors}
                        selectedColor={selectedProduct.selectedColor}
                        setSelectedColor={handleSetSelectedColor}
                        sizes={selectedProduct.sizes}
                        selectedSize={selectedProduct.selectedSize}
                        setSelectedSize={handleSetSelectedSize}
                        handleAddToCart={handleAddToCart}  // Aquí debe estar pasando correctamente el método
                        stock={selectedProduct.stock}
                    />

                )
            }
        </Container >
    );
}

export default ProductosUni;
