import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { ChatQuoteFill, StarFill } from 'react-bootstrap-icons';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import '../styles/PaginationDetalles.css'
import test1 from '../../../utils/testimonio1.webp';
import test2 from '../../../utils/testimonio2.webp';
import test3 from '../../../utils/testimonio3.webp';

function Testimonios() {
    const testimoniosData = [
        {
            image: test1,
            name: '',
            text: 'Este producto y su calidad son maravillosos',
            rating: 6,
            title: 'Definitivamente lo recomiendo',
        },
        {
            image: test2,
            name: '',
            text: 'La calidad superó todas mis espectativas',
            rating: 6,
            title: 'Súper contenta con mi pedido',
        },
        {
            image: test3,
            name: '',
            text: 'La atención al cliente es tan buena como las gorras',
            rating: 6,
            title: 'Súper recomendado',
        },
    ];

    return (
        <div className="mt-0 mb-0">
            <h2 className="text-center mb-4 text1-cr fw-bold fst-italic">LO QUE DICEN NUESTROS CLIENTES</h2>

            {/* Usar Swiper para pantallas pequeñas */}
            <Swiper
                className="d-block d-md-none"
                modules={[Pagination, Autoplay]} // Agregar los módulos de Paginación y Autoplay
                pagination={{ clickable: true }} // Activar la paginación
                spaceBetween={30}
                autoplay={{ delay: 3000, disableOnInteraction: false }} // Habilitar autoplay
                slidesPerView={1} // Mostrar un slide a la vez
            >
                {testimoniosData.map((testimonio, index) => (
                    <SwiperSlide key={index}>
                        <div className="d-flex justify-content-center">
                            <Card className="bg-light text1-cr" style={{ width: '90%', maxWidth: '400px', boxShadow: '0 4px 8px rgba(43, 35, 94, 0.3), 0 6px 20px rgba(43, 35, 94, 0.3)', borderRadius: '15px' }}>
                                <Card.Img
                                    variant="top"
                                    src={testimonio.image}
                                    alt={testimonio.name}
                                    style={{ height: '300px', objectFit: 'cover', borderTopLeftRadius: '15px', borderTopRightRadius: '15px' }}
                                />
                                <Card.Body className="text-center d-flex flex-column" style={{ height: '250px' }}>
                                    <div className="mt-auto">
                                        <div className="d-flex justify-content-center align-items-center">
                                            <ChatQuoteFill size={24} className="me-2 text2-cr" />
                                            <small>{testimonio.name}</small>
                                        </div>
                                    </div>
                                    <Card.Title className='text1-cr mt-2'><h3 className='fw-bold'>{testimonio.title}</h3></Card.Title>
                                    <Card.Text className="lead mt-0 text1-cr" style={{ flexGrow: 1 }}>
                                        {testimonio.text}
                                    </Card.Text>
                                    <Card.Footer>
                                        <div className="d-flex justify-content-center mb-2">
                                            {[...Array(testimonio.rating)].map((_, i) => (
                                                <StarFill key={i} color="#ffc107" />
                                            ))}
                                        </div>
                                    </Card.Footer>
                                </Card.Body>
                            </Card>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>

            <div className="swiper-pagination" /> {/* Ajustar el margen superior según sea necesario */}

            {/* Mostrar las tarjetas en filas para pantallas grandes */}
            <Row className="justify-content-center d-none d-md-flex m-2">
                {testimoniosData.map((testimonio, index) => (
                    <Col key={index} md={4} className="mb-4 d-flex">
                        <Card
                            className=" text1-cr h-100"
                            style={{ boxShadow: '0 4px 8px rgba(43, 35, 94, 0.3), 0 6px 20px rgba(43, 35, 94, 0.3)', borderRadius: '15px' }}
                        >
                            <Card.Img
                                variant="top"
                                src={testimonio.image}
                                alt={testimonio.name}
                                style={{ height: '300px', objectFit: 'cover', borderTopLeftRadius: '15px', borderTopRightRadius: '15px' }}
                            />
                            <Card.Body className="text-center d-flex flex-column" style={{ height: '250px' }}>
                                <div className="mt-auto">
                                    <div className="d-flex justify-content-center align-items-center">
                                        <ChatQuoteFill size={30} className="me-2 text2-cr" />
                                        <small className='text1-cr'>{testimonio.name}</small>
                                    </div>
                                </div>
                                <Card.Title className='text1-cr mt-2'><h3 className='fw-bold'>{testimonio.title}</h3></Card.Title>
                                <Card.Text className="lead mt-0 text1-cr" style={{ flexGrow: 1 }}>
                                    {testimonio.text}
                                </Card.Text>
                                <Card.Footer>
                                    <div className="d-flex justify-content-center mb-2">
                                        {[...Array(testimonio.rating)].map((_, i) => (
                                            <StarFill key={i} color="#ffc107" />
                                        ))}
                                    </div>
                                </Card.Footer>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </div>
    );
}

export default Testimonios;
