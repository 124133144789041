// ProductSearchModal.js
import React from 'react';
import { Modal, Form, Table, Button } from 'react-bootstrap';

const ProductSearchModal = ({
  showModal,
  setShowModal,
  searchQuery,
  handleSearchQueryChange,
  searchResults,
  formatCOP,
  handleSelectProduct
}) => {
  return (
    <Modal show={showModal} size='lg' onHide={() => setShowModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Buscar Producto</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Control
          type="text"
          value={searchQuery}
          onChange={handleSearchQueryChange}
          placeholder="Escriba el nombre del producto"
        />
        <Table responsive className='shadow-none text-center mt-3'>
          <thead className='bg-primary-subtle'>
            <tr>
              <th>Referencia</th>
              <th>Nombre</th>
              <th>Stock</th>
              <th>Valor Unitario</th>
              <th>Valor Distribución</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {searchResults.length ? searchResults.map(producto => (
              <tr key={producto.idProducto}>
                <td>{producto.referencia}</td>
                <td>{producto.nomProducto}</td>
                <td>{producto.stock}</td>
                <td>{formatCOP(producto.precioU)}</td>
                <td>{formatCOP(producto.precioM)}</td>
                <td>
                  <Button
                    variant='outline-success'
                    size='sm'
                    onClick={() => handleSelectProduct(producto)}
                    disabled={producto.stock <= 0} // Deshabilita el botón si el stock es 0 o menor
                  >
                    Seleccionar
                  </Button>
                </td>
              </tr>
            )) : (
              <tr>
                <td colSpan="6">No se encontraron resultados</td>
              </tr>
            )}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowModal(false)}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProductSearchModal;
