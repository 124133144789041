import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { ArrowLeftCircleFill } from 'react-bootstrap-icons';
import ProductosUni from './Productos';
import FloatingButton from '../../FloatingButton';
import FooterPublic from '../../FooterPublic';
import { useSucursal } from '../../../../contexts/SucursalContext';

function BodyProductUni({ idCategoria, searchTerm }) {
    const { idSucursal: contextIdSucursal } = useSucursal(); // Obtenemos el idSucursal desde el contexto global
    const { idSucursal: paramIdSucursal } = useParams(); // Obtenemos el idSucursal desde los parámetros de la URL
    const idSucursal = paramIdSucursal || contextIdSucursal; // Prioridad: URL > Contexto

    // Verificar el valor de idSucursal en consola
    useEffect(() => {
    }, [idSucursal]);

    return (
        <div>
            <Link to={`/Collections/${idSucursal}`}>
                <Button variant="link" className="text1-cr text-decoration-none my-3">
                    <ArrowLeftCircleFill className='me-2' size={18} /> Volver a Colecciones
                </Button>
            </Link>
            <ProductosUni
                idCategoria={idCategoria}
                searchTerm={searchTerm}
            />
            <FloatingButton />
            <FooterPublic idSucursal={idSucursal} /> {/* Pasamos el idSucursal desde la URL o el contexto */}
        </div>
    );
}

export default BodyProductUni;
