import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { CashCoin } from "react-bootstrap-icons";
import CrearPagoResModal from './componentes/crearPagoResModal'; // Importar el nuevo componente
import TablaPagosRes from "./componentes/tablaPagosRes";

const AdminPagosRes = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="mx-2 mt-3">
      <div className='d-flex flex-column flex-md-row align-items-center justify-content-between'>
        <p className='mb-2 mb-md-0 text-center'>
          Ingresa los pagos que realizan tus clientes a sus facturas.
        </p>
        <div className='d-flex mt-2 mt-md-0'>
          <Button variant="primary" onClick={handleShow}>
            <CashCoin className="me-2" /> Ingresar Pago
          </Button>
        </div>
      </div>
      {/* Modal para crear pago */}
      <CrearPagoResModal show={show} handleClose={handleClose} />
      {/* Table Pagos */}
      <div className="mt-4">
        <TablaPagosRes />
      </div>
    </div>
  );
};

export default AdminPagosRes;
