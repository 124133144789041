import React, { useState, useEffect } from 'react';
import { Table, Pagination, Button, Form, ButtonGroup } from 'react-bootstrap';
import { Filter, EyeFill, PencilFill, TrashFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import FiltrosPagos from '../../../Pagos/componentes/filtroPagos';
import EditarPago from '../../../Pagos/componentes/editarPago';
import CrearPagoModal from '../../../Pagos/componentes/crearPagoModal';
import { AlertsPagosEliminar } from '../../../Pagos/Utils/AlertsPagos';
import APIserver from '../../../../../api/axiosConfig';

function EgresoTable({ bancoId }) {
    const [showFilters, setShowFilters] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [editShow, setShowEdit] = useState(false);
    const [crearPagoShow, setCrearPagoShow] = useState(false);
    const [pagos, setPagos] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [editPagoId, setEditPagoId] = useState(null);
    const [filters, setFilters] = useState({});

    useEffect(() => {
        fetchPagos(currentPage, rowsPerPage, filters);
    }, [currentPage, rowsPerPage, filters, bancoId]);

    const fetchPagos = async (page, limit, filters = {}) => {
        try {
            const response = await APIserver.get(`/api/api/ver/getPagos`, {
                params: {
                    page,
                    limit,
                    idBanco: bancoId, // Usa bancoId en los parámetros de la solicitud
                    ...filters,
                },
            });

            const pagosFormateados = response.data.pagos.map((pago) => ({
                ...pago,
                fecha: new Date(pago.fecha).toLocaleDateString(),
            }));

            setPagos(pagosFormateados);
            setCurrentPage(response.data.currentPage);
            setTotalPages(response.data.totalPages);

        } catch (error) {
            console.error('Error al cargar los pagos:', error);
        }
    };

    const toggleFilters = () => {
        setShowFilters(!showFilters);
    };

    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(parseInt(e.target.value, 10));
        setCurrentPage(1);
    };

    const handleEditClose = () => {
        setShowEdit(false);
        setEditPagoId(null);
        fetchPagos(currentPage, rowsPerPage, filters);
    };

    const handleEditShow = (pagoId) => {
        setShowEdit(true);
        setEditPagoId(pagoId);
    };

    const handleCrearPagoClose = () => setCrearPagoShow(false);
    const handleCrearPagoShow = () => setCrearPagoShow(true);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleEliminarPago = async (idPago) => {
        AlertsPagosEliminar.showConfirmDelete(() => {
            APIserver.delete(`/api/pagos/${idPago}`)
                .then(response => {
                    if (response.status === 200) {
                        AlertsPagosEliminar.showSuccessMessage('El pago ha sido eliminado correctamente');
                        const updatedPagos = pagos.filter((pago) => pago.idPago !== idPago);
                        setPagos(updatedPagos);
                    } else {
                        AlertsPagosEliminar.showErrorMessage('Error al eliminar el pago');
                    }
                })
                .catch(error => {
                    console.error('Error al eliminar el pago:', error);
                    AlertsPagosEliminar.showErrorMessage('Error al eliminar el pago');
                });
        });
    };

    const applyFilters = (filters) => {
        setFilters(filters);
        setCurrentPage(1);
    };

    return (
        <div style={{ boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)' }}>
            <Table responsive className='text-center align-middle border'>
                <thead>
                    <tr>
                        <th colSpan={8}>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='d-flex flex-wrap'>
                                    <Button variant='link' size='sm' onClick={toggleFilters} title='Filtrar'>
                                        <Filter className='m-0' size={20} />
                                    </Button>
                                </div>
                                <div>
                                    <Form.Select className='ms-2' value={rowsPerPage} onChange={handleChangeRowsPerPage}>
                                        <option value='5'>5</option>
                                        <option value='10'>10</option>
                                        <option value='20'>20</option>
                                        <option value='50'>50</option>
                                    </Form.Select>
                                </div>
                            </div>
                            <div className={`filter-options ${showFilters ? 'show' : ''}`}>
                                <FiltrosPagos updateTable={applyFilters} />
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <th>Pago</th>
                        <th>Factura</th>
                        <th>Proveedor</th>
                        <th>Observaciones</th>
                        <th>Creación</th>
                        <th>Cuenta</th>
                        <th>Monto</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {pagos.map((pago) => (
                        <tr key={pago.idPago}>
                            <td>
                                <Link to={`/admin/PagosRealizados/DetallesPago/${pago.idPago}/`} className='text-decoration-none'>
                                    {pago.numPagoHe}
                                </Link>
                            </td>
                            <td>
                                <Link to={`/admin/Compras/DetallesCompra/${pago.idCompra}/`} className='text-decoration-none'>
                                    {pago.numCompra}
                                </Link>
                            </td>
                            <td>
                                <Link to={`/admin/Proveedores/DetallesProveedores/${pago.idProveedor}/`} className='text-decoration-none'>
                                    {pago.nomProveedor}
                                </Link>
                            </td>
                            <td>{pago.detalle}</td>
                            <td>{pago.fecha}</td>
                            <td>{pago.nombreBanco}</td>
                            <td>${pago.monto.toLocaleString()}</td>
                            <td>
                                <ButtonGroup aria-label='action-button'>
                                    <Link to={`/admin/PagosRealizados/DetallesPago/${pago.idPago}/`}>
                                        <Button variant='link' className='text-primary' title='Ver Pago'>
                                            <EyeFill size={18} />
                                        </Button>
                                    </Link>
                                    <Button variant='link' className='text-warning' title='Editar Pago' onClick={() => handleEditShow(pago.idPago)}>
                                        <PencilFill size={18} />
                                    </Button>
                                    <Button variant='link' className='text-danger' title='Eliminar Pago' onClick={() => handleEliminarPago(pago.idPago)}>
                                        <TrashFill size={18} />
                                    </Button>
                                </ButtonGroup>
                            </td>
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan={8}>
                            <div className='d-flex justify-content-center align-items-center'>
                                <Pagination>
                                    <Pagination.Prev disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)} />
                                    {Array.from({ length: totalPages }, (_, index) => (
                                        <Pagination.Item
                                            key={index + 1}
                                            active={index + 1 === currentPage}
                                            onClick={() => handlePageChange(index + 1)}
                                        >
                                            {index + 1}
                                        </Pagination.Item>
                                    ))}
                                    <Pagination.Next disabled={currentPage === totalPages} onClick={() => handlePageChange(currentPage + 1)} />
                                </Pagination>
                            </div>
                        </td>
                    </tr>
                </tfoot>
            </Table>

            <CrearPagoModal show={crearPagoShow} handleClose={handleCrearPagoClose} />
            <EditarPago show={editShow} handleClose={handleEditClose} pagoId={editPagoId} updatePagos={() => fetchPagos(currentPage, rowsPerPage, filters)} />
        </div>
    );
}

export default EgresoTable;
