import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { BarChart, Bar, PieChart, Pie, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LineChart, Line } from 'recharts';
import APIserver from '../../../../api/axiosConfig';

const ReporteFinanciero = () => {
    const [fechaInicio, setFechaInicio] = useState('');
    const [fechaFin, setFechaFin] = useState('');
    const [filter, setFilter] = useState('mes');
    const [bancosData, setBancosData] = useState([]);
    const [cuentasPorCobrarYPagarData, setCuentasPorCobrarYPagarData] = useState([]);
    const [flujoDeCajaData, setFlujoDeCajaData] = useState([]);

    useEffect(() => {
        const today = new Date();

        // Fecha de inicio del mes (primer día)
        const primerDiaMes = new Date(today.getFullYear(), today.getMonth(), 1);
        const fechaInicioMes = primerDiaMes.toISOString().split('T')[0]; // Formato YYYY-MM-DD

        // Fecha de fin del mes (último día)
        const ultimoDiaMes = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        const fechaFinMes = ultimoDiaMes.toISOString().split('T')[0];

        setFechaInicio(fechaInicioMes);
        setFechaFin(fechaFinMes);
    }, []);

    useEffect(() => {
        const fetchBancosData = async () => {
            try {
                const response = await APIserver.get('/api/reportesFinancieros/top-bancos', {
                    params: { fechaInicio, fechaFin }
                });
                setBancosData(response.data);
            } catch (error) {
                console.error('Error al obtener los bancos:', error);
            }
        };
        if (fechaInicio && fechaFin) {
            fetchBancosData();
        }
    }, [fechaInicio, fechaFin]);

    useEffect(() => {
        const fetchCuentasData = async () => {
            try {
                const response = await APIserver.get('/api/reportesFinancieros/cuentas-cobrar-pagar', {
                    params: { fechaInicio, fechaFin }
                });
                setCuentasPorCobrarYPagarData(response.data);
            } catch (error) {
                console.error('Error al obtener las cuentas:', error);
            }
        };
        if (fechaInicio && fechaFin) {
            fetchCuentasData();
        }
    }, [fechaInicio, fechaFin]);

    const COLORS = ['#2fb380', '#da292e'];
    useEffect(() => {
        const fetchFlujoDeCajaData = async () => {
            try {
                const response = await APIserver.get('/api/reportesFinancieros/flujo-de-caja', {
                    params: { filtro: filter },
                });

                // Transformar los datos para que se muestren correctamente en el gráfico
                const transformedData = response.data.map(item => {
                    if (filter === 'semana') {
                        // Para 'semana', calculamos la fecha del primer día de la semana
                        const date = getFirstDayOfWeek(item.periodo);  // Obtener primer día de la semana
                        const monthName = date.toLocaleString('default', { month: 'long' });  // Nombre del mes
                        const weekNumberInMonth = getWeekNumberInMonth(date);  // Número de semana dentro del mes
                        return {
                            ...item,
                            semana: `${monthName} Semana ${weekNumberInMonth}`,  // Mostrar mes y semana dentro del mes
                        };
                    } else if (filter === 'mes') {
                        // Para 'mes', mostramos el nombre del mes
                        const date = new Date(0);
                        date.setMonth(item.periodo - 1);  // Ajustamos el mes
                        const monthName = date.toLocaleString('default', { month: 'long' });
                        return {
                            ...item,
                            mes: monthName,  // Etiqueta solo con el nombre del mes
                        };
                    } else {
                        // Para 'ano', simplemente mostramos el año
                        return item;
                    }
                });

                setFlujoDeCajaData(transformedData);  // Actualizamos el estado con los datos transformados

            } catch (error) {
                console.error('Error al obtener los datos del flujo de caja:', error);
            }
        };

        fetchFlujoDeCajaData(); // Llamada a la API cuando el filtro cambia
    }, [filter]); // Se ejecuta cada vez que cambia el filtro

    // Función para obtener el primer día de la semana en base al número de semana
    function getFirstDayOfWeek(weekNumber) {
        const year = new Date().getFullYear(); // Usamos el año actual
        const date = new Date(year, 0, 1); // Primer día del año

        // Ajustar para que la semana comience correctamente (esto considera que el primer día de la semana es el lunes)
        const days = (weekNumber - 1) * 7;  // Número de días desde el inicio del año
        date.setDate(date.getDate() + days);

        // Establecer el lunes de esa semana
        const dayOfWeek = date.getDay();
        const diff = date.getDate() - dayOfWeek + (dayOfWeek == 0 ? -6 : 1);  // Si el día es domingo, ajustamos a lunes
        date.setDate(diff);

        return date;
    }

    // Función para obtener el número de semana dentro de un mes específico
    function getWeekNumberInMonth(date) {
        const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1); // Primer día del mes
        const firstDayOfWeek = firstDayOfMonth.getDay();  // Día de la semana del primer día del mes (0=Domingo, 1=Lunes, etc.)
        const diff = (date.getDate() + firstDayOfWeek) - 1; // Restamos 1 porque el 1 de mes ya es la primera semana

        return Math.ceil(diff / 7);  // Calculamos el número de semana dentro del mes
    }

    const exportarReportes = async () => {
        try {
            const response = await APIserver.get('/api/reportesFinancieros/exportar-reportes', {
                params: { fechaInicio, fechaFin },
                responseType: 'blob' // Especificamos que la respuesta será un archivo
            });

            // Crear un enlace para descargar el archivo Excel
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'reportes_financieros.xlsx');
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error('Error al descargar los reportes:', error);
        }
    };

    // Función para formatear valores en COP
    const formatCOP = (value) => value?.toLocaleString('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    }) || '$0';;

    return (
        <Container fluid className="py-3">
            {/* Filtros de Reporte */}
            <div className='d-flex flex-column flex-md-row align-items-center align-items-md-end justify-content-center mb-3 text-center'>
                <div className='d-flex flex-column me-md-3 mb-3 mb-md-0'>
                    <Form.Label>Desde</Form.Label>
                    <Form.Control
                        type='date'
                        value={fechaInicio}
                        onChange={(e) => {
                            const selectedDate = e.target.value;
                            if (new Date(selectedDate) <= new Date(fechaFin || new Date())) {
                                setFechaInicio(selectedDate);
                            }
                        }}
                        max={new Date().toISOString().split('T')[0]} // Fecha máxima: Hoy
                        style={{ minWidth: '200px' }}
                    />
                </div>
                <div className='d-flex flex-column me-md-3 mb-3 mb-md-0'>
                    <Form.Label>Hasta</Form.Label>
                    <Form.Control
                        type='date'
                        value={fechaFin}
                        onChange={(e) => {
                            const selectedDate = e.target.value;
                            if (new Date(selectedDate) >= new Date(fechaInicio) && new Date(selectedDate) <= new Date()) {
                                setFechaFin(selectedDate);
                            }
                        }}
                        max={new Date().toISOString().split('T')[0]} // Fecha máxima: Hoy
                        style={{ minWidth: '200px' }}
                    />
                </div>
                <div className='d-flex align-items-center justify-content-end mt-3 mt-md-0'>
                    <Button variant='success' onClick={exportarReportes}>
                        Exportar
                    </Button>
                </div>
            </div>

            {/* Análisis de Deudas y Cuentas por Cobrar */}
            <Row className="mb-4">
                <Col md={6}>
                    <h5 className='fw-bold mb-2'>Saldos de bancos</h5>
                    <ResponsiveContainer width="100%" height={300}>
                        <BarChart data={bancosData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="banco" />
                            <YAxis />

                            {/* Tooltip personalizado */}
                            <Tooltip content={({ payload }) => {
                                if (payload && payload.length > 0) {
                                    const { banco, ingresos, egresos, saldoFinal } = payload[0].payload;
                                    return (
                                        <div style={{
                                            backgroundColor: 'white',
                                            padding: '10px',
                                            borderRadius: '5px',
                                            border: '1px solid #ccc',
                                            color: 'black',
                                            fontSize: '14px',
                                        }}>
                                            <p><strong>Banco:</strong> {banco}</p>
                                            <p style={{ color: '#2fb380' }}><strong>Ingresos:</strong> {formatCOP(ingresos)}</p>
                                            <p style={{ color: '#da292e' }}><strong>Egresos:</strong> {formatCOP(egresos)}</p>
                                            <p><strong>Saldo:</strong> {formatCOP(saldoFinal)}</p>
                                        </div>
                                    );
                                }
                                return null;
                            }} />

                            {/* Barras de Ingresos y Egresos */}
                            <Bar dataKey="ingresos" fill="#2fb380" name="Ingresos" />
                            <Bar dataKey="egresos" fill="#da292e" name="Egresos" />
                        </BarChart>
                    </ResponsiveContainer>
                </Col>

                <Col md={6}>
                    <h5 className='fw-bold mb-2'>Análisis de Deudas y Cuentas por Cobrar</h5>
                    <ResponsiveContainer width="100%" height={300}>
                        <PieChart>
                            <Pie
                                data={cuentasPorCobrarYPagarData}
                                dataKey="monto"
                                nameKey="tipo"
                                cx="50%"
                                cy="50%"
                                outerRadius={100}
                                fill="#82ca9d"
                            >
                                {cuentasPorCobrarYPagarData.map((entry, index) => (
                                    <Cell
                                        key={`cell-${index}`}
                                        fill={COLORS[index % COLORS.length]}
                                    />
                                ))}
                            </Pie>
                            {/* Tooltip para mostrar los datos al pasar el cursor */}
                            <Tooltip
                                cursor={{ stroke: 'none', fill: 'rgba(0, 0, 0, 0.1)' }}
                                content={({ active, payload }) => {
                                    if (active && payload && payload.length) {
                                        const data = payload[0]; // Obtenemos el primer dato del tooltip
                                        return (
                                            <div style={{
                                                backgroundColor: '#fff',
                                                border: '1px solid #ccc',
                                                padding: '10px',
                                                borderRadius: '5px'
                                            }}>
                                                <p><strong>{data.name}</strong></p>
                                                <p>{`Total: ${formatCOP(data.value)}`}</p>
                                            </div>
                                        );
                                    }
                                    return null;
                                }}
                            />
                        </PieChart>
                    </ResponsiveContainer>
                </Col>

            </Row>

            {/* Flujo de Caja */}
            <Row>
                <Col>
                    <h5 className='fw-bold mb-2'>Flujo de Caja</h5>
                    <div className='d-flex justify-content-end w-100 mb-3 text-center text-md-center'>
                        <Form.Group controlId='filterSelect' className='d-flex align-items-center'>
                            <Form.Label className='me-2 mb-0'>Filtrar:</Form.Label>
                            <Form.Control
                                as='select'
                                value={filter}
                                onChange={(e) => setFilter(e.target.value)}
                                size='sm'
                            >
                                <option value="semana">Semana</option>
                                <option value="mes">Mes</option>
                                <option value="ano">Año</option>
                            </Form.Control>
                        </Form.Group>
                    </div>
                    <ResponsiveContainer width="100%" height={300}>
                        <LineChart data={flujoDeCajaData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey={filter === 'semana' ? 'semana' : filter === 'mes' ? 'mes' : 'ano'} />
                            <YAxis />
                            <Tooltip
                                content={({ active, payload, label }) => {
                                    if (active && payload && payload.length) {
                                        return (
                                            <div style={{
                                                backgroundColor: '#fff',
                                                border: '1px solid #ccc',
                                                padding: '10px',
                                                borderRadius: '5px'
                                            }}>
                                                <p><strong>{`Periodo: ${label}`}</strong></p>
                                                {payload.map((entry, index) => (
                                                    <p key={`item-${index}`} style={{ color: entry.color }}>
                                                        {`${entry.name}: ${formatCOP(entry.value)}`}
                                                    </p>
                                                ))}
                                            </div>
                                        );
                                    }
                                    return null;
                                }}
                            />
                            <Legend />
                            <Line type="monotone" dataKey="ingresos" stroke="#2fb380" name="Ingresos" />
                            <Line type="monotone" dataKey="egresos" stroke="#da292e" name="Egresos" />
                        </LineChart>
                    </ResponsiveContainer>
                </Col>
            </Row>
        </Container>
    );
};

export default ReporteFinanciero;
