import React, { useState } from 'react';
import { FormControl, Button, ButtonGroup } from 'react-bootstrap';
import { Search, XSquare } from 'react-bootstrap-icons';

function FiltrosClientes({ onFilterChange }) {
    const [filters, setFilters] = useState({
        clienteID: '',
        nomCliente: '',
        numCliente: '',
        correo: '',
        departamento: '',
        ciudad: '',
        pais: ''
    });

    const handleChange = (e) => {
        setFilters({
            ...filters,
            [e.target.name]: e.target.value
        });
    };

    const handleSearch = () => {
        onFilterChange(filters);
    };

    const handleClear = () => {
        setFilters({
            clienteID: '',
            nomCliente: '',
            numCliente: '',
            correo: '',
            departamento: '',
            ciudad: '',
            pais: ''
        });
        onFilterChange({
            clienteID: '',
            nomCliente: '',
            numCliente: '',
            correo: '',
            departamento: '',
            ciudad: '',
            pais: ''
        });
    };

    return (
        <div className='d-flex align-items-center m-3'>
            <FormControl
                name='clienteID'
                placeholder='Busca por Identificación'
                className='me-2'
                value={filters.clienteID}
                onChange={handleChange}
            />
            <FormControl
                name='nomCliente'
                placeholder='Busca por Nombre'
                className='me-2'
                value={filters.nomCliente}
                onChange={handleChange}
            />
            <FormControl
                name='numCliente'
                placeholder='Busca por Celular'
                className='me-2'
                value={filters.numCliente}
                onChange={handleChange}
            />
            <FormControl
                name='correo'
                placeholder='Busca por Correo'
                className='me-2'
                value={filters.correo}
                onChange={handleChange}
            />
            <FormControl
                name='departamento'
                placeholder='Busca por Dep.'
                className='me-2'
                value={filters.departamento}
                onChange={handleChange}
            />
            <FormControl
                name='ciudad'
                placeholder='Busca por Ciudad'
                className='me-2'
                value={filters.ciudad}
                onChange={handleChange}
            />
            <FormControl
                name='pais'
                placeholder='Busca por País'
                className='me-2'
                value={filters.pais}
                onChange={handleChange}
            />
            <ButtonGroup>
                <Button variant='primary' title='Buscar' onClick={handleSearch}><Search /></Button>
                <Button variant='danger' title='Limpiar' onClick={handleClear}><XSquare /></Button>
            </ButtonGroup>
        </div>
    );
}

export default FiltrosClientes;
