import React, { useState, useEffect } from 'react';
import { Table, Pagination, Button, Form, ButtonGroup } from 'react-bootstrap';
import { Filter, EyeFill, PencilFill, TrashFill } from 'react-bootstrap-icons';
import FiltrosProductos from './filtroProductos';
import EditProductoModal from './editProductoModal';
import VerProductoModal from './verProductoModal';
import { handleDelete } from '../utils/AlertsProductos';
import APIserver from '../../../../api/axiosConfig';

// Función para formatear valores como moneda COP
const formatCop = (value) => {
  return value?.toLocaleString('es-CO', {
    style: 'currency',
    currency: 'COP',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }) || '$0';
};

function TablaProductos() {
  const [showFilters, setShowFilters] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [editShow, setShowEdit] = useState(false);
  const [verShow, setShowVer] = useState(false);
  const [selectedProducto, setSelectedProducto] = useState({});
  const [productos, setProductos] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isFiltering, setIsFiltering] = useState(false);

  useEffect(() => {
    fetchProductos(currentPage, rowsPerPage);
  }, [currentPage, rowsPerPage]);

  const fetchProductos = async (page, limit, filters = {}) => {
    let url = `/api/productos`;
    const params = new URLSearchParams(filters);

    if (params.toString()) {
      url += `?${params.toString()}`;
      setIsFiltering(true);
    } else {
      url += `?page=${page}&limit=${limit}`;
      setIsFiltering(false);
    }

    try {
      const response = await APIserver.get(url);
      setProductos(response.data.products);
      const totalCount = response.data.totalCount;
      setTotalPages(Math.ceil(totalCount / limit));
    } catch (error) {
      console.error('Error al obtener los productos:', error);
    }
  };

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const handleEditClose = () => {
    setShowEdit(false);
    fetchProductos(currentPage, rowsPerPage); // Volver a cargar la lista de productos después de editar
  };

  const handleEditShow = (producto) => {
    setSelectedProducto({
      ...producto,
      imagenes: producto.imagenes || [] // Asegúrate de que existe la propiedad `imagenes`
    });
    setShowEdit(true);
  };


  const handleVerClose = () => setShowVer(false);
  const handleVerShow = (producto) => {
    setSelectedProducto(producto);
    setShowVer(true);
  };

  const handleDeleteProducto = async (productId) => {
    await handleDelete(productId, () => {
      if (currentPage > totalPages) {
        setCurrentPage(totalPages);
      } else {
        fetchProductos(currentPage, rowsPerPage);
      }
    });
  };

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleFilterChange = (filters) => {
    fetchProductos(1, rowsPerPage, filters);
  };
  const renderPaginationItems = () => {
    const items = [];
    const pageRangeDisplayed = 5; // Mostrar 5 páginas a la vez
    let startPage = Math.max(1, currentPage - Math.floor(pageRangeDisplayed / 2));
    let endPage = Math.min(totalPages, startPage + pageRangeDisplayed - 1);

    if (endPage - startPage < pageRangeDisplayed - 1) {
      startPage = Math.max(1, endPage - pageRangeDisplayed + 1);
    }

    if (startPage > 1) {
      items.push(<Pagination.First key="first" onClick={() => handlePageChange(1)} />);
    }

    if (currentPage > 1) {
      items.push(<Pagination.Prev key="prev" onClick={() => handlePageChange(currentPage - 1)} />);
    }

    for (let number = startPage; number <= endPage; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => handlePageChange(number)}
        >
          {number}
        </Pagination.Item>
      );
    }

    if (currentPage < totalPages) {
      items.push(<Pagination.Next key="next" onClick={() => handlePageChange(currentPage + 1)} />);
    }

    if (endPage < totalPages) {
      items.push(<Pagination.Last key="last" onClick={() => handlePageChange(totalPages)} />);
    }

    return items;
  };

  return (
    <div style={{ boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)" }}>
      <Table responsive className='text-center align-middle border'>
        <thead>
          <tr>
            <th colSpan={9}>
              <div className='d-flex justify-content-between align-items-center'>
                <div className='d-flex flex-wrap'>
                  <Button variant='link' size='sm' onClick={toggleFilters} title='Filtrar'>
                    <Filter className='m-0' size={20} />
                  </Button>
                </div>
                <div>
                  <Form.Select
                    className='ms-2'
                    value={rowsPerPage}
                    onChange={handleChangeRowsPerPage}
                  >
                    <option value='5'>5</option>
                    <option value='10'>10</option>
                    <option value='20'>20</option>
                    <option value='50'>50</option>
                  </Form.Select>
                </div>
              </div>
              <div className={`filter-options ${showFilters ? 'show' : ''}`}>
                <FiltrosProductos onFilterChange={handleFilterChange} />
              </div>
            </th>
          </tr>
          <tr>
            <th>Referencia</th>
            <th>Imagen</th>
            <th>Nombre</th>
            <th>Categoria</th>
            <th>Stock</th>
            <th>P.Unidad</th>
            <th>P.Mayor</th>
            <th>Total</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {productos.map(producto => (
            <tr key={producto.idProducto}>
              <td>{producto.referencia}</td>
              <td>
                <div style={{ width: 50, height: 50, overflow: 'hidden' }}>
                  <img src={`${producto.imagen}`} alt={producto.nombre} style={{ maxWidth: '100%', height: 'auto' }} />
                </div>
              </td>
              <td>{producto.nombre}</td>
              <td>{producto.categoria}</td>
              <td>{producto.stock}</td>
              <td>{formatCop(producto.precioUnidad)}</td>
              <td>{formatCop(producto.precioMayor)}</td>
              <td>{formatCop(producto.totalProducto)}</td>
              <td>
                <ButtonGroup aria-label='action-button'>
                  <Button variant='link' className='text-primary' title='ver producto' onClick={() => handleVerShow(producto)}>
                    <EyeFill size={18} />
                  </Button>
                  <Button variant='link' className='text-warning' title='editar producto' onClick={() => handleEditShow(producto)}>
                    <PencilFill size={18} />
                  </Button>
                  <Button variant='link' className='text-danger' title='eliminar producto' onClick={() => handleDeleteProducto(producto.idProducto)}>
                    <TrashFill size={18} />
                  </Button>
                </ButtonGroup>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          {!isFiltering && (
            <tr>
              <td colSpan={9}>
                <div className='d-flex justify-content-center align-items-center'>
                  <Pagination>
                    {renderPaginationItems()}
                  </Pagination>
                </div>
              </td>
            </tr>
          )}
        </tfoot>
      </Table>

      <EditProductoModal show={editShow} handleClose={handleEditClose} producto={selectedProducto} />
      <VerProductoModal show={verShow} handleClose={handleVerClose} productoId={selectedProducto.idProducto} />

    </div>
  );
}

export default TablaProductos;
