import React from 'react';
import { Table, Row, Col } from 'react-bootstrap';

function InfoClientePagoRes({ pago }) {
    // Asegúrate de que los datos están disponibles y se usan correctamente
    const { 
        nomCliente = '', 
        clienteID = '', 
        numCliente = '', 
        direccion = '', 
        ciudad = '', 
        departamento ='',
        fechaPago = null, 
        tipoPago = '' 
    } = pago || {}; // Asegúrate de que 'pago' no sea undefined

    return (
        <Row className='print-flex'>
            <Col md={8} className='print-half'>
                <Table responsive bordered size="sm" className='shadow-none'>
                    <tbody>
                        <tr>
                            <th className='bg-primary-subtle'>Realizó Pago</th>
                            <td colSpan="3">{nomCliente}</td>
                        </tr>
                        <tr>
                            <th className='bg-primary-subtle'>NIT</th>
                            <td>{clienteID}</td>
                            <th className='bg-primary-subtle'>Teléfono</th>
                            <td>{numCliente}</td>
                        </tr>
                        <tr>
                            <th className='bg-primary-subtle'>Dirección</th>
                            <td>{ direccion}</td>
                            <th className='bg-primary-subtle'>Ciudad</th>
                            <td>{ciudad} - {departamento}</td>
                        </tr>
                    </tbody>
                </Table>
            </Col>
            <Col className='print-half'>
                <Table bordered size="sm" className='shadow-none'>
                    <tbody>
                        <tr>
                            <th className='bg-primary-subtle'>Fecha pago</th>
                            <td>{fechaPago ? new Date(fechaPago).toLocaleDateString() : 'N/A'}</td>
                        </tr>
                        <tr>
                            <th className='bg-primary-subtle'>Forma de pago</th>
                            <td>{tipoPago}</td>
                        </tr>
                    </tbody>
                </Table>
            </Col>
        </Row>
    );
}

export default InfoClientePagoRes;
