import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import '../../styles/printComprobante.css';
import InfoClientePagoRes from './comprobante/infoClientePagoRes';
import InfoCompPagoRes from './comprobante/infoCompPagoRes';
import InfoDetallesPagoRes from './comprobante/infoDetallesPagoRes';

function ComprobantePagoRes({ pago, numPagoRe }) {
    return (
        <div className='m-4'>
            <Card className='table-responsive comprobante-container shadow-none'>
                <Card.Body>
                    {/* Info company */}
                    <InfoCompPagoRes pago={pago} numPagoRe={numPagoRe}/>
                    {/* Informacion Cliente */}
                    <InfoClientePagoRes pago={pago} />
                    {/* Info detalles pago recibido */}
                    <InfoDetallesPagoRes pago={pago} />
                    <Row className='mt-4 text-center'>
                        <Col className='mt-5'>
                            <hr className='mt-5 mx-5 border border-black' />
                            <p>Firma elaborado</p>
                        </Col>
                        <Col className='mt-5'>
                            <hr className='mt-5 mx-5 border border-black' />
                            <p>Firma recibido</p>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </div>
    );
}

export default ComprobantePagoRes;
