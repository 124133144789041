import React, { useState, useEffect } from 'react';
import { Table, Pagination, Button, Form, ButtonGroup } from 'react-bootstrap';
import { Filter, EyeFill, PencilFill, TrashFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import FiltrosPagosRes from './filtroPagosRes';
import EditarPagoRes from './editarPagoRes';
import AlertsPagosRecibidos from './Utils/AlertsPagosRecibidos';
import APIserver from '../../../../api/axiosConfig';

function TablaPagosRes() {
    const [showFilters, setShowFilters] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [editShow, setShowEdit] = useState(false);
    const [pagoToEdit, setPagoToEdit] = useState(null); // Estado para almacenar el pago a editar
    const [pagos, setPagos] = useState([]); // Estado para almacenar los pagos recibidos
    const [currentPage, setCurrentPage] = useState(1); // Estado para la página actual de la paginación
    const [hasMore, setHasMore] = useState(true); // Estado para verificar si hay más datos
    const [filters, setFilters] = useState({}); // Estado para los filtros
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        fetchPagos();
    }, [rowsPerPage, currentPage, filters]);

    const fetchPagos = async () => {
        try {
            const response = await APIserver.get(`/api/pagosrecibidos/pagos`, {
                params: {
                    limit: rowsPerPage,
                    offset: (currentPage - 1) * rowsPerPage,
                    ...filters
                }
            });
            const { results, totalPages } = response.data;
            setPagos(results);
            setTotalPages(totalPages); // Usar el totalPages que ahora viene del backend
        } catch (error) {
            console.error('Error al obtener los pagos:', error);
        }
    };    

    const handleSearch = (newFilters) => {
        setFilters(newFilters);
        setCurrentPage(1); // Resetear a la primera página al aplicar filtros
    };

    const handleClear = () => {
        setFilters({});
        setCurrentPage(1); // Resetear a la primera página al limpiar filtros
    };

    const toggleFilters = () => {
        setShowFilters(!showFilters);
    };
    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setCurrentPage(1); // Resetear a la primera página al cambiar el número de filas por página
    };

    const handleEditClose = () => {
        setShowEdit(false);
        setPagoToEdit(null); // Limpiar los datos del pago al cerrar el modal
    };

    const handleEditShow = (pago) => {
        setPagoToEdit(pago); // Establecer el pago a editar
        setShowEdit(true);
    };

    const handleDelete = (idPago) => {
        AlertsPagosRecibidos.showDeleteConfirmation(idPago, async () => {
            try {
                await APIserver.delete(`/api/pagosrecibidos/pagos/${idPago}`);
                AlertsPagosRecibidos.showDeleteConfirmationserver();
                fetchPagos(); // Refrescar la lista después de la eliminación
            } catch (error) {
                AlertsPagosRecibidos.showDeleteErrorMessage();
                console.error('Error al eliminar el pago:', error);
            }
        });
    };

    const renderPaginationItems = () => {
        const items = [];
        const pageRangeDisplayed = 5;
        const halfRange = Math.floor(pageRangeDisplayed / 2);
    
        let startPage = Math.max(1, currentPage - halfRange);
        let endPage = Math.min(totalPages, currentPage + halfRange);
    
        if (startPage > 1) {
            items.push(<Pagination.First key="first" onClick={() => setCurrentPage(1)} />);
            items.push(<Pagination.Prev key="prev" onClick={() => setCurrentPage(currentPage - 1)} />);
        }
    
        for (let number = startPage; number <= endPage; number++) {
            items.push(
                <Pagination.Item
                    key={number}
                    active={number === currentPage}
                    onClick={() => setCurrentPage(number)}
                >
                    {number}
                </Pagination.Item>
            );
        }
    
        if (currentPage < totalPages) {
            items.push(<Pagination.Next key="next" onClick={() => setCurrentPage(currentPage + 1)} />);
            items.push(<Pagination.Last key="last" onClick={() => setCurrentPage(totalPages)} />);
        }
    
        return items;
    };    

    return (
        <div style={{ boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)" }}>
            <Table responsive className='text-center align-middle border'>
                <thead>
                    <tr>
                        <th colSpan={8}>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='d-flex flex-wrap'>
                                    <Button variant='link' size='sm' onClick={toggleFilters} title='filtrar'>
                                        <Filter className='m-0' size={20} />
                                    </Button>
                                </div>
                                <div>
                                    <Form.Select
                                        className='ms-2'
                                        value={rowsPerPage}
                                        onChange={handleChangeRowsPerPage}
                                    >
                                        <option value='5'>5</option>
                                        <option value='10'>10</option>
                                        <option value='20'>20</option>
                                        <option value='50'>50</option>
                                    </Form.Select>
                                </div>
                            </div>
                            <div className={`filter-options ${showFilters ? 'show' : ''}`}>
                                {/* FILTROS DE TABLA */}
                                <FiltrosPagosRes onSearch={handleSearch} onClear={handleClear} />
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <th>Pago</th>
                        <th>Factura</th>
                        <th>Cliente</th>
                        <th>Observaciones</th>
                        <th>Creacion</th>
                        <th>Cuenta</th>
                        <th>Monto</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {pagos.map((pago) => (
                        <tr key={pago.idPago}>
                            <td>
                                <Link to={`/admin/PagosRecibidos/DetallesPago/${pago.idPago}/`} className='text-decoration-none'>
                                    {pago.numPagoRe}
                                </Link>
                            </td>
                            <td>
                                <Link to={`/admin/Facturas/DetallesFactura/${pago.idFacV}/`} className='text-decoration-none'>
                                    {pago.numFacV}
                                </Link>
                            </td>
                            <td>
                                <Link to={`/admin/Clientes/DetallesClientes/${pago.idCliente}/`} className='text-decoration-none'>
                                    {pago.nomCliente}
                                </Link>
                            </td>
                            <td>{pago.detalle}</td>
                            <td>{new Date(pago.fecha).toLocaleDateString()}</td>
                            <td>
                                <Link to={`/admin/Bancos/DetallesBancos/${pago.idBanco}/`} className='text-decoration-none'>
                                    {pago.nomBanco}
                                </Link>
                            </td>
                            <td>${pago.monto.toLocaleString()}</td>
                            <td>
                                <ButtonGroup aria-label='action-button'>
                                    <Link to={`/admin/PagosRecibidos/DetallesPago/${pago.idPago}/`}>
                                        <Button variant='link' className='text-primary' title='ver pago'>
                                            <EyeFill size={18} />
                                        </Button>
                                    </Link>
                                    <Button
                                        variant='link'
                                        className='text-warning'
                                        title='editar pago'
                                        onClick={() => handleEditShow(pago)}
                                    >
                                        <PencilFill size={18} />
                                    </Button>
                                    <Button variant='link' className='text-danger' title='eliminar pago' onClick={() => handleDelete(pago.idPago)}>
                                        <TrashFill size={18} />
                                    </Button>
                                </ButtonGroup>
                            </td>
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan={8}>
                            <div className='d-flex justify-content-center align-items-center'>
                                <Pagination>
                                    {renderPaginationItems()}
                                </Pagination>
                            </div>
                        </td>
                    </tr>
                </tfoot>
            </Table>
            {/* Modal Editar Pago */}
            <EditarPagoRes
                show={editShow}
                handleClose={handleEditClose}
                pago={pagoToEdit} // Pasar el pago a editar
                fetchPagos={fetchPagos} // Pasar la función fetchPagos para actualizar la lista
            />
        </div>
    );
}

export default TablaPagosRes;