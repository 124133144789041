import React, { useState, useEffect } from 'react';
import { Card, Form, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import Departamentos from '../../../../utils/places-colombia';
import Paises from '../../../../utils/paises';

function EditarClientePedido({ cliente }) {
    // Define los hooks de estado antes de cualquier retorno o condición
    const [pedido, setPedido] = useState(cliente || {});
    const [departamentoOptions, setDepartamentoOptions] = useState([]);
    const [ciudadOptions, setCiudadOptions] = useState([]);
    const [paisOptions, setPaisOptions] = useState([]);

    useEffect(() => {
        const depOptions = Departamentos.map(dep => ({ value: dep.departamento, label: dep.departamento }));
        setDepartamentoOptions(depOptions);

        const paisesOptions = Paises.map(pais => ({ value: pais.es_name, label: pais.es_name }));
        setPaisOptions(paisesOptions);

        // Set initial cities based on default department
        if (cliente?.depCliente) {
            const initialDep = Departamentos.find(dep => dep.departamento === cliente.depCliente.value);
            if (initialDep) {
                setCiudadOptions(initialDep.ciudades.map(ciudad => ({ value: ciudad, label: ciudad })));
            }
        }
    }, [cliente]);

    // Si el cliente aún no está cargado, mostrar un mensaje de carga
    if (!cliente) return <div>Cargando...</div>;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPedido(prevPedido => ({
            ...prevPedido,
            [name]: value
        }));
    };

    const handleSelectChange = (selectedOption, name) => {
        setPedido(prevPedido => ({
            ...prevPedido,
            [name]: selectedOption
        }));
    };

    const handleDepartamentoChange = (selectedOption) => {
        setPedido(prevPedido => ({
            ...prevPedido,
            depCliente: selectedOption,
            ciudadCliente: { value: '', label: '' } // Reset city when department changes
        }));

        const selectedDep = Departamentos.find(dep => dep.departamento === selectedOption.value);
        if (selectedDep) {
            setCiudadOptions(selectedDep.ciudades.map(ciudad => ({ value: ciudad, label: ciudad })));
        }
    };

    return (
        <Card className='shadow-none'>
            <Card.Header className='fw-bold'>Detalles Cliente</Card.Header>
            <Card.Body>
                <Form>
                    <Row>
                        <Col xs={12} md={4}>
                            <Form.Group className='mt-0'>
                                <Form.Label className='w-100'>NIT
                                    <Form.Control
                                        type='text'
                                        name='clienteID'
                                        value={pedido.clienteID || ''}
                                        onChange={handleChange}
                                        className='mt-2'
                                    />
                                </Form.Label>
                            </Form.Group>
                            <Form.Group className='mt-2'>
                                <Form.Label className='w-100'>Correo Electrónico
                                    <Form.Control
                                        type='text'
                                        name='correoCliente'
                                        value={pedido.correoCliente || ''}
                                        onChange={handleChange}
                                        className='mt-2'
                                    />
                                </Form.Label>
                            </Form.Group>
                            <Form.Group className='mt-2'>
                                <Form.Label className='w-100'>Ciudad
                                    <Select
                                        name='ciudadCliente'
                                        value={pedido.ciudad || null}
                                        onChange={(option) => handleSelectChange(option, 'ciudadCliente')}
                                        options={ciudadOptions}
                                        className='mt-2'
                                    />
                                </Form.Label>
                            </Form.Group>
                            <Form.Group className='mt-2'>
                                <Form.Label className='w-100'>Asesor
                                    <Form.Control
                                        type='text'
                                        name='asesorSucursal'
                                        value={pedido.asesorSucursal || ''}
                                        onChange={handleChange}
                                        className='mt-2'
                                    />
                                </Form.Label>
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={4}>
                            <Form.Group className='mt-0'>
                                <Form.Label className='w-100'>Cliente
                                    <Form.Control
                                        type='text'
                                        name='nomCliente'
                                        value={pedido.nomCliente || ''}
                                        onChange={handleChange}
                                        className='mt-2'
                                    />
                                </Form.Label>
                            </Form.Group>
                            <Form.Group className='mt-2'>
                                <Form.Label className='w-100'>Dirección
                                    <Form.Control
                                        type='text'
                                        name='dirCliente'
                                        value={pedido.direccion || ''}
                                        onChange={handleChange}
                                        className='mt-2'
                                    />
                                </Form.Label>
                            </Form.Group>
                            <Form.Group className='mt-2'>
                                <Form.Label className='w-100'>País
                                    <Select
                                        name='paisCliente'
                                        value={pedido.pais || null}
                                        onChange={(option) => handleSelectChange(option, 'paisCliente')}
                                        options={paisOptions}
                                        className='mt-2'
                                    />
                                </Form.Label>
                            </Form.Group>
                            <Form.Group className='mt-2'>
                                <Form.Label className='w-100'>Tipo
                                    <Select
                                        name='tipoPedido'
                                        value={pedido.tipoPedido || null}
                                        onChange={(option) => handleSelectChange(option, 'tipoPedido')}
                                        options={[
                                            { value: 'distribucion', label: 'Distribución' },
                                            { value: 'venta', label: 'Venta al público' }
                                        ]}
                                        className='mt-2'
                                    />
                                </Form.Label>
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={4}>
                            <Form.Group className='mt-0'>
                                <Form.Label className='w-100'>Celular
                                    <Form.Control
                                        type='text'
                                        name='celCliente'
                                        value={pedido.numCliente || ''}
                                        onChange={handleChange}
                                        className='mt-2'
                                    />
                                </Form.Label>
                            </Form.Group>
                            <Form.Group className='mt-2'>
                                <Form.Label className='w-100'>Departamento
                                    <Select
                                        name='depCliente'
                                        value={pedido.departamento || null}
                                        onChange={handleDepartamentoChange}
                                        options={departamentoOptions}
                                        className='mt-2'
                                    />
                                </Form.Label>
                            </Form.Group>
                            <Form.Group className='mt-2'>
                                <Form.Label className='w-100'>Elaboración
                                    <Form.Control
                                        type='text'
                                        name='creacionPedido'
                                        value={pedido.creacionPedido || ''}
                                        onChange={handleChange}
                                        className='mt-2'
                                    />
                                </Form.Label>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Card.Body>
        </Card>
    );
}

export default EditarClientePedido;
