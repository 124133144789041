import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Card } from 'react-bootstrap';
import { PlusCircle, TrashFill } from 'react-bootstrap-icons';
import Swal from 'sweetalert2';
import APIserver from '../../../../api/axiosConfig';

// Componente para mostrar los atributos (tallas o colores)
const AttributeCard = ({ attribute, onDelete, type }) => (
    <Card
        key={attribute.id}
        className="mb-3 me-3 position-relative shadow-sm hover-shadow-sm transition-shadow"
        style={{ width: '7.8rem' }}
    >
        <Card.Body className="d-flex flex-column align-items-center text-center">
            <Card.Text className="fw-bold mb-2">{attribute.name}</Card.Text>
            {type === 'color' && (
                <div
                    className="border rounded-circle mb-2"
                    style={{ width: '1.5rem', height: '1.5rem', backgroundColor: attribute.hexCode }}
                />
            )}
        </Card.Body>
        <Button
            variant="danger"
            size="sm"
            className="rounded-circle p-0 position-absolute top-0 end-0 shadow-sm"
            style={{ width: '1.5rem', height: '1.5rem', margin: '0.5rem' }}
            onClick={() => onDelete(attribute.id)}
        >
            <TrashFill style={{ fontSize: '0.8rem' }} />
        </Button>
    </Card>
);

////// TALLAS MODAL //////
export const TallasModal = ({ showTallas, handleCloseTallas }) => {
    const [tallas, setTallas] = useState([]);
    const [newTalla, setNewTalla] = useState('');
    const [error, setError] = useState('');

    const fetchTallas = async () => {
        try {
            const response = await APIserver.get(`/api/atributos-product/tallas`);
            setTallas(response.data);
        } catch (error) {
            console.error('Error al obtener tallas:', error);
        }
    };

    useEffect(() => {
        fetchTallas();
    }, []);

    const handleAddTalla = async () => {
        if (!newTalla) {
            setError('El nombre de la talla es obligatorio.');
            return;
        }

        setError('');
        try {
            await APIserver.post(`/api/atributos-product/tallas`, { nombreTalla: newTalla });
            setNewTalla('');

            // Alerta de éxito
            await Swal.fire({
                title: 'Talla creada con éxito',
                icon: 'success',
                confirmButtonText: 'Aceptar',
                allowOutsideClick: false,
                iconColor: '#2fb380',
                confirmButtonColor: "#2c4cc4"
            });

            // Preguntar si quiere crear otra talla
            const result = await Swal.fire({
                title: '¿Deseas crear otra talla?',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Sí',
                cancelButtonText: 'No',
                allowOutsideClick: false,
                iconColor: '#f4bd61',
                confirmButtonColor: '#2c4cc4',
                cancelButtonColor: '#da292e',
            });

            if (!result.isConfirmed) {
                window.location.reload();  // Recargar la página
            }

            fetchTallas();
        } catch (error) {
            console.error('Error al agregar talla:', error);
        }
    };

    const handleDeleteTalla = async (idTalla) => {
        // Mostrar confirmación antes de eliminar
        const result = await Swal.fire({
            title: '¿Estás seguro?',
            text: "¡No podrás revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar',
            allowOutsideClick: false,
            iconColor: '#f4bd61',
            confirmButtonColor: '#2c4cc4',
            cancelButtonColor: '#da292e'
        });

        if (result.isConfirmed) {
            try {
                await APIserver.delete(`/api/atributos-product/tallas/${idTalla}`);

                // Alerta de éxito y recarga de página
                await Swal.fire({
                    title: 'Eliminado',
                    text: 'La talla ha sido eliminada.',
                    icon: 'success',
                    iconColor: '#2fb380',
                    confirmButtonColor: "#2c4cc4"
                });
                window.location.reload();  // Recargar la página
            } catch (error) {
                console.error('Error al eliminar talla:', error);
            }
        }
    };

    return (
        <Modal show={showTallas} onHide={handleCloseTallas} centered size="lg" className="fade">
            <Modal.Header closeButton>
                <Modal.Title>Motivos</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label className="fw-bold mb-2">Nuevo Motivo</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Ejemplo: S, M, L, XL"
                            value={newTalla}
                            onChange={(e) => setNewTalla(e.target.value)}
                            className={`p-3 rounded-3 ${error ? "border-danger" : "border-secondary"}`}
                        />
                        <Button
                            variant="primary"
                            onClick={handleAddTalla}
                            className="mt-3 w-100 fw-bold py-2 rounded-pill"
                        >
                            <PlusCircle className="me-2" /> Crear Motivo
                        </Button>
                        {error && <p className="text-danger mt-2">{error}</p>}
                    </Form.Group>
                </Form>

                <hr className="my-4" />

                <h5 className="mb-3">Motivos Existentes</h5>

                <div className="d-flex flex-wrap justify-content-center align-items-center">
                    {tallas.map(talla => (
                        <AttributeCard
                            key={talla.idTalla}
                            attribute={{ id: talla.idTalla, name: talla.nombreTalla }}
                            onDelete={handleDeleteTalla}
                            type="talla"
                        />
                    ))}
                </div>
            </Modal.Body>
        </Modal>
    );
};

////// COLORES MODAL //////
export const ColoresModal = ({ showColor, handleCloseColor }) => {
    const [colores, setColores] = useState([]);
    const [newColor, setNewColor] = useState('');
    const [hexCode, setHexCode] = useState('#000000');
    const [error, setError] = useState('');

    const fetchColores = async () => {
        try {
            const response = await APIserver.get(`/api/atributos-product/colores`);
            setColores(response.data);
        } catch (error) {
            console.error('Error al obtener colores:', error);
        }
    };

    useEffect(() => {
        fetchColores();
    }, []);

    const handleAddColor = async () => {
        if (!newColor) {
            setError('El nombre del color es obligatorio.');
            return;
        }

        setError('');
        try {
            await APIserver.post(`/api/atributos-product/colores`, { nombreColor: newColor, hexCode });
            setNewColor('');
            setHexCode('#000000');

            // Alerta de éxito
            await Swal.fire({
                title: 'Color creado con éxito',
                icon: 'success',
                confirmButtonText: 'Aceptar',
                allowOutsideClick: false,
                iconColor: '#2fb380',
                confirmButtonColor: "#2c4cc4"
            });

            // Preguntar si quiere crear otro color
            const result = await Swal.fire({
                title: '¿Deseas crear otro color?',
                icon: 'question',
                showCancelButton: true,
                confirmButtonText: 'Sí',
                cancelButtonText: 'No',
                allowOutsideClick: false,
                iconColor: '#f4bd61',
                confirmButtonColor: '#2c4cc4',
                cancelButtonColor: '#da292e'
            });

            if (!result.isConfirmed) {
                window.location.reload();  // Recargar la página
            }

            fetchColores();
        } catch (error) {
            console.error('Error al agregar color:', error);
        }
    };

    const handleDeleteColor = async (idColor) => {
        // Mostrar confirmación antes de eliminar
        const result = await Swal.fire({
            title: '¿Estás seguro?',
            text: "¡No podrás revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar',
            allowOutsideClick: false,
            iconColor: '#f4bd61',
            confirmButtonColor: '#2c4cc4',
            cancelButtonColor: '#da292e'
        });

        if (result.isConfirmed) {
            try {
                await APIserver.delete(`/api/atributos-product/colores/${idColor}`);

                // Alerta de éxito y recarga de página
                await Swal.fire({
                    title: 'Eliminado',
                    text: 'El color ha sido eliminado.',
                    icon: 'success',
                    iconColor: '#2fb380',
                    confirmButtonColor: "#2c4cc4"
                });
                window.location.reload();  // Recargar la página
            } catch (error) {
                console.error('Error al eliminar color:', error);
            }
        }
    };

    return (
        <Modal show={showColor} onHide={handleCloseColor} centered size="lg" className="fade">
            <Modal.Header closeButton>
                <Modal.Title>Colores</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label className="fw-bold mb-2">Nuevo Color</Form.Label>
                        <div className="d-flex align-items-center">
                            <Form.Control
                                type="text"
                                placeholder="Ejemplo: Rojo, Azul, Verde"
                                value={newColor}
                                onChange={(e) => setNewColor(e.target.value)}
                                className={`p-3 rounded-3 ${error ? "border-danger" : "border-secondary"}`}
                            />
                            <Form.Control
                                type="color"
                                value={hexCode}
                                onChange={(e) => setHexCode(e.target.value)}
                                className="ms-2"
                                style={{ width: '70px' }}
                            />
                        </div>
                        <Button
                            variant="primary"
                            onClick={handleAddColor}
                            className="mt-3 w-100 fw-bold py-2 rounded-pill"
                        >
                            <PlusCircle className="me-2" /> Agregar Color
                        </Button>
                        {error && <p className="text-danger mt-2">{error}</p>}
                    </Form.Group>
                </Form>

                <hr className="my-4" />

                <h5 className="mb-3">Colores Existentes</h5>

                <div className="d-flex flex-wrap justify-content-center align-items-center">
                    {colores.map(color => (
                        <AttributeCard
                            key={color.idColor}
                            attribute={{ id: color.idColor, name: color.nombreColor, hexCode: color.hexCode }}
                            onDelete={handleDeleteColor}
                            type="color"
                        />
                    ))}
                </div>
            </Modal.Body>
        </Modal>
    );
};
