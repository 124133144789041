import React, { useState } from 'react';
import { FormControl, Button, ButtonGroup } from 'react-bootstrap';
import { Search, XSquare } from 'react-bootstrap-icons';

function FiltrosPagosRes({ onSearch, onClear }) {
    const [filtroPago, setFiltroPago] = useState('');
    const [filtroFactura, setFiltroFactura] = useState('');
    const [filtroCliente, setFiltroCliente] = useState('');
    const [filtroCreacion, setFiltroCreacion] = useState('');
    const [filtroCuenta, setFiltroCuenta] = useState('');
    const [filtroMonto, setFiltroMonto] = useState('');

    const handleSearch = () => {
        onSearch({
            pago: filtroPago,
            factura: filtroFactura,
            cliente: filtroCliente,
            creacion: filtroCreacion,
            cuenta: filtroCuenta,
            monto: filtroMonto,
        });
    };

    const handleClear = () => {
        setFiltroPago('');
        setFiltroFactura('');
        setFiltroCliente('');
        setFiltroCreacion('');
        setFiltroCuenta('');
        setFiltroMonto('');
        onClear();
    };

    return (
        <div className='d-flex align-items-center m-3'>
            <FormControl
                placeholder='Busca por Pago'
                value={filtroPago}
                onChange={(e) => setFiltroPago(e.target.value)}
                className='me-2'
            />
            <FormControl
                placeholder='Busca por Factura'
                value={filtroFactura}
                onChange={(e) => setFiltroFactura(e.target.value)}
                className='me-2'
            />
            <FormControl
                placeholder='Busca por Cliente'
                value={filtroCliente}
                onChange={(e) => setFiltroCliente(e.target.value)}
                className='me-2'
            />
            <FormControl
                type='date'
                value={filtroCreacion}
                onChange={(e) => setFiltroCreacion(e.target.value)}
                className='me-2'
            />
            <FormControl
                placeholder='Busca por Cuenta'
                value={filtroCuenta}
                onChange={(e) => setFiltroCuenta(e.target.value)}
                className='me-2'
            />
            <FormControl
                placeholder='Busca por Monto'
                value={filtroMonto}
                onChange={(e) => setFiltroMonto(e.target.value)}
                className='me-2'
            />
            <ButtonGroup>
                <Button variant='primary' title='Buscar' onClick={handleSearch}><Search /></Button>
                <Button variant='danger' title='Limpiar' onClick={handleClear}><XSquare /></Button>
            </ButtonGroup>
        </div>
    );
}

export default FiltrosPagosRes;
