import React, { useState, useEffect } from 'react';
import {
    Modal,
    Button,
    Form,
    Row,
    Col
} from 'react-bootstrap';
import Select from 'react-select';
import { showUpdateSuccess, showUpdateError } from '../Utils/AlertsProveedores'; // Importa las funciones de alerta
import Departamentos from '../../../../utils/places-colombia';
import Paises from '../../../../utils/paises';
import APIserver from '../../../../api/axiosConfig';

function EditProveedor({ show, handleClose, proveedorId }) {
    const [proveedorData, setProveedorData] = useState({
        proveedorID: '',
        nomProveedor: '',
        razonSocial: '',
        numProveedor: '',
        ciudad: '',
        departamento: '',
        direccion: '',
        pais: ''
    });

    const [selectedDepartamento, setSelectedDepartamento] = useState(null);
    const [selectedCiudad, setSelectedCiudad] = useState(null);
    const [selectedPais, setSelectedPais] = useState(null);
    const [ciudadesOptions, setCiudadesOptions] = useState([]);

    useEffect(() => {
        if (proveedorId) {
            fetchProveedor(proveedorId);
        }
    }, [proveedorId]);

    const fetchProveedor = async (id) => {
        try {
            const response = await APIserver.get(`/api/proveedores/${id}`);
            const proveedor = response.data.proveedor;
            setProveedorData({
                proveedorID: proveedor.proveedorID,
                nomProveedor: proveedor.nomProveedor,
                razonSocial: proveedor.razonSocial,
                numProveedor: proveedor.numProveedor,
                ciudad: proveedor.ciudad,
                departamento: proveedor.departamento,
                direccion: proveedor.direccion,
                pais: proveedor.pais
            });
            setSelectedDepartamento({ value: proveedor.departamento, label: proveedor.departamento });
            setSelectedCiudad({ value: proveedor.ciudad, label: proveedor.ciudad });
            setSelectedPais({ value: proveedor.pais, label: proveedor.pais });
        } catch (error) {
            console.error('Error al obtener el proveedor:', error);
            // Manejar errores según tu lógica de aplicación
        }
    };

    useEffect(() => {
        if (selectedDepartamento) {
            const departamento = Departamentos.find(dep => dep.departamento === selectedDepartamento.value);
            const ciudades = departamento ? departamento.ciudades.map(ciudad => ({ value: ciudad, label: ciudad })) : [];
            setCiudadesOptions(ciudades);
        }
    }, [selectedDepartamento]);

    const handleDepartamentoChange = (selectedOption) => {
        if (selectedOption) {
            const departamento = Departamentos.find(dep => dep.departamento === selectedOption.value);
            const ciudades = departamento ? departamento.ciudades.map(ciudad => ({ value: ciudad, label: ciudad })) : [];
            setCiudadesOptions(ciudades);
            setSelectedCiudad(null);
        } else {
            setCiudadesOptions([]);
            setSelectedCiudad(null);
        }
        setSelectedDepartamento(selectedOption);
    };

    const handleCiudadChange = (selectedOption) => {
        setSelectedCiudad(selectedOption);
    };

    const handlePaisChange = (selectedOption) => {
        setSelectedPais(selectedOption);
    };

    const handleGuardar = async (e) => {
        e.preventDefault();
        const updatedProveedorData = {
            ...proveedorData,
            ciudad: selectedCiudad ? selectedCiudad.value : '',
            departamento: selectedDepartamento ? selectedDepartamento.value : '',
            pais: selectedPais ? selectedPais.value : ''
        };
        try {
            await APIserver.put(`/api/proveedores/${proveedorId}`, updatedProveedorData);
            handleClose(); // Cerrar el modal después de guardar exitosamente
            showUpdateSuccess(); // Mostrar alerta de éxito
            // Aquí podrías agregar lógica adicional después de guardar, como actualizar la lista de proveedores, etc.
        } catch (error) {
            console.error('Error al actualizar el proveedor:', error);
            showUpdateError(); // Mostrar alerta de error
            // Manejar errores según tu lógica de aplicación
        }
    };

    const departamentosOptions = Departamentos.map(departamento => ({ value: departamento.departamento, label: departamento.departamento }));
    const paisesOptions = Paises.map(pais => ({ value: pais.es_name, label: pais.es_name }));

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProveedorData({ ...proveedorData, [name]: value });
    };

    return (
        <Modal size='lg' show={show} onHide={handleClose} aria-labelledby="modal-editar-proveedor">
            <Modal.Header closeButton>
                <Modal.Title>Editar Proveedor</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleGuardar}>
                    <Row>
                        <Col>
                            <Form.Group className='m-3 mt-0' controlId='editProveedorID'>
                                <Form.Label>Identificación</Form.Label>
                                <Form.Control
                                    type='number'
                                    name='proveedorID'
                                    value={proveedorData.proveedorID}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>
                            <Form.Group className='m-3' controlId='editnomProveedor'>
                                <Form.Label>Nombre Completo</Form.Label>
                                <Form.Control
                                    type='text'
                                    name='nomProveedor'
                                    value={proveedorData.nomProveedor}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>
                            <Form.Group className='m-3' controlId='editDireccion'>
                                <Form.Label>Dirección</Form.Label>
                                <Form.Control
                                    type='text'
                                    name='direccion'
                                    value={proveedorData.direccion}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>
                            <Form.Group className='m-3' controlId='editCiudad'>
                                <Form.Label>Ciudad</Form.Label>
                                <Select
                                    options={ciudadesOptions}
                                    value={selectedCiudad}
                                    onChange={handleCiudadChange}
                                    placeholder='Seleccione'
                                    isClearable
                                    required
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className='m-3 mt-0' controlId='editCelular'>
                                <Form.Label>Celular</Form.Label>
                                <Form.Control
                                    type='number'
                                    name='numProveedor'
                                    value={proveedorData.numProveedor}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>
                            <Form.Group className='m-3' controlId='editRazonSocial'>
                                <Form.Label>Razón Social</Form.Label>
                                <Form.Control
                                    type='text'
                                    name='razonSocial'
                                    value={proveedorData.razonSocial}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>
                            <Form.Group className='m-3' controlId='editDepartamento'>
                                <Form.Label>Departamento</Form.Label>
                                <Select
                                    options={departamentosOptions}
                                    value={selectedDepartamento}
                                    onChange={handleDepartamentoChange}
                                    placeholder='Seleccione'
                                    isClearable
                                    required
                                />
                            </Form.Group>
                            <Form.Group className='m-3' controlId='editPais'>
                                <Form.Label>País</Form.Label>
                                <Select
                                    options={paisesOptions}
                                    value={selectedPais}
                                    onChange={handlePaisChange}
                                    placeholder='Seleccione un País'
                                    required
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Modal.Footer>
                        <Button variant='secondary' onClick={handleClose}>
                            Cancelar
                        </Button>
                        <Button variant='primary' type='submit'>
                            Guardar
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default EditProveedor;
