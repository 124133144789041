// File path: TablaSucursal.js
import React, { useState, useEffect } from 'react';
import { Table, Pagination, Button, Form, ButtonGroup, Toast, ToastContainer } from 'react-bootstrap';
import { Filter, Copy, PencilFill, TrashFill } from 'react-bootstrap-icons';
import { showConfirmDelete, showAlertSuccess, showAlertError, showAlertUpdateConfirmation } from '../Utils/AlertsSucursales';
import FiltrosSucursal from './filtrosSucursal';
import EditarSucursalModal from './editarSucursalModal';
import APIserver from '../../../../api/axiosConfig';

function TablaSucursal() {
  const [sucursales, setSucursales] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [editShow, setEditShow] = useState(false);
  const [editSucursalId, setEditSucursalId] = useState(null);
  const [copySuccess, setCopySuccess] = useState(false);

  useEffect(() => {
    fetchSucursales(currentPage, rowsPerPage);
  }, [currentPage, rowsPerPage]);

  // Función para cargar las sucursales con paginación
  const fetchSucursales = (page, limit) => {
    APIserver.get(`/api/sucursales`, {
      params: { page, limit }
    })
      .then(response => {
        setSucursales(response.data.sucursales);
        setTotalRows(response.data.total);
      })
      .catch(error => {
        console.error('Error al obtener las sucursales:', error);
      });
  };

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const handleCopyLink = (link) => {
    navigator.clipboard.writeText(link)
      .then(() => {
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 2000);
      })
      .catch(err => {
        console.error('Error al copiar el enlace:', err);
      });
  };

  const handleEditSucursal = (sucursalId) => {
    setEditSucursalId(sucursalId);
    setEditShow(true);
  };

  const handleDeleteSucursal = async (sucursalId) => {
    try {
      const confirmed = await showConfirmDelete();
      if (confirmed) {
        await APIserver.delete(`/api/sucursales/${sucursalId}`);
        showAlertSuccess('Sucursal eliminada exitosamente');
        fetchSucursales(currentPage, rowsPerPage);
      }
    } catch (error) {
      console.error('Error al eliminar la sucursal:', error);

      // Verificar si el error es específico de una sucursal asociada a facturas
      if (error.response && error.response.status === 409) {
        showAlertError('No se puede eliminar la sucursal porque está asociada a una o más facturas.');
      } else {
        showAlertError('Error al eliminar la sucursal');
      }
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSearch = ({ asesor, numero }) => {
    APIserver.get(`/api/sucursales?asesor=${asesor}&numero=${numero}`)
      .then(response => {
        setSucursales(response.data.sucursales);
        setTotalRows(response.data.total);
      })
      .catch(error => {
        console.error('Error al buscar las sucursales filtradas:', error);
      });
  };

  const totalPages = Math.ceil(totalRows / rowsPerPage);

  // Función para renderizar los botones de paginación
  const renderPaginationItems = () => {
    const items = [];
    const pageRangeDisplayed = 5; // Número de páginas visibles
    const totalPages = Math.ceil(totalRows / rowsPerPage); // Total de páginas calculado

    let startPage = Math.max(1, currentPage - Math.floor(pageRangeDisplayed / 2));
    let endPage = Math.min(totalPages, startPage + pageRangeDisplayed - 1);

    if (endPage - startPage < pageRangeDisplayed - 1) {
      startPage = Math.max(1, endPage - pageRangeDisplayed + 1);
    }

    if (startPage > 1) {
      items.push(
        <Pagination.First key="first" onClick={() => handlePageChange(1)} />
      );
    }

    if (currentPage > 1) {
      items.push(
        <Pagination.Prev key="prev" onClick={() => handlePageChange(currentPage - 1)} />
      );
    }

    for (let number = startPage; number <= endPage; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => handlePageChange(number)}
        >
          {number}
        </Pagination.Item>
      );
    }

    if (currentPage < totalPages) {
      items.push(
        <Pagination.Next key="next" onClick={() => handlePageChange(currentPage + 1)} />
      );
    }

    if (endPage < totalPages) {
      items.push(
        <Pagination.Last key="last" onClick={() => handlePageChange(totalPages)} />
      );
    }

    return items;
  };

  return (
    <div style={{ boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)" }}>
      <Table responsive className='text-center align-middle border'>
        <thead>
          <tr>
            <th colSpan={4}>
              <div className='d-flex justify-content-between align-items-center'>
                <div className='d-flex flex-wrap'>
                  <Button variant='link' size='sm' onClick={toggleFilters} title='Filtrar'>
                    <Filter className='m-0' size={20} />
                  </Button>
                </div>
                <div>
                  <Form.Select className='ms-2' value={rowsPerPage} onChange={handleChangeRowsPerPage}>
                    <option value='5'>5</option>
                    <option value='10'>10</option>
                    <option value='20'>20</option>
                    <option value='50'>50</option>
                  </Form.Select>
                </div>
              </div>
              <div className={`filter-options ${showFilters ? 'show' : ''}`}>
                <FiltrosSucursal onSearch={handleSearch} />
              </div>
            </th>
          </tr>
          <tr>
            <th>Asesor</th>
            <th>Número</th>
            <th>Enlace</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {sucursales.map(sucursal => (
            <tr key={sucursal.idSucursal}>
              <td>{sucursal.nomSucursal}</td>
              <td>{sucursal.numSucursal}</td>
              <td>
                <Button
                  variant='success'
                  title='Copiar enlace'
                  onClick={() => handleCopyLink(sucursal.enlace)}
                >
                  <Copy />
                </Button>
              </td>
              <td>
                <ButtonGroup aria-label='action-button'>
                  <Button
                    variant='link'
                    className='text-warning'
                    title='Editar sucursal'
                    onClick={() => handleEditSucursal(sucursal.idSucursal)}
                  >
                    <PencilFill size={18} />
                  </Button>
                  <Button
                    variant='link'
                    className='text-danger'
                    title='Eliminar sucursal'
                    onClick={() => handleDeleteSucursal(sucursal.idSucursal)}
                  >
                    <TrashFill size={18} />
                  </Button>
                </ButtonGroup>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={4}>
              <div className='d-flex justify-content-center align-items-center'>
                <Pagination>
                  {renderPaginationItems()}
                </Pagination>
              </div>
            </td>
          </tr>
        </tfoot>
      </Table>

      <ToastContainer position="bottom-end" className="p-3" style={{ position: 'fixed', bottom: 0, right: 0 }}>
        <Toast onClose={() => setCopySuccess(false)} show={copySuccess} delay={2000} autohide bg='success'>
          <Toast.Header className=' bg-success'>
            <strong className="me-auto text-white">Notificación</strong>
          </Toast.Header>
          <Toast.Body className='text-white'>¡Enlace copiado con éxito!</Toast.Body>
        </Toast>
      </ToastContainer>

      <EditarSucursalModal
        show={editShow}
        handleClose={() => setEditShow(false)}
        sucursalId={editSucursalId}  // Aquí pasas el ID de la sucursal al modal
        onSuccessUpdate={() => {
          fetchSucursales(currentPage, rowsPerPage); // Actualizar la tabla tras el éxito
          showAlertUpdateConfirmation();
        }}
      />

    </div>
  );
}

export default TablaSucursal;
