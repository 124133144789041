import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import AlertsClientes from '../Utils/AlertsClientes';
import Departamentos from '../../../../utils/places-colombia';
import Paises from '../../../../utils/paises';
import APIserver from '../../../../api/axiosConfig';

function ClienteEditModal({ show, handleClose, cliente }) {
  const [formData, setFormData] = useState({    
    clienteID: cliente.clienteID,
    nomCliente: cliente.nomCliente,
    numCliente: cliente.numCliente,
    direccion: cliente.direccion,
    ciudad: cliente.ciudad,
    departamento: cliente.departamento,  
    pais: cliente.pais,
    correoCliente: cliente.correoCliente
  });

  const [selectedDepartamento, setSelectedDepartamento] = useState({ value: cliente.departamento, label: cliente.departamento });
  const [selectedCiudad, setSelectedCiudad] = useState({ value: cliente.ciudad, label: cliente.ciudad });
  const [selectedPais, setSelectedPais] = useState({ value: cliente.pais, label: cliente.pais });
  const [ciudadesOptions, setCiudadesOptions] = useState([]);

  useEffect(() => {
    const departamento = Departamentos.find(dep => dep.departamento === selectedDepartamento.value);
    const ciudades = departamento ? departamento.ciudades.map(ciudad => ({ value: ciudad, label: ciudad })) : [];
    setCiudadesOptions(ciudades);
  }, [selectedDepartamento]);

  const handleDepartamentoChange = (selectedOption) => {
    setSelectedDepartamento(selectedOption);
    const departamento = Departamentos.find(dep => dep.departamento === selectedOption.value);
    const ciudades = departamento ? departamento.ciudades.map(ciudad => ({ value: ciudad, label: ciudad })) : [];
    setCiudadesOptions(ciudades);
    setSelectedCiudad(null);
  };

  const handleCiudadChange = (selectedOption) => {
    setSelectedCiudad(selectedOption);
  };

  const handlePaisChange = (selectedOption) => {
    setSelectedPais(selectedOption);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedFormData = {
      ...formData,
      ciudad: selectedCiudad ? selectedCiudad.value : '',
      departamento: selectedDepartamento ? selectedDepartamento.value : '',
      pais: selectedPais ? selectedPais.value : ''
    };

    APIserver.put(`/api/clientes/${cliente.idCliente}`, updatedFormData)
      .then(response => {
        AlertsClientes.showSuccessMessage('¡Datos actualizados correctamente!');
        handleClose();
      })
      .catch(error => {
        console.error('Error al actualizar el cliente:', error);
        AlertsClientes.showErrorMessage('Hubo un error al actualizar los datos.');
      });
  };

  const departamentosOptions = Departamentos.map(departamento => ({ value: departamento.departamento, label: departamento.departamento }));
  const paisesOptions = Paises.map(pais => ({ value: pais.es_name, label: pais.es_name }));

  return (
    <Modal size='lg' show={show} onHide={handleClose} aria-labelledby='editar-client-modal'>
      <Modal.Header closeButton>
        <Modal.Title>Editar Cliente</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col>
              <Form.Group className='m-3 mt-0' controlId='clienteID'>
                <Form.Label>Identificación</Form.Label>
                <Form.Control type='number' name='clienteID' value={formData.clienteID} onChange={(e) => setFormData({ ...formData, clienteID: e.target.value })} required />
              </Form.Group>
              <Form.Group className='m-3' controlId='numCliente'>
                <Form.Label>Número de Celular</Form.Label>
                <Form.Control type='number' name='numCliente' value={formData.numCliente} onChange={(e) => setFormData({ ...formData, numCliente: e.target.value })} required />
              </Form.Group>
              <Form.Group className='m-3' controlId='direccion'>
                <Form.Label>Dirección</Form.Label>
                <Form.Control type='text' name='direccion' value={formData.direccion} onChange={(e) => setFormData({ ...formData, direccion: e.target.value })} required />
              </Form.Group>
              <Form.Group className='m-3' controlId='ciudad'>
                <Form.Label>Ciudad</Form.Label>
                <Select options={ciudadesOptions} value={selectedCiudad} onChange={handleCiudadChange} placeholder='Seleccione' isClearable />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className='m-3 mt-0' controlId='nomCliente'>
                <Form.Label>Nombre Completo</Form.Label>
                <Form.Control type='text' name='nomCliente' value={formData.nomCliente} onChange={(e) => setFormData({ ...formData, nomCliente: e.target.value })} required />
              </Form.Group>
              <Form.Group className='m-3' controlId='correoCliente'>
                <Form.Label>Correo Electrónico</Form.Label>
                <Form.Control type='email' name='correoCliente' value={formData.correoCliente} onChange={(e) => setFormData({ ...formData, correoCliente: e.target.value })} required />
              </Form.Group>
              <Form.Group className='m-3' controlId='departamento'>
                <Form.Label>Departamento</Form.Label>
                <Select options={departamentosOptions} value={selectedDepartamento} onChange={handleDepartamentoChange} placeholder='Seleccione' isClearable />
              </Form.Group>
              <Form.Group className='m-3' controlId='pais'>
                <Form.Label>País</Form.Label>
                <Select options={paisesOptions} value={selectedPais} onChange={handlePaisChange} placeholder='Seleccione un País' />
              </Form.Group>
            </Col>
          </Row>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleClose}>Cancelar</Button>
            <Button variant='primary' type='submit'>Guardar</Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default ClienteEditModal;
