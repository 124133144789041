import React, { useState } from 'react';
import { Table, Pagination, Button, Form, Dropdown } from 'react-bootstrap';
import { ThreeDotsVertical, EyeFill, PencilFill, TrashFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import CustomToggle from '../../styles/customToggle';
import EditarPago from '../../Pagos/componentes/editarPago';
import { AlertsPagosEliminar } from '../../Pagos/Utils/AlertsPagos';
import APIserver from '../../../../api/axiosConfig';

function TablaPagosDetalles({ pagos: initialPagos = [], idProveedor }) {
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [currentPage, setCurrentPage] = useState(1);
    const [pagos, setPagos] = useState(initialPagos);
    const [editShow, setShowEdit] = useState(false);
    const [editPagoId, setEditPagoId] = useState(null);

    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setCurrentPage(1); // Reset to first page when rows per page change
    };

    const handleEditShow = (pagoId) => {
        setShowEdit(true);
        setEditPagoId(pagoId);
    };

    const handleEditClose = () => {
        setShowEdit(false);
        setEditPagoId(null);
        window.location.reload(); // Recargar la página después de cerrar el modal de edición
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleEliminarPago = async (idPago) => {
        AlertsPagosEliminar.showConfirmDelete(() => {
            APIserver.delete(`/api/pagos/${idPago}`)
                .then(response => {
                    if (response.status === 200) {
                        AlertsPagosEliminar.showSuccessMessage('El pago ha sido eliminado correctamente');
                        setPagos(pagos.filter((pago) => pago.idPago !== idPago));
                    } else {
                        AlertsPagosEliminar.showErrorMessage('Error al eliminar el pago');
                    }
                })
                .catch(error => {
                    console.error('Error al eliminar el pago:', error);
                    AlertsPagosEliminar.showErrorMessage('Error al eliminar el pago');
                });
        });
    };

    // Agregar una función para actualizar la lista de pagos
    const updatePagos = () => {
        APIserver.get(`/api/api/pagos`)
            .then(response => {
                setPagos(response.data);
            })
            .catch(error => console.error('Error al obtener la lista de pagos:', error));
    };

    // Calculate the indices of the first and last item on the current page
    const indexOfLastItem = currentPage * rowsPerPage;
    const indexOfFirstItem = indexOfLastItem - rowsPerPage;
    const currentPagos = pagos.slice(indexOfFirstItem, indexOfLastItem);

    // Calculate total pages
    const totalPages = Math.ceil(pagos.length / rowsPerPage);

    return (
        <div>
            <Table responsive className='text-center align-middle border'>
                <thead>
                    <tr>
                        <th colSpan={7}>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='small'>
                                    <Form.Select
                                        className='ms-2'
                                        value={rowsPerPage}
                                        onChange={handleChangeRowsPerPage}
                                    >
                                        <option value='5'>5</option>
                                        <option value='10'>10</option>
                                        <option value='20'>20</option>
                                        <option value='50'>50</option>
                                    </Form.Select>
                                </div>
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <th>Pago</th>
                        <th>Factura</th>
                        <th>Proveedor</th>
                        <th>Creación</th>
                        <th>Cuenta</th>
                        <th>Total</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {currentPagos.map((pago) => (
                        <tr key={pago.idPago}>
                            <td>
                                <Link to={`/admin/PagosRealizados/DetallesPago/${pago.idPago}/`} className='text-decoration-none'>
                                    {pago.numPagoHe}
                                </Link>
                            </td>
                            <td>{pago.numCompra}</td>
                            <td>
                                <Link to={`/admin/Proveedores/DetallesProveedores/${idProveedor}/`} className='text-decoration-none'>
                                    {pago.nomProveedor}
                                </Link>
                            </td>
                            <td>{new Date(pago.fecha).toLocaleDateString()}</td>
                            <td>
                                <Link to={`/admin/Bancos/DetallesBancos/${pago.idBanco}/`} className='text-decoration-none'>
                                    {pago.nomBanco}
                                </Link>
                            </td>
                            <td>$ {pago.monto.toLocaleString()}</td>
                            <td>
                                <Dropdown>
                                    <Dropdown.Toggle as={CustomToggle} variant='link'>
                                        <ThreeDotsVertical />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Link to={`/admin/PagosRealizados/DetallesPago/${pago.idPago}/`}>
                                            <Button variant='link' className='text-primary' title='Ver pago'>
                                                <EyeFill size={18} />
                                            </Button>
                                        </Link>
                                        <Button variant='link' className='text-warning' title='Editar Pago' onClick={() => handleEditShow(pago.idPago)}>
                                            <PencilFill size={18} />
                                        </Button>
                                        <Button variant='link' className='text-danger' title='Eliminar Pago' onClick={() => handleEliminarPago(pago.idPago)}>
                                            <TrashFill size={18} />
                                        </Button>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </td>
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan={7}>
                            <div className='d-flex justify-content-center align-items-center'>
                                <Pagination>
                                    <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
                                    {Array.from({ length: totalPages }, (_, i) => (
                                        <Pagination.Item
                                            key={i + 1}
                                            active={i + 1 === currentPage}
                                            onClick={() => handlePageChange(i + 1)}
                                        >
                                            {i + 1}
                                        </Pagination.Item>
                                    ))}
                                    <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
                                </Pagination>
                            </div>
                        </td>
                    </tr>
                </tfoot>
            </Table>
            {/* Modal Editar Pago */}
            <EditarPago
                show={editShow}
                handleClose={handleEditClose}
                pagoId={editPagoId}
                updatePagos={updatePagos} // Pasar la función de actualización
            />
        </div>
    );
}

export default TablaPagosDetalles;
