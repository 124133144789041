import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { API } from '../api/URL_API';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [auth, setAuth] = useState(() => {
        const sessionAuth = sessionStorage.getItem('auth');
        const localAuth = localStorage.getItem('auth');
        return sessionAuth ? JSON.parse(sessionAuth) : localAuth ? JSON.parse(localAuth) : null;
    });

    const login = async (email, password, rememberMe) => {
        try {
            const response = await axios.post(`${API}/api/auth/login`, {
                emailInq: email,
                passInq: password,
                rememberMe
            });

            // Guardar en localStorage o sessionStorage según la opción "Recuérdame"
            if (rememberMe) {
                localStorage.setItem('auth', JSON.stringify(response.data)); 
            } else {
                localStorage.setItem('auth', JSON.stringify(response.data));  // Usamos localStorage temporalmente
            }

            setAuth(response.data);
        } catch (error) {
            console.error('Error during login:', error.response ? error.response.data : error.message);
            throw error;
        }
    };

    const logout = () => {
        setAuth(null);
        localStorage.removeItem('auth');
        sessionStorage.removeItem('auth');
    };

    // Sincronizar estado de autenticación entre pestañas
    useEffect(() => {
        const syncAuth = (e) => {
            if (e.key === 'auth') {
                const updatedAuth = e.newValue ? JSON.parse(e.newValue) : null;
                setAuth(updatedAuth);
            }
        };

        window.addEventListener('storage', syncAuth);

        return () => {
            window.removeEventListener('storage', syncAuth);
        };
    }, []);

    return (
        <AuthContext.Provider value={{ auth, setAuth, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

export { AuthContext };
