import React, { useState, useCallback, useEffect } from 'react';
import { Card, Table, Form, Button, InputGroup, Modal } from 'react-bootstrap';
import { PlusCircleFill, Search, TrashFill } from 'react-bootstrap-icons';
import AlertsFacturas from '../Utils/AlertsFacturas';
import APIserver from '../../../../api/axiosConfig';

// Función para formatear en pesos colombianos
const formatCOP = (value) => {
    return new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    }).format(value);
};

const ProductoRow = React.memo(({ producto, onChange, onRemove, onSearch, onReferenciaEnter }) => {
    const handleChange = (e) => {
        const { name, value } = e.target;
        onChange(producto.id, name, value);
    };

    const handleSearchClick = () => {
        onSearch(producto.id);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            onReferenciaEnter(producto.id, producto.referencia);
        }
    };

    const calculateTotal = () => {
        const { cantidad, valorUnitario } = producto;
        return (parseFloat(cantidad) * parseFloat(valorUnitario)).toFixed(2);
    };

    return (
        <tr>
            <td>
                <Form.Control
                    type="text"
                    name="referencia"
                    value={producto.referencia}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    placeholder="Referencia"
                />
            </td>
            <td>
                <InputGroup>
                    <Form.Control
                        type="text"
                        name="nomProducto"
                        value={producto.nomProducto}
                        onChange={handleChange}
                        placeholder="Nombre"
                        readOnly
                    />
                    <Button variant='outline-success' size='sm' title='Buscar producto' onClick={handleSearchClick}>
                        <Search />
                    </Button>
                </InputGroup>
            </td>
            <td>
                <Form.Control
                    type="number"
                    name="cantidad"
                    value={producto.cantidad}
                    onChange={handleChange}
                    placeholder="Cantidad"
                />
            </td>
            <td>
                {producto.referencia ? (
                    <Form.Control
                        type="text"
                        name="valorUnitario"
                        value={formatCOP(producto.valorUnitario)}
                        placeholder="Valor Unitario"
                        readOnly
                    />
                ) : (
                    <Form.Control
                        type="text"
                        placeholder="Valor Unitario"
                        readOnly
                    />
                )}
            </td>
            <td>
                {producto.referencia ? (
                    <Form.Control
                        type="text"
                        value={formatCOP(calculateTotal())}
                        placeholder="Total"
                        disabled
                    />
                ) : (
                    <Form.Control
                        type="text"
                        placeholder="Total"
                        disabled
                    />
                )}
            </td>
            <td>
                <Button variant='danger' onClick={() => onRemove(producto.id)}>
                    <TrashFill />
                </Button>
            </td>
        </tr>
    );
});

const Productos = ({ setProductos, setSubtotal, setTotal, setCantItem, setEnvio, setDescuento, tipoFactura }) => {
    const [localProductos, setLocalProductos] = useState([{
        id: Date.now(),
        referencia: '',
        nomProducto: '',
        cantidad: 1,
        valorUnitario: 0,
        valorUnitarioM: 0,
        valorUnitarioU: 0
    }]);
    const [envio, setLocalEnvio] = useState(0);
    const [descuento, setLocalDescuento] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentProductId, setCurrentProductId] = useState(null);

    const handleAddRow = () => {
        setLocalProductos(prevProductos => [
            ...prevProductos,
            {
                id: Date.now(),
                referencia: '',
                nomProducto: '',
                cantidad: 1,
                valorUnitario: 0,
                valorUnitarioM: 0,
                valorUnitarioU: 0
            }
        ]);
    };

    const handleRemoveRow = (id) => {
        setLocalProductos(prevProductos => prevProductos.filter(p => p.id !== id));
    };

    const handleChange = useCallback(async (id, name, value) => {
        if (name === 'cantidad') {
            const producto = localProductos.find(p => p.id === id);
            if (producto && producto.idProducto) {
                try {
                    const response = await APIserver.get(`/api/productos/${producto.idProducto}`);
                    const cantidadDisponible = response.data.stock || 0;
                    if (parseInt(value) > cantidadDisponible) {
                        AlertsFacturas.showStockAlert(cantidadDisponible);
                        return;
                    }
                } catch (error) {
                    console.error('Error al obtener el stock del producto:', error);
                }
            }
        }
        setLocalProductos(prevProductos => {
            const updatedProductos = prevProductos.map(p => p.id === id ? { ...p, [name]: value } : p);
            return updatedProductos;
        });
    }, [localProductos]);

    const handleReferenciaEnter = async (id, referencia) => {
        try {
            const response = await APIserver.get(`/api/referencia/${referencia}`);
            const producto = response.data;

            if (!producto) {
                AlertsFacturas.showErrorEncontrar('Producto no encontrado. Verifique la referencia.');
                return;
            }

            const cantidadDefault = 1;
            if (cantidadDefault > producto.stock) {
                AlertsFacturas.showStockAlert(producto.stock);
                setLocalProductos(prevProductos => {
                    return prevProductos.map(p => p.id === id ? {
                        ...p,
                        referencia: '',
                        nomProducto: '',
                        cantidad: 1,
                        valorUnitario: 0,
                        valorUnitarioM: producto.precioM,
                        valorUnitarioU: producto.precioU
                    } : p);
                });
                return;
            }

            setLocalProductos(prevProductos => {
                let foundDuplicate = false;
                const updatedProductos = prevProductos.map(p => {
                    if (p.referencia === referencia && p.id !== id) {
                        const nuevaCantidad = parseInt(p.cantidad, 10) + parseInt(prevProductos.find(x => x.id === id).cantidad, 10);
                        if (nuevaCantidad > producto.stock) {
                            AlertsFacturas.showStockAlert(producto.stock);
                            return p; // No actualiza la cantidad si no hay stock suficiente
                        }
                        foundDuplicate = true;
                        return {
                            ...p,
                            cantidad: nuevaCantidad
                        };
                    }
                    const newProducto = p.id === id ? {
                        ...p,
                        nomProducto: producto.nomProducto,
                        valorUnitario: tipoFactura === 'distribucion' ? producto.precioM : producto.precioU,
                        idProducto: producto.idProducto,
                        idSucursal: producto.idSucursal,
                        valorUnitarioM: producto.precioM,
                        valorUnitarioU: producto.precioU
                    } : p;

                    return newProducto;
                });

                if (foundDuplicate) {
                    return updatedProductos.filter(p => p.id !== id);
                }

                const currentProduct = updatedProductos.find(p => p.id === id);
                if (currentProduct.referencia && currentProduct.nomProducto && currentProduct.cantidad > 0) {
                    const cantidadTotal = updatedProductos.reduce((total, p) => total + (p.referencia === referencia ? parseInt(p.cantidad, 10) : 0), 0);
                    if (cantidadTotal <= producto.stock) {
                        const lastProduct = updatedProductos[updatedProductos.length - 1];
                        if (lastProduct.referencia || lastProduct.nomProducto || lastProduct.cantidad > 0) {
                            updatedProductos.push({
                                id: Date.now(),
                                referencia: '',
                                nomProducto: '',
                                cantidad: 1,
                                valorUnitario: 0,
                                valorUnitarioM: producto.precioM,
                                valorUnitarioU: producto.precioU
                            });
                        }
                    } else {
                        AlertsFacturas.showStockAlert(producto.stock);
                        updatedProductos[updatedProductos.length - 1] = {
                            id: Date.now(),
                            referencia: '',
                            nomProducto: '',
                            cantidad: 1,
                            valorUnitario: 0,
                            valorUnitarioM: producto.precioM,
                            valorUnitarioU: producto.precioU
                        };
                    }
                }

                return updatedProductos;
            });
        } catch (error) {
            AlertsFacturas.showErrorEncontrar();
            console.error('Error al buscar producto por referencia:', error);
        }
    };

    const handleSearch = (id) => {
        setCurrentProductId(id);
        setShowModal(true);
    };

    const handleSearchQueryChange = async (e) => {
        const query = e.target.value;
        setSearchQuery(query);
        try {
            const response = await APIserver.get(`/api/info/productos`, {
                params: { nombre: query, tipoFactura }
            });
            setSearchResults(response.data);
        } catch (error) {
            console.error('Error al buscar productos:', error);
        }
    };

    const handleSelectProduct = async (product) => {
        try {
            const response = await APIserver.get(`/api/productos/${product.idProducto}`);
            const stockDisponible = response.data.stock || 0;

            setLocalProductos(prevProductos => {
                let foundDuplicate = false;
                const updatedProductos = prevProductos.map(p => {
                    if (p.referencia === product.referencia) {
                        foundDuplicate = true;
                        const nuevaCantidad = parseInt(p.cantidad, 10) + 1;
                        if (nuevaCantidad > stockDisponible) {
                            AlertsFacturas.showStockAlert(stockDisponible);
                            // Limpiar los campos si el stock no es suficiente
                            return {
                                ...p,
                                cantidad: 1,
                                valorUnitario: tipoFactura === 'distribucion' ? 0 : 0
                            };
                        }
                        return {
                            ...p,
                            cantidad: nuevaCantidad
                        };
                    }
                    return p;
                });

                if (foundDuplicate) {
                    return updatedProductos;
                }

                const newProduct = {
                    ...updatedProductos[updatedProductos.length - 1],
                    referencia: product.referencia,
                    nomProducto: product.nomProducto,
                    valorUnitario: tipoFactura === 'distribucion' ? product.precioM : product.precioU,
                    idProducto: product.idProducto,
                    idSucursal: product.idSucursal,
                    valorUnitarioM: product.precioM,
                    valorUnitarioU: product.precioU
                };

                updatedProductos[updatedProductos.length - 1] = newProduct;

                const lastProduct = updatedProductos[updatedProductos.length - 1];
                if (lastProduct.referencia && lastProduct.nomProducto && lastProduct.cantidad > 0) {
                    const cantidadTotal = updatedProductos.reduce((total, p) => total + (p.referencia === product.referencia ? parseInt(p.cantidad, 10) : 0), 0);
                    if (cantidadTotal <= stockDisponible) {
                        updatedProductos.push({
                            id: Date.now(),
                            referencia: '',
                            nomProducto: '',
                            cantidad: 1,
                            valorUnitario: 0,
                            valorUnitarioM: product.precioM,
                            valorUnitarioU: product.precioU
                        });
                    } else {
                        AlertsFacturas.showStockAlert(stockDisponible);
                    }
                }

                return updatedProductos;
            });

            setShowModal(false);
        } catch (error) {
            console.error('Error al obtener el stock del producto:', error);
            AlertsFacturas.showErrorEncontrar();
        }
    };

    const handleEnvioChange = (e) => {
        const envioValue = parseFloat(e.target.value) || 0;
        setLocalEnvio(Math.max(0, envioValue));
    };

    const handleDescuentoChange = (e) => {
        let descuentoValue = parseFloat(e.target.value) || 0;
        descuentoValue = Math.max(0, Math.min(descuentoValue, 100));
        setLocalDescuento(descuentoValue);
    };

    const calculateSubtotal = useCallback(() => {
        return localProductos.reduce((total, producto) => {
            const { cantidad, valorUnitario } = producto;
            if (producto.referencia) {
                const productoTotal = parseFloat(cantidad) * parseFloat(valorUnitario);
                return total + productoTotal;
            }
            return total;
        }, 0).toFixed(2);
    }, [localProductos]);

    const calculateTotalItems = useCallback(() => {
        return localProductos.reduce((total, producto) => {
            if (producto.referencia && producto.nomProducto && parseInt(producto.cantidad, 10) > 0) {
                return total + parseInt(producto.cantidad, 10);
            }
            return total;
        }, 0);
    }, [localProductos]);

    const calculateTotal = useCallback(() => {
        const subtotal = parseFloat(calculateSubtotal());
        const descuentoTotal = subtotal * (descuento / 100);
        return (subtotal + envio - descuentoTotal).toFixed(2);
    }, [calculateSubtotal, envio, descuento]);

    useEffect(() => {
        setProductos(localProductos);
        setSubtotal(calculateSubtotal());
        setTotal(calculateTotal());
        setCantItem(calculateTotalItems());
        setEnvio(envio);
        setDescuento(descuento);
    }, [localProductos, envio, descuento, setProductos, setSubtotal, setTotal, setCantItem, setEnvio, setDescuento, calculateSubtotal, calculateTotalItems, calculateTotal]);

    useEffect(() => {
        const actualizarPrecios = () => {
            setLocalProductos(prevProductos => {
                return prevProductos.map(p => ({
                    ...p,
                    valorUnitario: tipoFactura === 'distribucion' ? p.valorUnitarioM : p.valorUnitarioU
                }));
            });
        };

        actualizarPrecios();
    }, [tipoFactura]);

    return (
        <Card className='mt-2 shadow-none'>
            <Card.Header className='fw-bold'>Productos</Card.Header>
            <Table responsive className='shadow-none text-center'>
                <thead className='bg-primary-subtle'>
                    <tr>
                        <th>Referencia</th>
                        <th>Nombre</th>
                        <th>Cant.</th>
                        <th>Valor Unitario</th>
                        <th>Total</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {localProductos.map(producto => (
                        <ProductoRow
                            key={producto.id}
                            producto={producto}
                            onChange={handleChange}
                            onRemove={handleRemoveRow}
                            onSearch={handleSearch}
                            onReferenciaEnter={handleReferenciaEnter}
                        />
                    ))}
                    <tr>
                        <td colSpan="6" className='text-start'>
                            <Button variant='outline-success' onClick={handleAddRow}>
                                <PlusCircleFill size={18} className='me-2' /> Agregar Linea
                            </Button>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="4" className='text-end fw-bold'>Subtotal:</td>
                        <td colSpan="2" className='text-start'>{formatCOP(calculateSubtotal())}</td>
                    </tr>
                    <tr>
                        <td colSpan="4" className='text-end fw-bold'>Descuento:</td>
                        <td colSpan="2" className='text-start'>
                            <InputGroup size='sm'>
                                <InputGroup.Text>%</InputGroup.Text>
                                <Form.Control
                                    type="number"
                                    value={descuento}
                                    onChange={handleDescuentoChange}
                                    placeholder="Descuento"
                                />
                            </InputGroup>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="4" className='text-end fw-bold'>Items:</td>
                        <td colSpan="1" className='text-start'>{calculateTotalItems()}</td>
                    </tr>
                    <tr>
                        <td colSpan="4" className='text-end fw-bold'>Envio:</td>
                        <td colSpan="2" className='text-start'>
                            <InputGroup size='sm'>
                                <InputGroup.Text>$</InputGroup.Text>
                                <Form.Control
                                    type="number"
                                    value={envio}
                                    onChange={handleEnvioChange}
                                    placeholder="Envio"
                                />
                            </InputGroup>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="4" className='text-end fw-bold'>Total:</td>
                        <td colSpan="2" className='text-start'>{formatCOP(calculateTotal())}</td>
                    </tr>
                </tbody>
            </Table>

            {/* Modal for searching products */}
            <Modal show={showModal} size='lg' onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Buscar Producto</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control
                        type="text"
                        value={searchQuery}
                        onChange={handleSearchQueryChange}
                        placeholder="Escriba el nombre del producto"
                    />
                    <Table responsive className='shadow-none text-center mt-3'>
                        <thead className='bg-primary-subtle'>
                            <tr>
                                <th>Referencia</th>
                                <th>Nombre</th>
                                <th>Stock</th>
                                <th>Valor Unitario</th>
                                <th>Valor Distribución</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {searchResults.length ? searchResults.map(producto => (
                                <tr key={producto.idProducto}>
                                    <td>{producto.referencia}</td>
                                    <td>{producto.nomProducto}</td>
                                    <td>{producto.stock}</td>
                                    <td>{formatCOP(producto.precioU)}</td>
                                    <td>{formatCOP(producto.precioM)}</td>
                                    <td>
                                        <Button
                                            variant='outline-success'
                                            size='sm'
                                            disabled={producto.stock <= 0}
                                            onClick={() => {
                                                const stockDisponible = producto.stock || 0;
                                                if (stockDisponible > 0) {
                                                    handleSelectProduct(producto);
                                                } else {
                                                    AlertsFacturas.showStockAlert(stockDisponible);
                                                }
                                            }}
                                        >
                                            Seleccionar
                                        </Button>
                                    </td>
                                </tr>
                            )) : (
                                <tr>
                                    <td colSpan="5">No se encontraron resultados</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </Card>
    );
};

export default Productos;

