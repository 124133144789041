import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Button } from 'react-bootstrap';
import { ReceiptCutoff, Upload, NutFill } from 'react-bootstrap-icons';
import '../styles/tables.css';
import CustomToggle from '../styles/customToggle';
import TablaFacturas from './componentes/tablaFacturas';

function AdminFacturas() {
    return (
        <div className='mx-2 mt-3'>
            <div className='d-flex flex-column flex-md-row align-items-center justify-content-between'>
                <p className='mb-2 mb-md-0 text-center'>
                    Administra todas las facturas de venta de tu negocio.
                </p>
                <div className='d-flex mt-2 mt-md-0'>
                    <Link to='/admin/Facturas/CrearFactura/'>
                        <Button variant='primary'>
                            <ReceiptCutoff /> Nueva Factura
                        </Button>
                    </Link>
                    <Dropdown>
                        <Dropdown.Toggle as={CustomToggle} variant='secondary' className='ms-2'>
                            <NutFill />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item><Upload className='text-danger mx-1' /> Exportar</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
            {/* Tabla Facturas */}
            <div className='mt-4'>
                <TablaFacturas />
            </div>
        </div>
    );
}

export default AdminFacturas;