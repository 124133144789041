import React from 'react';

const messages = [
    "¡Aprovecha nuestros increíbles descuentos!",
    "¡Aprovecha nuestros increíbles descuentos!",
    "¡Aprovecha nuestros increíbles descuentos!",
    "¡Aprovecha nuestros increíbles descuentos!",
];

const Marquee = () => {
    return (
        <div className="marquee-container" >
            <div className="marquee-content my-2">
                {messages.map((msg, index) => (
                    <span key={index}>{msg}</span>
                ))}
                {/* Repetir los mensajes */}
                {messages.map((msg, index) => (
                    <span key={index + messages.length}>{msg}</span>
                ))}
            </div>
        </div>
    );
};

export default Marquee;