import React, { useState } from 'react';
import { Form, InputGroup, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { API } from '../../../api/URL_API';
import publi from '../../../utils/publi.webp';

function JoinEmail() {
    const [formData, setFormData] = useState({ nameuser: '', email: '' });
    const [message, setMessage] = useState(null);
    const [loading, setLoading] = useState(false); // Estado para manejar el loading

    // Manejador de cambios en los campos del formulario
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // Función para manejar el envío del formulario
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); // Activar estado de carga

        try {
            const response = await axios.post(`${API}/api/marketing/subscribe`, formData);
            setMessage({ text: response.data.message, type: 'success' });
            setFormData({ nameuser: '', email: '' }); // Resetea el formulario
        } catch (error) {
            const errorMsg = error.response?.data?.error || 'Hubo un error al suscribirse. Inténtalo de nuevo.';
            setMessage({ text: errorMsg, type: 'error' });
        } finally {
            setLoading(false); // Desactivar estado de carga
        }
    };

    return (
        <>
            {/* SVG de transición en forma de onda invertida */}
            <svg viewBox="0 -1 1440 320" className="wave-transition wave-transition-top" xmlns="http://www.w3.org/2000/svg">
                <path fill="#2b235e" fillOpacity="1" d="M0,96L40,90.7C80,85,160,75,240,106.7C320,139,400,213,480,213.3C560,213,640,139,720,128C800,117,880,171,960,202.7C1040,235,1120,245,1200,240C1280,235,1360,213,1400,202.7L1440,192L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"></path>
            </svg>

            <Row className="align-items-center bg-cr">
                <Col xs={12} md={6} className="text-center">
                    <div className='d-flex justify-content-center align-items-center'>
                        <div className="text-center mx-3" style={{ maxWidth: '35rem', width: '100%' }}>
                            <h2 className='text-light fst-italic fw-bold'>SUSCRIBETE</h2>
                            <p className='text-light'>Suscribete y obten increibles descuentos en tus compras.</p>

                            {message && (
                                <div className={`alert ${message.type === 'success' ? 'alert-success' : 'alert-danger'}`} role="alert">
                                    {message.text}
                                </div>
                            )}

                            <Form onSubmit={handleSubmit}>
                                <InputGroup className="justify-content-center mb-3">
                                    <Form.Control
                                        type="text"
                                        name="nameuser"
                                        placeholder="Ingresa tu nombre"
                                        aria-label="Ingresa tu nombre"
                                        value={formData.nameuser}
                                        onChange={handleChange}
                                        required
                                    />
                                </InputGroup>
                                <InputGroup className="justify-content-center">
                                    <Form.Control
                                        type="email"
                                        name="email"
                                        placeholder="Ingresa tu email aquí"
                                        aria-label="Ingresa tu email aquí"
                                        value={formData.email}
                                        onChange={handleChange}
                                        required
                                    />
                                </InputGroup>
                                <button type="submit" className="button-suscribe mt-3 w-100 text-center" disabled={loading}>
                                    {loading ? 'Cargando...' : 'Suscríbete'}
                                </button>
                            </Form>
                        </div>
                    </div>
                </Col>
                <Col xs={12} md={6} className="d-flex justify-content-center align-items-center mt-4 mt-md-0">
                    <div className="image-wrapper">
                        <img src={publi} className="img-fluid" alt="Publicidad" />
                    </div>
                </Col>
            </Row>
            {/* SVG de transición en forma de onda */}
            <svg viewBox="0 0 1440 320" className="wave-transition wave-transition-bot" style={{ marginBottom: "-5px" }} xmlns="http://www.w3.org/2000/svg">
                <path fill="#2b235e" fillOpacity="1" d="M0,128L40,133.3C80,139,160,149,240,176C320,203,400,245,480,240C560,235,640,181,720,160C800,139,880,149,960,165.3C1040,181,1120,203,1200,213.3C1280,224,1360,224,1400,224L1440,224L1440,0L1400,0C1360,0,1280,0,1200,0C1120,0,1040,0,960,0C880,0,800,0,720,0C640,0,560,0,480,0C400,0,320,0,240,0C160,0,80,0,40,0L0,0Z"></path>
            </svg>
        </>
    );
}

export default JoinEmail;
