import React, { useState, useEffect } from 'react';
import { Modal, Form, Row, Col, InputGroup, Button } from 'react-bootstrap';
import Select from 'react-select';
import { AlertsPagos } from '../Utils/AlertsPagos';
import APIserver from '../../../../api/axiosConfig';

function CrearPagoModal({ show, handleClose, compraId, numCompra, saldo }) {
  const [formData, setFormData] = useState({
    numCompra: numCompra || '',
    conceptoPago: '',
    detallePago: '',
    fechaPago: '',
    idBanco: null,
    tipoPago: null,
    montoPago: ''
  });

  const [currentDate, setCurrentDate] = useState('');
  const [bancosOptions, setBancosOptions] = useState([]);
  const [nomProveedor, setNombreProveedor] = useState('');
  const [errors, setErrors] = useState({}); // Estado para manejar errores

  const optionsPago = [
    { value: 'transferencia', label: 'Transferencia' },
    { value: 'efectivo', label: 'Efectivo' }
  ];

  const autocompleteProveedor = async (numCompra) => {
    try {
      const response = await APIserver.get(`/api/proveedor/${numCompra}`);
      if (response.data.nomProveedor) {
        setNombreProveedor(response.data.nomProveedor);
      } else {
        setNombreProveedor('');
      }
    } catch (error) {
      console.error('Error al autocompletar el nombre del proveedor:', error);
      setNombreProveedor('');
    }
  };

  useEffect(() => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const dd = String(today.getDate()).padStart(2, '0');
    setCurrentDate(`${yyyy}-${mm}-${dd}`);
    setFormData((prevData) => ({ ...prevData, fechaPago: `${yyyy}-${mm}-${dd}`, numCompra: numCompra || '' }));
  }, [numCompra]);

  useEffect(() => {
    const fetchBancos = async () => {
      try {
        const response = await APIserver.get(`/api/pagosrecibidos/mostrar/bancos`);
        const data = response.data;
        if (Array.isArray(data.bancos)) {
          const optionsBancos = data.bancos.map(banco => ({
            value: banco.idBanco,
            label: banco.nomBanco
          }));
          setBancosOptions(optionsBancos);
        } else {
          console.error('La propiedad "bancos" de la respuesta no es un arreglo válido:', data.bancos);
        }
      } catch (error) {
        console.error('Error al obtener los bancos:', error);
      }
    };
    fetchBancos();
  }, []);

  useEffect(() => {
    if (numCompra) {
      autocompleteProveedor(numCompra);
    }
  }, [numCompra]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });

    if (name === 'numCompra') {
      autocompleteProveedor(value);
    }
  };

  const handleBancoChange = (selectedOption) => {
    setFormData({ ...formData, idBanco: selectedOption });
    setErrors({ ...errors, idBanco: !selectedOption });
  };

  const handleTipoPagoChange = (selectedOption) => {
    setFormData({ ...formData, tipoPago: selectedOption });
    setErrors({ ...errors, tipoPago: !selectedOption });
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.numCompra) newErrors.numCompra = 'El número de factura es obligatorio.';
    if (!formData.fechaPago) newErrors.fechaPago = 'La fecha de pago es obligatoria.';
    if (!nomProveedor) newErrors.nomProveedor = 'No se encontró un proveedor para esta factura.';
    if (!formData.idBanco) newErrors.idBanco = 'Debe seleccionar un banco.';
    if (!formData.tipoPago) newErrors.tipoPago = 'Debe seleccionar un tipo de pago.';
    if (!formData.montoPago || formData.montoPago <= 0) newErrors.montoPago = 'El monto debe ser mayor a 0.';

    setErrors(newErrors);

    // Si no hay errores, retorna true
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = async () => {
    if (!validateForm()) {
      return; // Detener si hay errores en el formulario
    }
  
    try {
      const compraResponse = await APIserver.get(`/api/compras/id/${formData.numCompra}`);
      const idCompra = compraResponse.data.idCompra;
  
      const payload = {
        idCompra,
        fecha: formData.fechaPago,
        detalle: formData.detallePago,
        idBanco: formData.idBanco.value,
        monto: formData.montoPago,
        concepto: formData.conceptoPago,
        tipoPago: formData.tipoPago.value
      };
  
      const response = await APIserver.post(`/api/pagos`, payload);
      
      if (response.data.idPago) {
        AlertsPagos.showSuccessMessage();
        handleClose();
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        // Si el backend devuelve un error específico, mostrarlo
        const errorMessage = error.response.data.error;
        const detalles = error.response.data.detalles;
  
        // Puedes personalizar el mensaje de error con más detalles si es necesario
        let customErrorMessage = `${errorMessage}`;
        if (detalles) {
          customErrorMessage += ``;
        }
  
        AlertsPagos.showErrorMessage(customErrorMessage);
      } else {
        // Para otros errores, mostrar un mensaje genérico
        AlertsPagos.showErrorMessage('Ha ocurrido un error al guardar el pago.');
      }
    }
  };  

  return (
    <Modal size='lg' show={show} onHide={handleClose} aria-labelledby='create-pagoHe-modal'>
      <Modal.Header closeButton>
        <Modal.Title>Nuevo Pago Realizado</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group className='m-3 mt-0' controlId='numCompra'>
                <Form.Label>Factura</Form.Label>
                <Form.Control
                  type='text'
                  name='numCompra'
                  value={formData.numCompra}
                  onChange={handleInputChange}
                  placeholder='Numero de factura de compra'
                  isInvalid={!!errors.numCompra}
                  required
                />
                <Form.Control.Feedback type='invalid'>{errors.numCompra}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className='m-3' controlId='nomProveedor'>
                <Form.Label>Proveedor</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='Nombre del proveedor'
                  value={nomProveedor}
                  isInvalid={!!errors.nomProveedor}
                  disabled
                />
                <Form.Control.Feedback type='invalid'>{errors.nomProveedor}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group className='m-3' controlId='conceptoPago'>
                <Form.Label>Concepto</Form.Label>
                <Form.Control
                  type='text'
                  name='conceptoPago'
                  value={formData.conceptoPago}
                  onChange={handleInputChange}
                  placeholder='Concepto del pago'
                  required
                />
              </Form.Group>
              <Form.Group className='m-3' controlId='detallePago'>
                <Form.Label>Observaciones</Form.Label>
                <Form.Control
                  as='textarea'
                  name='detallePago'
                  value={formData.detallePago}
                  onChange={handleInputChange}
                  placeholder='Observaciones sobre el pago (Opcional)'
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className='m-3 mt-0' controlId='fechaPago'>
                <Form.Label>Fecha</Form.Label>
                <Form.Control
                  type='date'
                  name='fechaPago'
                  value={formData.fechaPago || currentDate}
                  onChange={handleInputChange}
                  isInvalid={!!errors.fechaPago}
                  required
                />
                <Form.Control.Feedback type='invalid'>{errors.fechaPago}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className='m-3' controlId='idBanco'>
                <Form.Label>Banco</Form.Label>
                <Select
                  placeholder='Seleccione el Banco'
                  name='idBanco'
                  value={formData.idBanco}
                  onChange={handleBancoChange}
                  options={bancosOptions}
                  className={errors.idBanco ? 'is-invalid' : ''}
                />
                {errors.idBanco && <div className="invalid-feedback d-block">{errors.idBanco}</div>}
              </Form.Group>
              <Form.Group className='m-3' controlId='tipoPago'>
                <Form.Label>Tipo</Form.Label>
                <Select
                  placeholder='Seleccione el Tipo de Pago'
                  name='tipoPago'
                  value={formData.tipoPago}
                  onChange={handleTipoPagoChange}
                  options={optionsPago}
                  className={errors.tipoPago ? 'is-invalid' : ''}
                />
                {errors.tipoPago && <div className="invalid-feedback d-block">{errors.tipoPago}</div>}
              </Form.Group>
              <Form.Group className='m-3' controlId='montoPago'>
                <Form.Label>Monto</Form.Label>
                <InputGroup>
                  <InputGroup.Text>$</InputGroup.Text>
                  <Form.Control
                    type='number'
                    name='montoPago'
                    value={formData.montoPago}
                    onChange={handleInputChange}
                    placeholder='Pago total realizado'
                    isInvalid={!!errors.montoPago}
                    required
                  />
                  <InputGroup.Text>.00</InputGroup.Text>
                </InputGroup>
                {errors.montoPago && <div className="invalid-feedback d-block">{errors.montoPago}</div>}

                {saldo && (
                  <small className="text-muted">
                    Saldo de la factura {new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 0 }).format(saldo)}
                  </small>
                )}
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose}>
          Cancelar
        </Button>
        <Button variant='primary' onClick={handleSave}>
          Guardar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CrearPagoModal;
