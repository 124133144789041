import React, { useState, useRef } from 'react';
import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2';
import ImageCropperModal from './ImageCropperModal'; // Asegúrate de importar tu modal de recorte
import { Pencil } from 'react-bootstrap-icons';
import { API } from '../../../api/URL_API';

function InfoTienda({ onNext }) {
    const [formData, setFormData] = useState({
        shopLogo: null,
        shopBanner: null,
        shopDescription: ''
    });

    const [showCropperModal, setShowCropperModal] = useState(false);
    const [tempImageFile, setTempImageFile] = useState(null);
    const [cropType, setCropType] = useState(''); // 'logo' or 'banner'
    const [cropperError, setCropperError] = useState('');

    const fileInputRefLogo = useRef(null);
    const fileInputRefBanner = useRef(null);

    const handleChange = (e) => {
        const { name, files } = e.target;
        if (files && files[0]) {
            const file = files[0];
            if (file.type.startsWith('image/')) {
                setTempImageFile(file);
                setCropType(name === 'shopLogo' ? 'logo' : 'banner');
                setShowCropperModal(true);
                setCropperError(''); // Clear error if a valid image is selected
            } else {
                setCropperError('Por favor, selecciona un archivo de imagen.');
                e.target.value = ''; // Clear the file input
            }
        }
    };

    const handleCropComplete = (croppedImageFile) => {
        if (croppedImageFile) {
            setFormData({ ...formData, [`shop${cropType.charAt(0).toUpperCase() + cropType.slice(1)}`]: croppedImageFile });
            setCropperError('');
        }
        setShowCropperModal(false);
    };

    const handleCropCancel = (action) => {
        if (action === 'cancel') {
            setCropperError(`El usuario no ha seleccionado una imagen.`);
        }
        setShowCropperModal(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (cropperError) {
            return;
        }
        try {
            const dataToSave = new FormData();
            Object.keys(formData).forEach(key => {
                dataToSave.append(key, formData[key]);
            });

            const response = await axios.post(`${API}/api/tienda`, dataToSave, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            console.log('Datos de la tienda a guardar:', response.data);
            const previousData = JSON.parse(localStorage.getItem('companyFormData')) || {};
            localStorage.setItem('shopFormData', JSON.stringify({ ...previousData, ...response.data }));

            Swal.fire({
                icon: 'success',
                title: 'Registro exitoso',
                text: 'Tu tienda se ha registrado correctamente.',
                confirmButtonText: 'Continuar'
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.href = `http://localhost:3000/Login`;
                }
            });

        } catch (error) {
            console.error('Error al enviar el formulario', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Hubo un problema al registrar la tienda.',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#3085d6'
            });
        }
    };

    return (
        <Container className='mt-4'>
            <h2>Personaliza tu Tienda</h2>
            <Form onSubmit={handleSubmit}>
                <Row className="justify-content-center">
                    <Col md={5} className="d-flex justify-content-center align-items-center">
                        <Form.Group controlId='shopLogo' className='m-3'>
                            <Form.Label style={{ textAlign: 'center', display: 'block' }}>Logo de la Tienda</Form.Label>
                            <input
                                type="file"
                                name='shopLogo'
                                accept='image/*'
                                onChange={handleChange}
                                ref={fileInputRefLogo}
                                style={{
                                    display: 'none',
                                }}
                            />
                            <div
                                className="position-relative"
                                style={{
                                    width: '120px',
                                    height: '120px',
                                    border: formData.shopLogo ? 'none' : '2px dashed #007bff',
                                    borderRadius: '50%',
                                    cursor: 'pointer',
                                    backgroundColor: formData.shopLogo ? 'transparent' : '#f8f9fa',
                                    overflow: 'hidden',
                                    position: 'relative',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                                onClick={() => fileInputRefLogo.current.click()}
                            >
                                {formData.shopLogo ? (
                                    <>
                                        <img
                                            src={URL.createObjectURL(formData.shopLogo)}
                                            alt="Logo de la tienda"
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover',
                                            }}
                                        />
                                        <button
                                            type="button"
                                            className="btn position-absolute top-50 start-50 translate-middle p-2"
                                            style={{
                                                borderRadius: '50%',
                                                backgroundColor: 'white',
                                                border: 'none',
                                                cursor: 'pointer',
                                                width: '30px',
                                                height: '30px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <Pencil color="black" size={16} />
                                        </button>
                                    </>
                                ) : (
                                    <span
                                        style={{
                                            fontSize: '24px',
                                            color: '#007bff',
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            borderRadius: '50%',
                                            width: '30px',
                                            height: '30px',
                                            lineHeight: '30px',
                                            textAlign: 'center',
                                            backgroundColor: 'white',
                                            border: '2px solid #007bff',
                                            cursor: 'pointer',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
                                            transition: 'background-color 0.3s, color 0.3s, border-color 0.3s',
                                        }}
                                        onMouseEnter={(e) => {
                                            e.currentTarget.style.backgroundColor = '#007bff';
                                            e.currentTarget.style.color = 'white';
                                            e.currentTarget.style.borderColor = '#007bff';
                                        }}
                                        onMouseLeave={(e) => {
                                            e.currentTarget.style.backgroundColor = 'white';
                                            e.currentTarget.style.color = '#007bff';
                                            e.currentTarget.style.borderColor = '#007bff';
                                        }}
                                    >
                                        +
                                    </span>
                                )}
                            </div>
                        </Form.Group>
                    </Col>
                    <Col md={5} className="d-flex justify-content-center align-items-center">
                        <Form.Group controlId='shopBanner' className='m-3'>
                            <Form.Label style={{ textAlign: 'center', display: 'block' }}>Banner de la Tienda</Form.Label>
                            <input
                                type="file"
                                name='shopBanner'
                                accept='image/*'
                                onChange={handleChange}
                                ref={fileInputRefBanner}
                                style={{
                                    display: 'none',
                                }}
                            />
                            <div
                                className="position-relative"
                                style={{
                                    width: '480px',
                                    height: '120px',
                                    border: formData.shopBanner ? 'none' : '2px dashed #007bff',
                                    borderRadius: '5px',
                                    cursor: 'pointer',
                                    backgroundColor: formData.shopBanner ? 'transparent' : '#f8f9fa',
                                    overflow: 'hidden',
                                    position: 'relative',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                                onClick={() => fileInputRefBanner.current.click()}
                            >
                                {formData.shopBanner ? (
                                    <>
                                        <img
                                            src={URL.createObjectURL(formData.shopBanner)}
                                            alt="Banner de la tienda"
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover',
                                            }}
                                        />
                                        <button
                                            type="button"
                                            className="btn position-absolute top-50 start-50 translate-middle p-2"
                                            style={{
                                                borderRadius: '50%',
                                                backgroundColor: 'white',
                                                border: 'none',
                                                cursor: 'pointer',
                                                width: '30px',
                                                height: '30px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <Pencil color="black" size={16} />
                                        </button>
                                    </>
                                ) : (
                                    <span
                                        style={{
                                            fontSize: '24px',
                                            color: '#007bff',
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            borderRadius: '50%',
                                            width: '30px',
                                            height: '30px',
                                            lineHeight: '30px',
                                            textAlign: 'center',
                                            backgroundColor: 'white',
                                            border: '2px solid #007bff',
                                            cursor: 'pointer',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
                                            transition: 'background-color 0.3s, color 0.3s, border-color 0.3s',
                                        }}
                                        onMouseEnter={(e) => {
                                            e.currentTarget.style.backgroundColor = '#007bff';
                                            e.currentTarget.style.color = 'white';
                                            e.currentTarget.style.borderColor = '#007bff';
                                        }}
                                        onMouseLeave={(e) => {
                                            e.currentTarget.style.backgroundColor = 'white';
                                            e.currentTarget.style.color = '#007bff';
                                            e.currentTarget.style.borderColor = '#007bff';
                                        }}
                                    >
                                        +
                                    </span>
                                )}
                            </div>
                        </Form.Group>
                    </Col>
                </Row>
                <Form.Group controlId='shopDescription' className='m-3'>
                    <Form.Label>Descripción de la Tienda</Form.Label>
                    <Form.Control
                        as='textarea'
                        name='shopDescription'
                        value={formData.shopDescription}
                        onChange={(e) => setFormData({ ...formData, shopDescription: e.target.value })}
                        rows={3}
                    />
                </Form.Group>
                {cropperError && <p className='text-danger'>{cropperError}</p>}
                <Button type='submit' className='btn btn-primary'>Terminar</Button>
            </Form>
            <ImageCropperModal
                show={showCropperModal}
                imageFile={tempImageFile}
                onSave={handleCropComplete}
                onClose={handleCropCancel}
                cropType={cropType}
            />
        </Container>
    );
}

export default InfoTienda;
