import React, { useState } from 'react';
import { Modal, Form, Row, Col, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Select from 'react-select';
import AlertsClientes from '../Utils/AlertsClientes';
import Departamentos from '../../../../utils/places-colombia';
import Paises from '../../../../utils/paises';
import { QuestionCircle } from 'react-bootstrap-icons';
import APIserver from '../../../../api/axiosConfig';

function ClienteModal({ show, handleClose }) {
    const [selectedDepartamento, setSelectedDepartamento] = useState(null);
    const [selectedCiudad, setSelectedCiudad] = useState(null);
    const [selectedPais, setSelectedPais] = useState({ value: 'Colombia', label: 'Colombia' });

    const [clienteId, setClienteId] = useState('');
    const [nomCliente, setNomCliente] = useState('');
    const [numCliente, setNumCliente] = useState('');
    const [direccion, setDireccion] = useState('');
    const [correoCliente, setcorreoCliente] = useState('');

    const [ciudadesOptions, setCiudadesOptions] = useState([]);

    const handleDepartamentoChange = (selectedOption) => {
        setSelectedDepartamento(selectedOption);
        const departamento = Departamentos.find(dep => dep.departamento === selectedOption.value);
        const ciudades = departamento ? departamento.ciudades.map(ciudad => ({ value: ciudad, label: ciudad })) : [];
        setCiudadesOptions(ciudades);
        setSelectedCiudad(null);
    };

    const handleCiudadChange = (selectedOption) => {
        setSelectedCiudad(selectedOption);
    };

    const handlePaisChange = (selectedOption) => {
        setSelectedPais(selectedOption);
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        const clienteData = {
            clienteId,
            nomCliente,
            correoCliente,
            numCliente,
            ciudad: selectedCiudad ? selectedCiudad.value : '',
            departamento: selectedDepartamento ? selectedDepartamento.value : '',
            direccion,
            pais: selectedPais ? selectedPais.value : 'Colombia'
        };

        try {
            const response = await APIserver.post(`/api/clientes`, clienteData);
            console.log(response);

            if (response.status >= 200 && response.status < 300) {
                AlertsClientes.showSuccessMessage('Cliente creado exitosamente');
                handleClose();
            } else {
                throw new Error('Error al crear el cliente');
            }
        } catch (error) {
            console.error('Error al crear el cliente:', error);
            AlertsClientes.showErrorMessage('Error al procesar la solicitud del cliente');
        }
    };

    const departamentosOptions = Departamentos.map(departamento => ({ value: departamento.departamento, label: departamento.departamento }));
    const paisesOptions = Paises.map(pais => ({ value: pais.es_name, label: pais.es_name }));

    const renderTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Este campo siempre tendrá como valor predefinido "Colombia".
            Si no es necesario se sugiere no modificarlo.
        </Tooltip>
    );

    return (
        <Modal size='lg' show={show} onHide={handleClose} aria-labelledby='create-new-client-modal'>
            <Modal.Header closeButton>
                <Modal.Title>Crear Nuevo Cliente</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleFormSubmit}>
                    <Row>
                        <Col xs={12} md={6}>
                            <Form.Group className='m-3 mt-0' controlId='clienteID'>
                                <Form.Label>Identificacion</Form.Label>
                                <Form.Control type='number' placeholder='Numero de identificacion' value={clienteId} onChange={(e) => setClienteId(e.target.value)} required />
                            </Form.Group>
                            <Form.Group className='m-3' controlId='numCliente'>
                                <Form.Label>Numero de Celular</Form.Label>
                                <Form.Control type='number' placeholder='Numero de Celular' value={numCliente} onChange={(e) => setNumCliente(e.target.value)} required />
                            </Form.Group>
                            <Form.Group className='m-3' controlId='direccion'>
                                <Form.Label>Direccion</Form.Label>
                                <Form.Control type='text' placeholder='Direccion' value={direccion} onChange={(e) => setDireccion(e.target.value)} required />
                            </Form.Group>
                            <Form.Group className='m-3' controlId='departamento'>
                                <Form.Label>Departamento</Form.Label>
                                <Select options={departamentosOptions} value={selectedDepartamento} onChange={handleDepartamentoChange} placeholder='Seleccione' isClearable required/>
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                        <Form.Group className='m-3 mt-0' controlId='ciudad'>
                                <Form.Label>Ciudad</Form.Label>
                                <Select options={ciudadesOptions} value={selectedCiudad} onChange={handleCiudadChange} placeholder='Seleccione' isClearable required/>
                            </Form.Group>
                            <Form.Group className='m-3' controlId='nomCliente'>
                                <Form.Label>Nombre Completo</Form.Label>
                                <Form.Control type='text' placeholder='Nombre Completo' value={nomCliente} onChange={(e) => setNomCliente(e.target.value)} required />
                            </Form.Group>
                            <Form.Group className='m-3' controlId='correoCliente'>
                                <Form.Label>Correo Electronico</Form.Label>
                                <Form.Control type='email' placeholder='usuario@example.com' value={correoCliente} onChange={(e) => setcorreoCliente(e.target.value)} required  />
                            </Form.Group>                           
                            <Form.Group className='m-3' controlId='pais'>
                                <Form.Label>Pais</Form.Label>
                                <OverlayTrigger placement='right' delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
                                    <QuestionCircle className='ms-2' />
                                </OverlayTrigger>
                                <Select options={paisesOptions} value={selectedPais} onChange={handlePaisChange} placeholder='Seleccione un Pais' required/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Modal.Footer>
                        <Button variant='secondary' onClick={handleClose}>
                            Cancelar
                        </Button>
                        <Button variant='primary' type='submit'>
                            Guardar
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default ClienteModal;
