import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Button, Table, ButtonGroup, Pagination, Form } from 'react-bootstrap';
import { EyeFill, Filter, PencilFill, TrashFill } from 'react-bootstrap-icons';
import ClienteEditModal from './editClienteModal';
import AlertsClientes from '../Utils/AlertsClientes';
import FiltrosClientes from './filtrosClientes';
import APIserver from '../../../../api/axiosConfig';

function TablaCliente() {
  const [clientes, setClientes] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [editingCliente, setEditingCliente] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalClients, setTotalClients] = useState(0);
  const [filters, setFilters] = useState({
    clienteID: '',
    nomCliente: '',
    numCliente: '',
    direccion: '',
    departamento: '',
    ciudad: '',
    pais: ''
  });

  // Función para cargar los clientes con paginación y filtros
  const fetchClientes = useCallback(() => {
    const params = {
      page: currentPage,
      limit: rowsPerPage,
      ...filters
    };

    APIserver.get(`/api/clientes`, { params })
      .then(response => {
        setClientes(response.data.clients);
        setTotalClients(response.data.total);
      })
      .catch(error => {
        console.error('Error al obtener los clientes:', error);
      });
  }, [currentPage, rowsPerPage, filters]);

  useEffect(() => {
    fetchClientes();
  }, [currentPage, rowsPerPage, filters, fetchClientes]);

  const handleEditClose = () => {
    setEditingCliente(null);
  };

  const handleEditShow = (cliente) => {
    setEditingCliente(cliente);
  };

  const handleDelete = (idCliente) => {
    const clienteNombre = clientes.find(cliente => cliente.idCliente === idCliente).nomCliente;

    AlertsClientes.showDeleteConfirmation(clienteNombre, () => {
      APIserver.delete(`/api/clientes/${idCliente}`)
        .then(response => {
          fetchClientes(); // Actualizamos la lista de clientes tras la eliminación
          AlertsClientes.showSuccessMessage('El cliente se eliminó correctamente.');
        })
        .catch(error => {
          if (error.response && error.response.status === 400 && error.response.data.error === 'No se puede eliminar el cliente porque tiene facturas asociadas') {
            // Si el servidor retorna un error 400 por facturas asociadas, mostramos una alerta específica
            AlertsClientes.showErrorMessage('No se puede eliminar este cliente porque tiene facturas asociadas.');
          } else {
            // Manejamos otros errores
            AlertsClientes.showErrorMessage('Hubo un error al eliminar el cliente.');
          }
        });
    });
  };

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
    setCurrentPage(1);
  };

  const totalPages = Math.ceil(totalClients / rowsPerPage);

  // Verificar si hay filtros activos
  const isFilterActive = Object.values(filters).some(filter => filter !== '');

  // Función para renderizar los botones de paginación
  const renderPaginationItems = () => {
    const items = [];
    const pageRangeDisplayed = 5; // Mostrar 5 páginas a la vez
    const totalPages = Math.ceil(totalClients / rowsPerPage); // Total de páginas calculado

    let startPage = Math.max(1, currentPage - Math.floor(pageRangeDisplayed / 2));
    let endPage = Math.min(totalPages, startPage + pageRangeDisplayed - 1);

    if (endPage - startPage < pageRangeDisplayed - 1) {
      startPage = Math.max(1, endPage - pageRangeDisplayed + 1);
    }

    if (startPage > 1) {
      items.push(
        <Pagination.First key="first" onClick={() => handlePageChange(1)} />
      );
    }

    if (currentPage > 1) {
      items.push(
        <Pagination.Prev key="prev" onClick={() => handlePageChange(currentPage - 1)} />
      );
    }

    for (let number = startPage; number <= endPage; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => handlePageChange(number)}
        >
          {number}
        </Pagination.Item>
      );
    }

    if (currentPage < totalPages) {
      items.push(
        <Pagination.Next key="next" onClick={() => handlePageChange(currentPage + 1)} />
      );
    }

    if (endPage < totalPages) {
      items.push(
        <Pagination.Last key="last" onClick={() => handlePageChange(totalPages)} />
      );
    }

    return items;
  };

  return (
    <div style={{ boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)" }}>
      <Table responsive className='text-center align-middle border'>
        <thead>
          <tr>
            <th colSpan='9'>
              <div className='d-flex justify-content-between align-items-center'>
                <div className='d-flex flex-wrap text-black'>
                  <Button variant='link' size='sm' onClick={toggleFilters} title='Filtrar'>
                    <Filter className='m-0' size={20} />
                  </Button>
                </div>
                <div>
                  <Form.Select
                    className='ms-2'
                    value={rowsPerPage}
                    onChange={handleChangeRowsPerPage}
                    disabled={isFilterActive}
                  >
                    <option value='5'>5</option>
                    <option value='10'>10</option>
                    <option value='20'>20</option>
                    <option value='50'>50</option>
                  </Form.Select>
                </div>
              </div>
              <div className={`filter-options ${showFilters ? 'show' : ''}`}>
                {/* FILTROS PARA LA TABLA */}
                <FiltrosClientes onFilterChange={handleFilterChange} />
              </div>
            </th>
          </tr>
          <tr>
            <th>Identificacion</th>
            <th>Nombre</th>
            <th>Celular</th>
            <th>Correo</th>
            <th>Direccion</th>
            <th>Departamento</th>
            <th>Ciudad</th>
            <th>Pais</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {clientes.map(cliente => (
            <tr key={cliente.idCliente}>
              <td>{cliente.clienteID}</td>
              <td>
                <Link to={`/admin/Clientes/DetallesClientes/${cliente.idCliente}/`} className='text-decoration-none'>
                  {cliente.nomCliente}
                </Link>
              </td>
              <td>{cliente.numCliente}</td>
              <td>{cliente.correoCliente}</td>
              <td>{cliente.direccion}</td>
              <td>{cliente.departamento}</td>
              <td>{cliente.ciudad}</td>
              <td>{cliente.pais}</td>
              <td>
                <ButtonGroup aria-label='action-button m-0'>
                  <Link to={`/admin/Clientes/DetallesClientes/${cliente.idCliente}/`}>
                    <Button variant='link' className='text-primary' title='Ver cliente'>
                      <EyeFill size={18} />
                    </Button>
                  </Link>
                  <Button onClick={() => handleEditShow(cliente)} variant='link' className='text-warning' title='Editar cliente'>
                    <PencilFill size={18} />
                  </Button>
                  <Button variant='link' className='text-danger' title='Eliminar cliente' onClick={() => handleDelete(cliente.idCliente)}>
                    <TrashFill size={18} />
                  </Button>
                </ButtonGroup>
              </td>
            </tr>
          ))}
        </tbody>
        {!isFilterActive && (
          <tfoot>
            <tr>
              <td colSpan='9'>
                <div className='d-flex justify-content-center align-items-center'>
                  <Pagination>
                    {renderPaginationItems()}
                  </Pagination>
                </div>
              </td>
            </tr>
          </tfoot>
        )}
      </Table>

      {/* MODAL EDITAR CLIENTES */}
      {editingCliente && (
        <ClienteEditModal show={true} cliente={editingCliente} handleClose={handleEditClose} />
      )}
    </div>
  );
}

export default TablaCliente;
