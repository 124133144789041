import React from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import { format } from 'date-fns';

function DetallesClienteFactura({ factura }) {
    const formattedCreationDate = format(new Date(factura.creacion), 'dd/MM/yyyy');
    const formattedDueDate = format(new Date(factura.vence), 'dd/MM/yyyy');

    return (
        <Row className='print-flex'>
            <Col md={8} className='print-half'>
                <Table responsive size='sm' bordered className='shadow-none'>
                    <tbody>
                        <tr>
                            <th className='bg-primary-subtle'>Señores</th>
                            <td colSpan='3'>{factura.nomCliente}</td>
                        </tr>
                        <tr>
                            <th className='bg-primary-subtle'>NIT</th>
                            <td>{factura.clienteID}</td>
                            <th className='bg-primary-subtle'>Teléfono</th>
                            <td>{factura.numCliente}</td>
                        </tr>
                        <tr>
                            <th className='bg-primary-subtle'>Dirección</th>
                            <td>{factura.direccion}</td>
                            <th className='bg-primary-subtle'>Ciudad</th>
                            <td>{factura.ciudad} - {factura.departamento}</td>
                        </tr>
                    </tbody>
                </Table>
            </Col>
            <Col className='print-half'>
                <Table bordered size='sm' className='shadow-none'>
                    <tbody>
                        <tr>
                            <th className='bg-primary-subtle'>Fecha de elaboración</th>
                            <td>{formattedCreationDate}</td>
                        </tr>
                        <tr>
                            <th className='bg-primary-subtle'>Fecha de vencimiento</th>
                            <td>{formattedDueDate}</td>
                        </tr>
                    </tbody>
                </Table>
            </Col>
        </Row>
    );
}

export default DetallesClienteFactura;
