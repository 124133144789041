// File path: src/components/common/Navigation.js

import React, { useState } from "react";
import { Nav, Card } from 'react-bootstrap';
import { HouseDoorFill, GraphUpArrow, GraphDownArrow, PersonLinesFill, Grid, Whatsapp, Bank, ChevronRight, ChevronDown, Clipboard2DataFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import './styles/Navigation.css';

const sections = [
  {
    key: 'ingresos',
    icon: GraphUpArrow,
    label: 'Ingresos',
    subItems: [
      { to: '/admin/IngresosFacturas/', label: 'Facturas' },
      { to: '/admin/PagosRecibidos/', label: 'Pagos Recibidos' },
      { to: '/admin/Pedidos/', label: 'Pedidos' },
    ],
  },
  {
    key: 'gastos',
    icon: GraphDownArrow,
    label: 'Gastos',
    subItems: [
      { to: '/admin/Compras/', label: 'Facturas de Compra' },
      { to: '/admin/PagosRealizados/', label: 'Pagos Realizados' },
    ],
  },
  {
    key: 'contactos',
    icon: PersonLinesFill,
    label: 'Contactos',
    subItems: [
      { to: '/admin/Clientes/', label: 'Clientes' },
      { to: '/admin/Proveedores/', label: 'Proveedores' },
      { to: '/admin/Marketing/', label: 'Marketing'}
    ],
  },
  {
    key: 'inventario',
    icon: Grid,
    label: 'Inventario',
    subItems: [
      { to: '/admin/Categorias/', label: 'Categorias' },
      { to: '/admin/Productos/', label: 'Productos' },
    ],
  },
];

const Sidebar = () => {
  const [activeSection, setActiveSection] = useState(null);
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);

  const handleSectionClick = (key) => {
    setActiveSection((prevKey) => (prevKey === key ? null : key));
  };

  const handleMouseEnter = () => {
    setIsSidebarExpanded(true);
  };

  const handleMouseLeave = () => {
    setIsSidebarExpanded(false);
    setActiveSection(null);
  };

  const isMobileDevice = () => {
    return window.innerWidth < 768; // Define aquí el ancho máximo para dispositivos móviles según tus necesidades
  };

  if (isMobileDevice()) {
    return null; // Devolver null para no renderizar nada en dispositivos móviles
  }

  return (
    <div className="d-none d-sm-block"> {/* Ocultar en dispositivos pequeños (sm) y menores */}
      <div
        className={`d-flex flex-column bg-primary text-white sidebar ${isSidebarExpanded ? 'expanded' : 'collapsed'}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className={`text-center my-4 mb-0 header ${isSidebarExpanded ? 'expanded' : 'collapsed'}`}>
          <h2>{isSidebarExpanded ? 'TuBisnez' : 'TB'}</h2>
        </div>
        <hr className="border-light mx-3" />
        <Nav defaultActiveKey="/admin/Dashboard/" className="flex-column">
          <Nav.Link as={Link} to="/admin/Dashboard/" className="text-white d-flex align-items-center">
            <HouseDoorFill className="m-2" size={24} /> <span className={`menu-text ${isSidebarExpanded ? 'show' : 'hide'}`}>Dashboard</span>
          </Nav.Link>
          {sections.map((section) => (
            <div key={section.key} className="nav-item">
              <Nav.Link
                onClick={() => handleSectionClick(section.key)}
                className="text-white d-flex align-items-center justify-content-between"
                id={`nav-link-${section.key}`}
              >
                <span>
                  <section.icon className="m-2" size={24} />
                  {isSidebarExpanded && <span className={`menu-text ${isSidebarExpanded ? 'show' : 'hide'}`}>{section.label}</span>}
                </span>
                {isSidebarExpanded && (
                  activeSection === section.key ? <ChevronDown /> : <ChevronRight />
                )}
              </Nav.Link>
              {isSidebarExpanded && activeSection === section.key && (
                <div className="collapsible open" style={{ overflow: 'hidden', maxHeight: '200px', transition: 'max-height 0.3s ease-out' }}>
                  <Card className="bg-white text-dark border-0 rounded m-2">
                    <Card.Body className="p-2">
                      {section.subItems.map((subItem) => (
                        <Nav.Link as={Link} to={subItem.to} key={subItem.to} className="text-dark p-2">
                          {subItem.label}
                        </Nav.Link>
                      ))}
                    </Card.Body>
                  </Card>
                </div>
              )}
            </div>
          ))}
          <Nav.Link as={Link} to="/admin/Sucursales/" className="text-white d-flex align-items-center">
            <Whatsapp className="m-2" size={24} /> <span className={`menu-text ${isSidebarExpanded ? 'show' : 'hide'}`}>Sucursales</span>
          </Nav.Link>
          <Nav.Link as={Link} to="/admin/Bancos/" className="text-white d-flex align-items-center">
            <Bank className="m-2" size={24} /> <span className={`menu-text ${isSidebarExpanded ? 'show' : 'hide'}`}>Bancos</span>
          </Nav.Link>
          <Nav.Link as={Link} to='/admin/Reportes/' className="text-white d-flex align-items-center">
            <Clipboard2DataFill className="m-2" size={24}/> <span className={`menu-text ${isSidebarExpanded ? 'show' : 'hide'}`}>Reportes</span>
          </Nav.Link>
        </Nav>
      </div>
    </div>
  );
};

export default Sidebar;
