import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Form, Row, Col, InputGroup } from 'react-bootstrap';
import { Pencil, TrashFill } from 'react-bootstrap-icons';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { handleUpdate } from '../utils/AlertsProductos';
import ImageCropperModal from './ImageCropperModal';
import '../components/editProductoModal.css'; // Importa tu archivo CSS aquí
import APIserver from '../../../../api/axiosConfig';

function EditProductoModal({ show, handleClose, producto, fetchProductos }) {
  const [formData, setFormData] = useState({
    referencia: '',
    nombre: '',
    stock: 0,
    precioUnidad: 0,
    precioMayor: 0,
    idCategoria: '',
    categoria: '',
    descripcion: ''
  });

  const [categorias, setCategorias] = useState([]);
  const [selectedCategoria, setSelectedCategoria] = useState(null);
  const [imagenes, setImagenes] = useState([null, null, null, null]);
  const [showCropper, setShowCropper] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(null);
  const [tempImage, setTempImage] = useState(null);
  const [tallas, setTallas] = useState([]);
  const [colores, setColores] = useState([]);
  const [selectedTallas, setSelectedTallas] = useState([]);
  const [selectedColores, setSelectedColores] = useState([]);

  const fetchTallas = async () => {
    try {
      const response = await APIserver.get(`/api/atributos-product/tallas`);
      if (response.data && Array.isArray(response.data)) {
        const formattedTallas = response.data.map(talla => ({
          value: talla.idTalla,
          label: talla.nombreTalla
        }));
        setTallas(formattedTallas);
      } else {
        setTallas([]); // Si la respuesta no es un array, inicializamos con un array vacío
      }
    } catch (error) {
      console.error('Error fetching tallas:', error);
      Swal.fire({
        title: 'Error',
        text: 'No se pudieron cargar las tallas',
        icon: 'error',
        iconColor: '#da292e'
      });
      setTallas([]); // En caso de error, también inicializamos con un array vacío
    }
  };

  const fetchColores = async () => {
    try {
      const response = await APIserver.get(`/api/atributos-product/colores`);
      if (response.data && Array.isArray(response.data)) {
        const formattedColores = response.data.map(color => ({
          value: color.idColor,
          label: color.nombreColor
        }));
        setColores(formattedColores);
      } else {
        setColores([]); // Si la respuesta no es un array, inicializamos con un array vacío
      }
    } catch (error) {
      console.error('Error fetching colores:', error);
      Swal.fire({
        title: 'Error',
        text: 'No se pudieron cargar los colores',
        icon: 'error',
        iconColor: '#da292e'
      });
      setColores([]); // En caso de error, también inicializamos con un array vacío
    }
  };

  const fileInputRefs = useRef([]);

  useEffect(() => {
    // Cargar tallas y colores antes de procesar los valores iniciales del producto
    fetchCategorias();
    fetchTallas();
    fetchColores();
  }, []);

  useEffect(() => {
    if (producto && tallas.length > 0 && colores.length > 0) {
      setFormData({
        referencia: producto.referencia || '',
        nombre: producto.nombre || '',
        stock: producto.stock || 0,
        precioUnidad: producto.precioUnidad || 0,
        precioMayor: producto.precioMayor || 0,
        idCategoria: producto.idCategoria || '',
        categoria: producto.categoria || '',
        descripcion: producto.descripcion || '',
      });

      setSelectedCategoria({
        value: producto.idCategoria,
        label: producto.categoria
      });

      fetchImagenes(producto.idProducto);

      // Convertir las tallas y colores existentes en IDs utilizando las opciones cargadas en tallas y colores
      const tallasArray = producto.tallas ? producto.tallas.split(',') : [];
      const coloresArray = producto.colores ? producto.colores.split(',') : [];

      const newSelectedTallas = tallasArray.map(talla => {
        const tallaObj = tallas.find(t => t.label === talla.trim());
        return tallaObj ? { value: tallaObj.value, label: tallaObj.label } : null;
      }).filter(t => t); // Filtrar nulos

      const newSelectedColores = coloresArray.map(color => {
        const colorObj = colores.find(c => c.label === color.trim());
        return colorObj ? { value: colorObj.value, label: colorObj.label } : null;
      }).filter(c => c); // Filtrar nulos

      setSelectedTallas(newSelectedTallas);
      setSelectedColores(newSelectedColores);
    }
  }, [producto, tallas, colores]); // Asegurarse de que se ejecuta después de que tallas y colores se hayan cargado


  const fetchCategorias = async () => {
    try {
      const response = await APIserver.get(`/api/categorias`);

      // Aquí simplemente usamos el campo 'label' ya que la respuesta lo tiene así
      const formattedCategorias = response.data.map(categoria => ({
        value: categoria.idCategoria,
        label: categoria.label // Usamos el campo 'label' directamente
      }));

      setCategorias(formattedCategorias); // Asignamos las categorías al estado
    } catch (error) {
      console.error('Error fetching categorias:', error);
      Swal.fire({
        title: 'Error',
        text: 'No se pudieron cargar las categorías',
        icon: 'error',
        iconColor: '#da292e',
      });
    }
  };

  const fetchImagenes = async (idProducto) => {
    try {
      const response = await APIserver.get(`/api/productos/${idProducto}/imagenes`);

      // El array de imágenes devuelto por el backend se establece directamente en el estado
      setImagenes(response.data);
    } catch (error) {
      console.error('Error fetching imagenes:', error);
    }
  };

  const handleTallasChange = (selectedOptions) => {
    console.log("Nuevas tallas seleccionadas:", selectedOptions);
    setSelectedTallas(selectedOptions);
  };

  const handleColoresChange = (selectedOptions) => {
    console.log("Nuevos colores seleccionados:", selectedOptions);
    setSelectedColores(selectedOptions);
  };

  const handleCategoriaChange = (selectedOption) => {
    setSelectedCategoria(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      idCategoria: selectedOption ? selectedOption.value : '',
      categoria: selectedOption ? selectedOption.label : ''
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleImagenChange = (e, index) => {
    const imagen = e.target.files[0];

    if (imagen && imagen instanceof Blob) {
      setCurrentImageIndex(index);

      // Crear una URL temporal para la imagen seleccionada y guardarla en tempImage
      const imageURL = URL.createObjectURL(imagen);
      setTempImage(imageURL);

      // Mostrar el cropper modal con la nueva imagen seleccionada
      setShowCropper(true);
    } else {
      Swal.fire({
        title: 'Archivo no válido',
        text: 'Por favor selecciona una imagen válida.',
        icon: 'error',
        iconColor: '#da292e'
      });
    }
  };

  const handleCroppedImageSave = (croppedFile) => {
    const updatedImagenes = [...imagenes];

    // Reemplaza la imagen en el índice actual con el archivo recortado
    updatedImagenes[currentImageIndex] = croppedFile;

    setImagenes(updatedImagenes);
    setTempImage(null);
    setShowCropper(false);
  };

  const handleCancelCrop = () => {
    // Resetea el índice de la imagen actual y la imagen temporal
    setCurrentImageIndex(null);
    setTempImage(null);

    // Cierra el modal del cropper
    setShowCropper(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataWithImages = new FormData();
      formDataWithImages.append('referencia', formData.referencia);
      formDataWithImages.append('nombre', formData.nombre);
      formDataWithImages.append('stock', formData.stock);
      formDataWithImages.append('precioUnidad', formData.precioUnidad);
      formDataWithImages.append('precioMayor', formData.precioMayor);
      formDataWithImages.append('idCategoria', formData.idCategoria);
      formDataWithImages.append('descripcion', formData.descripcion);

      selectedTallas.forEach(talla => {
        if (typeof talla.value === 'number') {
          formDataWithImages.append('tallas[]', talla.value);
        }
      });

      selectedColores.forEach(color => {
        if (typeof color.value === 'number') {
          formDataWithImages.append('colores[]', color.value);
        }
      });

      imagenes.forEach((img, index) => {
        if (img && img instanceof File) {
          formDataWithImages.append(`imagen${index}`, img);
        }
      });

      await handleUpdate(producto.idProducto, formDataWithImages, handleClose, fetchProductos);
    } catch (error) {
      console.error('Error actualizando el producto:', error);
      await Swal.fire({
        title: 'Error',
        text: 'Ocurrió un error al actualizar el producto',
        icon: 'error',
        iconColor: '#da292e'
      });
    }
  };

  const triggerFileInput = (index) => {
    if (fileInputRefs.current[index]) {
      fileInputRefs.current[index].click();
    }
  };

  const handleDeleteImage = async (index) => {
    const imagen = imagenes[index];
    if (!imagen) return;

    const result = await Swal.fire({
      title: '¿Estás seguro?',
      text: 'Esta acción eliminará la imagen del producto.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar',
      iconColor: '#f4bd61',
      confirmButtonColor: '#2c4cc4',
      cancelButtonColor: '#da292e'
    });

    if (result.isConfirmed) {
      try {
        await APIserver.delete(`/api/productos/${producto.idProducto}/imagenes/${imagen.split('/').pop()}`);
        const updatedImagenes = [...imagenes];
        updatedImagenes[index] = null;
        setImagenes(updatedImagenes);

        await Swal.fire({
          title: 'Eliminado',
          text: 'La imagen ha sido eliminada',
          icon: 'success',
          iconColor: '#2fb380',
        });
      } catch (error) {
        console.error('Error al eliminar la imagen:', error);
        await Swal.fire({
          title: 'Error',
          text: 'Ocurrió un error al eliminar la imagen',
          icon: 'error',
          iconColor: '#da292e',
        });
      }
    }
  };
  return (
    <>
      <Modal size='xl' show={show} onHide={handleClose} aria-labelledby="modal-editar-producto" dialogClassName="custom-modal-width">
        <Modal.Header closeButton>
          <Modal.Title>Editar Producto</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12} md={4}>
                <Form.Group className='m-3 mt-0'>
                  <Form.Label>Referencia</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Referencia del producto'
                    name='referencia'
                    value={formData.referencia}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group className='m-3'>
                  <Form.Label>Stock</Form.Label>
                  <Form.Control
                    type='number'
                    placeholder='Cantidad en stock'
                    name='stock'
                    value={formData.stock}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group className='m-3'>
                  <Form.Label>Precio al por mayor</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control
                      type='number'
                      placeholder='Precio al por mayor'
                      name='precioMayor'
                      value={formData.precioMayor}
                      onChange={handleChange}
                      required
                    />
                    <InputGroup.Text>COP</InputGroup.Text>
                  </InputGroup>
                </Form.Group>
                <Form.Group controlId="tallasProducto" className="form-group-tallas">
                  <Form.Label className="form-label">Motivos</Form.Label>
                  <Select
                    className="select-container"
                    isMulti
                    options={tallas} // Opciones disponibles
                    value={selectedTallas} // Valores seleccionados
                    onChange={handleTallasChange} // Actualización del estado
                  />
                </Form.Group>
                <Form.Group controlId="descripcion" className="form-group-custom form-group-descripcion">
                  <Form.Label className="form-label">Descripción</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={6}
                    className="form-control-textarea"
                    placeholder="Descripción del producto"
                    name="descripcion"
                    value={formData.descripcion}
                    onChange={(e) => setFormData({ ...formData, descripcion: e.target.value })}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={4}>
                <Form.Group className='m-3 mt-0'>
                  <Form.Label>Nombre</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Nombre del producto'
                    name='nombre'
                    value={formData.nombre}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group className='m-3'>
                  <Form.Label>Precio por unidad</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>$</InputGroup.Text>
                    <Form.Control
                      type='number'
                      placeholder='Precio por unidad'
                      name='precioUnidad'
                      value={formData.precioUnidad}
                      onChange={handleChange}
                      required
                    />
                    <InputGroup.Text>COP</InputGroup.Text>
                  </InputGroup>
                </Form.Group>
                <Form.Group controlId='categoriaProducto' className='m-3' style={{ zIndex: "30", position: "relative" }}>
                  <Form.Label>Categoría</Form.Label>
                  <Select
                    options={categorias}
                    value={selectedCategoria}
                    onChange={handleCategoriaChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="coloresProducto" className="m-3" style={{ zIndex: "20", position: "relative" }}>
                  <Form.Label>Colores</Form.Label>
                  <Select
                    isMulti
                    options={colores} // Opciones disponibles
                    value={selectedColores} // Valores seleccionados
                    onChange={handleColoresChange} // Actualización del estado
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={4}>
                <Form.Group className='m-3 mt-0'>
                  <Form.Label className="text-center d-block">Imágenes del Producto</Form.Label>
                  {/* Primera imagen grande y cuadrada */}
                  <Row className="d-flex justify-content-center mb-2">
                    <Col className="text-center mx-auto position-relative">
                      <div className="image-container large-image mx-auto">
                        {imagenes[0] ? (
                          <img
                            src={typeof imagenes[0] === 'string' ? imagenes[0] : URL.createObjectURL(imagenes[0])}
                            alt={`Imagen 1`}
                            className="image centered-image"
                          />
                        ) : (
                          <div className="no-image">
                            No hay imagen
                          </div>
                        )}
                        <div className="image-actions">
                          <div className="d-flex">
                            <Button
                              variant="white"
                              className="rounded-circle me-2"
                              onClick={() => triggerFileInput(0)}
                            >
                              <Pencil color="#265CB6" />
                            </Button>
                          </div>
                        </div>
                      </div>
                      <input
                        ref={el => fileInputRefs.current[0] = el}
                        type="file"
                        accept="image/*"
                        className="d-none"
                        onChange={(e) => handleImagenChange(e, 0)}
                      />
                    </Col>
                  </Row>
                  {/* Imágenes en fila, cuadradas y grandes */}
                  <Row>
                    {imagenes.slice(1, 4).map((img, index) => (
                      <Col key={index + 1} md={4} className="mb-3">
                        <div className="image-container small-image mx-auto">
                          {img ? (
                            <img
                              src={typeof img === 'string' ? img : URL.createObjectURL(img)}
                              alt={`Imagen ${index + 2}`}
                              className="image"
                            />
                          ) : (
                            <div className="no-image">
                              No hay imagen
                            </div>
                          )}
                          <div className="image-actions">
                            <div className="d-flex">
                              <Button
                                variant="white"
                                className="button-white rounded-circle me-2"
                                onClick={() => triggerFileInput(index + 1)}
                              >
                                <Pencil color="#265CB6" />
                              </Button>
                              {img && (
                                <Button
                                  variant="white"
                                  className="rounded-circle button-white"
                                  onClick={() => handleDeleteImage(index + 1)}
                                >
                                  <TrashFill color="#265CB6" />
                                </Button>
                              )}
                            </div>
                          </div>
                        </div>
                        <input
                          ref={el => fileInputRefs.current[index + 1] = el}
                          type="file"
                          accept="image/*"
                          className="d-none"
                          onChange={(e) => handleImagenChange(e, index + 1)}
                        />
                      </Col>
                    ))}
                  </Row>
                </Form.Group>
              </Col>
            </Row>
            <Modal.Footer>
              <Button variant='secondary' onClick={handleClose}>
                Cancelar
              </Button>
              <Button variant='primary' type='submit'>
                Guardar Cambios
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
      {showCropper && currentImageIndex !== null && (
        <ImageCropperModal
          show={showCropper}
          imageFile={tempImage} // Pasa la imagen temporal al cropper
          onSave={handleCroppedImageSave}
          onClose={handleCancelCrop}
          cropType="square"
        />
      )}
    </>
  );
}

export default EditProductoModal;
