import React, { useState } from 'react';
import { Table, Pagination, Button, Form, Dropdown, Card } from 'react-bootstrap';
import { ThreeDotsVertical, EyeFill, PencilFill, TrashFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import CustomToggle from '../../styles/customToggle';
import EditarPagoRes from '../../PagosResibidos/componentes/editarPagoRes';
import AlertsPagosRecibidos from '../../PagosResibidos/componentes/Utils/AlertsPagosRecibidos';
import APIserver from '../../../../api/axiosConfig';

function TablaPagosFactura({ pagos }) {
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [currentPage, setCurrentPage] = useState(1);
    const [editShow, setShowEdit] = useState(false);  // Estado para controlar la visibilidad del modal de edición
    const [pagoToEdit, setPagoToEdit] = useState(null);  // Estado para almacenar el pago seleccionado para editar

    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setCurrentPage(1); // Resetear a la primera página al cambiar el número de filas por página
    };

    const handlePagination = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const handleEditShow = (pago) => {
        setPagoToEdit(pago);  // Establecer el pago a editar en el estado
        setShowEdit(true);  // Mostrar el modal de edición
    };

    const handleEditClose = () => {
        setShowEdit(false);
        setPagoToEdit(null);  // Limpiar el pago a editar cuando se cierra el modal
    };

    const handleDelete = (idPago) => {
        AlertsPagosRecibidos.showDeleteConfirmation(idPago, async () => {
            try {
                await APIserver.delete(`/api/pagosrecibidos/pagos/${idPago}`);
                AlertsPagosRecibidos.showDeleteConfirmationserver();
                // Refrescar la lista de pagos si es necesario
            } catch (error) {
                AlertsPagosRecibidos.showDeleteErrorMessage();
                console.error('Error al eliminar el pago:', error);
            }
        });
    };

    // Paginación de los pagos
    const startIndex = (currentPage - 1) * rowsPerPage;
    const paginatedPagos = pagos.slice(startIndex, startIndex + rowsPerPage);

    return (
        <Card>
            <Card.Header>Pagos Realizados</Card.Header>
            <Table responsive className='text-center align-middle border'>
                <thead>
                    <tr>
                        <th colSpan={7}>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div>
                                    <Form.Select
                                        className='ms-2'
                                        value={rowsPerPage}
                                        onChange={handleChangeRowsPerPage}
                                    >
                                        <option value='5'>5</option>
                                        <option value='10'>10</option>
                                        <option value='20'>20</option>
                                        <option value='50'>50</option>
                                    </Form.Select>
                                </div>
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <th>Pago</th>
                        <th>Factura</th>
                        <th>Cliente</th>
                        <th>Creacion</th>
                        <th>Cuenta</th>
                        <th>Total</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {paginatedPagos.map(pago => (
                        <tr key={pago.idPago}>
                            <td>
                                <Link to={`/admin/PagosRecibidos/DetallesPago/${pago.idPago}/`} className='text-decoration-none'>
                                    {pago.numPagoRe}
                                </Link>
                            </td>
                            <td>{pago.numFacV}</td>
                            <td>
                                <Link to={`/admin/Clientes/DetallesClientes/${pago.idCliente}/`} className='text-decoration-none'>
                                    {pago.nomCliente || 'Cargando...'}
                                </Link>
                            </td>
                            <td>{new Date(pago.fecha).toLocaleDateString()}</td>
                            <td>
                                <Link to={`/admin/Bancos/DetallesBancos/${pago.idBanco}/`} className='text-decoration-none'>
                                    {pago.nomBanco || 'Cargando...'}
                                </Link>
                            </td>
                            <td>{pago.monto.toLocaleString('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 0 })}</td>
                            <td>
                                <Dropdown>
                                    <Dropdown.Toggle as={CustomToggle} variant='link'>
                                        <ThreeDotsVertical />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Link to={`/admin/PagosRecibidos/DetallesPago/${pago.idPago}/`}>
                                            <Button variant='link' className='text-primary' title='ver pago'>
                                                <EyeFill size={18} />
                                            </Button>
                                        </Link>
                                        <Button 
                                            variant='link' 
                                            className='text-warning' 
                                            title='editar pago' 
                                            onClick={() => handleEditShow(pago)}  // Al hacer clic, se mostrará el modal de edición
                                        >
                                            <PencilFill size={18} />
                                        </Button>
                                        <Button 
                                            variant='link' 
                                            className='text-danger' 
                                            title='eliminar pago' 
                                            onClick={() => handleDelete(pago.idPago)}
                                        >
                                            <TrashFill size={18} />
                                        </Button>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </td>
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan={7}>
                            <div className='d-flex justify-content-center align-items-center'>
                                <Pagination>
                                    <Pagination.Prev
                                        onClick={() => handlePagination(currentPage - 1)}
                                        disabled={currentPage === 1}
                                    />
                                    {Array.from({ length: Math.ceil(pagos.length / rowsPerPage) }, (_, i) => (
                                        <Pagination.Item
                                            key={i + 1}
                                            active={i + 1 === currentPage}
                                            onClick={() => handlePagination(i + 1)}
                                        >
                                            {i + 1}
                                        </Pagination.Item>
                                    ))}
                                    <Pagination.Next
                                        onClick={() => handlePagination(currentPage + 1)}
                                        disabled={currentPage === Math.ceil(pagos.length / rowsPerPage)}
                                    />
                                </Pagination>
                            </div>
                        </td>
                    </tr>
                </tfoot>
            </Table>

            {/* Modal Editar Pago */}
            <EditarPagoRes 
                show={editShow} 
                handleClose={handleEditClose} 
                pago={pagoToEdit}  // Pasar el pago a editar
                fetchPagos={() => { /* Aquí puedes definir cómo refrescar la lista si es necesario */ }}  
            />
        </Card>
    );
}

export default TablaPagosFactura;