import React, { useState } from 'react';
import { Dropdown, Button } from 'react-bootstrap';
import { BagFill, NutFill, Upload } from 'react-bootstrap-icons';
import '../styles/tables.css';
import CustomToggle from '../styles/customToggle';
import CrearCompra from './componentes/crearCompraModal';
import CardCompra from './componentes/cardCompras';
import TablaCompra from './componentes/tablaCompras';

function AdminCompras() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className='mx-2 mt-3'>
      <div className='d-flex flex-column flex-md-row align-items-center justify-content-between'>
        <p className='mb-2 mb-md-0 text-center'>
          Administra las compras que realizas para medir los gastos de tu negocio. 
        </p>
        <div className='d-flex mt-2 mt-md-0'>
          <Button variant='primary' onClick={handleShow}>
            <BagFill /> Nueva Compra
          </Button>
          <Dropdown>
            <Dropdown.Toggle as={CustomToggle} variant='secondary' className='ms-2'>
              <NutFill />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item><Upload className='text-danger mx-1' /> Exportar</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      {/* Modal crear nueva compra */}
      <CrearCompra show={show} handleClose={handleClose} />
      {/* Card de Stats */}
      <div className='mt-4'>
        <CardCompra />
      </div>
      {/* Tabla de compras */}
      <div className='my-2'>
        <TablaCompra />
      </div>
    </div>
  );
}

export default AdminCompras;