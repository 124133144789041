// File path: src/components/Charts.js

import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Form } from 'react-bootstrap';
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import useStats from '../../../../hooks/useStats';

const Charts = () => {
  const [filter, setFilter] = useState('mes');
  const { stats, loading } = useStats(filter);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (stats) {
      //console.log('Datos recibidos:', stats);

      let transformedData = [];

      switch (filter) {
        case 'dia':
          transformedData = [
            {
              name: 'Hoy',
              ventas: stats.ventas || 0,
              ingresos: stats.ingresos || 0,
              compras: stats.compras || 0,
              gastos: stats.gastos || 0
            }
          ];
          break;

        case 'semana':
          if (stats.dias && Array.isArray(stats.dias)) {
            transformedData = stats.dias.map((dia) => ({
              name: dia.fecha,
              ventas: dia.ventas || 0,
              ingresos: dia.ingresos || 0,
              compras: dia.compras || 0,
              gastos: dia.gastos || 0,
            }));
          }
          break;

        case 'mes':
          if (stats.meses && Array.isArray(stats.meses)) {
            transformedData = stats.meses.map((mes) => ({
              name: mes.nombre,
              ventas: mes.ventas || 0,
              ingresos: mes.ingresos || 0,
              compras: mes.compras || 0,
              gastos: mes.gastos || 0,
            }));
          }
          break;

        case 'ano':
          if (stats.anos && Array.isArray(stats.anos)) {
            transformedData = stats.anos.map((ano) => ({
              name: ano.anio,
              ventas: ano.ventas || 0,
              ingresos: ano.ingresos || 0,
              compras: ano.compras || 0,
              gastos: ano.gastos || 0,
            }));
          }
          break;

        default:
          transformedData = [];
      }

      setChartData(transformedData);
    }
  }, [stats, filter]);

  // Función para formatear valores como moneda COP sin decimales
  const formatCurrency = (value) => {
    return value?.toLocaleString('es-CO', { 
      style: 'currency', 
      currency: 'COP',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0 
    }) || '$0';
  };

  if (loading) return <div>Cargando...</div>;

  return (
    <Card bg='light' className='shadow-none border-0 rounded-4 mt-4 mt-md-0'>
      <Card.Body>
        <div className='d-flex justify-content-end w-100 mb-3 text-center text-md-center'>
          <Form.Group controlId='filterSelect' className='d-flex align-items-center'>
            <Form.Label className='me-2 mb-0'>Filtrar:</Form.Label>
            <Form.Control
              as='select'
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              size='sm'
            >
              <option value="dia">Día</option>
              <option value="semana">Semana</option>
              <option value="mes">Mes</option>
              <option value="ano">Año</option>
            </Form.Control>
          </Form.Group>
        </div>
        <Row>
          <Col md={12}>
            <ResponsiveContainer width="100%" height={400}>
              <ComposedChart
                data={chartData.length > 0 ? chartData : [{ name: 'No data', ventas: 0, ingresos: 0, compras: 0, gastos: 0 }]}
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 20,
                  left: 20,
                }}
              >
                <CartesianGrid stroke="#f5f5f5" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip 
                  formatter={(value) => formatCurrency(value)} // Formatea los valores en el tooltip
                />
                <Legend />
                <Bar dataKey="ventas" barSize={20} fill="#3459e6" />
                <Bar dataKey="compras" barSize={20} fill="#f4bd61" />
                <Line type="monotone" dataKey="ingresos" stroke="#2fb380" />
                <Line type="monotone" dataKey="gastos" stroke="#da292e" />
              </ComposedChart>
            </ResponsiveContainer>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default Charts;