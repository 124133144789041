import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Badge } from 'react-bootstrap';
import { Card, CardActionArea, CardContent, CardMedia, Skeleton } from '@mui/material';
import { ArrowRightCircleFill } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import '../styles/CardProductos.css';
import { API } from '../../../api/URL_API';
import { useSucursal } from '../../../contexts/SucursalContext';

function NewProduct() {
    const [products, setProducts] = useState([]);
    const [hoveredImage, setHoveredImage] = useState({});
    const { idSucursal } = useSucursal();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);

    // Efecto para obtener productos nuevos
    useEffect(() => {
        if (idSucursal) {
            setIsLoading(true);
            axios.get(`${API}/api/mostrarCategorias/productos-nuevos`, { params: { limit: 4, idSucursal } })
                .then(response => {
                    setProducts(response.data);
                    setIsLoading(false);
                })
                .catch(error => {
                    console.error('Error fetching new products:', error);
                    setIsLoading(false);
                });
        }
    }, [idSucursal]);

    const formatCop = (value) => {
        return value?.toLocaleString('es-CO', {
            style: 'currency',
            currency: 'COP',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        }) || '$0';
    };

    const handleViewMore = () => {
        if (idSucursal) {
            navigate(`/Collections/${idSucursal}/Products/NewProductos`);
        }
    };

    const handleMouseEnter = (productId, img1) => {
        if (img1) {
            setHoveredImage(prev => ({ ...prev, [productId]: img1 }));
        }
    };

    const handleMouseLeave = (productId) => {
        setHoveredImage(prev => ({ ...prev, [productId]: null }));
    };

    return (
        <Container fluid className='mt-2'>
            <Row className='mb-2'>
                <div className='d-flex align-items-center justify-content-between w-100'>
                    <h4 className='ms-2 text1-cr fw-bold fst-italic'>Lo más nuevo ⚡</h4>
                    <button
                        variant='link'
                        className='text-decoration-none button-cr fst-italic'
                        onClick={handleViewMore}
                    >
                        Ver más <ArrowRightCircleFill size={18} className='text-light ms-2' />
                    </button>
                </div>
            </Row>
            <Row>
                {isLoading
                    ? Array.from({ length: 4 }).map((_, idx) => (
                        <Col key={idx} xs={6} sm={6} md={3} className='d-flex justify-content-center mb-3'>
                            <Card
                                className="product-card mt-2"
                                style={{ boxShadow: '0 4px 8px rgba(43, 35, 94, 0.3), 0 6px 20px rgba(43, 35, 94, 0.3)', borderRadius: '15px' }}
                            >
                                <CardActionArea>
                                    <Skeleton variant="rectangular" height={300} width={300} animation="wave" />
                                    <CardContent className='text-center text1-cr'>
                                        <Skeleton variant="text" width="80%" height={30} />
                                        <Skeleton variant="text" width="60%" height={25} />
                                        <Skeleton variant="text" width="50%" height={20} />
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Col>
                    ))
                    : products.map((product) => (
                        <Col key={product.idProducto} xs={6} sm={6} md={3} className='d-flex justify-content-center mb-3'>
                            <Card
                                className="product-card mt-2"
                                onClick={handleViewMore}
                                style={{ boxShadow: '0 4px 8px rgba(43, 35, 94, 0.3), 0 6px 20px rgba(43, 35, 94, 0.3)', borderRadius: '15px' }}
                            >
                                <CardActionArea
                                    onMouseEnter={() => handleMouseEnter(product.idProducto, product.img1)}
                                    onMouseLeave={() => handleMouseLeave(product.idProducto)}
                                >
                                    <div className="badge-container">
                                        <Badge bg="danger" className="new-badge">Nuevo</Badge>
                                        <CardMedia
                                            component='img'
                                            height='300'
                                            image={ 
                                                hoveredImage[product.idProducto]
                                                    ? `${hoveredImage[product.idProducto]}`
                                                    : `${product.imgProducto}`
                                            }
                                            alt={`product-${product.nomProducto}`}
                                            className='card-media-image'
                                        />
                                    </div>
                                    <CardContent className='text-center text1-cr'>
                                        <h4 className='fw-bold'>{product.nomProducto}</h4>
                                        <h5>{formatCop(product.precioU)}</h5>
                                        <h6 className='mb-3 fs-6 text-muted'>Gran Mayorista {formatCop(product.precioM)}</h6>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Col>
                    ))}
            </Row>
        </Container>
    );
}

export default NewProduct;
