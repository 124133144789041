import React, { useState } from 'react';
import { FormControl, Button, ButtonGroup } from 'react-bootstrap';
import Select from 'react-select';  // Importa react-select
import { Search, XSquare } from 'react-bootstrap-icons';

function FiltroFacturas({ onFilter }) {
    const [factura, setFactura] = useState('');
    const [cliente, setCliente] = useState('');
    const [creacion, setCreacion] = useState('');
    const [vence, setVence] = useState('');
    const [total, setTotal] = useState('');
    const [fraElectronica, setFraElectronica] = useState('');
    const [estado, setEstado] = useState('');  // Nuevo estado para el filtro de estado

    // Opciones para el select de estados
    const estadoOptions = [
        { value: 'pendiente', label: 'Pendiente' },
        { value: 'pagado', label: 'Pagado' },
        { value: 'vencido', label: 'Vencido' },
        { value: 'anulado', label: 'Anulado' }
    ];

    // Función para limpiar valores numéricos
    const formatValue = (value) => {
        return value.replace(/[^0-9]/g, '');
    };

    // Función para validar y formatear fechas en formato YYYY-MM-DD
    const formatDate = (value) => {
        return value;
    };

    const handleSearch = () => {
        onFilter({
            factura,
            cliente,
            creacion: formatDate(creacion),
            vence: formatDate(vence),
            total: formatValue(total),
            fraElectronica,
            estado: estado ? estado.value : ''  // Envía el valor del estado si está seleccionado
        });
    };

    const handleClear = () => {
        setFactura('');
        setCliente('');
        setCreacion('');
        setVence('');
        setTotal('');
        setFraElectronica('');
        setEstado('');  // Limpiar el select de estado
        onFilter({});
    };

    return (
        <div className='d-flex align-items-center m-3'>
            <FormControl
                placeholder='Factura'
                className='me-2'
                value={factura}
                onChange={(e) => setFactura(e.target.value)}
            />
            <FormControl
                placeholder='Cliente'
                className='me-2'
                value={cliente}
                onChange={(e) => setCliente(e.target.value)}
            />
            <FormControl
                type='date'
                className='me-2'
                value={creacion}
                onChange={(e) => setCreacion(e.target.value)}
            />
            <FormControl
                type='date'
                className='me-2'
                value={vence}
                onChange={(e) => setVence(e.target.value)}
            />
            <FormControl
                placeholder='Total'
                className='me-2'
                value={total}
                onChange={(e) => setTotal(e.target.value)}
            />
            <FormControl
                placeholder='FRA.Electronica'
                className='me-2'
                value={fraElectronica}
                onChange={(e) => setFraElectronica(e.target.value)}
            />
            <Select
                className='me-2 w-100'
                options={estadoOptions}
                value={estado}
                onChange={setEstado}
                placeholder='Estado'
                menuPlacement="auto"  // Despliega hacia arriba o abajo dependiendo del espacio
                menuPortalTarget={document.body}  // Renderiza el menú en el body
                styles={{
                    menuPortal: base => ({ ...base, zIndex: 9999 }),  // Asegura que el menú esté siempre al frente
                    container: base => ({ ...base, position: 'relative', zIndex: 1050 }),
                }}
            />
            <ButtonGroup>
                <Button variant='primary' title='Buscar' onClick={handleSearch}><Search /></Button>
                <Button variant='danger' title='Limpiar' onClick={handleClear}><XSquare /></Button>
            </ButtonGroup>
        </div>
    );
}

export default FiltroFacturas;
