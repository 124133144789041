import React from 'react';
import { Outlet } from 'react-router-dom';
import '../../index.css';
import { CarritoProvider } from '../../pages/Public/ventaUnidad/componenetes/CarritoContext'; // Ajusta la ruta según tu estructura
import { SearchProvider } from '../../pages/Public/ventaUnidad/componenetes/SearchContext'; // Ajusta la ruta según tu estructurai
import { SucursalProvider } from '../../contexts/SucursalContext';

const PublicLayout = () => {
    return (
        <SucursalProvider>
            <SearchProvider>
                <CarritoProvider>
                    <div className='bg-light'>
                        <Outlet />
                    </div>
                </CarritoProvider>
            </SearchProvider>
        </SucursalProvider>
    );
}

export default PublicLayout;