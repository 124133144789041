import React from 'react';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <button
    type="button"
    className="btn btn-secondary ms-2"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </button>
));

export default CustomToggle;