import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';

function CardDetallesProveedor({ totalCompras, totalPagado, totalVencida, totalPendiente }) {
    // Valores predeterminados para evitar errores
    const defaultTotal = { count: 0, amount: 0 };

    return (
        <div className='d-flex justify-content-center'>
            <Row className='text-center'>
                <Col xs={12} md={3} className='mb-4 d-flex justify-content-center'>
                    <Card bg='info' text='white' style={{ width: '18rem' }}>
                        <Card.Body>
                            <Card.Text>Compras: <b>{(totalCompras && totalCompras.count) || defaultTotal.count}</b></Card.Text>
                            <Card.Text><b>${((totalCompras && totalCompras.amount) || defaultTotal.amount).toLocaleString()}</b></Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={3} className='mb-4 d-flex justify-content-center'>
                    <Card bg='success' text='white' style={{ width: '18rem' }}>
                        <Card.Body>
                            <Card.Text>Compras pagadas: <b>{(totalPagado && totalPagado.count) || defaultTotal.count}</b></Card.Text>
                            <Card.Text><b>${((totalPagado && totalPagado.amount) || defaultTotal.amount).toLocaleString()}</b></Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={3} className='mb-4 d-flex justify-content-center'>
                    <Card bg='warning' text='white' style={{ width: '18rem' }}>
                        <Card.Body>
                            <Card.Text>Compras pendientes: <b>{(totalPendiente && totalPendiente.count) || defaultTotal.count}</b></Card.Text>
                            <Card.Text><b>${((totalPendiente && totalPendiente.amount) || defaultTotal.amount).toLocaleString()}</b></Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={3} className='mb-4 d-flex justify-content-center'>
                    <Card bg='danger' text='white' style={{ width: '18rem' }}>
                        <Card.Body>
                            <Card.Text>Compras vencidas: <b>{(totalVencida && totalVencida.count) || defaultTotal.count}</b></Card.Text>
                            <Card.Text><b>${((totalVencida && totalVencida.amount) || defaultTotal.amount).toLocaleString()}</b></Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default CardDetallesProveedor;
