import React, { useState } from 'react';
import { Card, Table } from 'react-bootstrap';

// Función para formatear el número con separador de miles y dos decimales
const formatCurrency = (value) => {
    return new Intl.NumberFormat('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    }).format(value);
};

const ProductoRow = ({ producto }) => {
    const calculateTotal = () => {
        const cantidad = parseFloat(producto.cantidad) || 0;
        const valorUnitario = parseFloat(producto.valorUnitario) || 0;
        const total = cantidad * valorUnitario;
        return formatCurrency(total);
    };

    return (
        <tr>
            <td>{producto.referencia}</td>
            <td>
                {producto.nombre}
                {producto.nombreTalla && (
                    <div><small>Talla: {producto.nombreTalla}</small></div>
                )}
                {producto.hexCode && (
                    <div>
                    <small>Color:</small>
                    <div 
                        style={{
                            backgroundColor: producto.hexCode, 
                            width: '20px', 
                            height: '20px', 
                            display: 'inline-block', 
                            marginLeft: '5px'
                        }}
                    />
                </div>
                )}
            </td>
            <td>{producto.cantidad}</td>
            <td>{formatCurrency(producto.valorUnitario)}</td>
            <td>{calculateTotal()}</td>
        </tr>
    );
};

const ProductoPedido = ({ productos, envio, descuento }) => {

    if (!productos || productos.length === 0) return <div>Cargando...</div>;

    const calculateSubtotal = () => {
        const subtotal = productos.reduce((total, producto) => {
            const cantidad = parseFloat(producto.cantidad) || 0;
            const valorUnitario = parseFloat(producto.valorUnitario) || 0;
            const productoTotal = cantidad * valorUnitario;
            return total + productoTotal;
        }, 0);
        return subtotal;
    };

    const calculateTotalItems = () => {
        return productos.reduce((total, producto) => total + (parseFloat(producto.cantidad) || 0), 0);
    };

    const calculateDiscountAmount = () => {
        const subtotal = calculateSubtotal();
        return subtotal * (descuento / 100); // Calcula el valor del descuento
    };

    const calculateTotal = () => {
        const subtotal = calculateSubtotal();
        const envioAmount = parseFloat(envio) || 0;
        const discountAmount = calculateDiscountAmount(); // Aplica el descuento
        return subtotal - discountAmount + envioAmount; // Subtotal menos el descuento más el envío
    };

    return (
        <Card className='mt-2 shadow-none'>
            <Card.Header className='fw-bold'>Productos</Card.Header>
            <Table responsive className='shadow-none text-center'>
                <thead className='bg-primary-subtle'>
                    <tr>
                        <th>Producto</th>
                        <th>Nombre</th>
                        <th>Cant.</th>
                        <th>Valor Unitario</th>
                        <th>Total</th>
                    </tr>
                </thead>
                <tbody>
                    {productos.map(producto => (
                        <ProductoRow key={producto.idProducto} producto={producto} />
                    ))}
                    <tr>
                        <td colSpan="4" className='text-end fw-bold td-label'>Subtotal:</td>
                        <td colSpan="1" className='text-start td-value'>{formatCurrency(calculateSubtotal())}</td>
                    </tr>
                    <tr>
                        <td colSpan="4" className='text-end fw-bold td-label'>Descuento:</td>
                        <td colSpan="1" className='text-start td-value'>
                             {descuento}%
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="4" className='text-end fw-bold td-label'>Items:</td>
                        <td colSpan="1" className='text-start td-value'>{calculateTotalItems()}</td>
                    </tr>
                    <tr>
                        <td colSpan="4" className='text-end fw-bold td-label'>Envio:</td>
                        <td colSpan="1" className='text-start td-value'>{formatCurrency(parseFloat(envio) || 0)}</td>
                    </tr>
                    <tr>
                        <td colSpan="4" className='text-end fw-bold td-label'>Total:</td>
                        <td colSpan="1" className='text-start td-value'>{formatCurrency(calculateTotal())}</td>
                    </tr>
                </tbody>
            </Table>
        </Card>
    );
};

export default ProductoPedido;
