import React, { useEffect } from 'react';
import { useSucursal } from '../../contexts/SucursalContext'; // Usamos el hook de contexto
import NavbarUni from './componentes/headUni';
import BodyUni from './componentes/bodyUni';
import JoinEmail from './componentes/joinEmail';
import Testimonios from './componentes/testimonios';
import FooterPublic from './FooterPublic';
import Loader from './ventaUnidad/componenetes/Loader';
import ModalAuto from './componentes/ModalAuto';
import FloatingButton from './FloatingButton';
import Marquee from './componentes/marquee';
import './styles/HomePage.css';

function HomePage() {
    const { idSucursal, loading, setIdSucursal } = useSucursal(); // Obtenemos idSucursal, loading y setIdSucursal

    useEffect(() => {

        // Verifica si hay idSucursal en los parámetros de la URL
        const urlIdSucursal = window.location.pathname.split('/')[1]; // Extraer idSucursal de la URL

        if (urlIdSucursal && urlIdSucursal !== idSucursal) {

            // Si hay un idSucursal en la URL, lo ponemos en el contexto y lo guardamos en localStorage
            setIdSucursal(urlIdSucursal);
            localStorage.setItem('idSucursal', urlIdSucursal);
        } else if (!idSucursal) {
            // Si no hay idSucursal en el contexto, verificar si hay uno en localStorage
            const storedIdSucursal = localStorage.getItem('idSucursal');

            if (storedIdSucursal) {
                setIdSucursal(storedIdSucursal); // Usar el idSucursal guardado en localStorage
            }
        }
    }, [idSucursal, setIdSucursal]); // Ejecutar solo cuando idSucursal cambie

    return (
        <div className='bg-light'>
            {loading && <Loader />} {/* Muestra el loader mientras se está cargando */}
            <ModalAuto />
            <Marquee />
            <NavbarUni />
            <BodyUni />
            <JoinEmail />
            <Testimonios />
            <FooterPublic idSucursal={idSucursal} />
            <FloatingButton />
        </div>
    );
}

export default HomePage;
