// src/api/tiendaConfig.js
import axios from 'axios';
import { API } from './URL_API';

const API_URL = `${API}/api/links`;

export const fetchSocialLinks = async () => {
    try {
        const response = await axios.get(API_URL);
        return response.data;
    } catch (error) {
        console.error('Error al obtener los enlaces:', error);
        throw error;
    }
};

export const sendSocialLinkToBackend = async (link) => {
    try {
        const response = await axios.post(API_URL, link);
        if (response.status !== 201) throw new Error('Error al enviar el enlace social');
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const updateSocialLinkInBackend = async (link) => {
    try {
        const response = await axios.put(`${API_URL}/${link.id}`, link);
        if (response.status !== 200) throw new Error('Error al actualizar el enlace social');
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const deleteSocialLinkFromBackend = async (id) => {
    try {
        const response = await axios.delete(`${API_URL}/${id}`);
        if (response.status !== 200) throw new Error('Error al eliminar el enlace social');
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

