import React, { useState, useEffect, useCallback } from 'react';
import { CalendarEventFill, GeoAltFill } from 'react-bootstrap-icons';
import CardDetallesClientes from './components/cardDetallesClientes'; // Asegúrate de que el nombre del archivo sea correcto
import PedidosCliente from './components/pedidosCLiente'; // Asegúrate de que el nombre del archivo sea correcto
import { useParams } from 'react-router-dom';
import APIserver from '../../../api/axiosConfig';

function DetallesClientes() {
    const { idCliente } = useParams(); // Obtener el parámetro de la URL

    const [cliente, setCliente] = useState(null); // Estado para almacenar la información del cliente
    const [detalles, setDetalles] = useState(null); // Estado para almacenar los detalles adicionales

    // Función para formatear la fecha
    const formatTimes = (times) => {
        if (!times) return ''; // Retorna una cadena vacía si `times` es null o undefined
        const date = new Date(times);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString('es-ES', options); // Cambia 'es-ES' por el código de tu país si es diferente
    };

    // Función para obtener la información del cliente y los detalles adicionales
    const fetchCliente = useCallback(() => {
      APIserver.get(`/api/clientes/${idCliente}`)
          .then(response => {
  
              const formattedCliente = {
                  ...response.data,
                  times: formatTimes(response.data.times) // Formatear la fecha aquí
              };
              setCliente(formattedCliente); // Almacenar los detalles del cliente formateado en el estado
  
              // Obtener los detalles adicionales para CardDetallesClientes
              return APIserver.get(`/api/mostrar/detallesCliente/${idCliente}`);
          })
          .then(response => {
              setDetalles(response.data);
          })
          .catch(error => {
              console.error('Error al obtener los detalles del cliente:', error);
          });
  }, [idCliente]);
  

    useEffect(() => {
        fetchCliente(); // Llamar a la función para obtener los detalles del cliente
    }, [fetchCliente]); // Incluir fetchCliente en la lista de dependencias

    // Verificar si el cliente está cargando
    if (!cliente || !detalles) {
        return <div>Cargando detalles del cliente...</div>;
    }

    return (
        <div className='mx-2 mt-3'>
            <div className='text-center my-4'>
                <h1>{cliente.nomCliente}</h1>
                <div className='d-flex justify-content-center'>
                    <h6 className='me-2'>
                        <GeoAltFill className='text-danger mx-1' /> {cliente.ciudad} - {cliente.departamento}
                    </h6>
                    <h6>
                        <CalendarEventFill className='text-info mx-1' /> Creado el {cliente.times}
                    </h6>
                </div>
            </div>
            {/* Card Detalles Clientes */}
            <div>
                <CardDetallesClientes detalles={detalles} />
            </div>
            {/* Tabla de Pedidos del Cliente */}
            <div>
                <PedidosCliente clienteId={idCliente} />
            </div>
        </div>
    );
}

export default DetallesClientes;
