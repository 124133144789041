import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Row, Col, Table, Container } from 'react-bootstrap';
import axios from 'axios';
import { API } from '../../../api/URL_API';
import { useTiendaConfig } from '../../../hooks/useTiendaConfig';
import getIcon from '../../../utils/getIcon';
import Loader from './componenetes/Loader';
import { useNavigate } from 'react-router-dom';
import icon from '../../../utils/icon.png';
import logo from '../../../utils/logofull.png';

function ViewPedido() {
    const { numPedido } = useParams();
    const { socialLinks } = useTiendaConfig();
    const [pedido, setPedido] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPedido = async () => {
            try {
                const response = await axios.get(`${API}/api/verpedidos/carrito/${numPedido}`);
                setPedido(response.data);
            } catch (error) {
                console.error('Error al obtener los datos del pedido:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchPedido();
    }, [numPedido]);

    const redirectToRoot = () => {
        navigate(`/`);
    };

    if (loading) return <div className="text-center"><Loader /></div>;
    if (!pedido || !pedido.productos || pedido.productos.length === 0) return <div>No hay productos en este pedido.</div>;

    const totalItems = pedido.productos.reduce((acc, item) => acc + item.cantidad, 0);
    const totalProductos = pedido.productos.reduce((acc, item) => acc + item.subtotal, 0);

    const formatCop = (value) => value?.toLocaleString('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    }) || '$0';

    return (
        <>
            <div className='m-4'>
                <button className='button' onClick={redirectToRoot}>
                    <img
                        src={icon}
                        alt='logo'
                        width='20'
                        height='20'
                        style={{ marginRight: '8px' }}
                    />
                    volver a crishacaps
                </button>
            </div>
            <Container className="my-4">
                {/* Encabezado simplificado */}
                <header className="text-center mb-4">
                    <img
                        src={logo}
                        alt='crishacaps'
                        width="250"
                        className="mb-2"
                    />
                    <div className="d-flex justify-content-center">
                        {socialLinks.map((link, index) => (
                            <a key={index} href={link.url} target='_balank' title={link.title}>
                                <button
                                    className='button-social' // Agregado margen para separar los íconos
                                >
                                    {getIcon(link.url)}
                                </button>
                            </a>
                        ))}
                    </div>

                </header>

                {/* Resumen de Pedido */}
                <Row className="mb-5">
                    <Col md={8}>
                        <Card className='mb-4 shadow-sm'>
                            <Card.Header className='bg-cr text-white text-center fw-bold'>
                                Productos en tu Pedido
                            </Card.Header>
                            <Card.Body>
                                <Table responsive hover>
                                    <tbody>
                                        {pedido.productos.map((item, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <img
                                                        src={`${item.imgProducto}`}
                                                        alt="Producto"
                                                        width="70"
                                                        style={{ border: '1px solid #ddd', padding: '4px' }}
                                                        className="rounded"
                                                    />
                                                </td>
                                                <td>{item.nomProducto}</td>
                                                <td>{formatCop(item.precioU)}</td>
                                                <td>{item.cantidad}</td>
                                                <td>{item.nombreTalla || '-'}</td>
                                                <td>
                                                    {item.color}
                                                    {item.hexCode && (
                                                        <span
                                                            style={{
                                                                backgroundColor: item.hexCode,
                                                                width: '15px',
                                                                height: '15px',
                                                                display: 'inline-block',
                                                                marginLeft: '8px',
                                                                borderRadius: '50%',
                                                                border: '1px solid #ddd'
                                                            }}
                                                            title={item.color}
                                                        ></span>
                                                    )}
                                                </td>
                                                <td>{formatCop(item.subtotal)}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col md={4}>
                        <Card className='shadow-sm'>
                            <Card.Header className='bg-cr text-white text-center fw-bold'>Resumen del Pedido</Card.Header>
                            <Card.Body>
                                <Table borderless>
                                    <tbody>
                                        <tr>
                                            <th>Número de Pedido</th>
                                            <td>{pedido.numPedido}</td>
                                        </tr>
                                        <tr>
                                            <th>Fecha</th>
                                            <td>{new Date(pedido.creacion).toLocaleDateString()}</td>
                                        </tr>
                                        <tr>
                                            <th>Subtotal</th>
                                            <td>{formatCop(totalProductos)}</td>
                                        </tr>
                                        <tr>
                                            <th>Descuento</th>
                                            <td>{pedido.descuento} %</td>
                                        </tr>
                                        <tr>
                                            <th>Items Totales</th>
                                            <td>{totalItems}</td>
                                        </tr>
                                        <tr>
                                            <th>Costo de Envío</th>
                                            <td>{formatCop(pedido.costoEnvio)}</td>
                                        </tr>
                                        <tr className="border-top">
                                            <th>Total del Pedido</th>
                                            <td className="fw-bold fs-6">{formatCop(pedido.totalPedido)}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default ViewPedido;
