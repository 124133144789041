import React, { useEffect, useState } from 'react';
import { CalendarEventFill, GeoAltFill } from 'react-bootstrap-icons';
import CardDetallesProveedor from './componentes/cardDetallesProveedor'; // Asegúrate de usar el nombre correcto
import ComprasProveedor from './componentes/comprasProveedor';
import { useParams } from 'react-router-dom';
import APIserver from '../../../api/axiosConfig';

function DetallesProveedor() {
  const { idProveedor } = useParams(); // Obtener idProveedor de los parámetros de la URL
  const [proveedor, setProveedor] = useState(null); // Estado para almacenar los datos del proveedor
  const [detalles, setDetalles] = useState({
    totalCompras: { count: 0, amount: 0 },
    totalPagado: { count: 0, amount: 0 },
    totalVencida: { count: 0, amount: 0 },
    totalPendiente: { count: 0, amount: 0 }
  });

  useEffect(() => {
    const fetchProveedor = async () => {
      try {
        // Obtener información básica del proveedor
        const proveedorResponse = await APIserver.get(`/api/proveedoress/${idProveedor}`);
        setProveedor(proveedorResponse.data); // Almacenar los datos del proveedor en el estado

        // Obtener detalles adicionales del proveedor
        const detallesResponse = await APIserver.get(`/api/detalles-proveedor/${idProveedor}`);
        setDetalles(detallesResponse.data); // Almacenar los detalles en el estado
      } catch (error) {
        console.error('Error al cargar los detalles del proveedor:', error);
      }
    };

    if (idProveedor) {
      fetchProveedor(); // Llamar a la función para obtener los detalles del proveedor solo si idProveedor está definido
    }
  }, [idProveedor]);

  // Verificar si aún se está cargando el proveedor o si no se encontraron datos
  if (!proveedor) {
    return <div>Cargando detalles del proveedor...</div>;
  }

  // Función para formatear la fecha en día, mes y año
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('es-ES', options);
  };
  
  return (
    <div className='mx-2 mt-3'>
      <div className='text-center my-4'>
        <h1>{proveedor.nomProveedor}</h1>
        <div className='d-flex justify-content-center'>
          <h6 className='me-2'><GeoAltFill className='text-danger mx-1' /> {proveedor.ciudad} - {proveedor.departamento}</h6>
          <h6><CalendarEventFill className='text-info mx-1' /> Creado el {formatDate(proveedor.times)}</h6>
        </div>
      </div>
      {/* Card Detalles Proveedor */}
      <div>
        <CardDetallesProveedor
          totalCompras={detalles.totalCompras}
          totalPagado={detalles.totalPagado}
          totalVencida={detalles.totalVencida}
          totalPendiente={detalles.totalPendiente}
        />
      </div>
      {/* Tabla de Pedidos Del Cliente */}
      <ComprasProveedor />
    </div>
  );
}

export default DetallesProveedor;
