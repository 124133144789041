import React, { useState, useEffect } from 'react';
import { Modal, Form, Row, Col, Button, InputGroup } from 'react-bootstrap';
import Select from 'react-select';
import AlertsFacturas from '../../facturas/Utils/AlertsFacturas';
import APIserver from '../../../../api/axiosConfig';

const CrearPagoResModal = ({ show, handleClose, factura, bancos }) => {
  const [currectDate, setCurrentDate] = useState('');
  const [formData, setFormData] = useState({
    numeroFactura: '',
    nombreCliente: '',
    tipoPago: '',
    banco: null,
    monto: '',
    observaciones: ''
  });
  const [montoMaximo, setMontoMaximo] = useState(0);
  const [idFactura, setIdFactura] = useState(null); // Estado para guardar el ID de la factura
  const [bankOptions, setBankOptions] = useState([]); // Estado para guardar las opciones de banco
  const [errors, setErrors] = useState({}); // Estado para errores de validación

  // Este useEffect se ejecuta al abrir el modal o al cambiar la prop `factura`
  useEffect(() => {
    if (factura && factura.numeroFactura.trim() !== '') {
      // Asignar los valores iniciales de la factura al formulario
      setFormData({
        numeroFactura: factura.numeroFactura || '',
        nombreCliente: factura.clienteNombre || '',
        tipoPago: '',
        banco: null,
        monto: '',
        observaciones: ''
      });

      // Actualizar el ID de la factura y calcular el monto máximo permitido
      const obtenerDatosClienteYFactura = async () => {
        try {
          const response = await APIserver.get(`/api/pagosrecibidos/nombreCliente/${factura.numeroFactura}`);
          const { nombreCliente, idFactura, totalFacV, pagadoFacV } = response.data;

          if (totalFacV === undefined || pagadoFacV === undefined) {
            console.error('Datos de factura incompletos:', response.data);
            AlertsFacturas.showErrorMessage('Datos de factura incompletos.');
            return;
          }

          // Actualizar el estado del formulario con los datos obtenidos
          setFormData(prevFormData => ({
            ...prevFormData,
            nombreCliente: nombreCliente,
            numeroFactura: factura.numeroFactura  // Mantener el número de factura actualizado
          }));
          setIdFactura(idFactura);

          // Calcular el monto máximo permitido
          const calculadoMontoMaximo = totalFacV - pagadoFacV;
          setMontoMaximo(calculadoMontoMaximo);
        } catch (error) {
          console.error('Error al buscar la factura:', error);
          AlertsFacturas.showErrorMessage('Factura no encontrada.');
        }
      };

      obtenerDatosClienteYFactura();

      // Cargar las opciones de bancos
      APIserver.get(`/api/pagosrecibidos/mostrar/bancos`)
        .then(response => {
          const banks = response.data.bancos;
          setBankOptions(banks.map(banco => ({
            value: banco.idBanco,
            label: banco.nomBanco
          })));
        })
        .catch(error => {
          console.error('Error al obtener bancos:', error);
          AlertsFacturas.showErrorMessage('Error al obtener bancos.');
        });
    }
  }, [factura]);  // Ejecuta este efecto cuando la prop `factura` cambia

  useEffect(() => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const dd = String(today.getDate()).padStart(2, '0');
    setCurrentDate(`${yyyy}-${mm}-${dd}`);
  }, []);

  useEffect(() => {
    // Llamada para obtener las opciones de bancos al montar el componente
    APIserver.get(`/api/pagosrecibidos/mostrar/bancos`)
      .then(response => {
        const banks = response.data.bancos;
        setBankOptions(banks.map(banco => ({
          value: banco.idBanco,
          label: banco.nomBanco
        })));
      })
      .catch(error => {
        console.error('Error al obtener bancos:', error);
        AlertsFacturas.showErrorMessage('Error al obtener bancos.');
      });
  }, []);

  const optionsPago = [
    { value: 'transferencia', label: 'Transferencias' },
    { value: 'efectivo', label: 'Efectivo' }
  ];

  // Cambiamos la función handleInputChange
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Nueva función para manejar la búsqueda al salir del input
  const handleFacturaBlur = async () => {
    const { numeroFactura } = formData;

    if (numeroFactura.trim() !== '') {
      try {
        const response = await APIserver.get(`/api/pagosrecibidos/nombreCliente/${numeroFactura}`);
        const { nombreCliente, idFactura, totalFacV, pagadoFacV } = response.data;

        if (totalFacV === undefined || pagadoFacV === undefined) {
          console.error('Datos de factura incompletos:', response.data);
          AlertsFacturas.showErrorMessage('Datos de factura incompletos.');
          return;
        }

        // Actualizar el estado con los datos obtenidos
        setFormData(prevFormData => ({
          ...prevFormData,
          nombreCliente: nombreCliente,
          numeroFactura: numeroFactura
        }));
        setIdFactura(idFactura);
        setMontoMaximo(totalFacV - pagadoFacV);
      } catch (error) {
        console.error('Error al buscar la factura:', error);
        AlertsFacturas.showErrorMessage('Factura no encontrada.');
      }
    }
  };

  const handleSelectChange = (selectedOption, { name }) => {
    setFormData({
      ...formData,
      [name]: selectedOption
    });
  };

  const handleDateChange = (e) => {
    setCurrentDate(e.target.value); // Actualizar la fecha seleccionada
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.numeroFactura.trim()) newErrors.numeroFactura = 'Este campo es obligatorio';
    if (!formData.nombreCliente.trim()) newErrors.nombreCliente = 'Este campo es obligatorio';
    if (!formData.tipoPago) newErrors.tipoPago = 'Seleccione un tipo de pago';
    if (!formData.banco) newErrors.banco = 'Seleccione un banco';
    if (!currectDate) newErrors.fecha = 'Seleccione una fecha';
    if (!formData.monto || parseFloat(formData.monto) <= 0) newErrors.monto = 'Ingrese un monto válido';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = async () => {
    if (!validateForm()) {
      return; // Si hay errores, no continuar
    }
    try {
      const monto = parseFloat(formData.monto.replace(/,/g, '')); // Remover comas y convertir a número flotante

      if (monto > montoMaximo) {
        AlertsFacturas.showErrorMessage('El monto ingresado excede el monto pendiente.');
        return;
      }

      const pendiente = montoMaximo - monto;

      await APIserver.post(`/api/pagosrecibidos/guardar/pagoF`, {
        fecha: currectDate,
        detalle: formData.observaciones,
        idBanco: formData.banco ? formData.banco.value : null,
        monto: monto,
        pendiente: pendiente,
        idFacV: idFactura
      });

      await APIserver.put(`/api/actualizar/factura`, {
        idFactura: idFactura,
        pagadoFacV: monto,
        pendienteFacV: pendiente,
        numeroFactura: formData.numeroFactura
      });

      AlertsFacturas.showPaymentSuccessMessage('Pago agregado exitosamente');
      handleClose();
    } catch (error) {
      console.error('Error al guardar el pago:', error);
      AlertsFacturas.showErrorMessage('Hubo un error al agregar el pago.');
    }
  };

  return (
    <Modal size="lg" show={show} onHide={handleClose} aria-labelledby='create-pagoRes-modal'>
      <Modal.Header closeButton>
        <Modal.Title>Nuevo Pago Recibido</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group className="m-3 mt-0" controlId="numPedido">
                <Form.Label>Numero de Factura</Form.Label>
                <Form.Control
                  type="text"
                  name="numeroFactura"
                  value={formData.numeroFactura}
                  onChange={handleInputChange}
                  onBlur={handleFacturaBlur}  // Ahora la búsqueda se ejecuta al perder el foco
                  placeholder="Número de factura"
                  isInvalid={!!errors.numeroFactura}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.numeroFactura}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="m-3" controlId="nomCliente">
                <Form.Label>Cliente</Form.Label>
                <Form.Control
                  type="text"
                  name="nombreCliente"
                  value={formData.nombreCliente}
                  placeholder='Nombre del Cliente'
                  readOnly
                  isInvalid={!!errors.nombreCliente}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.nombreCliente}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className='m-3' controlId='tipoPago'>
                <Form.Label className='w-100'>Tipo</Form.Label>
                <Select
                  placeholder='Tipo de Pago'
                  name='tipoPago'
                  value={optionsPago.find(option => option.value === formData.tipoPago)}
                  onChange={(selectedOption) => handleSelectChange(selectedOption, { name: 'tipoPago' })}
                  options={optionsPago}
                  isInvalid={!!errors.tipoPago}
                />
                {errors.tipoPago && <div className="invalid-feedback d-block">{errors.tipoPago}</div>}
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className="m-3 mt-0" controlId="fechaPago">
                <Form.Label>Fecha</Form.Label>
                <Form.Control
                  type="date"
                  value={currectDate}
                  onChange={handleDateChange}
                  isInvalid={!!errors.fecha}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {errors.fecha}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="m-3" controlId="nomBanco">
                <Form.Label>Banco</Form.Label>
                <Select
                  placeholder='Seleccione el Banco'
                  name='banco'
                  value={formData.banco}
                  onChange={(selectedOption) => handleSelectChange(selectedOption, { name: 'banco' })}
                  options={bankOptions}
                  isInvalid={!!errors.banco}
                />
                {errors.banco && <div className="invalid-feedback d-block">{errors.banco}</div>}
              </Form.Group>
              <Form.Group className="m-3" controlId="montoPago">
                <Form.Label>Monto</Form.Label>
                <InputGroup className="mb-3">
                  <InputGroup.Text>$</InputGroup.Text>
                  <Form.Control
                    type='number'
                    name='monto'
                    value={formData.monto}
                    onChange={handleInputChange}
                    placeholder='Pago total realizado'
                    isInvalid={!!errors.monto}
                    step="0.01"
                    required
                  />
                  <InputGroup.Text>.00</InputGroup.Text>
                  <Form.Control.Feedback type="invalid">
                    {errors.monto}
                  </Form.Control.Feedback>
                </InputGroup>
                <Form.Text className='text-muted'>
                  Saldo de factura: {montoMaximo.toLocaleString('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                </Form.Text>
              </Form.Group>
            </Col>
          </Row>
          <Form.Group className="m-3 mt-0" controlId="detallePago">
            <Form.Label>Observaciones</Form.Label>
            <Form.Control
              as="textarea"
              name="observaciones"
              value={formData.observaciones}
              onChange={handleInputChange}
              placeholder="Observaciones sobre el pago (Opcional)"
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>Cancelar</Button>
        <Button variant="primary" onClick={handleSave}>Guardar</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CrearPagoResModal;
