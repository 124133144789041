import React, { useState, useEffect, useCallback } from 'react';
import { Modal, Button, Form, InputGroup } from 'react-bootstrap';
import APIserver from '../../../../api/axiosConfig';

function EditarSucursalModal({ show, handleClose, sucursalId, onSuccessUpdate }) {
  const [nomSucursal, setNomSucursal] = useState('');
  const [numSucursal, setNumSucursal] = useState('');
  const [enlace, setEnlace] = useState('');

  // Obtener los datos de la sucursal usando el ID
  const fetchSucursalData = useCallback((id) => {
    APIserver.get(`/api/sucursales/${id}`)
      .then(response => {
        const { nomSucursal, numSucursal } = response.data;
        setNomSucursal(nomSucursal);
        setNumSucursal(numSucursal);
      })
      .catch(error => {
        console.error('Error al obtener los datos de la sucursal:', error);
      });
  }, []);

  // Actualiza el enlace de forma independiente al numSucursal, usando siempre sucursalId
  useEffect(() => {
    if (sucursalId) {
      setEnlace(`https://www.crishacaps.com/Collections/${sucursalId}/`);  // Usa directamente sucursalId aquí
    }
  }, [sucursalId]);

  // Este useEffect es para cargar los datos de la sucursal cuando el modal se abre
  useEffect(() => {
    if (show && sucursalId) {
      fetchSucursalData(sucursalId);
    }
  }, [show, sucursalId, fetchSucursalData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'nomSucursalEdit') {
      setNomSucursal(value);
    } else if (name === 'numSucursalEdit') {
      setNumSucursal(value);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedSucursal = {
      nomSucursal: nomSucursal,
      numSucursal: numSucursal,
      enlace: enlace
    };

    APIserver.put(`/api/sucursales/${sucursalId}`, updatedSucursal)
      .then(response => {
        onSuccessUpdate();
        handleClose();
      })
      .catch(error => {
        console.error('Error al actualizar la sucursal:', error);
      });
  };

  return (
    <Modal show={show} onHide={handleClose} aria-labelledby="modal-editar-sucursal">
      <Modal.Header closeButton>
        <Modal.Title>Editar Sucursal</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className='m-3 mt-0' controlId='nomSucursalEdit'>
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              type='text'
              name='nomSucursalEdit'
              value={nomSucursal}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
          <Form.Group className='m-3' controlId='numSucursalEdit'>
            <Form.Label>Número</Form.Label>
            <InputGroup>
              <InputGroup.Text>+57</InputGroup.Text>
              <Form.Control
                type='text'
                name='numSucursalEdit'
                value={numSucursal}
                onChange={handleInputChange}
                required
              />
            </InputGroup>
          </Form.Group>
          <Form.Group className='m-3' controlId='enlaceWhatsApp'>
            <Form.Label>Enlace de WhatsApp</Form.Label>
            <InputGroup>
              <Form.Control
                type='text'
                name='enlaceWhatsApp'
                value={enlace}
                readOnly
              />
              <Button variant='outline-secondary' onClick={() => navigator.clipboard.writeText(enlace)}>
                Copiar
              </Button>
            </InputGroup>
          </Form.Group>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleClose}>Cancelar</Button>
            <Button variant='primary' type='submit'>Guardar</Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default EditarSucursalModal;
