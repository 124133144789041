// src/components/Header.js
import React, { useState } from "react";
import { Navbar, Container, Nav, Offcanvas } from 'react-bootstrap';
import { Justify } from 'react-bootstrap-icons';
import { useLocation } from 'react-router-dom';

import SidebarContent from './SidebarContent';
import NotificationsDropdown from "./componentes/Notifications";
import AlertsDropdown from "./componentes/Alerts";
import ProfileDropdown from "./componentes/UserOptions";

const Header = () => {
  const location = useLocation();
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const handleClose = () => setShowOffcanvas(false);
  const handleShow = () => setShowOffcanvas(true);

  let pageTitle;
  let breadcrumb;

  // Rutas dinámicas con sus expresiones regulares
  const dynamicRoutes = [
    {
      regex: /^\/admin\/Facturas\/DetallesFactura\/\d+\/?$/,
      pageTitle: 'Detalles Factura',
      breadcrumb: 'Admin / Facturas / Detalles',
    },
    {
      regex: /^\/admin\/Facturas\/EditarFactura\/\d+\/?$/,
      pageTitle: 'Editar Factura',
      breadcrumb: 'Admin / Facturas / Editar',
    },
    {
      regex: /^\/admin\/PagosRecibidos\/DetallesPago\/\d+\/?$/,
      pageTitle: 'Detalles Pago',
      breadcrumb: 'Admin / Ingresos / Detalles',
    },
    {
      regex: /^\/admin\/Compras\/DetallesCompra\/\d+\/?$/,
      pageTitle: 'Detalles Compra',
      breadcrumb: 'Admin / Compras / Detalles',
    },
    {
      regex: /^\/admin\/PagosRealizados\/DetallesPago\/\d+\/?$/,
      pageTitle: 'Detalles Pago',
      breadcrumb: 'Admin / Gastos / Pago',
    },
    {
      regex: /^\/admin\/Clientes\/DetallesClientes\/\d+\/?$/,
      pageTitle: 'Detalles Cliente',
      breadcrumb: 'Admin / Clientes / Detalles',
    },
    {
      regex: /^\/admin\/Proveedores\/DetallesProveedores\/\d+\/?$/,
      pageTitle: 'Detalles Proveedor',
      breadcrumb: 'Admin / Proveedores / Detalles',
    },
    {
      regex: /^\/admin\/Bancos\/DetallesBancos\/\d+\/?$/,
      pageTitle: 'Detalles Banco',
      breadcrumb: 'Admin / Bancos / Detalles',
    },
    {
      regex: /^\/admin\/Pedidos\/FacturarPedidos\/CrearFacturarPedidos\/\d+\/?$/,
      pageTitle: 'Facturar Pedido',
      breadcrumb: 'Admin / Pedidos / Facturar Pedido',
    },
    {
      regex: /^\/admin\/Pedidos\/DetallesPedido\/\d+\/?$/,
      pageTitle: 'Detalles Pedido',
      breadcrumb: 'Admin / Pedidos / Detalles Pedido',
    }
  ];

  // Lógica de coincidencia de rutas
  const matchDynamicRoute = dynamicRoutes.find(route => route.regex.test(location.pathname));

  if (matchDynamicRoute) {
    pageTitle = matchDynamicRoute.pageTitle;
    breadcrumb = matchDynamicRoute.breadcrumb;
  } else {
    // Rutas estáticas
    switch (location.pathname) {

      case '/admin/Dashboard/':
        pageTitle = 'Dashboard';
        breadcrumb = 'Admin / Dashboard';
        break;
      case '/admin/IngresosFacturas/':
        pageTitle = 'Facturas';
        breadcrumb = 'Admin / Facturas de Ingreso';
        break;
      case '/admin/Pedidos/':
        pageTitle = 'Pedidos';
        breadcrumb = 'Admin / Pedidos';
        break;
      case '/admin/PagosRecibidos/':
        pageTitle = 'Pagos Recibidos';
        breadcrumb = 'Admin / Pagos Recibidos';
        break;
      case '/admin/Compras/':
        pageTitle = 'Compras';
        breadcrumb = 'Admin / Compras';
        break;
      case '/admin/PagosRealizados/':
        pageTitle = 'Pagos Realizados';
        breadcrumb = 'Admin / Pagos Realizados';
        break;
      case '/admin/Clientes/':
        pageTitle = 'Clientes';
        breadcrumb = 'Admin / Clientes';
        break;
      case '/admin/Proveedores/':
        pageTitle = 'Proveedores';
        breadcrumb = 'Admin / Proveedores';
        break;
      case '/admin/Marketing/':
        pageTitle = 'Marketing';
        breadcrumb = 'Admin / Marketing';
        break;
      case '/admin/Categorias/':
        pageTitle = 'Categorías';
        breadcrumb = 'Admin / Categorías';
        break;
      case '/admin/Productos/':
        pageTitle = 'Productos';
        breadcrumb = 'Admin / Productos';
        break;
      case '/admin/Sucursales/':
        pageTitle = 'Sucursales';
        breadcrumb = 'Admin / Sucursales';
        break;
      case '/admin/Bancos/':
        pageTitle = 'Bancos';
        breadcrumb = 'Admin / Bancos';
        break;
      case '/admin/Facturas/CrearFactura/':
        pageTitle = 'Crear Factura';
        breadcrumb = 'Admin / Facturas de Ingreso / Crear';
        break;
      case '/admin/Configuracion/':
        pageTitle = 'Configuracion';
        breadcrumb = 'Admin / Configuracion';
        break;
      case '/admin/Reportes/':
        pageTitle = 'Reportes';
        breadcrumb = 'Admin / Reportes';
        break;
      default:
        pageTitle = 'Main Dashboard';
        breadcrumb = 'Admin / Main Dashboard';
    }
  }

  return (
    <div>
      <Navbar bg="white" variant="dark" expand="lg" className="header position-relative w-auto">
        <Container fluid className="m-2 d-flex align-items-start justify-content-between">
          <div className="d-flex flex-column">
            <small className="text-muted">{breadcrumb}</small>
            <Navbar.Brand><h1 className="text-dark fw-bold mb-0">{pageTitle}</h1></Navbar.Brand>
          </div>
          <Nav className="d-none d-md-flex align-items-center bg-white rounded-pill p-1 mt-1" style={{ boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.2)" }}>
            <Nav.Link className="d-flex align-items-center text-dark icon-container m-2 d-md-none" onClick={handleShow}>
              <Justify className="navbar-toggler-icon" />
            </Nav.Link>
            <NotificationsDropdown />
            <AlertsDropdown />
            <ProfileDropdown />
          </Nav>
        </Container>
        <Container fluid className="d-flex d-md-none justify-content-center mt-3">
          <Nav className="d-flex flex-row align-items-center bg-white rounded-pill p-1" style={{ boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.2)" }}>
            <Nav.Link className="d-flex align-items-center text-dark icon-container m-2" onClick={handleShow}>
              <Justify className="navbar-toggler-icon" />
            </Nav.Link>
            <NotificationsDropdown />
            <AlertsDropdown />
            <ProfileDropdown />
          </Nav>
        </Container>
      </Navbar>
      <Offcanvas show={showOffcanvas} onHide={handleClose} className="bg-primary text-white">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>TuBisnez</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <SidebarContent handleClose={handleClose} />
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default Header;
