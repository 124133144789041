// src/components/NotificationsDropdown.js
import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Bell } from 'react-bootstrap-icons';
import ContentNotification from "./contentNotifications"; // Asegúrate de que la ruta sea correcta
import { Badge } from '@mui/material';
import APIserver from "../../../api/axiosConfig";

const NotificationsDropdown = () => {
  const [notificaciones, setNotificaciones] = useState([]);
  const [loading, setLoading] = useState(true);
  
  // Función para obtener las notificaciones desde la API
  const fetchNotificaciones = async () => {
    try {
      const response = await APIserver.get('/api/notificaciones'); // Ajusta la ruta según tu backend
      setNotificaciones(response.data);
    } catch (error) {
      console.error('Error al obtener notificaciones:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNotificaciones();
    const interval = setInterval(fetchNotificaciones, 5000); // Polling cada 5 segundos
    return () => clearInterval(interval); // Limpiar el intervalo al desmontar
  }, []);

  const markAsRead = async () => {
    const idsToMark = notificaciones.filter(n => !n.leida).map(n => n.id);
    // Solo ejecutar la API si hay IDs para marcar
    if (idsToMark.length === 0) {
      return; // Salir de la función si no hay IDs
    }
  
    try {
      await APIserver.post('/api/notificaciones/marcar-leidas', { ids: idsToMark });
      // Actualiza el estado de las notificaciones como leídas
      const updatedNotificaciones = notificaciones.map(noti => ({
        ...noti,
        leida: true
      }));
      setNotificaciones(updatedNotificaciones);
    } catch (error) {
      console.error('Error al marcar las notificaciones como leídas:', error);
    }
  };
  
  // Marcar las notificaciones como leídas 5 segundos después de abrir el dropdown
  const handleDropdownToggle = (isOpen) => {
    if (isOpen) {
      setTimeout(() => {
        markAsRead();
      }, 5000); // Espera 5 segundos antes de marcar como leídas
    }
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <button
      type="button"
      className="btn btn-link ms-2 text-dark"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </button>
  ));

  return (
    <Dropdown align="start" onToggle={handleDropdownToggle}>
      <Dropdown.Toggle as={CustomToggle} variant="light" id="dropdown-notifications">
        <Badge badgeContent={notificaciones.filter(n => !n.leida).length} color="primary">
          <Bell size={15} />
        </Badge>
      </Dropdown.Toggle>
      <Dropdown.Menu
        className="p-0 dropdown-menu"
        style={{ width: '300px', position: 'absolute', left: '-80px' }}
      >
        {loading ? (
          <div className="text-center p-3">Cargando...</div>
        ) : (
          <ContentNotification notificaciones={notificaciones} />
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NotificationsDropdown;
