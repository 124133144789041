import React, { useState } from 'react';
import { Nav } from 'react-bootstrap';
import Profile from './componentes/profileConfig';
import UsersRolesPermisos from './componentes/usersConfig';
import CompanyConfig from './componentes/configCompany';
import TiendaConfig from './componentes/tiendaConfig';
import useAuth from '../../../hooks/useAuth';
function AdminConfiguracion() {
    const { auth } = useAuth(); // Obtén `auth` del contexto de autenticación
    const [activeKey, setActiveKey] = useState('/profile');

    const renderContent = () => {
        switch (activeKey) {
            case '/profile':
                return <Profile />;
            case '/users':
                return <UsersRolesPermisos loggedUser={auth} />;  // Pasa `auth` como `loggedUser`
            case '/configCompany':
                return <CompanyConfig />;
            case '/configShop':
                return <TiendaConfig />;
            default:
                return null;
        }
    };
    
    return (
        <div>
            <Nav variant="tabs" defaultActiveKey="/profile" onSelect={(selectedKey) => setActiveKey(selectedKey)}>
                <Nav.Item>
                    <Nav.Link eventKey="/profile">Perfil</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="/users">Usuarios</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="/configCompany">Empresa</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="/configShop">Tienda</Nav.Link>
                </Nav.Item>
            </Nav>
            <div>
                {renderContent()}
            </div>
        </div>
    );
}

export default AdminConfiguracion;
