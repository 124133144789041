// src/components/admin/ForgotPassword.js
import React, { useState } from 'react';
import axios from 'axios';
import { TextField } from '@mui/material';  // Usamos TextField en lugar de Input
import { Button, Container, Card } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { API } from '../../api/URL_API';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(`${API}/api/auth/forgot-password`, { emailInq: email });
            Swal.fire({
                title: 'Éxito',
                text: response.data.message,
                icon: 'success',
                iconColor: '#2fb380',
                confirmButtonColor: "#2c4cc4"
            });
        } catch (error) {
            Swal.fire({
                title: 'Error',
                text: error.response?.data?.message || 'Error al enviar la solicitud',
                icon: 'error',
                iconColor: '#da292e',
                confirmButtonColor: "#da292e"
            });
        }
    };

    return (
        <Container
            className="d-flex justify-content-center align-items-center min-vh-100">
            <Card className='rounded-4 border-0' style={{ maxWidth: '500px', width: '100%' }}>
                <Card.Body className='mx-4 mt-3'>
                    <h2 className='text-center fw-bold'>Recuperar Contraseña</h2>
                    <small>Por favor ingrese el correo electrónico con el cual se registró en <b>TuBisnez.</b> Le enviaremos un correo con un enlace para que restablezca su contraseña.</small>
                    <form className='my-3' onSubmit={handleSubmit}>
                        <TextField
                            label="Correo Electrónico"
                            type='email'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            fullWidth
                            required
                            variant='standard'  // Usamos la variante estándar de MUI
                        />
                        <Button type="submit" variant='primary' className='w-100 mt-4'>
                            Enviar enlace de restablecimiento
                        </Button>
                    </form>
                </Card.Body>
            </Card>
        </Container>
    );
};

export default ForgotPassword;
