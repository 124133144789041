import React, { useState, useEffect, useContext } from 'react';
import { Card, Button, ProgressBar, Modal, Dropdown, DropdownButton } from 'react-bootstrap';
import APIserver from '../../../../api/axiosConfig';
import CrearMeta from './crearMeta';
import { AuthContext } from '../../../../contexts/AuthContext';
import Swal from 'sweetalert2';

const getProgressColor = (percent) => {
    if (percent <= 20) return 'danger';
    if (percent <= 60) return 'warning';
    return 'success';
};

const Metas = () => {
    const { auth } = useContext(AuthContext);
    const [metas, setMetas] = useState([]);
    const [showModal, setShowModal] = useState(false);

    const isAdmin = auth && auth.user && auth.user.role === 'Administrador';
    const canCreateMeta = isAdmin && metas.length < 3;

    // Función para obtener las metas
    const fetchMetas = async () => {
        try {
            const response = await APIserver.get('/api/metas/obtener');
            const metasData = response.data;
            setMetas(metasData);
            checkForCompletedGoals(metasData);
        } catch (error) {
            console.error('Error al obtener las metas:', error);
        }
    };

    // Función para verificar metas completadas al 100%
    const checkForCompletedGoals = (metasData) => {
        metasData.forEach((meta) => {
            const progressPercentage = (meta.progreso_actual / meta.objetivo) * 100;
            const hasShownAlert = localStorage.getItem(`meta-${meta.id_meta}-alert`);

            if (progressPercentage >= 100 && !hasShownAlert) {
                Swal.fire({
                    icon: 'success',
                    title: '¡Meta Cumplida!',
                    text: `La meta "${meta.nombre_meta}" ha alcanzado el 100% de su objetivo.`,
                    confirmButtonColor: '#2c4cc4'
                });

                // Guardar en el localStorage que la alerta de esta meta ya se mostró
                localStorage.setItem(`meta-${meta.id_meta}-alert`, 'true');
            }
        });
    };

    useEffect(() => {
        fetchMetas();
    }, []);

    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    const showSuccessMessage = (message) => {
        Swal.fire({
            icon: 'success',
            title: '¡Éxito!',
            text: message,
            showConfirmButton: false,
            iconColor: '#2fb380',
            confirmButtonColor: "#2c4cc4",
            timer: 2000
        });
    };

    const showErrorMessage = (message) => {
        Swal.fire({
            icon: 'error',
            title: 'Error',
            text: message,
            showConfirmButton: false,
            iconColor: '#e74c3c',
            confirmButtonColor: "#c0392b",
            timer: 2500
        });
    };

    const handleDeleteMeta = async (metaId) => {
        const result = await Swal.fire({
            title: '¿Estás seguro?',
            text: 'Una vez eliminada, no podrás recuperar esta meta.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar'
        });

        if (result.isConfirmed) {
            try {
                await APIserver.delete(`/api/metas/eliminar/${metaId}`);
                fetchMetas();
                showSuccessMessage('Meta eliminada exitosamente');
                localStorage.removeItem(`meta-${metaId}-alert`); // Borrar alerta en caché si la meta se elimina
            } catch (error) {
                console.error(`Error al eliminar la meta con id ${metaId}:`, error);
                showErrorMessage('Ocurrió un error al eliminar la meta. Por favor, inténtalo de nuevo.');
            }
        } else {
            Swal.fire({
                icon: 'info',
                title: 'Cancelado',
                text: 'La eliminación de la meta ha sido cancelada.',
                showConfirmButton: false,
                timer: 1500
            });
        }
    };

    return (
        <div>
            <Card bg='light' className='mt-3 shadow-none border-0 rounded-4'>
                <Card.Body>
                    <div className='d-flex align-items-center justify-content-between'>
                        <Card.Title className='mb-0 mb-md-0 text-center fw-bold'>Metas</Card.Title>
                        {canCreateMeta && (
                            <Button variant="primary" size='sm' onClick={handleOpenModal}>Crear Meta</Button>
                        )}
                    </div>
                    {metas.map((meta) => (
                        <div key={meta.id_meta} className="mt-3 d-flex align-items-center">
                            <h6 className="me-3">{meta.nombre_meta}</h6>
                            <ProgressBar
                                now={(meta.progreso_actual / meta.objetivo) * 100}
                                label={`${Math.round((meta.progreso_actual / meta.objetivo) * 100)}%`}
                                variant={getProgressColor((meta.progreso_actual / meta.objetivo) * 100)}
                                className="flex-grow-1"
                            />
                            {isAdmin && (
                                <DropdownButton
                                    className="ms-2"
                                    title="⋮"
                                    size="sm"
                                    variant="secondary"
                                >
                                    <Dropdown.Item onClick={() => handleDeleteMeta(meta.id_meta)}>
                                        Eliminar
                                    </Dropdown.Item>
                                </DropdownButton>
                            )}
                        </div>
                    ))}
                </Card.Body>
            </Card>

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Crear Nueva Meta</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CrearMeta 
                        onMetaCreated={() => {
                            fetchMetas();
                            handleCloseModal();
                        }} 
                        userId={auth && auth.user && auth.user.id ? auth.user.id : null}
                    />
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default Metas;
