import React from 'react';
import { Table, Row, Col } from 'react-bootstrap';

const ProductoRow = ({ producto }) => {
    const calculateTotal = () => {
        const { cantidad, valorUnitario } = producto;
        const total = cantidad * valorUnitario;
        return total.toLocaleString('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 0 });
    };

    return (
        <tr>
            <td>{producto.producto}</td>
            <td>{producto.nombre}</td>
            <td>{producto.cantidad}</td>
            <td>{producto.valorUnitario.toLocaleString('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 0 })}</td>
            <td>{calculateTotal()}</td>
        </tr>
    );
};

const DetallesProductoFac = ({ productos, envio = 0, descuento = 0 }) => {

    const calculateSubtotal = () => {
        const subtotal = productos.reduce((total, producto) => {
            const { cantidad = 0, valorUnitario = 0 } = producto;
            const productoTotal = cantidad * valorUnitario;
            return total + productoTotal;
        }, 0);
        return subtotal;
    };

    const calculateTotalItems = () => {
        return productos.reduce((total, producto) => total + (producto.cantidad || 0), 0);
    };

    const calculateDiscountAmount = () => {
        const subtotal = calculateSubtotal();
        return subtotal * (descuento / 100); // Calcula el valor del descuento basado en el porcentaje
    };

    const calculateTotal = () => {
        const subtotal = calculateSubtotal();
        const discountAmount = calculateDiscountAmount(); // Monto de descuento calculado
        const envioAmount = parseFloat(envio) || 0;
        const total = subtotal - discountAmount + envioAmount;
        return total.toLocaleString('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 0 });
    };

    const formatCurrency = (value) => {
        return value.toLocaleString('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 0 });
    };

    const subtotal = calculateSubtotal();
    const discountAmount = calculateDiscountAmount();

    return (
        <div>
            <Row>
                <Col md={12}>
                    <Table responsive className='shadow-none text-center'>
                        <thead className='bg-primary-subtle'>
                            <tr>
                                <th className='bg-primary-subtle'>Producto</th>
                                <th className='bg-primary-subtle'>Nombre</th>
                                <th className='bg-primary-subtle'>Cant.</th>
                                <th className='bg-primary-subtle'>Valor Unitario</th>
                                <th className='bg-primary-subtle'>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {productos.map(producto => (
                                <ProductoRow key={producto.id} producto={producto} />
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Row className='justify-content-end'>
                <Col md={4} className='print-half'>
                    <Table responsive bordered size='sm' className='shadow-none text-center'>
                        <tbody>
                            <tr>
                                <td colSpan="2" className='text-end fw-bold'>Subtotal:</td>
                                <td colSpan="1" className='text-start'>{formatCurrency(subtotal)}</td>
                            </tr>
                            <tr>
                                <td colSpan="2" className='text-end fw-bold'>Descuento:</td>
                                <td colSpan="1" className='text-start'>
                                    {descuento}%
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2" className='text-end fw-bold'>Items:</td>
                                <td colSpan="1" className='text-start'>{calculateTotalItems()}</td>
                            </tr>
                            <tr>
                                <td colSpan="2" className='text-end fw-bold'>Envio:</td>
                                <td colSpan="1" className='text-start'>{formatCurrency(envio)}</td>
                            </tr>
                            <tr>
                                <td colSpan="2" className='text-end fw-bold bg-primary-subtle'>Total:</td>
                                <td colSpan="1" className='text-start bg-primary-subtle'>{calculateTotal()}</td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </div>
    );
};

export default DetallesProductoFac;
