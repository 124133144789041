// AlertsCompras.js
import Swal from 'sweetalert2';
import APIserver from '../../../../api/axiosConfig';

const AlertsCompras = {
  showSuccessMessage: (message) => {
    Swal.fire({
      icon: 'success',
      title: '¡Éxito!',
      text: message,
      showConfirmButton: false,
      iconColor: '#2fb380',
      confirmButtonColor: "#2c4cc4",
      timer: 2000
    }).then(() => {
      window.location.reload(); // Recarga la página
    });
  },
  showSuccessMessages: (message) => {
    Swal.fire({
      icon: 'success',
      title: '¡Éxito!',
      text: message,
      showConfirmButton: false,
      timer: 2000,
      iconColor: '#2fb380'
    })
  },

  showErrorMessage: (message) => {
    Swal.fire({
      icon: 'error',
      iconColor: '#da292e',
      title: 'Error',
      text: message,
    });
  },

  handleEliminarCompra: (compraId, fetchCompras) => {
    Swal.fire({
      icon: 'warning',
      title: '¿Estás seguro?',
      text: 'Los pagos asociados a esta compra tambien se eliminaran. ¡No podrás revertir esto!',
      showCancelButton: true,
      iconColor: '#f4bd61',
      confirmButtonColor: '#2c4cc4',
      cancelButtonColor: '#da292e',
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar',
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await APIserver.delete(`/api/compras/${compraId}`);
          fetchCompras(); // Actualizar la tabla después de eliminar
          AlertsCompras.showSuccessMessages('¡Compra eliminada correctamente!');
        } catch (error) {
          console.error('Error al eliminar la compra:', error);
          AlertsCompras.showErrorMessage('No se pudo eliminar la compra. Inténtalo de nuevo más tarde.');
        }
      }
    });
  },
};

export default AlertsCompras;
