import React from 'react';
import { Card, Form, Row, Col } from 'react-bootstrap';

// Función para formatear la fecha
const formatDate = (date) => {
    if (!date) return '';
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return new Intl.DateTimeFormat('es-CO', options).format(new Date(date));
};

function ClientePedido({ cliente }) {
    // Manejo del caso en que no se pasen datos
    if (!cliente) return <div>Cargando...</div>;

    return (
        <Card className='shadow-none'>
            <Card.Header className='fw-bold'>
                Detalles de cliente
            </Card.Header>
            <Card.Body>
                <Form>
                    <Row>
                        <Col xs={12} md={4}>
                            <Form.Group className='mt-0'>
                                <Form.Label className='w-100'>NIT
                                    <Form.Control type='text' readOnly value={cliente.clienteID || ''} className='mt-2' />
                                </Form.Label>
                            </Form.Group>
                            <Form.Group className='mt-2'>
                                <Form.Label className='w-100'>Correo Electrónico
                                    <Form.Control type='text' readOnly value={cliente.correoCliente || ''} className='mt-2' />
                                </Form.Label>
                            </Form.Group>
                            <Form.Group className='mt-2'>
                                <Form.Label className='w-100'>Ciudad
                                    <Form.Control type='text' readOnly value={cliente.ciudad || ''} className='mt-2' />
                                </Form.Label>
                            </Form.Group>
                            <Form.Group className='mt-2'>
                                <Form.Label className='w-100'>Asesor
                                    <Form.Control type='text' readOnly value={cliente.asesorSucursal || ''} className='mt-2' />
                                </Form.Label>
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={4}>
                            <Form.Group className='mt-0'>
                                <Form.Label className='w-100'>Cliente
                                    <Form.Control type='text' readOnly value={cliente.nomCliente || ''} className='mt-2' />
                                </Form.Label>
                            </Form.Group>
                            <Form.Group className='mt-2'>
                                <Form.Label className='w-100'>Dirección
                                    <Form.Control type='text' readOnly value={cliente.direccion || ''} className='mt-2' />
                                </Form.Label>
                            </Form.Group>
                            <Form.Group className='mt-2'>
                                <Form.Label className='w-100'>País
                                    <Form.Control type='text' readOnly value={cliente.pais || ''} className='mt-2' />
                                </Form.Label>
                            </Form.Group>
                            <Form.Group className='mt-2'>
                                <Form.Label className='w-100'>Tipo
                                    <Form.Control type='text' readOnly value={cliente.tipoPedido || ''} className='mt-2' />
                                </Form.Label>
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={4}>
                            <Form.Group className='mt-0'>
                                <Form.Label className='w-100'>Celular
                                    <Form.Control type='text' readOnly value={cliente.numCliente || ''} className='mt-2' />
                                </Form.Label>
                            </Form.Group>
                            <Form.Group className='mt-2'>
                                <Form.Label className='w-100'>Departamento
                                    <Form.Control type='text' readOnly value={cliente.departamento || ''} className='mt-2' />
                                </Form.Label>
                            </Form.Group>
                            <Form.Group className='mt-2'>
                                <Form.Label className='w-100'>Elaboración
                                    <Form.Control type='text' readOnly value={formatDate(cliente.creacionPedido) || ''} className='mt-2' />
                                </Form.Label>
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            </Card.Body>
        </Card>
    );
}

export default ClientePedido;
