import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Table, ButtonGroup, Pagination, Form } from 'react-bootstrap';
import { Filter, EyeFill, PencilFill, TrashFill, CashCoin } from 'react-bootstrap-icons';
import FiltrosDetallesProveedor from './filtrosDetallesProveedor';
import EditarCompra from '../../Compras/componentes/editarCompra';
import AlertsCompras from '../../Compras/Utils/AlertsCompras';
import CrearPagoModal from '../../Pagos/componentes/crearPagoModal';
import APIserver from '../../../../api/axiosConfig';

function ComprasProveedor() {
    const { idProveedor } = useParams();
    const [showFilters, setShowFilters] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [compras, setCompras] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [filters, setFilters] = useState({});
    const [isFiltering, setIsFiltering] = useState(false);
    const [editShow, setEditShow] = useState(false);
    const [selectedCompra, setSelectedCompra] = useState(null);
    const [crearShow, setCrearShow] = useState(false);

    useEffect(() => {
        fetchCompras(currentPage, rowsPerPage, filters);
    }, [currentPage, rowsPerPage, filters]);

    const fetchCompras = (page, limit, filters) => {
        const validPage = isNaN(page) || page <= 0 ? 1 : parseInt(page, 10);
        const validLimit = isNaN(limit) || limit <= 0 ? 10 : parseInt(limit, 10);

        let url = `/api/compras/proveedor/${idProveedor}`;
        const params = new URLSearchParams({ ...filters, page: validPage, limit: validLimit });

        url += `?${params.toString()}`;

        APIserver.get(url)
            .then(response => {
                setCompras(response.data.compras);
                const totalCount = response.data.totalCount;
                setTotalPages(Math.ceil(totalCount / validLimit));
            })
            .catch(error => {
                console.error('Error al obtener las compras:', error);
            });
    };

    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setCurrentPage(1);
    };

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    const handleEditClose = () => setEditShow(false);
    const handleCrearClose = () => setCrearShow(false); // Cerrar modal de CrearPago

    // Función para abrir el modal de Editar Compra si no está pagada
    const handleEditShow = (compraId) => {
        const compra = compras.find(compra => compra.idCompra === compraId);
        if (compra.estado !== 'pagada') {  // Verificar si el estado no es 'pagada'
            setSelectedCompra(compra);
            setEditShow(true);
        }
    };

    // Función para abrir el modal de Crear Pago si no está pagada
    const handleCrearShow = (compraId) => {
        const compra = compras.find(compra => compra.idCompra === compraId);
        if (compra.estado !== 'pagada') {  // Verificar si el estado no es 'pagada'
            setSelectedCompra(compra);
            setCrearShow(true);
        }
    };

    const handleEliminarCompra = async (compraId) => {
        try {
            await AlertsCompras.handleEliminarCompra(compraId, fetchCompras);
        } catch (error) {
            console.error('Error al eliminar la compra:', error);
        }
    };

    const handleSearch = (newFilters) => {
        setFilters(newFilters);
        setCurrentPage(1); // Resetear la página actual a 1 al aplicar filtros
    };

    const handleClear = () => {
        setFilters({});
        setCurrentPage(1); // Resetear la página actual a 1 al limpiar los filtros
    };

    return (
        <div style={{ boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)" }}>
            <Table responsive className='text-center align-middle border'>
                <thead>
                    <tr>
                        <th colSpan={8}>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='d-flex flex-wrap'>
                                    <Button variant='link' size='sm' onClick={() => setShowFilters(!showFilters)} title='Filtrar'>
                                        <Filter className='m-0' size={20} />
                                    </Button>
                                </div>
                                <div>
                                    <Form.Select
                                        className='ms-2'
                                        value={rowsPerPage}
                                        onChange={handleChangeRowsPerPage}
                                    >
                                        <option value='5'>5</option>
                                        <option value='10'>10</option>
                                        <option value='20'>20</option>
                                        <option value='50'>50</option>
                                    </Form.Select>
                                </div>
                            </div>
                            <div className={`filter-options ${showFilters ? 'show' : ''}`}>
                                <FiltrosDetallesProveedor onSearch={handleSearch} onClear={handleClear} />
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <th>Compra</th>
                        <th>Nombre</th>
                        <th>Elaboracion</th>
                        <th>Vencimiento</th>
                        <th>Total</th>
                        <th>Estado</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {compras.map(compra => (
                        <tr key={compra.idCompra}>
                            <td>
                                <Link to={`/admin/Compras/DetallesCompra/${compra.idCompra}/`} className='text-decoration-none'>
                                    {compra.numCompra}
                                </Link>
                            </td>
                            <td>{compra.nomProveedor}</td>
                            <td>{new Date(compra.creacion).toLocaleDateString()}</td>
                            <td>{new Date(compra.vence).toLocaleDateString()}</td>
                            <td>${compra.totalCompra.toLocaleString()}</td>
                            <td className={`text-${compra.estado === 'pagada' ? 'success' : compra.estado === 'vencida' ? 'danger' : 'warning'}`}>
                                {compra.estado === 'pagada' ? 'Pagada' : compra.estado === 'vencida' ? <span style={{ color: 'red' }}>Vencida</span> : 'Pendiente'}
                            </td>
                            <td>
                                <ButtonGroup aria-label='action-buttons'>
                                    <Link to={`/admin/Compras/DetallesCompra/${compra.idCompra}/`}>
                                        <Button variant='link' className='text-primary' title='Ver Compra'>
                                            <EyeFill size={18} />
                                        </Button>
                                    </Link>
                                    <Button
                                        variant='link'
                                        className='text-success'
                                        title='Agregar Pago'
                                        onClick={() => handleCrearShow(compra.idCompra)}
                                        disabled={compra.estado === 'pagada'} // Deshabilitar si está pagada
                                    >
                                        <CashCoin size={18} />
                                    </Button>
                                    <Button
                                        variant='link'
                                        className='text-warning'
                                        title='Editar Compra'
                                        onClick={() => handleEditShow(compra.idCompra)}
                                        disabled={compra.estado === 'pagada'} // Deshabilitar si está pagada
                                    >
                                        <PencilFill size={18} />
                                    </Button>
                                    <Button
                                        variant='link'
                                        className='text-danger'
                                        title='Eliminar Compra'
                                        onClick={() => handleEliminarCompra(compra.idCompra)}
                                    >
                                        <TrashFill size={18} />
                                    </Button>
                                </ButtonGroup>
                            </td>
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    {!isFiltering && (
                        <tr>
                            <td colSpan='8'>
                                <div className='d-flex justify-content-center align-items-center'>
                                    <Pagination>
                                        <Pagination.Prev onClick={() => handlePageChange(currentPage > 1 ? currentPage - 1 : 1)} disabled={currentPage === 1} />
                                        {[...Array(totalPages).keys()].map((page) => (
                                            <Pagination.Item key={page + 1} active={page + 1 === currentPage} onClick={() => handlePageChange(page + 1)}>
                                                {page + 1}
                                            </Pagination.Item>
                                        ))}
                                        <Pagination.Next onClick={() => handlePageChange(currentPage < totalPages ? currentPage + 1 : totalPages)} disabled={currentPage === totalPages} />
                                    </Pagination>
                                </div>
                            </td>
                        </tr>
                    )}
                </tfoot>
            </Table>
            {/* Modal Editar Compra */}
            {selectedCompra && <EditarCompra show={editShow} handleClose={handleEditClose} compraId={selectedCompra.idCompra} />}
            {/* Modal Crear Pago */}
            {selectedCompra && <CrearPagoModal show={crearShow} handleClose={handleCrearClose} compraId={selectedCompra.idCompra} numCompra={selectedCompra.numCompra} />} 
        </div>
    );
}

export default ComprasProveedor;
