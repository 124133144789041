import React from 'react';
import { ListGroup, Badge } from 'react-bootstrap';

function ContentAlert({ showAlert }) {
  if (!showAlert) {
    return (
      <ListGroup variant="flush">
        <ListGroup.Item className="text-center text-muted">
          No hay alertas activas.
        </ListGroup.Item>
      </ListGroup>
    );
  }

  const today = new Date();
  const currentDay = today.getDate();
  const currentMonth = today.getMonth();
  const currentYear = today.getFullYear();

  // Fecha del próximo día 1
  const nextMonth = currentMonth === 11 ? 0 : currentMonth + 1;
  const nextYear = currentMonth === 11 ? currentYear + 1 : currentYear;
  const nextMonthStart = new Date(nextYear, nextMonth, 1);

  // Calcula los días restantes
  const timeDiff = nextMonthStart - today;
  const daysLeft = Math.ceil(timeDiff / (1000 * 60 * 60 * 24)); // Días restantes

  return (
    <ListGroup variant="flush">
      <ListGroup.Item className="d-flex justify-content-between align-items-start">
        <div>
          <div className="fw-bold">¡Recuerda renovar tu suscripcion!</div>
          <small className="text-muted">
            Faltan {daysLeft} {daysLeft === 1 ? "día" : "días"} para la fecha de pago.
          </small>
        </div>
        <Badge bg="danger" pill>Urgente</Badge>
      </ListGroup.Item>
    </ListGroup>
  );
}

export default ContentAlert;
