// src/api/axiosConfig.js
import axios from 'axios';
import { API } from './URL_API';

// Crear una instancia de Axios
const APIserver = axios.create({
    baseURL: `${API}`, // Base URL de tu dev
});

// Interceptor para agregar el token automáticamente en los headers de cada solicitud
APIserver.interceptors.request.use((config) => {
    const token = localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')).token : null;

    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`; // Agrega el token al header de la solicitud
    }

    return config; // Retorna la configuración actualizada
}, (error) => {
    return Promise.reject(error); // Maneja el error
});

export default APIserver;