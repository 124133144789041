import React, { useState } from 'react';
import { FormControl, Button, ButtonGroup } from 'react-bootstrap';
import { Search, XSquare } from 'react-bootstrap-icons';

function FiltrosProductos({ onFilterChange }) {
  const [filtroReferencia, setFiltroReferencia] = useState('');
  const [filtroNombre, setFiltroNombre] = useState('');
  const [filtroStock, setFiltroStock] = useState('');
  const [filtroPrecioUnidad, setFiltroPrecioUnidad] = useState('');
  const [filtroPrecioMayor, setFiltroPrecioMayor] = useState('');
  const [filtroCategoria, setFiltroCategoria] = useState('');

  const handleBuscarClick = () => {
    const filters = {
      referencia: filtroReferencia,
      nombre: filtroNombre,
      stock: filtroStock,
      precioUnidad: filtroPrecioUnidad,
      precioMayor: filtroPrecioMayor,
      categoria: filtroCategoria
    };

    // Llama a la función prop onFilterChange con los filtros seleccionados
    onFilterChange(filters);
  };

  const handleLimpiarClick = () => {
    setFiltroReferencia('');
    setFiltroNombre('');
    setFiltroStock('');
    setFiltroPrecioUnidad('');
    setFiltroPrecioMayor('');
    setFiltroCategoria('');
    onFilterChange({}); // Llama a onFilterChange con un objeto vacío para limpiar los filtros
   
  };

  return (
    <div className='d-flex align-items-center m-3'>
      <FormControl
        placeholder='Filtra por Referencia'
        className='me-2'
        value={filtroReferencia}
        onChange={(e) => setFiltroReferencia(e.target.value)}
      />
      <FormControl
        placeholder='Filtra por Nombre'
        className='me-2'
        value={filtroNombre}
        onChange={(e) => setFiltroNombre(e.target.value)}
      />
      <FormControl
        placeholder='Filtra por Stock'
        className='me-2'
        value={filtroStock}
        onChange={(e) => setFiltroStock(e.target.value)}
      />
      <FormControl
        placeholder='Filtra por P.Unidad'
        className='me-2'
        value={filtroPrecioUnidad}
        onChange={(e) => setFiltroPrecioUnidad(e.target.value)}
      />
      <FormControl
        placeholder='Filtra por P.Mayor'
        className='me-2'
        value={filtroPrecioMayor}
        onChange={(e) => setFiltroPrecioMayor(e.target.value)}
      />
      <FormControl
        placeholder='Filtra por Categoria'
        className='me-2'
        value={filtroCategoria}
        onChange={(e) => setFiltroCategoria(e.target.value)}
      />
      <ButtonGroup>
        <Button variant='primary' onClick={handleBuscarClick}>
          <Search />
        </Button>
        <Button variant='danger' title='Limpiar' onClick={handleLimpiarClick}>
          <XSquare />
        </Button>
      </ButtonGroup>
    </div>
  );
}

export default FiltrosProductos;
