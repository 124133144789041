import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import Swal from 'sweetalert2';
import APIserver from '../../../../api/axiosConfig';
import Select from 'react-select';

const CrearMeta = ({ onMetaCreated, userId }) => {
    const [nombreMeta, setNombreMeta] = useState('');
    const [descripcion, setDescripcion] = useState('');
    const [objetivo, setObjetivo] = useState('');
    const [fechaInicio, setFechaInicio] = useState('');
    const [fechaFin, setFechaFin] = useState('');
    const [tipoMeta, setTipoMeta] = useState(null); // Almacenar solo el valor seleccionado

    const options = [
        { value: 'nuevos_clientes', label: 'Nuevos Clientes' },
        { value: 'ventas', label: 'Ventas' }
    ];

    const showSuccessMessage = (message) => {
        Swal.fire({
            icon: 'success',
            title: '¡Éxito!',
            text: message,
            showConfirmButton: false,
            iconColor: '#2fb380',
            confirmButtonColor: "#2c4cc4",
            timer: 2000
        });
    };

    const showErrorMessage = (message) => {
        Swal.fire({
            icon: 'error',
            title: 'Error',
            text: message,
            showConfirmButton: false,
            iconColor: '#e74c3c',
            confirmButtonColor: "#c0392b",
            timer: 2500
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await APIserver.post('/api/metas/crear', {
                idInq: userId,
                nombre_meta: nombreMeta,
                descripcion,
                objetivo,
                fecha_inicio: fechaInicio,
                fecha_fin: fechaFin,
                tipoMeta: tipoMeta ? tipoMeta.value : '', // Extraer solo el valor de tipoMeta
            });

            showSuccessMessage('Meta creada exitosamente');
            onMetaCreated(response.data);
        } catch (error) {
            console.error('Error al crear meta:', error);
            showErrorMessage('Ocurrió un error al crear la meta. Por favor, inténtalo de nuevo.');
        }
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group>
                <Form.Label>Nombre de la Meta</Form.Label>
                <Form.Control 
                    type="text" 
                    value={nombreMeta} 
                    onChange={(e) => setNombreMeta(e.target.value)} 
                    required 
                />
            </Form.Group>
            <Form.Group>
                <Form.Label>Tipo de Meta</Form.Label>
                <Select
                    options={options}
                    value={options.find(option => option.value === tipoMeta?.value)} 
                    onChange={(selectedOption) => setTipoMeta(selectedOption)}
                    placeholder="Seleccionar tipo"
                    isClearable
                />
            </Form.Group>
            <Form.Group>
                <Form.Label>Descripción</Form.Label>
                <Form.Control 
                    type="text" 
                    value={descripcion} 
                    onChange={(e) => setDescripcion(e.target.value)} 
                    required 
                />
            </Form.Group>
            <Form.Group>
                <Form.Label>Objetivo</Form.Label>
                <Form.Control 
                    type="number" 
                    value={objetivo} 
                    onChange={(e) => setObjetivo(e.target.value)} 
                    required 
                />
            </Form.Group>
            <Form.Group>
                <Form.Label>Fecha de Inicio</Form.Label>
                <Form.Control 
                    type="date" 
                    value={fechaInicio} 
                    onChange={(e) => setFechaInicio(e.target.value)} 
                    required 
                />
            </Form.Group>
            <Form.Group>
                <Form.Label>Fecha de Fin</Form.Label>
                <Form.Control 
                    type="date" 
                    value={fechaFin} 
                    onChange={(e) => setFechaFin(e.target.value)} 
                    required 
                />
            </Form.Group>

            <Button variant="primary" type="submit">
                Crear Meta
            </Button>
        </Form>
    );
};

export default CrearMeta;
