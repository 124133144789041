import React, { useState, useEffect } from 'react';
import { Card, Table, Form, Button } from 'react-bootstrap';
import { PlusCircleFill, TrashFill } from 'react-bootstrap-icons';

const ProductoRow = ({ producto, onChange, onRemove }) => {
    const handleChange = (e) => {
        const { name, value } = e.target;
        let parsedValue;

        // Convertir el valor a número si corresponde
        if (name === 'cantidad' || name === 'valorUnitario') {
            parsedValue = parseFloat(value) || 0;
        } else {
            parsedValue = value;
        }

        onChange(producto.id, name, parsedValue);
    };

    const formatPrice = (value) => {
        return Math.floor(value).toLocaleString('es-ES'); // Formato: 25.000
    };

    const calculateTotal = () => {
        const cantidad = parseFloat(producto.cantidad) || 0;
        const valorUnitario = parseFloat(producto.valorUnitario) || 0;
        const descuento = 0; // Descuento fijo al 0%
        const total = cantidad * valorUnitario * (1 - descuento / 100);
        return formatPrice(total);
    };

    return (
        <tr>
            <td>
                <Form.Control
                    type="text"
                    name="producto"
                    value={producto.producto}
                    onChange={handleChange}
                    placeholder="Producto"
                />
            </td>
            <td>
                <Form.Control
                    type="text"
                    name="nombre"
                    value={producto.nombre}
                    onChange={handleChange}
                    placeholder="Nombre"
                />
            </td>
            <td>
                <Form.Control
                    type="number"
                    name="cantidad"
                    value={producto.cantidad || ''}
                    onChange={handleChange}
                    placeholder="Cantidad"
                    min="0"
                />
            </td>
            <td>
                <Form.Control
                    type="text"
                    name="valorUnitario"
                    value={formatPrice(producto.valorUnitario)}
                    onChange={handleChange}
                    placeholder="Valor Unitario"
                    min="0"
                />
            </td>
            <td>
                <Form.Control
                    type="number"
                    name="descuento"
                    value={0} // Siempre muestra 0%
                    placeholder="% Desc."
                    disabled // Deshabilitamos el campo para que no pueda ser editado
                />
            </td>
            <td>
                <Form.Control
                    type="text"
                    value={calculateTotal()}
                    placeholder="Total"
                    disabled
                />
            </td>
            <td>
                <Button variant='danger' onClick={() => onRemove(producto.id)}>
                    <TrashFill />
                </Button>
            </td>
        </tr>
    );
};

const EditarProductoPedido = ({ productos: initialProductos, envio: initialEnvio }) => {
    const [productos, setProductos] = useState(initialProductos || []);
    const [envio, setEnvio] = useState(initialEnvio || 0);

    useEffect(() => {
        setProductos(initialProductos || []);
        setEnvio(initialEnvio || 0);
    }, [initialProductos, initialEnvio]);

    const handleAddRow = () => {
        setProductos([...productos, {
            id: Date.now(),
            producto: '',
            nombre: '',
            cantidad: 1,
            valorUnitario: 0,
            descuento: 0 // Descuento siempre 0%
        }]);
    };

    const handleRemoveRow = (id) => {
        setProductos(productos.filter(p => p.id !== id));
    };

    const handleChange = (id, name, value) => {
        setProductos(productos.map(p =>
            p.id === id ? { ...p, [name]: value } : p
        ));
    };

    const handleEnvioChange = (e) => {
        const value = parseFloat(e.target.value) || 0;
        setEnvio(value);
    };

    const formatPrice = (value) => {
        return Math.floor(value).toLocaleString('es-ES'); // Formato: 25.000
    };

    const calculateSubtotal = () => {
        return formatPrice(productos.reduce((total, producto) => {
            const cantidad = parseFloat(producto.cantidad) || 0;
            const valorUnitario = parseFloat(producto.valorUnitario) || 0;
            const descuento = 0; // Descuento fijo al 0%
            const productoTotal = cantidad * valorUnitario * (1 - descuento / 100);
            return total + productoTotal;
        }, 0));
    };

    const calculateTotalItems = () => {
        return productos.reduce((total, producto) => total + parseInt(producto.cantidad, 10), 0);
    };

    const calculateTotal = () => {
        const subtotal = productos.reduce((total, producto) => {
            const cantidad = parseFloat(producto.cantidad) || 0;
            const valorUnitario = parseFloat(producto.valorUnitario) || 0;
            const descuento = 0; // Descuento fijo al 0%
            const productoTotal = cantidad * valorUnitario * (1 - descuento / 100);
            return total + productoTotal;
        }, 0);
        return formatPrice(subtotal + envio);
    };

    return (
        <Card className='mt-2 shadow-none'>
            <Card.Header className='fw-bold'>Productos</Card.Header>
            <Table responsive className='shadow-none text-center'>
                <thead className='bg-primary-subtle'>
                    <tr>
                        <th>Producto</th>
                        <th>Nombre</th>
                        <th>Cant.</th>
                        <th>Valor Unitario</th>
                        <th>% Desc.</th>
                        <th>Total</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {productos.map(producto => (
                        <ProductoRow
                            key={producto.id}
                            producto={producto}
                            onChange={handleChange}
                            onRemove={handleRemoveRow}
                        />
                    ))}
                    <tr>
                        <td colSpan="7" className='text-start'>
                            <Button variant='outline-success' onClick={handleAddRow}>
                                <PlusCircleFill size={18} className='me-2' /> Agregar Línea
                            </Button>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="5" className='text-end fw-bold'>Subtotal:</td>
                        <td colSpan="2" className='text-start'>${calculateSubtotal()}</td>
                    </tr>
                    <tr>
                        <td colSpan="5" className='text-end fw-bold'>Items:</td>
                        <td colSpan="2" className='text-start'>{calculateTotalItems()}</td>
                    </tr>
                    <tr>
                        <td colSpan="5" className='text-end fw-bold'>Envío:</td>
                        <td colSpan="2" className='text-start'>
                            <Form.Control
                                type="text"
                                value={formatPrice(envio)}
                                onChange={handleEnvioChange}
                                placeholder="Envío"
                                min="0"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="5" className='text-end fw-bold'>Total:</td>
                        <td colSpan="2" className='text-start'>${calculateTotal()}</td>
                    </tr>
                </tbody>
            </Table>
        </Card>
    );
};

export default EditarProductoPedido;
