import React, { useState } from 'react';
import { FormControl, Button, ButtonGroup } from 'react-bootstrap';
import { Search, XSquare } from 'react-bootstrap-icons';
import Select from 'react-select';  // Importamos el select
import moment from 'moment';
import APIserver from '../../../../api/axiosConfig';

function FiltrosCompra({ updateTable }) {
  const [filtros, setFiltros] = useState({
    numCompra: '',
    proveedor: '',
    creacion: '',
    vencimiento: '',
    total: '',
    estado: ''
  });

  // Opciones del select para el estado
  const estadoOptions = [
    { value: 'pendiente', label: 'Pendiente' },
    { value: 'pagada', label: 'Pagado' },
    { value: 'vencida', label: 'Vencido' }
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFiltros({
      ...filtros,
      [name]: value
    });
  };

  // Manejar el cambio en el select de estado
  const handleEstadoChange = (selectedOption) => {
    setFiltros({
      ...filtros,
      estado: selectedOption ? selectedOption.value : ''
    });
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    return moment(dateString).format('YYYY-MM-DD');
  };

  const handleSearch = async () => {
    try {
      const formattedFilters = {
        ...filtros,
        creacion: formatDate(filtros.creacion),
        vencimiento: formatDate(filtros.vencimiento)
      };

      const response = await APIserver.get(`/api/compras`, {
        params: formattedFilters
      });

      const comprasData = response.data.compras.map(compra => ({
        ...compra,
        creacion: moment(compra.creacion).format('DD/MM/YYYY'),
        vence: moment(compra.vence).format('DD/MM/YYYY')
      }));

      updateTable(comprasData); // Actualizar la tabla con los resultados filtrados
    } catch (error) {
      console.error('Error al buscar compras:', error);
    }
  };

  const handleClear = async () => {
    try {
      setFiltros({
        numCompra: '',
        proveedor: '',
        creacion: '',
        vencimiento: '',
        total: '',
        estado: ''
      });

      const response = await APIserver.get(`/api/compras`);

      const comprasData = response.data.compras.map(compra => ({
        ...compra,
        creacion: moment(compra.creacion).format('DD/MM/YYYY'),
        vence: moment(compra.vence).format('DD/MM/YYYY')
      }));

      updateTable(comprasData); // Actualizar la tabla con la lista completa de compras
    } catch (error) {
      console.error('Error al limpiar y obtener compras:', error);
    }
  };

  return (
    <div className='d-flex align-items-center m-3'>
      <FormControl
        name='numCompra'
        value={filtros.numCompra}
        onChange={handleInputChange}
        placeholder='Busca por Factura'
        className='me-2'
      />
      <FormControl
        name='proveedor'
        value={filtros.proveedor}
        onChange={handleInputChange}
        placeholder='Busca por Proveedor'
        className='me-2'
      />
      <FormControl
        name='creacion'
        value={filtros.creacion}
        onChange={handleInputChange}
        type='date'
        className='me-2'
      />
      <FormControl
        name='vencimiento'
        value={filtros.vencimiento}
        onChange={handleInputChange}
        type='date'
        className='me-2'
      />
      <FormControl
        name='total'
        value={filtros.total}
        onChange={handleInputChange}
        placeholder='Busca por Total'
        className='me-2'
      />
      <Select
        name='estado'
        value={estadoOptions.find(option => option.value === filtros.estado) || null}  // Si el valor es vacío, selecciona null
        onChange={handleEstadoChange}
        options={estadoOptions}
        placeholder='Estado'
        className='me-2 w-100'
        menuPlacement="auto"  // Despliega hacia arriba o abajo dependiendo del espacio
        menuPortalTarget={document.body}  // Renderiza el menú en el body
        styles={{
          menuPortal: base => ({ ...base, zIndex: 9999 }),  // Asegura que el menú esté siempre al frente
          container: base => ({ ...base, position: 'relative', zIndex: 1050 }),
        }}
      />
      <ButtonGroup>
        <Button variant='primary' title='Buscar' onClick={handleSearch}>
          <Search />
        </Button>
        <Button variant='danger' title='Limpiar' onClick={handleClear}>
          <XSquare />
        </Button>
      </ButtonGroup>
    </div>
  );
}

export default FiltrosCompra;
