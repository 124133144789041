import React from 'react';
import { Modal, Form, Button, InputGroup } from 'react-bootstrap';
import { showAlertSuccess, showAlertError } from '../Utils/AlertsSucursales';
import APIserver from '../../../../api/axiosConfig';

const CrearSucursalModal = ({ show, handleClose }) => {
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const nomSucursal = formData.get('nomSucursal');
    const numSucursal = formData.get('numSucursal');

    const sucursalData = {
      nomSucursal,
      numSucursal
    };

    try {
      const response = await APIserver.post(`/api/sucursales`, sucursalData);

      if (response.status === 201) {
        showAlertSuccess('Sucursal guardada correctamente');
        handleClose();
      } else {
        throw new Error('Error al guardar la sucursal');
      }
    } catch (error) {
      console.error('Error al crear la sucursal:', error);
      showAlertError('Error al guardar la sucursal');
    }
  };

  return (
    <Modal show={show} onHide={handleClose} aria-labelledby='create-sucursal-modal'>
      <Modal.Header closeButton>
        <Modal.Title>Crear Nueva Sucursal</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleFormSubmit}>
          <Form.Group className='m-3 mt-0' controlId='nomSucursal'>
            <Form.Label>Nombre</Form.Label>
            <Form.Control type='text' name='nomSucursal' placeholder='Nombre del asesor' required />
          </Form.Group>
          <Form.Group className='m-3' controlId='numSucursal'>
            <Form.Label>Numero</Form.Label>
            <InputGroup>
              <InputGroup.Text>+57</InputGroup.Text>
              <Form.Control type='text' name='numSucursal' placeholder='Numero de contacto' required />
            </InputGroup>
          </Form.Group>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleClose}>Cancelar</Button>
            <Button variant='primary' type='submit'>Guardar</Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CrearSucursalModal;
