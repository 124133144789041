// ImageCropperModal.js (no hay cambios necesarios aquí)
import React, { useRef, useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import imageCompression from 'browser-image-compression';
import Swal from 'sweetalert2';

const ImageCropperModal = ({ show, imageFile, onSave, onClose, cropType }) => {
    const cropperRef = useRef(null);
    const [imageSrc, setImageSrc] = useState(null);
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        if (imageFile && (imageFile instanceof Blob || typeof imageFile === 'string')) {
            const reader = new FileReader();
            reader.onload = () => {
                setImageSrc(reader.result); // Cargar la imagen como base64
            };
            if (imageFile instanceof Blob) {
                reader.readAsDataURL(imageFile);
            } else {
                // Si imageFile es una URL, simplemente úsala directamente
                setImageSrc(imageFile);
            }
        } else {
            setImageSrc(null);
        }
    }, [imageFile]);

    const handleSave = async () => {
        if (cropperRef.current && cropperRef.current.cropper) {
            setIsSaving(true);
            try {
                const cropOptions = cropType === 'banner'
                    ? { width: 1920, height: 495, imageSmoothingEnabled: true, imageSmoothingQuality: 'high' }
                    : { width: 500, height: 500, imageSmoothingEnabled: true, imageSmoothingQuality: 'high' };

                const croppedCanvas = cropperRef.current.cropper.getCroppedCanvas(cropOptions);
                if (croppedCanvas) {
                    const croppedBlob = await new Promise((resolve) => {
                        croppedCanvas.toBlob(resolve, 'image/webp');
                    });

                    if (croppedBlob) {
                        const fileName = cropType === 'banner' ? 'banner.webp' : 'image.webp';
                        const compressedFile = new File([croppedBlob], fileName, {
                            type: 'image/webp',
                        });

                        onSave(compressedFile); // Pasar la imagen comprimida al componente padre
                    } else {
                        console.error('No se pudo convertir el canvas a un blob');
                    }
                } else {
                    console.error('No se pudo obtener el canvas recortado');
                }
            } catch (error) {
                console.error('Error al comprimir la imagen:', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Hubo un problema al procesar la imagen.',
                    iconColor: '#da292e',
                });
            } finally {
                setIsSaving(false);
                onClose(); // Cerrar el modal después de guardar o error
            }
        } else {
            console.error('Cropper no está disponible');
        }
    };

    const handleCancel = () => {
        setImageSrc(null); // Resetea la imagen seleccionada
        onClose('cancel'); // Notify parent that the user canceled
    };

    return (
        <Modal show={show} onHide={handleCancel} backdrop='static' centered size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Recortar Imagen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {imageSrc ? (
                    <Cropper
                        ref={cropperRef}
                        src={imageSrc}
                        style={{ height: 400, width: '100%' }}
                        aspectRatio={cropType === 'banner' ? 1920 / 495 : 1}
                        viewMode={1}
                        guides={false}
                        background={false}
                    />
                ) : (
                    <p>No se ha seleccionado ninguna imagen.</p>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCancel} disabled={isSaving}>
                    Cancelar
                </Button>
                <Button
                    variant="primary"
                    onClick={handleSave}
                    disabled={isSaving}
                >
                    {isSaving ? 'Guardando...' : 'Guardar'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ImageCropperModal;