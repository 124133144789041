// src/components/PrivateRoute.js
import React, { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import Sidebar from '../components/common/Navigation';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';
import '../index.css'; // Assuming you have a main.css for general styles

const PrivateRoute = () => {
    const { auth, loading, checkTokenValidity } = useAuth();
  
    useEffect(() => {
        checkTokenValidity(); // Verifica el token en cada carga de la página
    }, [auth, checkTokenValidity]); // Se ejecuta cuando el estado de `auth` cambia
  
    if (loading) {
      return <div>Loading...</div>; // Muestra un spinner o un mensaje de carga
    }
  
    //console.log('Auth state in PrivateRoute:', auth); // Log del estado de autenticación
  
    if (!auth) {
      return <Navigate to="/login" />; // Redirige a login si no está autenticado
    }
  
    return (
      <div className="d-flex flex-column flex-md-row">
        <Sidebar />
        <div className="main-content flex-grow-1 bg-secondary-subtle">
          <Header />
          <div className="container-fluid">
            <Outlet />
          </div>
          <div className='mt-3'>
            <Footer />
          </div>
        </div>
      </div>
    );
};

export default PrivateRoute;