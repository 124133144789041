// File: CategoriaCard.js
import React from 'react';
import { Card, Button, ButtonGroup } from 'react-bootstrap';
import { PencilSquare, TrashFill } from 'react-bootstrap-icons';

const CategoriaCard = ({ categoria, handleEditClick, handleDeleteClick }) => (
  <div className="col-12 col-sm-6 col-md-4 col-lg-3 p-2">
    <Card className="h-100">
      <div>
        <Card.Img
          variant="top"
          src={categoria.imgCategoria}
          alt={categoria.nomCategoria}
        />
      </div>
      <Card.Body className="text-center">
        <div>
          <Card.Title className='m-2'>{categoria.nomCategoria}</Card.Title>
          <small className='text-muted'>Productos: {categoria.totalProductos}</small>
        </div>
        <ButtonGroup aria-label="Basic example" className='m-2 mt-3'>
          <Button variant="warning" className="btn-sm" onClick={handleEditClick}>
            <PencilSquare />
          </Button>
          <Button variant="danger" className="btn-sm" onClick={handleDeleteClick}>
            <TrashFill />
          </Button>
        </ButtonGroup>
      </Card.Body>
    </Card>
  </div>
);

export default CategoriaCard;
