import React from 'react';
import { ListGroup, Badge, Dropdown } from 'react-bootstrap';
import { formatDistanceToNow } from 'date-fns';

function ContentNotification({ notificaciones }) {
    return (
        <ListGroup 
            variant="flush" 
            style={{
                maxHeight: notificaciones.length > 4 ? '400px' : 'auto', // Altura máxima de 300px si hay más de 5 notificaciones
                overflowY: notificaciones.length > 4 ? 'auto' : 'visible' // Activa el scroll si hay más de 5 notificaciones
            }}
        >
            {notificaciones.length === 0 ? (
                <ListGroup.Item className="text-center">No hay notificaciones.</ListGroup.Item>
            ) : (
                notificaciones.map((notificacion) => (
                    <React.Fragment key={notificacion.id}>
                        <ListGroup.Item className="d-flex justify-content-between align-items-start">
                            <div>
                                <div className="fw-bold">{notificacion.tipo}</div>
                                <small className="text-muted">
                                    {notificacion.mensaje} <br /> {formatDistanceToNow(new Date(notificacion.fecha_creacion), { addSuffix: true })}
                                </small>
                            </div>
                            <Badge bg={notificacion.leida ? "success" : "danger"} pill>
                                {notificacion.leida ? "Leído" : "Nuevo"}
                            </Badge>
                        </ListGroup.Item>
                        <Dropdown.Divider />
                    </React.Fragment>
                ))
            )}
 
        </ListGroup>
    );
}

export default ContentNotification;
