import React, { useRef, useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import imageCompression from 'browser-image-compression';
import Swal from 'sweetalert2'; // Importación añadida para Swal

const ImageCropperModal = ({ show, imageFile, onSave, onClose, cropType }) => {
    const cropperRef = useRef(null);
    const [imageSrc, setImageSrc] = useState(null);
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        if (imageFile) {
            const reader = new FileReader();
            reader.onload = () => {
                setImageSrc(reader.result);
            };
            reader.readAsDataURL(imageFile);
        } else {
            setImageSrc(null);
        }
    }, [imageFile]);

    const handleSave = async () => {
        if (cropperRef.current && cropperRef.current.cropper) {
            setIsSaving(true);
            try {
                const cropOptions = cropType === 'banner'
                    ? { width: 1920, height: 495, imageSmoothingEnabled: true, imageSmoothingQuality: 'high' }
                    : { width: 500, height: 500, imageSmoothingEnabled: true, imageSmoothingQuality: 'high' };

                const croppedCanvas = cropperRef.current.cropper.getCroppedCanvas(cropOptions);
                if (croppedCanvas) {
                    const croppedBlob = await new Promise((resolve) => {
                        croppedCanvas.toBlob(resolve, 'image/webp'); // Convert to webp format
                    });
                    if (croppedBlob) {
                        // Compress the image
                        const compressedBlob = await imageCompression(croppedBlob, {
                            maxSizeMB: 1,
                            maxWidthOrHeight: cropType === 'banner' ? 1920 : 500,
                            useWebWorker: true,
                        });

                        // Convert compressed blob to File
                        const fileName = cropType === 'banner' ? 'banner.webp' : 'logo.webp';
                        const compressedFile = new File([compressedBlob], fileName, {
                            type: 'image/webp',
                        });

                        onSave(compressedFile); // Notify parent with the cropped file
                    } else {
                        console.error('No se pudo convertir el canvas a un blob');
                    }
                } else {
                    console.error('No se pudo obtener el canvas recortado');
                }
            } catch (error) {
                console.error('Error al comprimir la imagen:', error);
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Hubo un problema al procesar la imagen.',
                });
            } finally {
                setIsSaving(false);
                onClose(); // Close modal after saving or error
            }
        } else {
            console.error('Cropper no está disponible');
        }
    };

    const handleCancel = () => {
        setImageSrc(null); // Resetea la imagen seleccionada
        onClose('cancel'); // Notify parent that the user canceled
    };

    return (
        <Modal show={show} onHide={handleCancel} backdrop='static' centered size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Recortar Imagen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {imageSrc ? (
                    <Cropper
                        ref={cropperRef}
                        src={imageSrc}
                        style={{ height: 400, width: '100%' }}
                        aspectRatio={cropType === 'banner' ? 1920 / 495 : 1}
                        viewMode={1}
                        guides={false}
                        background={false}
                    />
                ) : (
                    <p>No se ha seleccionado ninguna imagen.</p>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCancel} disabled={isSaving}>
                    Cancelar
                </Button>
                <Button
                    variant="primary"
                    onClick={handleSave}
                    disabled={isSaving}
                >
                    {isSaving ? 'Guardando...' : 'Guardar'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ImageCropperModal;
