import React, { useState } from 'react';
import {
  Button,
  Dropdown
} from 'react-bootstrap';
import { Cash, Upload, NutFill } from 'react-bootstrap-icons';
import '../styles/tables.css';
import CustomToggle from '../styles/customToggle';
import CrearPagoModal from './componentes/crearPagoModal';
import TablaPagos from './componentes/tablaPagos';

function AdminPagosHe() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className='mx-2 mt-3'>
      <div className='d-flex flex-column flex-md-row align-items-center justify-content-between'>
        <p className='mb-2 mb-md-0 text-center'>
          Registra tus egresos y mantiene una contabilidad precisa de tu negocio.
        </p>
        <div className='d-flex mt-2 mt-md-0'>
          <Button variant='primary' onClick={handleShow}>
            <Cash /> Nuevo Pago
          </Button>
          <Dropdown>
            <Dropdown.Toggle as={CustomToggle} variant='secondary' className='ms-2'>
              <NutFill />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item><Upload className='text-danger mx-1' /> Exportar</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      {/* Modal para crear pago */}
      <CrearPagoModal show={show} handleClose={handleClose} />
      {/* Tabla Pagos */}
      <div className='mt-4'>
      <TablaPagos />
      </div>
    </div>

  );
}

export default AdminPagosHe;
