import { useContext, useEffect } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';  // Importar SweetAlert2

const useAuth = () => {
    const { auth, setAuth, login, logout } = useContext(AuthContext);
    const navigate = useNavigate();

    // Verificar la validez del token
    const checkTokenValidity = () => {
        const token = auth?.token;
        if (!token) return;

        try {
            const decodedPayload = JSON.parse(atob(token.split('.')[1]));
            const expiry = decodedPayload.exp;

            if (Date.now() >= expiry * 1000) {
                // Mostrar alerta con SweetAlert2 cuando la sesión ha expirado
                Swal.fire({
                    icon: 'warning',
                    title: 'Sesión expirada',
                    text: 'Tu sesión ha expirado. Por favor, inicia sesión nuevamente.',
                    confirmButtonText: 'OK',
                }).then(() => {
                    // Cerrar sesión y redirigir después de que el usuario haga clic en "OK"
                    logout();
                    navigate('/login');
                });
            }
        } catch (error) {
            console.error('Error decoding the token:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error de sesión',
                text: 'Hubo un problema con tu sesión. Por favor, inicia sesión nuevamente.',
                confirmButtonText: 'OK',
            }).then(() => {
                logout();
                navigate('/login');
            });
        }
    };

    useEffect(() => {
        const localAuth = localStorage.getItem('auth');
        if (localAuth) {
            setAuth(JSON.parse(localAuth));
        }

        const handleBeforeUnload = () => {
            const openedTabs = parseInt(localStorage.getItem('openedTabs') || '0');
            localStorage.setItem('openedTabs', Math.max(openedTabs - 1, 0).toString());

            if (openedTabs <= 1) {
                localStorage.removeItem('auth');
            }
        };

        const handleNewTab = () => {
            const openedTabs = parseInt(localStorage.getItem('openedTabs') || '0');
            localStorage.setItem('openedTabs', (openedTabs + 1).toString());
        };

        handleNewTab();
        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [setAuth]);

    return { auth, setAuth, login, logout, checkTokenValidity };
};

export default useAuth;
