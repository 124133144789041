// File path: src/routes.js
import React from "react";
import { Route, Routes, Outlet } from "react-router-dom"; // Importar Outlet
import PublicLayout from "./components/layouts/PublicLayout";
import PrivateRoute from "./components/PrivateRoute"; // Importamos el PrivateRoute

// Admin imports
import AdminDashboard from "./pages/Admin/Dashboard/AdminDashboard";
import AdminFacturas from "./pages/Admin/facturas/AdminFacturas";
import AdminPedidos from "./pages/Admin/Pedidos/AdminPedidos";
import AdminPagosRes from "./pages/Admin/PagosResibidos/AdminPagosRes";
import AdminCompras from "./pages/Admin/Compras/AdminCompras";
import AdminPagosHe from "./pages/Admin/Pagos/AdminPagosHe";
import AdminClientes from "./pages/Admin/Clientes/AdminClientes";
import AdminProveedores from "./pages/Admin/Proveedores/AdminProveedores";
import AdminCategorias from "./pages/Admin/Categorias/AdminCategorias";
import AdminProductos from "./pages/Admin/Productos/AdminProductos";
import AdminSucursales from "./pages/Admin/Sucursales/AdminSucursales";
import AdminBancos from "./pages/Admin/Bancos/AdminBancos";
import DetallesClientes from "./pages/Admin/Clientes/DetallesClientes";
import DetallesPedido from "./pages/Admin/Pedidos/DetallesPedido";
import DetallesCompra from "./pages/Admin/Compras/DetallesCompra";
import DetallesProveedor from "./pages/Admin/Proveedores/DetallesProveedor";
import DetallesBanco from "./pages/Admin/Bancos/DetallesBancos";
import DetallesPagoHe from "./pages/Admin/Pagos/DetallesPagosHe";
import CrearFactura from "./pages/Admin/facturas/CrearFactura";
import EditarPedido from "./pages/Admin/Pedidos/editarPedido";
import EditarFactura from "./pages/Admin/facturas/EditarFactura";
import DetallesFactura from "./pages/Admin/facturas/DetallesFactura";
import DetallesPagoRes from "./pages/Admin/PagosResibidos/DetallesPagosRes";
import AdminConfiguracion from "./pages/Admin/Configuracion/AdminConfig";
import CrearFacturarPedidos from "./pages/Admin/Pedidos/FacturarPedidos/CrearFacturarPedidos";
import AdminMarketing from "./pages/Admin/marketing/AdminMarketing";
import AdminReportes from "./pages/Admin/Reportes/AdminReportes";
// Public Imports
import HomePage from "./pages/Public/HomePage";
import AdminLogin from "./components/admin/Login";
import AdminUnidad from "./pages/Public/ventaUnidad/AdminUnidad";
import VerProductUni from "./pages/Public/ventaUnidad/VerProductosUni";
import RegisterPage from "./pages/FynDex/RegisterPage";
import ViewPedido from "./pages/Public/ventaUnidad/ViewPedido";
import ResetPassword from "./components/admin/ResetPassword";
import ForgotPassword from "./components/admin/ForgotPassword";
import NotFoundPage from "./pages/NotFoundPage";

const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<PublicLayout />}>
        <Route index path="/:idSucursal?" element={<HomePage />} />
        <Route path="Login" element={<AdminLogin />} />
        <Route path="Collections/:idSucursal?/:nomSeccion?" element={<AdminUnidad />} />
        <Route path="Collections/:idSucursal/Products/:idCategoria" element={<VerProductUni />} />
        <Route path="ViewOrder/:numPedido" element={<ViewPedido />} />
        <Route path="/register/:token" element={<RegisterPage />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route path='/reset-password/:token' element={<ResetPassword />} />
      </Route>
      <Route element={<PrivateRoute />}>
        <Route path="/admin" element={<Outlet />}>
          <Route path="Dashboard/" element={<AdminDashboard />} />
          <Route path="IngresosFacturas/" element={<AdminFacturas />} />
          <Route path="Pedidos/" element={<AdminPedidos />} />
          <Route path="PagosRecibidos/" element={<AdminPagosRes />} />
          <Route path="Compras/" element={<AdminCompras />} />
          <Route path="PagosRealizados/" element={<AdminPagosHe />} />
          <Route path="Clientes/" element={<AdminClientes />} />
          <Route path="Proveedores/" element={<AdminProveedores />} />
          <Route path="Categorias/" element={<AdminCategorias />} />
          <Route path="Productos/" element={<AdminProductos />} />
          <Route path="Sucursales/" element={<AdminSucursales />} />
          <Route path="Bancos/" element={<AdminBancos />} />
          <Route path="Clientes/DetallesClientes/:idCliente/" element={<DetallesClientes />} />
          <Route path="Pedidos/DetallesPedido/:idPedido/" element={<DetallesPedido />} />
          <Route path="Compras/DetallesCompra/:idCompra/" element={<DetallesCompra />} />
          <Route path="Proveedores/DetallesProveedores/:idProveedor/" element={<DetallesProveedor />} />
          <Route path="Bancos/DetallesBancos/:id/" element={<DetallesBanco />} />
          <Route path="PagosRealizados/DetallesPago/:idPago/" element={<DetallesPagoHe />} />
          <Route path="Facturas/CrearFactura/" element={<CrearFactura />} />
          <Route path="Pedidos/EditarPedido/:idPedido/" element={<EditarPedido />} />
          <Route path="Pedidos/FacturarPedidos/CrearFacturarPedidos/:idPedido/" element={<CrearFacturarPedidos />} />
          <Route path="Facturas/EditarFactura/:idFactura/" element={<EditarFactura />} />
          <Route path="Facturas/DetallesFactura/:idFactura/" element={<DetallesFactura />} />
          <Route path="PagosRecibidos/DetallesPago/:idPago/" element={<DetallesPagoRes />} />
          <Route path="Configuracion/" element={<AdminConfiguracion />} />
          <Route path="Marketing/" element={<AdminMarketing />} />
          <Route path="Reportes/" element={<AdminReportes />} />
        </Route>
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default AppRoutes;