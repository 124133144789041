import React, { useEffect, useRef, useState } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { PencilFill, PrinterFill, ThreeDotsVertical, TrashFill } from 'react-bootstrap-icons';
import { useReactToPrint } from 'react-to-print';
import { useParams, Link, useNavigate } from 'react-router-dom';  // Cambiado useHistory a useNavigate
import ComprobantePagoHe from './componentes/comprobantePagoHe';
import CustomToggle from '../styles/customToggle';
import EditarPago from './componentes/editarPago';
import { AlertsPagosEliminar } from './Utils/AlertsPagos';
import APIserver from '../../../api/axiosConfig';

function DetallesPagoHe() {
    const { idPago } = useParams();
    const [pagoData, setPagoData] = useState(null);
    const [editShow, setShowEdit] = useState(false); 
    const componentRef = useRef();
    const navigate = useNavigate();  // Cambiado useHistory a useNavigate

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const fetchPagoData = async () => {
        try {
            const response = await APIserver.get(`/api/detallespagos/${idPago}`);
            setPagoData(response.data);
        } catch (error) {
            console.error('Error fetching pago data:', error);
        }
    };

    useEffect(() => {
        fetchPagoData();
    }, [idPago]);

    const handleEditShow = () => {
        setShowEdit(true);
    };

    const handleEditClose = () => {
        setShowEdit(false);
    };

    const handleEliminarPago = async () => {
        AlertsPagosEliminar.showConfirmDelete(async () => {
            try {
                const response = await APIserver.delete(`/api/pagos/${idPago}`);
                if (response.status === 200) {
                    AlertsPagosEliminar.showSuccessMessage('El pago ha sido eliminado correctamente');
                    navigate('/admin/PagosRealizados/');  // Usar navigate en lugar de history.push
                } else {
                    AlertsPagosEliminar.showErrorMessage('Error al eliminar el pago');
                }
            } catch (error) {
                console.error('Error al eliminar el pago:', error);
                AlertsPagosEliminar.showErrorMessage('Error al eliminar el pago');
            }
        });
    };

    return (
        <div className='mx-2 mt-3 mt-md-0'>
            <div className='d-flex flex-column flex-md-row align-items-center justify-content-between'>
                <h4 className='mb-2 mb-md-0 text-center'>
                    Comprobante de pago realizado: <b>{pagoData ? `${pagoData.numPagoHe}` : 'Cargando...'}</b>
                </h4>
                <div className='d-flex mt-2 mt-md-0'>
                    <Button variant='primary' onClick={handlePrint}>
                        <PrinterFill className='me-2' /> Imprimir
                    </Button>
                    <Link to='/admin/PagosRealizados'>
                        <Button variant='secondary' className='ms-2'>Volver</Button>
                    </Link>
                    <Dropdown>
                        <Dropdown.Toggle as={CustomToggle} variant='secondary' className='ms-2'>
                            <ThreeDotsVertical />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={handleEditShow}>
                                <PencilFill className='me-2 text-warning' /> Editar
                            </Dropdown.Item>
                            <Dropdown.Item onClick={handleEliminarPago}>
                                <TrashFill className='me-2 text-danger' /> Eliminar
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>
            <div>
                {/* Card de comprobante */}
                <div ref={componentRef}>
                    {pagoData && <ComprobantePagoHe pagoData={pagoData} numPago={pagoData ? pagoData.numPagoHe : ''} />}
                </div>
            </div>

            {/* Modal para editar */}
            {pagoData && (
                <EditarPago show={editShow} handleClose={handleEditClose} pagoId={idPago} updatePagos={fetchPagoData} />
            )}
        </div>
    );
}

export default DetallesPagoHe;
