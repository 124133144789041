import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, InputGroup, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import { AlertsPagosActualizar } from '../Utils/AlertsPagos'; // Asegúrate de importar correctamente AlertsPagosActualizar
import APIserver from '../../../../api/axiosConfig';

function EditarPago({ show, handleClose, pagoId, updatePagos }) {
  const [editPago, setEditPago] = useState({
    idPago: '',
    numCompra: '',
    nomProveedor: '',
    detalle: '',
    fecha: '',
    idBanco: '',
    monto: '',
    tipoPago: '',
    concepto: ''
  });

  const [bancos, setBancos] = useState([]);
  const [errors, setErrors] = useState({}); // Estado para almacenar errores

  const optionsPago = [
    { value: 'transferencia', label: 'Transferencia' },
    { value: 'efectivo', label: 'Efectivo' }
  ];

  useEffect(() => {
    if (pagoId) {
      fetchPagoData();
    }
  }, [pagoId]);

  useEffect(() => {
    fetchBancos();
  }, []);

  const fetchPagoData = async () => {
    try {
      const response = await APIserver.get(`/api/api/pagos/${pagoId}`);
      const pago = response.data;

      const formattedFecha = pago.fecha ? new Date(pago.fecha).toISOString().split('T')[0] : '';

      // Buscar el objeto que coincida con el valor de tipoPago
      const tipoPagoSeleccionado = optionsPago.find(option => option.value === pago.tipoPago);

      setEditPago({
        idPago: pago.idPago,
        numCompra: pago.numCompra,
        nomProveedor: pago.nomProveedor,
        detalle: pago.detalle || '',
        fecha: formattedFecha,
        idBanco: pago.idBanco || '',
        monto: pago.monto || '',
        tipoPago: tipoPagoSeleccionado || null,  // Asegurar que es { value, label }
        concepto: pago.concepto || ''
      });
    } catch (error) {
      console.error('Error fetching payment data:', error);
    }
  };

  const fetchBancos = async () => {
    try {
      const response = await APIserver.get(`/api/pagosrecibidos/mostrar/bancos`);
      const data = response.data;

      if (Array.isArray(data.bancos)) {
        const options = data.bancos.map(banco => ({
          value: banco.idBanco,
          label: banco.nomBanco
        }));
        setBancos(options);
      } else {
        console.error('La propiedad "bancos" de la respuesta no es un arreglo válido:', data.bancos);
      }
    } catch (error) {
      console.error('Error al obtener los bancos:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditPago({
      ...editPago,
      [name]: value,
    });
  };

  const handleBancoChange = (selectedOption) => {
    setEditPago({
      ...editPago,
      idBanco: selectedOption ? selectedOption.value : '',
    });
  };

  const handleTipoPagoChange = (selectedOption) => {
    setEditPago({
      ...editPago,
      tipoPago: selectedOption || '',  // Guardamos el objeto { value, label }
    });
  };

  const validateForm = () => {
    const newErrors = {};

    if (!editPago.numCompra) newErrors.numCompra = 'La factura es obligatoria.';
    if (!editPago.fecha) newErrors.fecha = 'La fecha es obligatoria.';
    if (!editPago.idBanco) newErrors.idBanco = 'Debe seleccionar un banco.';
    if (!editPago.monto || editPago.monto <= 0) newErrors.monto = 'El monto debe ser mayor a 0.';
    if (!editPago.tipoPago) newErrors.tipoPago = 'Debe seleccionar un tipo de pago.';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Retorna true si no hay errores
  };

  const handleSave = async () => {
    console.log('Datos del pago antes de la validación:', editPago);  // Verificar los datos antes de validar

    if (!validateForm()) {
      return;
    }

    const payload = {
      ...editPago,
      monto: parseFloat(editPago.monto),  // Convertir monto a número
      tipoPago: editPago.tipoPago ? editPago.tipoPago.value : '',  // Extraer solo el `value` antes de enviarlo
      concepto: editPago.concepto
    };

    console.log('Payload enviado al backend:', payload);  // Depurar para verificar que solo enviamos el `value`

    try {
      const response = await APIserver.put(`/api/pagos/${editPago.idPago}`, payload);
      console.log('Respuesta del backend:', response.data);  // Ver la respuesta del backend

      AlertsPagosActualizar.showSuccessMessage('¡Pago actualizado exitosamente!');
      handleClose();
      if (updatePagos) {
        updatePagos();
      }
    } catch (error) {
      if (error.response) {
        console.error('Error en la respuesta del backend:', error.response.data);  // Mostrar los detalles del error de la respuesta
        if (error.response.status === 400 && error.response.data.message === 'El monto del pago excede el total de la compra') {
          AlertsPagosActualizar.showErrorMessage('El monto ingresado excede el valor total de la compra.');
        } else {
          AlertsPagosActualizar.showErrorMessage('Error al guardar los cambios');
        }
      } else {
        console.error('Error desconocido al guardar los cambios:', error);  // Mostrar errores que no vienen en la respuesta
        AlertsPagosActualizar.showErrorMessage('Error al guardar los cambios');
      }
    }
  };

  return (
    <Modal size='lg' show={show} onHide={handleClose} aria-labelledby="modal-editar-pago">
      <Modal.Header closeButton>
        <Modal.Title>Editar Pago</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Row>
            <Col>
              <Form.Group className='m-3 mt-0' controlId='editNumCompra'>
                <Form.Label>Factura</Form.Label>
                <Form.Control
                  type='text'
                  name='numCompra'
                  value={editPago.numCompra}
                  onChange={handleChange}
                  placeholder='Numero de factura de compra'
                  isInvalid={!!errors.numCompra} // Validar si hay error en numCompra
                  required
                />
                <Form.Control.Feedback type='invalid'>{errors.numCompra}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className='m-3' controlId='editnomProveedor'>
                <Form.Label>Proveedor</Form.Label>
                <Form.Control
                  type='text'
                  name='nomProveedor'
                  value={editPago.nomProveedor}
                  readOnly
                />
              </Form.Group>
              <Form.Group className='m-3' controlId='editConceptoPago'>
                <Form.Label>Concepto</Form.Label>
                <Form.Control
                  type='text'
                  name='concepto'
                  value={editPago.concepto}  // Asignar el valor del concepto
                  onChange={handleChange}  // Manejar los cambios en el input
                  placeholder='Concepto del pago'
                />
              </Form.Group>
              <Form.Group className='m-3' controlId='detallePago'>
                <Form.Label>Observaciones</Form.Label>
                <Form.Control
                  as="textarea"
                  name='detalle'
                  value={editPago.detalle}
                  onChange={handleChange}
                  placeholder='Observaciones sobre el pago (Opcional)'
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className='m-3 mt-0' controlId='editfechaPago'>
                <Form.Label>Fecha</Form.Label>
                <Form.Control
                  type='date'
                  name='fecha'
                  value={editPago.fecha}
                  onChange={handleChange}
                  isInvalid={!!errors.fecha} // Validar si hay error en fecha
                  required
                />
                <Form.Control.Feedback type='invalid'>{errors.fecha}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className='m-3' controlId='editbancoPago'>
                <Form.Label>Banco</Form.Label>
                <Select
                  options={bancos}  // Las opciones generadas desde fetchBancos
                  value={bancos.find(banco => banco.value === editPago.idBanco) || null}  // Buscar la opción que coincide con `editPago.idBanco`
                  onChange={handleBancoChange}  // Cambiar el estado cuando se selecciona una opción
                  placeholder='Selecciona el Banco'
                  className={errors.idBanco ? 'is-invalid' : ''}  // Mostrar error si existe
                  required
                />
                {errors.idBanco && <div className="invalid-feedback d-block">{errors.idBanco}</div>}
              </Form.Group>
              <Form.Group className='m-3' controlId='edittipoPago'>
                <Form.Label>Tipo de Pago</Form.Label>
                <Select
                  options={optionsPago}  // Las opciones disponibles
                  value={editPago.tipoPago || null}  // El valor seleccionado actualmente, debe ser un objeto { value, label }
                  onChange={handleTipoPagoChange}  // Cuando cambia, actualizar el estado
                  placeholder='Selecciona el Tipo de Pago'
                  className={errors.tipoPago ? 'is-invalid' : ''}
                />
                {errors.tipoPago && <div className="invalid-feedback d-block">{errors.tipoPago}</div>}
              </Form.Group>
              <Form.Group className="m-3" controlId="editmontoPago">
                <Form.Label>Monto</Form.Label>
                <InputGroup className="mb-3">
                  <InputGroup.Text>$</InputGroup.Text>
                  <Form.Control
                    type='number'
                    name='monto'
                    value={editPago.monto}
                    onChange={handleChange}
                    placeholder='Pago total realizado'
                    isInvalid={!!errors.monto} // Validar si hay error en monto
                  />
                  <InputGroup.Text>.00</InputGroup.Text>
                  <Form.Control.Feedback type='invalid'>{errors.monto}</Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleClose}>Cancelar</Button>
        <Button variant='primary' onClick={handleSave}>Guardar</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditarPago;
