import Swal from 'sweetalert2';
import APIserver from '../../../../api/axiosConfig';

export const showAlertSuccess = (message) => {
    Swal.fire({
        icon: 'success',
        iconColor: '#2fb380',
        title: '¡Éxito!',
        text: message,
        showConfirmButton: false,
        timer: 2000
    }).then(() => {
        window.location.reload();
    });
};

export const showAlertError = (message) => {
    Swal.fire({
        icon: 'error',
        iconColor: '#da292e',
        title: 'Error',
        text: message,
    });
};

export const showUpdateSuccess = () => {
    Swal.fire({
        icon: 'success',
        iconColor: '#2fb380',
        title: '¡Éxito!',
        text: 'Proveedor actualizado correctamente',
        showConfirmButton: false,
        timer: 2000
    }).then(() => {
        window.location.reload();
    });
};

export const showUpdateError = () => {
    Swal.fire({
        icon: 'error',
        iconColor: '#da292e',
        title: 'Error',
        text: 'Error al actualizar el proveedor',
    });
};

export const handleDeleteProveedor = (proveedorId, setProveedores, proveedores) => {
    Swal.fire({
        title: '¿Estás seguro?',
        text: "¡No podrás revertir esto!",
        icon: 'warning',
        showCancelButton: true,
        iconColor: '#f4bd61',
        confirmButtonColor: '#2c4cc4',
        cancelButtonColor: '#da292e',
        confirmButtonText: 'Sí, eliminarlo!',
        cancelButtonText: 'Cancelar'
    }).then((result) => {
        if (result.isConfirmed) {
            APIserver.delete(`/api/proveedores/${proveedorId}`)
                .then(response => {
                    setProveedores(proveedores.filter(proveedor => proveedor.idProveedor !== proveedorId));
                    Swal.fire({
                        title: '¡Eliminado!',
                        text: 'El proveedor ha sido eliminado.',
                        icon: 'success',
                        confirmButtonText: 'Aceptar',
                        iconColor: '#2fb380',
                        confirmButtonColor: "#2c4cc4"
                    }).then(() => {
                        // Recarga la página después de eliminar con éxito
                        window.location.reload();
                    });
                })
                .catch(error => {
                    if (error.response && error.response.status === 400 && error.response.data.error === 'No se puede eliminar el proveedor porque tiene compras asociadas') {
                        Swal.fire({
                            title: 'Error',
                            text: 'No se puede eliminar este proveedor porque tiene compras asociadas.',
                            icon: 'error',
                            confirmButtonText: 'Aceptar',
                            iconColor: '#da292e',
                            confirmButtonColor: "#da292e"
                        });
                    } else {
                        Swal.fire({
                            title: 'Error',
                            text: 'Hubo un error al eliminar el proveedor.',
                            icon: 'error',
                            confirmButtonText: 'Aceptar',
                            iconColor: '#da292e',
                            confirmButtonColor: "#da292e"
                        });
                    }
                });
        }
    });
};