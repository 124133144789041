import Swal from 'sweetalert2';

export const showConfirmDelete = () => {
  return Swal.fire({
    icon: 'warning',
    title: '¿Estás seguro?',
    text: '¿Estás seguro de eliminar esta sucursal?',
    showCancelButton: true,
    iconColor: '#f4bd61',
    confirmButtonColor: '#2c4cc4',
    cancelButtonColor: '#da292e',
    confirmButtonText: 'Sí, eliminar',
    cancelButtonText: 'Cancelar'
  }).then((result) => {
    return result.isConfirmed;
  });
};

export const showAlertSuccess = (message) => {
  Swal.fire({
    icon: 'success',
    title: '¡Éxito!',
    text: message,
    showConfirmButton: false,
    timer: 2000,
    iconColor: '#2fb380',
    confirmButtonColor: "#2c4cc4"
  }).then(() => {
    window.location.reload();
  });
};

export const showAlertError = (message) => {
  Swal.fire({
    icon: 'error',
    title: 'Error',
    text: message,
    iconColor: '#da292e',
    confirmButtonColor: "#da292e"
  });
};

export const showAlertUpdateConfirmation = () => {
  Swal.fire({
    icon: 'success',
    title: '¡Éxito!',
    text: 'La sucursal se actualizó correctamente',
    showConfirmButton: false,
    timer: 2000,
    iconColor: '#2fb380',
    confirmButtonColor: "#2c4cc4"
  });
};
