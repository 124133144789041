import React, { useState } from 'react';
import { FormControl, Button, ButtonGroup } from 'react-bootstrap';
import { Search, XSquare } from 'react-bootstrap-icons';
import Select from 'react-select'; // Importar react-select

function FiltrosPedidos({ onSearch, onClear }) {
    // Estados para cada campo de filtro
    const [pedido, setPedido] = useState('');
    const [cliente, setCliente] = useState('');
    const [fecha, setFecha] = useState('');
    const [total, setTotal] = useState('');
    const [asesor, setAsesor] = useState('');
    const [tipo, setTipo] = useState(null); // Cambiar a null para react-select
    const [estado, setEstado] = useState(null); // Cambiar a null para react-select

    // Opciones para el select de Estado
    const estadoOptions = [
        { value: 'pendiente', label: 'Pendiente' },
        { value: 'facturado', label: 'Facturado' },
        { value: 'anulado', label: 'Anulado' }
    ];

    // Opciones para el select de Tipo
    const tipoOptions = [
        { value: 'unidad', label: 'Unidad' },
        { value: 'distribucion', label: 'Distribución' }
    ];

    const handleSearch = () => {
        if (typeof onSearch === 'function') {
            onSearch({
                pedido,
                cliente,
                fecha,
                total,
                asesor,
                tipo: tipo ? tipo.value : '', // Obtener el valor seleccionado del select de Tipo
                estado: estado ? estado.value : '' // Obtener el valor seleccionado del select de Estado
            });
        } else {
            console.error('onSearch no es una función');
        }
    };

    const handleClear = () => {
        setPedido('');
        setCliente('');
        setFecha('');
        setTotal('');
        setAsesor('');
        setTipo(null); // Limpiar select de Tipo
        setEstado(null); // Limpiar select de Estado

        if (typeof onClear === 'function') {
            onClear();
        } else {
            console.error('onClear no es una función');
        }
    };

    return (
        <div className='d-flex align-items-center m-3'>
            <FormControl
                placeholder='Busca por Pedido'
                className='me-2'
                value={pedido}
                onChange={(e) => setPedido(e.target.value)}
            />
            <FormControl
                placeholder='Busca por Cliente'
                className='me-2'
                value={cliente}
                onChange={(e) => setCliente(e.target.value)}
            />
            <FormControl
                type='date'
                className='me-2'
                value={fecha}
                onChange={(e) => setFecha(e.target.value)}
            />
            <FormControl
                placeholder='Busca por Total'
                className='me-2'
                value={total}
                onChange={(e) => setTotal(e.target.value)}
            />
            <FormControl
                placeholder='Busca por Asesor'
                className='me-2'
                value={asesor}
                onChange={(e) => setAsesor(e.target.value)}
            />

            {/* Select para Tipo */}
            <Select
                value={tipo}
                onChange={setTipo}
                options={tipoOptions}
                placeholder='Tipo'
                className='me-2 w-100'
                menuPortalTarget={document.body}  // Asegura que el menú se renderice en el body
                styles={{
                    menuPortal: base => ({ ...base, zIndex: 9999 }), // Z-index para evitar superposición de otros elementos
                    container: base => ({ ...base, position: 'relative', zIndex: 1050 })
                }}
            />

            {/* Select para Estado */}
            <Select
                value={estado}
                onChange={setEstado}
                options={estadoOptions}
                placeholder='Estado'
                className='me-2 w-100'
                menuPortalTarget={document.body}  // Asegura que el menú se renderice en el body
                styles={{
                    menuPortal: base => ({ ...base, zIndex: 9999 }), // Z-index para evitar superposición de otros elementos
                    container: base => ({ ...base, position: 'relative', zIndex: 1050 })
                }}
            />

            <ButtonGroup>
                <Button variant='primary' title='Buscar' onClick={handleSearch}>
                    <Search />
                </Button>
                <Button variant='danger' title='Limpiar' onClick={handleClear}>
                    <XSquare />
                </Button>
            </ButtonGroup>
        </div>
    );
}

export default FiltrosPedidos;
