import React, { useState } from 'react';
import { Button, Container, ListGroup, InputGroup, FormControl, Row, Col, ButtonGroup, Modal } from 'react-bootstrap';
import { TrashFill, CameraFill, PencilFill } from 'react-bootstrap-icons';
import { useTiendaConfig } from '../../../../hooks/useTiendaConfig';
import ImageCropperModal from './ImageCropperModal'; // Ajusta la ruta según tu estructura de carpetas
import './TiendaConfig.css';
import EnviosTienda from './tienda/enviosConfig';
import DescuentosTienda from './tienda/descuentosConfig';
import getIcon from '../../../../utils/getIcon';

function TiendaConfig() {
    const {
        socialLinks,
        newLink,
        editIndex,
        companyInfo,
        storeInfo,
        setNewLink,
        addLink,
        editLink,
        saveLink,
        cancelEdit,
        deleteLink,
        updateBannerImage,
        updateProfileImage,
        updateDescription,
        setStoreInfo
    } = useTiendaConfig();

    const [showBannerModal, setShowBannerModal] = useState(false);
    const [showProfileModal, setShowProfileModal] = useState(false);
    const [showDescriptionModal, setShowDescriptionModal] = useState(false);
    const [newImage, setNewImage] = useState(null);
    const [cropType, setCropType] = useState('');
    const [newDescription, setNewDescription] = useState(storeInfo.descripcionTienda);
    const [hoverBanner, setHoverBanner] = useState(false);
    const [hoverProfile, setHoverProfile] = useState(false);
    const [aspectRatio, setAspectRatio] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewLink({ ...newLink, [name]: value });
    };

    const handleImageChange = (e, type) => {
        const file = e.target.files[0];
        setNewImage(file);
        setCropType(type);
        if (type === 'banner') {
            setShowBannerModal(true);
        } else if (type === 'profile') {
            setShowProfileModal(true);
        }
    };

    const handleImageSave = (croppedImage) => {
        if (cropType === 'banner') {
            updateBannerImage(croppedImage).then((newImageUrl) => {
                setStoreInfo((prev) => ({
                    ...prev,
                    imagenBanner: `${newImageUrl}?t=${new Date().getTime()}`, // Evita caché
                }));
            });
        } else if (cropType === 'profile') {
            updateProfileImage(croppedImage).then((newImageUrl) => {
                setStoreInfo((prev) => ({
                    ...prev,
                    imagenPerfil: `${newImageUrl}?t=${new Date().getTime()}`, // Evita caché
                }));
            });
        }
        setShowBannerModal(false);
        setShowProfileModal(false);
    };

    const handleDescriptionChange = (e) => {
        setNewDescription(e.target.value);
    };

    const handleDescriptionSave = () => {
        updateDescription(newDescription);
        setShowDescriptionModal(false);
    };

    const openDescriptionModal = () => {
        setNewDescription(storeInfo.descripcionTienda);
        setShowDescriptionModal(true);
    };

    const handleImageLoad = (e) => {
        const { naturalWidth, naturalHeight } = e.target;
        setAspectRatio(naturalWidth / naturalHeight);
    };


    return (
        <div className='m-4'>
            <Container>
                <div className='mt-4 position-relative'>
                    {/* img bannerfuncional no utilizado
                    <div
                        onMouseEnter={() => setHoverBanner(true)}
                        onMouseLeave={() => setHoverBanner(false)}
                        className='position-relative'
                    >
                        <img
                            src={storeInfo.imagenBanner ? (storeInfo.imagenBanner) : 'https://via.placeholder.com/1920x495'}
                            className={`w-100 ${hoverBanner ? 'img-darken' : ''}`}
                            alt='banner'
                        />
                        {hoverBanner && (
                            <div className='overlay'>
                                <Button
                                    variant='link'
                                    className='edit-button'
                                    onClick={() => document.getElementById('banner-image-input').click()}
                                    title='Cambiar banner'
                                >
                                    <CameraFill size={24} />
                                </Button>
                                <input
                                    type='file'
                                    id='banner-image-input'
                                    accept='image/*'
                                    style={{ display: 'none' }}
                                    onChange={(e) => handleImageChange(e, 'banner')}
                                />
                            </div>
                        )}
                    </div>
                    */}
                    <Row>
                        <Col>
                            <h2 className='fw-bold text-black'>Logo de tienda</h2>
                            <p>Este logo se mostrara en el menu de navegacion de tu tienda.</p>
                            <div className='d-flex flex-column flex-md-row align-items-center'>
                                <div
                                    className='position-relative m-4 logo-profile-wrapper bg-cr'
                                    style={{ marginTop: '20px' }}
                                    onMouseEnter={() => setHoverProfile(true)}
                                    onMouseLeave={() => setHoverProfile(false)}
                                >
                                    <img
                                        src={storeInfo.imagenPerfil ? storeInfo.imagenPerfil : 'https://via.placeholder.com/500'}
                                        width={aspectRatio > 1 ? 250 : 100} // Ajusta el width a 250 si es panorámica; 100 si es cuadrada o vertical
                                        className={`m-2 logo-profile ${hoverProfile ? 'img-darken' : ''}`}
                                        alt='logo-profile'
                                        onLoad={handleImageLoad} // Calcula el aspect ratio cuando la imagen termine de cargar
                                        style={{ objectFit: 'cover' }} // Opcional: mantiene la imagen ajustada en su contenedor
                                    />
                                    {hoverProfile && (
                                        <div className='overlay'>
                                            <Button
                                                variant='link'
                                                className='edit-button ms-3'
                                                onClick={() => document.getElementById('profile-image-input').click()}
                                                title='Cambiar imagen de perfil'
                                                disabled
                                            >
                                                <CameraFill size={24} />
                                            </Button>
                                            <input
                                                type='file'
                                                id='profile-image-input'
                                                accept='image/*'
                                                style={{ display: 'none' }}
                                                onChange={(e) => handleImageChange(e, 'profile')}
                                            />
                                        </div>
                                    )}
                                </div>
                                {/* 
                                <div className='d-flex flex-column mt-2 mt-md-0 ms-5 store-name'>
                                    <h3>
                                        {companyInfo.nombreEmpresa}
                                        <Button variant='link' disabled title='compartir'>
                                            <BoxArrowUpRight size={18} />
                                        </Button>
                                    </h3>
                                    <p className='store-description'>
                                        {storeInfo.descripcionTienda}
                                        <Button variant='link' className='text-warning' onClick={openDescriptionModal} title='Editar descripcion'>
                                            <PencilFill size={18} />
                                        </Button>
                                    </p>
                                </div>
                                */}
                            </div>
                        </Col>

                    </Row>
                </div>

                <div className='mt-4'>
                    <h2 className='fw-bold text-black'>Enlaces de redes sociales</h2>
                    <p>Añade hasta 4 enlaces a redes sociales que se mostrarán en el perfil de tu tienda.</p>
                    {(socialLinks.length < 4 && editIndex === -1) && (
                        <InputGroup className='mb-3'>
                            <FormControl
                                placeholder='Título del enlace'
                                name='title'
                                value={newLink.title}
                                onChange={handleChange}
                            />
                            <FormControl
                                placeholder='URL del enlace'
                                name='url'
                                value={newLink.url}
                                onChange={handleChange}
                            />
                            <Button variant='primary' onClick={addLink}>
                                Añadir
                            </Button>
                        </InputGroup>
                    )}
                    <ListGroup>
                        {socialLinks.map((link, index) => (
                            <ListGroup.Item key={index} className='d-flex justify-content-between align-items-center'>
                                {editIndex === index ? (
                                    <>
                                        <InputGroup className='mb-3'>
                                            <FormControl
                                                placeholder='Título del enlace'
                                                name='title'
                                                value={newLink.title}
                                                onChange={handleChange}
                                            />
                                            <FormControl
                                                placeholder='URL del enlace'
                                                name='url'
                                                value={newLink.url}
                                                onChange={handleChange}
                                            />
                                            <Button variant='primary' onClick={saveLink}>
                                                Guardar
                                            </Button>
                                            <Button variant='secondary' onClick={cancelEdit}>
                                                Cancelar
                                            </Button>
                                        </InputGroup>
                                    </>
                                ) : (
                                    <div className='d-flex justify-content-between w-100'>
                                        <div>
                                            {getIcon(link.url)}
                                            <strong className='ms-2'>{link.title}</strong> <br />
                                            <a href={link.url} target='_blank' rel='noopener noreferrer'>
                                                {link.url}
                                            </a>
                                        </div>
                                        <ButtonGroup>
                                            <Button variant='link' onClick={() => editLink(index)} className='text-warning'>
                                                <PencilFill size={18} />
                                            </Button>
                                            <Button variant='link' onClick={() => deleteLink(link.id)} className='text-danger'>
                                                <TrashFill size={18} />
                                            </Button>
                                        </ButtonGroup>
                                    </div>
                                )}
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </div>

                <EnviosTienda />
                <DescuentosTienda />
            </Container>

            <ImageCropperModal
                show={showBannerModal || showProfileModal}
                onHide={() => {
                    setShowBannerModal(false);
                    setShowProfileModal(false);
                }}
                onSave={handleImageSave}
                onClose={() => {
                    setShowBannerModal(false);
                    setShowProfileModal(false);
                }}
                aspectRatio={cropType === 'banner' ? 1920 / 495 : 1}
                imageFile={newImage}
            />

            <Modal show={showDescriptionModal} onHide={() => setShowDescriptionModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Editar descripción de la tienda</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormControl
                        as='textarea'
                        rows={3}
                        value={newDescription}
                        onChange={handleDescriptionChange}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={() => setShowDescriptionModal(false)}>
                        Cancelar
                    </Button>
                    <Button variant='primary' onClick={handleDescriptionSave}>
                        Guardar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default TiendaConfig;
