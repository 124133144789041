// EditarPedido.js
import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import EditarClientePedido from './componentes/editarClientePedido';
import EditarProductoPedido from './componentes/editarProductosPedido';
import { useParams } from 'react-router-dom';
import APIserver from '../../../api/axiosConfig';

function EditarPedido() {
    const { idPedido } = useParams();
    const [pedido, setPedido] = useState(null);

    useEffect(() => {
        const fetchPedido = async () => {
            try {
                const response = await APIserver.get(`/api/verpedidos/detalles/${idPedido}`);
                setPedido(response.data);
            } catch (error) {
                console.error('Error fetching pedido', error);
            }
        };
        fetchPedido();
    }, [idPedido]);

    if (!pedido) {
        return <div>Cargando...</div>;
    }

    return (
        <div className='mx-2 mt-4 mt-md-0'>
            <div className='d-flex flex-column flex-md-row align-items-center justify-content-between'>
                <h4 className='mb-2 mb-md-0 text-center'>
                    <span className=' fw-bold text-black'>Editar Pedido No.</span> {pedido.numPedido}
                </h4>
                <div className='d-flex mt-2 mt-md-0'>
                    <Button variant='primary' className='me-2'>Guardar</Button>
                    <Button variant='secondary' className='me-2'>Cancelar</Button>
                </div>
            </div>
            {/* Editar informacion Cliente */}
            <div className='mt-4'>
                <EditarClientePedido cliente={pedido} />
            </div>
            {/* Editar productos */}
            <div className='mt-2'>
                <EditarProductoPedido productos={pedido.productos} envio={pedido.costoEnvio} />
            </div>
        </div>
    );
}

export default EditarPedido;