// src/componentes/ModalAuto.js
import React, { useEffect, useState } from 'react';
import { Modal, InputGroup, Form, Row, Col, Image } from 'react-bootstrap';
import '../styles/ModalAuto.css'; // Importar el archivo de estilos
import axios from 'axios';
import { API } from '../../../api/URL_API';
import publi from '../../../utils/publi.webp';

function ModalAuto() {
    const [show, setShow] = useState(false);

    const MODAL_CLOSE_TIME_KEY = 'modalCloseTime';
    const MODAL_DISPLAY_INTERVAL = 24 * 60 * 60 * 1000; // 1 día en milisegundos

    useEffect(() => {
        const lastCloseTime = localStorage.getItem(MODAL_CLOSE_TIME_KEY);
        const now = new Date().getTime();

        // Verifica si han pasado 2 minutos o si nunca se ha cerrado el modal
        if (!lastCloseTime || now - lastCloseTime > MODAL_DISPLAY_INTERVAL) {
            setShow(true);
        }
    }, []);

    const handleClose = () => {
        setShow(false);
        localStorage.setItem(MODAL_CLOSE_TIME_KEY, new Date().getTime());
    };
    const [formData, setFormData] = useState({ nameuser: '', email: '' });
    const [message, setMessage] = useState(null);
    const [loading, setLoading] = useState(false); // Estado para manejar el loading

    // Manejador de cambios en los campos del formulario
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // Función para manejar el envío del formulario
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); // Activar estado de carga

        try {
            const response = await axios.post(`${API}/api/marketing/subscribe`, formData);
            setMessage({ text: response.data.message, type: 'success' });
            setFormData({ nameuser: '', email: '' }); // Resetea el formulario
        } catch (error) {
            const errorMsg = error.response?.data?.error || 'Hubo un error al suscribirse. Inténtalo de nuevo.';
            setMessage({ text: errorMsg, type: 'error' });
        } finally {
            setLoading(false); // Desactivar estado de carga
        }
    };

    return (
        <Modal size='xl' show={show} onHide={handleClose} centered className="modal-custom">
            <Modal.Body className="modal-body-custom gradient-background">
            <Modal.Header closeButton></Modal.Header>
                <Row className="align-items-center">
                    <Col xs={12} md={6} className="text-center">
                        <Modal.Title className='text1-cr fst-italic fw-bold'>SUSCRIBETE</Modal.Title>
                        <p className='text1-cr'>Suscribete y obten increibles descuentos en tus compras.</p>
                        {message && (
                            <div className={`alert ${message.type === 'success' ? 'alert-success' : 'alert-danger'}`} role="alert">
                                {message.text}
                            </div>
                        )}
                        <Form onSubmit={handleSubmit}>
                            <InputGroup className="justify-content-center">
                                <Form.Control
                                    type="text"
                                    name="nameuser"
                                    placeholder="Ingresa tu nombre"
                                    aria-label="Ingresa tu nombre"
                                    value={formData.nameuser}
                                    onChange={handleChange}
                                    required
                                />
                            </InputGroup>
                            <InputGroup className="justify-content-center mt-3">
                                <Form.Control
                                    type="email"
                                    name="email"
                                    placeholder="Ingresa tu email aquí"
                                    aria-label="Ingresa tu email aquí"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                />
                            </InputGroup>
                            <button type="submit" className="button-suscribe mt-3 w-100 text-center" disabled={loading}>
                                {loading ? 'Cargando...' : 'Suscríbete'}
                            </button>
                        </Form>
                    </Col>
                    <Col xs={12} md={6} className="d-flex justify-content-center align-items-center mt-4 mt-md-0">
                        <div className="image-wrapper">
                            <img src={publi} className="img-fluid" alt="Publicidad" />
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
}

export default ModalAuto;
