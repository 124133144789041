import React from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import CustomToggle from '../styles/customToggle';
import { Download, NutFill } from 'react-bootstrap-icons';
import TablaMarketing from './componentes/tablaMarketing';
import axios from 'axios';
import APIserver from '../../../api/axiosConfig';

function AdminMarketing() {
    const handleExport = async () => {
        try {
            const response = await APIserver.get(`/api/marketingMostrar/export`, {
                responseType: 'blob', // Indica que esperas un archivo
            });

            // Crear un enlace para descargar el archivo
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'marketing_data.xlsx'); // Nombre del archivo
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error('Error exporting data:', error);
        }
    };

    return (
        <div className='mx-2 mt-3'>
            <div className='d-flex flex-column flex-md-row align-items-center justify-content-between'>
                <p className='mb-2 mb-md-0'>
                    Aquí encontrarás la lista de correos electrónicos suscritos desde tu tienda.
                </p>
                <div className='d-flex mt-2 mt-md-0'>
                    <Button variant='primary'>
                        Botón
                    </Button>
                    <Dropdown>
                        <Dropdown.Toggle as={CustomToggle} variant='secondary' className='ms-2'>
                            <NutFill />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={handleExport}>
                                <Download className='text-success mx-1' /> Exportar
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </div>

            <div className='my-4'>
                <TablaMarketing />
            </div>
        </div>
    );
}

export default AdminMarketing;
