import React, { useState, useRef } from 'react';
import { Container, Form, Row, Col, Button } from 'react-bootstrap';
import Select from 'react-select';
import { Pencil } from 'react-bootstrap-icons'; // Importa el ícono Pencil desde react-bootstrap-icons
import Departamentos from '../../../utils/places-colombia';
import axios from 'axios';
import Swal from 'sweetalert2';
import ImageCropperModal from './ImageCropperModal';
import { API } from '../../../api/URL_API';

function InfoUsuario({ token, onNext }) {
    const [selectedDepartamento, setSelectedDepartamento] = useState(null);
    const [selectedCiudad, setSelectedCiudad] = useState(null);
    const [errors, setErrors] = useState({});
    const [showCropperModal, setShowCropperModal] = useState(false);
    const [tempImageFile, setTempImageFile] = useState(null);
    const [croppedImageUrl, setCroppedImageUrl] = useState(null);
    const fileInputRef = useRef(null);
    const [formData, setFormData] = useState({
        userNit: '',
        userName: '',
        lastName: '',
        userEmail: '',
        userPhone: '',
        userPassword: '',
        userDep: '',
        userCity: '',
        userAdress: '',
        userImgProfile: null
    });

    const [cropperError, setCropperError] = useState('');
    const [ciudadesOptions, setCiudadesOptions] = useState([]);
    const departamentosOptions = Departamentos.map(departamento => ({ value: departamento.departamento, label: departamento.departamento }));

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
        const { files } = e.target;
        if (files && files[0]) {
            setTempImageFile(files[0]);
            setShowCropperModal(true);
        }
    };
    const handleDepartamentoChange = (selectedOption) => {
        setSelectedDepartamento(selectedOption);
        setFormData({ ...formData, userDep: selectedOption.value });
        const departamento = Departamentos.find(dep => dep.departamento === selectedOption.value);
        const ciudades = departamento ? departamento.ciudades.map(ciudad => ({ value: ciudad, label: ciudad })) : [];
        setCiudadesOptions(ciudades);
        setSelectedCiudad(null);
    };

    const handleCiudadChange = (selectedOption) => {
        setSelectedCiudad(selectedOption);
        setFormData({ ...formData, userCity: selectedOption.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (cropperError) {
            return;
        }

        try {
            const dataToSave = new FormData();
            Object.keys(formData).forEach(key => {
                dataToSave.append(key, formData[key]);
            });

            const response = await axios.post(`${API}/api/inquilino`, dataToSave, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            Swal.fire({
                icon: 'success',
                title: 'Registro Exitoso',
                text: 'El usuario se ha registrado correctamente',
                confirmButtonText: 'Continuar'
            });

            localStorage.setItem('userFormData', JSON.stringify(response.data));
            onNext();
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                setErrors({ userEmail: 'Este correo ya está registrado, por favor cambielo.' });
            } else {
                console.error('Error al enviar el formulario', error);
            }
        }
    };

    const handleCropComplete = (croppedImageFile) => {
        if (croppedImageFile) {
            setFormData({ ...formData, userImgProfile: croppedImageFile });
            const objectUrl = URL.createObjectURL(croppedImageFile);
            setCroppedImageUrl(objectUrl);
            setCropperError('');
        }
        setShowCropperModal(false);
    };

    const handleCropCancel = (action) => {
        if (action === 'cancel') {
            setCropperError('El usuario no ha seleccionado una imagen.');
        }
        setShowCropperModal(false);
    };

    return (
        <Container className='mt-4'>
            <h2>Registro de Usuario</h2>
            <Form onSubmit={handleSubmit}>
                <Row>
                    <Col>
                        <Form.Group controlId='userNit' className='m-3'>
                            <Form.Label>No. Identificación</Form.Label>
                            <Form.Control
                                type='text'
                                name='userNit'
                                value={formData.userNit}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId='lastName' className='m-3'>
                            <Form.Label>Apellidos</Form.Label>
                            <Form.Control
                                type='text'
                                name='lastName'
                                value={formData.lastName}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId='userPhone' className='m-3'>
                            <Form.Label>Teléfono</Form.Label>
                            <Form.Control
                                type='number'
                                name='userPhone'
                                value={formData.userPhone}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId='userDep' className='m-3'>
                            <Form.Label>Departamento</Form.Label>
                            <Select
                                value={selectedDepartamento}
                                onChange={handleDepartamentoChange}
                                options={departamentosOptions}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId='userAdress' className='m-3'>
                            <Form.Label>Dirección</Form.Label>
                            <Form.Control
                                type='text'
                                name='userAdress'
                                value={formData.userAdress}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId='userName' className='m-3'>
                            <Form.Label>Nombres</Form.Label>
                            <Form.Control
                                type='text'
                                name='userName'
                                value={formData.userName}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId='userEmail' className='m-3'>
                            <Form.Label>Correo Electrónico</Form.Label>
                            <Form.Control
                                type='email'
                                name='userEmail'
                                value={formData.userEmail}
                                onChange={handleChange}
                                required
                            />
                            {errors.userEmail && <p className='text-danger'>{errors.userEmail}</p>}
                        </Form.Group>
                        <Form.Group controlId='userPassword' className='m-3'>
                            <Form.Label>Contraseña</Form.Label>
                            <Form.Control
                                type='password'
                                name='userPassword'
                                value={formData.userPassword}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId='userCity' className='m-3'>
                            <Form.Label>Ciudad</Form.Label>
                            <Select
                                value={selectedCiudad}
                                onChange={handleCiudadChange}
                                options={ciudadesOptions}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId='userImgProfile' className='m-3'>
                            <Form.Label style={{ textAlign: 'center', display: 'block' }}>Imagen de Perfil</Form.Label>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleFileChange}
                                ref={fileInputRef}
                                style={{
                                    display: 'none', // Oculta el input de archivo
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    width: '100%',
                                    height: '100%',
                                    cursor: 'pointer',
                                }}
                            />
                            <div
                                className="position-relative"
                                style={{
                                    width: '150px',
                                    height: '150px',
                                    border: croppedImageUrl ? 'none' : '2px dashed #007bff', // Borde azul del recuadro de previsualización
                                    borderRadius: '5px',
                                    cursor: 'pointer',
                                    backgroundColor: croppedImageUrl ? 'transparent' : '#f8f9fa',
                                    overflow: 'hidden',
                                    margin: '0 auto',
                                    position: 'relative', // Para que el input se posicione correctamente
                                }}
                                onClick={() => fileInputRef.current.click()}
                            >
                                {croppedImageUrl ? (
                                    <>
                                        <img
                                            src={croppedImageUrl}
                                            alt="Cropped"
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover',
                                            }}
                                        />
                                        <button
                                            type="button"
                                            className="btn position-absolute top-50 start-50 translate-middle p-2"
                                            style={{
                                                borderRadius: '50%',
                                                backgroundColor: 'white', // Fondo blanco para el botón circular
                                                border: 'none', // Sin borde
                                                cursor: 'pointer',
                                                width: '40px', // Tamaño del botón
                                                height: '40px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <Pencil color="black" size={20} />
                                        </button>
                                    </>
                                ) : (
                                    <span
                                        style={{
                                            fontSize: '24px',
                                            color: '#007bff', // Color del símbolo +
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                            borderRadius: '50%',
                                            width: '40px', // Tamaño del botón
                                            height: '40px',
                                            lineHeight: '40px',
                                            textAlign: 'center',
                                            backgroundColor: 'white',
                                            border: '2px solid #007bff', // Borde azul del círculo
                                            cursor: 'pointer',
                                            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)', // Sombra para el círculo
                                            transition: 'none', // Elimina la transición para evitar cambios de color no deseados
                                        }}
                                        onMouseEnter={(e) => {
                                            e.currentTarget.style.backgroundColor = '#007bff'; // Color de fondo al pasar el ratón
                                            e.currentTarget.style.color = 'white'; // Cambia el color del texto
                                            e.currentTarget.style.borderColor = '#007bff'; // Cambia el color del borde
                                        }}
                                        onMouseLeave={(e) => {
                                            e.currentTarget.style.backgroundColor = 'white'; // Color de fondo original
                                            e.currentTarget.style.color = '#007bff'; // Color del texto original
                                            e.currentTarget.style.borderColor = '#007bff'; // Color del borde original
                                        }}
                                    >
                                        +
                                    </span>
                                )}
                            </div>
                            {cropperError && <p className='text-danger'>{cropperError}</p>}
                        </Form.Group>
                    </Col>
                </Row>
                <Button variant='primary' type='submit'>
                    Registrar
                </Button>
            </Form>
            <ImageCropperModal
                show={showCropperModal}
                imageFile={tempImageFile}
                onSave={handleCropComplete}
                onClose={handleCropCancel}
            />
        </Container>
    );
}


export default InfoUsuario;
