import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col, Alert } from 'react-bootstrap';
import Select from 'react-select';
import AlertsCompras from '../Utils/AlertsCompras'; // Importamos AlertsCompras desde el archivo correspondiente
import APIserver from '../../../../api/axiosConfig';

function EditarCompra({ show, handleClose, compraId }) {
    const [compra, setCompra] = useState({
        idCompra: '',
        numCompra: '',
        idProveedor: '',
        nombreProveedor: '',
        creacion: '',
        vence: '',
        totalCompra: ''
    });

    const [proveedores, setProveedores] = useState([]);
    const [selectedProveedor, setSelectedProveedor] = useState(null);
    const [errors, setErrors] = useState({}); // Estado para almacenar errores

    useEffect(() => {
        if (show && compraId) {
            fetchCompra(compraId);
        }
        fetchProveedores();
    }, [show, compraId]);

    const fetchCompra = async (id) => {
        try {
            const response = await APIserver.get(`/api/compras/${id}`);
            if (response.data.compra) {
                const { idCompra, numCompra, idProveedor, nombreProveedor, creacion, vence, totalCompra } = response.data.compra;
                setCompra({
                    idCompra,
                    numCompra,
                    idProveedor,
                    nombreProveedor,
                    creacion: creacion.split('T')[0],
                    vence: vence.split('T')[0],
                    totalCompra
                });
                setSelectedProveedor({
                    value: idProveedor,
                    label: nombreProveedor
                });
            }
        } catch (error) {
            console.error('Error al obtener detalles de la compra:', error);
        }
    };

    const fetchProveedores = async () => {
        try {
            const response = await APIserver.get(`/api/proveedores`);
            if (response.data.proveedores) {
                const options = response.data.proveedores.map(proveedor => ({
                    value: proveedor.idProveedor,
                    label: proveedor.nomProveedor
                }));
                setProveedores(options);
            }
        } catch (error) {
            console.error('Error al obtener los proveedores:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCompra({
            ...compra,
            [name]: value
        });
    };

    const handleProveedorChange = (selectedOption) => {
        setSelectedProveedor(selectedOption);
        setCompra(prevCompra => ({
            ...prevCompra,
            idProveedor: selectedOption ? selectedOption.value : '',
            nombreProveedor: selectedOption ? selectedOption.label : ''
        }));
    };

    // Función para validar los campos del formulario
    const validateForm = () => {
        const newErrors = {};

        if (!compra.numCompra) newErrors.numCompra = 'El número de compra es obligatorio.';
        if (!compra.creacion) newErrors.creacion = 'La fecha de creación es obligatoria.';
        if (!compra.vence) newErrors.vence = 'La fecha de vencimiento es obligatoria.';
        if (!compra.totalCompra || compra.totalCompra <= 0) newErrors.totalCompra = 'El monto debe ser mayor que 0.';
        if (!selectedProveedor) newErrors.idProveedor = 'Debe seleccionar un proveedor.';

        setErrors(newErrors);

        // Retorna true si no hay errores
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            try {
                await APIserver.put(`/api/compras/${compra.idCompra}`, compra);
                handleClose();
                AlertsCompras.showSuccessMessage('¡Compra actualizada exitosamente!');
            } catch (error) {
                AlertsCompras.showErrorMessage('Error al guardar la compra. Por favor, intenta nuevamente.');
            }
        }
    };

    return (
        <Modal size='lg' show={show} onHide={handleClose} aria-labelledby="modal-editar-compra">
            <Modal.Header closeButton>
                <Modal.Title>Editar Compra</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col>
                            <Form.Group className='m-3 mt-0' controlId='numCompra'>
                                <Form.Label>Numero Compra</Form.Label>
                                <Form.Control 
                                    type='text' 
                                    name='numCompra' 
                                    value={compra.numCompra} 
                                    onChange={handleInputChange} 
                                    placeholder='Numero de Factura' 
                                    isInvalid={!!errors.numCompra} 
                                />
                                <Form.Control.Feedback type='invalid'>
                                    {errors.numCompra}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className='m-3' controlId='creacionCompra'>
                                <Form.Label>Elaboracion</Form.Label>
                                <Form.Control 
                                    type='date' 
                                    name='creacion' 
                                    value={compra.creacion} 
                                    onChange={handleInputChange} 
                                    isInvalid={!!errors.creacion} 
                                />
                                <Form.Control.Feedback type='invalid'>
                                    {errors.creacion}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className='m-3 mt-0' controlId='idProveedor'>
                                <Form.Label>Proveedor</Form.Label>
                                <Select
                                    options={proveedores}
                                    value={selectedProveedor}
                                    onChange={handleProveedorChange}
                                    className={errors.idProveedor ? 'is-invalid' : ''} // Agregamos clase para mostrar error
                                />
                                {errors.idProveedor && (
                                    <div className="invalid-feedback d-block">
                                        {errors.idProveedor}
                                    </div>
                                )}
                            </Form.Group>
                            <Form.Group className='m-3' controlId='vencimiento'>
                                <Form.Label>Vence</Form.Label>
                                <Form.Control 
                                    type='date' 
                                    name='vence' 
                                    value={compra.vence} 
                                    onChange={handleInputChange} 
                                    isInvalid={!!errors.vence} 
                                />
                                <Form.Control.Feedback type='invalid'>
                                    {errors.vence}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Form.Group className='m-3 mt-0' controlId='totalCompra'>
                        <Form.Label>Total</Form.Label>
                        <Form.Control 
                            type='number' 
                            name='totalCompra' 
                            value={compra.totalCompra} 
                            onChange={handleInputChange} 
                            placeholder='Valor total de la compra' 
                            isInvalid={!!errors.totalCompra} 
                        />
                        <Form.Control.Feedback type='invalid'>
                            {errors.totalCompra}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Modal.Footer>
                        <Button variant='secondary' onClick={handleClose}>
                            Cancelar
                        </Button>
                        <Button variant='primary' type='submit'>
                            Guardar
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal.Body>
        </Modal>
    );
}

export default EditarCompra;
