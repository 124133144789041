// src/hooks/useCompanyInfo.js

import { useState, useEffect } from 'react';
import { fetchCompanyInfo, updateCompanyInfo, updateCompanyLogo  } from '../api/companyConfigAPI';

export const useCompanyInfo = () => {
    const [companyData, setCompanyData] = useState({
        idEmpresa: null,
        nombreEmpresa: '',
        nit: '',
        direccion: '',
        email: '',
        ciudad: '',
        departamento: '',
        telefono: '',
        logoEmpresa: '',
    });
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const loadCompanyData = async () => {
            try {
                const data = await fetchCompanyInfo();
                if (data.length > 0) {
                    const companyInfo = data[0];
                    setCompanyData({
                        idEmpresa: companyInfo.idEmpresa || null,
                        nombreEmpresa: companyInfo.nombreEmpresa || '',
                        nit: companyInfo.nit || '',
                        direccion: companyInfo.direccion || '',
                        email: companyInfo.email || '',
                        ciudad: companyInfo.ciudad || '',
                        departamento: companyInfo.departamento || '',
                        telefono: companyInfo.telefono || '',
                        logoEmpresa: companyInfo.logoEmpresa || '',
                    });
                }
                setIsLoading(false);
            } catch (err) {
                console.error('Error al cargar la información de la empresa:', err);
                setError('No se pudo cargar la información de la empresa.');
                setIsLoading(false);
            }
        };
        loadCompanyData();
    }, []);

    const handleUpdateCompanyInfo = async (updatedData) => {
        try {
            const response = await updateCompanyInfo(updatedData);

            // Verifica si la respuesta tiene el formato esperado
            if (response && response.data && response.data.message === 'Información actualizada correctamente') {
                setCompanyData(updatedData);
                return response.data;
            } else {
                setError('No se pudo actualizar la información de la empresa.');
                throw new Error(response?.data?.error || 'Error desconocido en la actualización');
            }
        } catch (err) {
            console.error('Error al actualizar la información de la empresa:', err);
            setError('No se pudo actualizar la información de la empresa.');
            throw err;
        }
    };

    const updateLogoImage = async (logoFile) => {
        try {
            const response = await updateCompanyLogo(logoFile, companyData.logoEmpresa);
            return response.imageUrl; // Devolvemos la URL de la imagen actualizada
        } catch (err) {
            console.error('Error al actualizar el logo de la empresa:', err);
            throw err;
        }
    };

    return {
        companyData,
        isLoading,
        error,
        setCompanyData,  
        handleUpdateCompanyInfo,
        updateLogoImage, // Añade la función aquí
    };
};