import React, { useState, useEffect } from 'react';
import { Card, Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import Productos from './ProductosFacturarPedido';
import ClienteFactura from './ClienteFacturaPedido';
import axios from 'axios';
import AlertsFacturas from './Utils/AlertsFacturasPedidos';
import APIserver from '../../../../api/axiosConfig';

function CrearFactura() {
  const [idCliente, setIdCliente] = useState('');
  const [clienteID, setClienteIDs] = useState('');
  const [currentDate, setCurrentDate] = useState('');
  const [venceDate, setVenceDate] = useState('');
  const [productos, setProductos] = useState([]);
  const [tipoFactura, setTipoFactura] = useState('');
  const [asesor, setAsesor] = useState('');
  const [envio, setEnvio] = useState(0);

  const [subtotal, setSubtotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [cantItem, setCantItem] = useState(0);
  const navigate = useNavigate();
  const { idPedido } = useParams(); // Obtener el idPedido de los parámetros de la URL
  const [costoEnvio, setCostoEnvio] = useState(0); // Valor predeterminado en 0
  const [descuento, setDescuento] = useState(0);


  useEffect(() => {
    const fetchPedido = async () => {
      if (idPedido) {
        try {
          const response = await APIserver.get(`/api/facturarPedidos/pedidos/${idPedido}`);
          const pedidoData = response.data;

          if (pedidoData) {
            setIdCliente(pedidoData.idCliente || '');
            setClienteIDs(pedidoData.clienteID || '');
            setCurrentDate(pedidoData.creacion || '');
            setVenceDate(pedidoData.vence || '');
            setTotal(pedidoData.totalPedido || 0);
            setCantItem(pedidoData.totalProductos || 0);
            setCostoEnvio(pedidoData.costoEnvio || 0); // Actualiza el estado costoEnvio aquí
            setDescuento(pedidoData.descuento || 0);

            if (pedidoData.detalles && Array.isArray(pedidoData.detalles)) {
              setProductos(pedidoData.detalles.map(d => ({
                id: d.idProducto,
                referencia: d.referencia || '',
                cantidad: d.cantidad || 1
              })));
            } else {
              console.error('Detalles del pedido no están disponibles o no son un arreglo');
            }
          } else {
            console.error('Datos del pedido no están disponibles');
          }
        } catch (error) {
          AlertsFacturas.showErrorMessage('Error al obtener los detalles del pedido');
        }
      }
    };
    console.log("datos recibidos: ".pedidoData);
    fetchPedido();
  }, [idPedido]);

  const validateFacturaData = () => {
    if (!idCliente || !currentDate || !venceDate || !tipoFactura || !asesor || productos.length === 0) {
      AlertsFacturas.showErrorMessage('Por favor complete todos los campos obligatorios.');
      return false;
    }
    return true;
  };

  const handleCreateFactura = async () => {
    if (!validateFacturaData()) return;

    // Mapea los productos para asegurarte de que cada producto tenga la propiedad 'idProducto'
    const productosConIdProducto = productos.map(producto => ({
      ...producto,
      idProducto: producto.idProducto || producto.id  // Usa idProducto si está disponible, de lo contrario usa id
    }));

    const facturaData = {
      idCliente: idCliente,
      creacion: currentDate,
      vence: venceDate,
      totalFacV: total,
      subtotal,
      cantItem: cantItem,
      cosEnvio: envio, // Aquí es donde cambiamos el nombre del campo
      descuento: descuento,
      tipoFactura,
      asesor,
      productos: productosConIdProducto // Usa la versión mapeada con idProducto
    };
    console.log("pedidos a enviar: ", facturaData);
    try {
      // Crear la factura
      await APIserver.post(`/api/facturas`, facturaData);

      // Obtener la fecha y hora actuales
      const fechaEstado = new Date().toISOString().slice(0, 19).replace('T', ' ');

      // Actualizar el estado del pedido a "facturado" y registrar la fecha de facturación
      await APIserver.put(`/api/facturarPedidos/estado/${idPedido}`, {
        estado: 'facturado',
        fechaEstado
      });

      AlertsFacturas.showSuccessMessage('Factura creada con éxito');
      navigate('/admin/IngresosFacturas/');
    } catch (error) {
      // Verificar si el error tiene un mensaje relacionado con stock insuficiente
      if (error.response && error.response.data && error.response.data.error) {
        const errorMessage = error.response.data.error;

        // Verifica si el mensaje de error está relacionado con el stock insuficiente
        if (errorMessage.includes('Stock insuficiente')) {
          AlertsFacturas.showErrorMessage('Error al crear la factura, un producto no tiene stock suficiente.');
        } else {
          // Muestra un mensaje genérico de error si no es un problema de stock
          AlertsFacturas.showErrorMessage('Error al crear la factura');
        }
      } else {
        // Error genérico en caso de que no haya un mensaje de error específico en la respuesta
        AlertsFacturas.showErrorMessage('Error al crear la factura');
      }
    }
  };

  const handleCancel = () => {
    if (
      idCliente ||
      currentDate ||
      venceDate ||
      productos.length > 0 ||
      tipoFactura ||
      asesor ||
      envio ||
      descuento ||
      subtotal ||
      total ||
      cantItem
    ) {
      AlertsFacturas.showCancelConfirmation(() => {
        navigate('/admin/Pedidos/');
      });
    } else {
      navigate('/admin/Pedidos/');
    }
  };

  return (
    <div className='mx-2 mt-3 mt-md-0'>
      <div className='m-2 text-center'>
        <Button variant='primary' className='me-2' onClick={handleCreateFactura}>Facturar Pedido</Button>
        <Button variant='secondary' className='me-2' onClick={handleCancel}>Cancelar</Button>
      </div>
      <Card className='shadow-none'>
        <Card.Header className='fw-bold'>
          Detalles de cliente
        </Card.Header>
        <Card.Body>
          <ClienteFactura
            setClienteID={setIdCliente}
            setVenceDate={setVenceDate}
            setTipoFactura={setTipoFactura}
            setAsesor={setAsesor}
            setCreacionDate={setCurrentDate}
            pedidoId={idPedido} // Pasar idPedido para cargar datos si es necesario
          />
        </Card.Body>
      </Card>
      <Productos
        setProductos={setProductos}
        setSubtotal={setSubtotal}
        setTotal={setTotal}
        setCantItem={setCantItem}
        setEnvio={setEnvio}
        setDescuento={setDescuento}
        tipoFactura={tipoFactura}
        productos={productos} // Pasar los productos con referencia y cantidad
        costoEnvio={costoEnvio} // Asegúrate de que esto no sea undefined
        descuento={descuento}

      />
    </div>
  );
}

export default CrearFactura;
