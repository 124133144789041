import React, { useState } from 'react';
import { Card, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Productos from './componentes/productosFactura';
import ClienteFactura from './componentes/clienteFactura';
import AlertsFacturas from './Utils/AlertsFacturas';
import APIserver from '../../../api/axiosConfig';

function CrearFactura() {
  const [idCliente, setIdCliente] = useState('');
  const [currentDate, setCurrentDate] = useState('');
  const [venceDate, setVenceDate] = useState('');
  const [productos, setProductos] = useState([]);
  const [tipoFactura, setTipoFactura] = useState('unidad');
  const [asesor, setAsesor] = useState('');
  const [envio, setEnvio] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [cantItem, setCantItem] = useState(0);
  const [descuento, setDescuento] = useState(0);  // Agregar esto al estado del componente padre

  const navigate = useNavigate();

  const handleTipoFacturaChange = (e) => {
    setTipoFactura(e.target.value);
  };

  const validateFacturaData = () => {
    
    // Verifica que haya productos válidos (con referencia y cantidad > 0)
    const productosValidos = productos.filter(
      (producto) => producto.referencia && producto.cantidad > 0
    );

    if (!idCliente || !currentDate || !venceDate || !tipoFactura || !asesor || productosValidos.length === 0) {
      AlertsFacturas.showErrorMessage('Por favor complete todos los campos obligatorios, incluyendo al menos un producto válido.');
      return false;
    }
    return true;
  };

  const handleCreateFactura = async () => {
    if (!validateFacturaData()) return;

    const facturaData = {
      idCliente: idCliente,
      creacion: currentDate,
      vence: venceDate,
      totalFacV: total,
      subtotal,
      cantItem,
      cosEnvio: envio,
      descuento,
      tipoFactura,
      asesor,
      productos
    };


    try {
      await APIserver.post(`/api/facturas`, facturaData);
      AlertsFacturas.showSuccessMessage('Factura creada con éxito');
    } catch (error) {
      console.error('Error al crear la factura:', error);
      AlertsFacturas.showErrorMessage('Error al crear la factura');
    }
  };

  const handleCancel = () => {
    if (
      idCliente ||
      currentDate ||
      venceDate ||
      productos.length > 0 ||
      tipoFactura ||
      asesor ||
      envio ||
      subtotal ||
      total ||
      cantItem
    ) {
      AlertsFacturas.showCancelConfirmation(() => {
        navigate('/admin/IngresosFacturas/');
      });
    } else {
      navigate('/admin/IngresosFacturas/');
    }
  };

  return (
    <div className='mx-2 mt-3 mt-md-0'>
      <div className='m-2 text-center'>
        <Button variant='primary' className='me-2' onClick={handleCreateFactura}>Crear Factura</Button>
        <Button variant='secondary' className='me-2' onClick={handleCancel}>Cancelar</Button>
      </div>
      <Card className='shadow-none'>
        <Card.Header className='fw-bold'>
          Detalles de cliente
        </Card.Header>
        <Card.Body>
          <ClienteFactura
            setClienteID={setIdCliente}
            setVenceDate={setVenceDate}
            setTipoFactura={setTipoFactura}
            setAsesor={setAsesor}
            setCreacionDate={setCurrentDate}
          />
        </Card.Body>
      </Card>
      <Productos
        setProductos={setProductos}
        setSubtotal={setSubtotal}
        setTotal={setTotal}
        setCantItem={setCantItem}
        setEnvio={setEnvio}
        setDescuento={setDescuento}
        tipoFactura={tipoFactura}
      />
    </div>
  );
}

export default CrearFactura;