import Swal from 'sweetalert2';
import APIserver from '../../../../api/axiosConfig';

export const handleSubmit = async (formData, handleClose, fetchProductos) => {
  try {
    // Enviar la solicitud POST con los datos del formulario y las imágenes
    const response = await APIserver.post(`/api/productos`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (response.status === 200) {
      Swal.fire({
        title: 'Producto Creado',
        text: 'El producto ha sido creado exitosamente',
        icon: 'success',
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        iconColor: '#2fb380',
        confirmButtonColor: "#2c4cc4"
      }).then(() => {
        handleClose();
        if (fetchProductos) {
          fetchProductos();
        }
      });
    } else {
      const errorData = await response.data;
      await Swal.fire({
        title: 'Error',
        text: 'Error al crear el producto',
        icon: 'error',
        iconColor: '#da292e',
        confirmButtonColor: "#da292e"
      });
    }
  } catch (error) {
    console.error('Error al enviar el formulario:', error);
    await Swal.fire({
      title: 'Error',
      text: 'Ocurrió un error al crear el producto',
      icon: 'error',
      iconColor: '#da292e',
      confirmButtonColor: "#da292e"
    });
  }
};

export const handleDelete = async (productId, fetchProductos) => {
  const result = await Swal.fire({
    title: '¿Estás seguro?',
    text: 'Esta acción no se puede deshacer',
    icon: 'warning',
    showCancelButton: true,
    iconColor: '#f4bd61',
    confirmButtonColor: '#2c4cc4',
    cancelButtonColor: '#da292e',
    confirmButtonText: 'Sí, eliminar',
    cancelButtonText: 'Cancelar'
  });

  if (result.isConfirmed) {
    try {
      await deleteProducto(productId); // Intentar eliminar el producto
      await Swal.fire({
        title: '¡Eliminado!',
        text: 'El producto ha sido eliminado correctamente',
        icon: 'success',
        iconColor: '#2fb380',
        confirmButtonColor: "#2c4cc4"
      });
      if (fetchProductos) {
        fetchProductos(); // Actualizar la lista de productos después de eliminar
      }
    } catch (error) {
      console.error('Error al eliminar el producto:', error);

      // Verificar si el error proviene de la respuesta del servidor y contiene un mensaje específico
      if (error.response && error.response.status === 400 && error.response.data.error) {
        // Mostrar la alerta específica para productos asociados con facturas
        await Swal.fire({
          title: 'Error',
          text: error.response.data.error,
          icon: 'error',
          confirmButtonText: 'Aceptar',
          iconColor: '#da292e',
          confirmButtonColor: "#da292e"
        });
      } else {
        await Swal.fire({
          title: 'Error',
          text: 'Ocurrió un error al eliminar el producto',
          icon: 'error',
          confirmButtonText: 'Aceptar',
          iconColor: '#2fb380',
          confirmButtonColor: "#2c4cc4"
        });
      }
    }
  }
};

const deleteProducto = async (productId) => {
  try {
    await APIserver.delete(`/api/productos/${productId}`);
  } catch (error) {
    // Propagar el error para que sea manejado en handleDelete
    throw error;
  }
};

export const handleUpdate = async (productId, updatedData, handleClose, fetchProductos) => {
  try {
    const response = await APIserver.put(`/api/productos/${productId}`, updatedData);

    if (response.status === 200) {
      Swal.fire({
        title: 'Producto Actualizado',
        text: 'El producto ha sido actualizado exitosamente',
        icon: 'success',
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        iconColor: '#2fb380',
        confirmButtonColor: "#2c4cc4"
      }).then(() => {
        handleClose(); // Cierra el modal después de que el alerta desaparezca
        if (fetchProductos) {
          fetchProductos(); // Llama a fetchProductos si está definida
        }
        // Recargar la página después de mostrar el alerta
        //window.location.reload();
      });
    } else {
      const errorData = await response.data;
      await Swal.fire({
        title: 'Error',
        text: 'Error al actualizar el producto',
        icon: 'error',
        iconColor: '#da292e',
        confirmButtonColor: "#da292e"
      });
    }
  } catch (error) {
    console.error('Error al enviar el formulario:', error);
    await Swal.fire({
      title: 'Error',
      text: 'Ocurrió un error al actualizar el producto',
      icon: 'error',
      iconColor: '#da292e',
      confirmButtonColor: "#da292e"
    });
  }
};