import React from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { showAlertSuccess, showAlertError } from '../Utils/AlertsBancos'; // Asegúrate de que la ruta sea correcta
import APIserver from '../../../../api/axiosConfig';

const CrearBancoModal = ({ show, handleClose }) => {
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const bancoData = {
      nomBanco: formData.get('nomBanco'),
      tipoCuenta: formData.get('tipoBanco'),
      numeroCuenta: formData.get('numBanco'),
      saldoBanco: parseFloat(formData.get('saldoBanco')) || 0
    };

    try {
      const response = await APIserver.post(`/api/bancos`, bancoData);

      if (response.status === 201) {
        showAlertSuccess('Banco guardado correctamente');
        handleClose();
      } else {
        throw new Error('Error al guardar el banco');
      }
    } catch (error) {
      console.error('Error al crear el banco:', error);
      showAlertError('Error al procesar la solicitud del banco');
    }
  };

  return (
    <Modal show={show} onHide={handleClose} aria-labelledby='create-banco-modal'>
      <Modal.Header closeButton>
        <Modal.Title>Crear Nuevo Banco</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleFormSubmit}>
          <Form.Group className='m-3 mt-0' controlId='nomBanco'>
            <Form.Label>Nombre</Form.Label>
            <Form.Control type='text' name='nomBanco' placeholder='Nombre del Banco' required />
          </Form.Group>
          <Form.Group className='m-3' controlId='tipoBanco'>
            <Form.Label>Tipo de Cuenta</Form.Label>
            <Form.Control type='text' name='tipoBanco' placeholder='ejemplo: Nequi, Bancolombia, Davivienda etc.' required />
          </Form.Group>
          <Form.Group className='m-3' controlId='numBanco'>
            <Form.Label>Numero</Form.Label>
            <Form.Control type='number' name='numBanco' placeholder='Numero de la cuenta' required />
          </Form.Group>
          <Form.Group className='m-3' controlId='saldoBanco'>
            <Form.Label>Saldo</Form.Label>
            <Form.Control type='number' step='0.01' name='saldoBanco' placeholder='Saldo inicial' defaultValue={0} />
          </Form.Group>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleClose}>Cancelar</Button>
            <Button variant='primary' type='submit'>Guardar</Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CrearBancoModal;
