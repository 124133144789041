import React from 'react';
import { Row, Col, Table } from 'react-bootstrap';
import { format } from 'date-fns'; // Importar la función para formatear fechas

function InfoProveedorPagoHe({ pagoData }) {
    // Formatear la fecha con date-fns
    const fechaFormateada = pagoData.fecha ? format(new Date(pagoData.fecha), 'dd/MM/yyyy') : 'Fecha no disponible';

    return (
        <Row className='print-flex'>
            <Col md={8} className='print-half'>
                <Table responsive bordered size="sm" className='shadow-none'>
                    <tbody>
                        <tr>
                            <th className='bg-primary-subtle'>Pagado a</th>
                            <td colSpan="3">{pagoData.nomProveedor}</td>
                        </tr>
                        <tr>
                            <th className='bg-primary-subtle'>NIT</th>
                            <td>{pagoData.proveedorID}</td>
                            <th className='bg-primary-subtle'>Teléfono</th>
                            <td>{pagoData.numProveedor}</td>
                        </tr>
                        <tr>
                            <th className='bg-primary-subtle'>Dirección</th>
                            <td>{pagoData.direccion}</td>
                            <th className='bg-primary-subtle'>Ciudad</th>
                            <td>{pagoData.ciudad}</td>
                        </tr>
                    </tbody>
                </Table>
            </Col>
            <Col className='print-half'>
                <Table bordered size="sm" className='shadow-none'>
                    <tbody>
                        <tr>
                            <th className='bg-primary-subtle'>Fecha pago</th>
                            <td>{fechaFormateada}</td>
                        </tr>
                        <tr>
                            <th className='bg-primary-subtle'>Forma de pago</th>
                            <td>{pagoData.tipoPago}</td>
                        </tr>
                    </tbody>
                </Table>
            </Col>
        </Row>
    );
}

export default InfoProveedorPagoHe;
