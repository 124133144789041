import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import ImageCropperModal from './ImageCropperModal'; // Importar el componente de recorte
import { handleSeccionSubmit, handleEditSeccion, handleCategoriaSubmit, handleCategoriaUpdate } from '../Utils/AlertsCategorias';
import { Pencil, Plus } from 'react-bootstrap-icons'; // Importar los iconos de lápiz y signo más

// Utilidad para convertir a WebP
const convertToWebP = (file, callback) => {
  const reader = new FileReader();

  reader.onload = function (event) {
    const img = new Image();
    img.onload = function () {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);

      canvas.toBlob(function (blob) {
        callback(new File([blob], 'image.webp', { type: 'image/webp' }));
      }, 'image/webp');
    };
    img.src = event.target.result;
  };

  reader.readAsDataURL(file);
};

const initialState = {
  nombreCategoria: '',
  idSeccion: null,
  imagenCategoria: null,
  isCropped: false,
  showCropper: false,
};

export const SeccionModal = ({ show, handleClose, refreshSecciones }) => {
  const [nombreSeccion, setNombreSeccion] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    await handleSeccionSubmit(nombreSeccion);
    refreshSecciones();
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Nueva Sección</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="nombreSeccion" className='m-3 mt-0'>
            <Form.Label>Nombre de la Sección</Form.Label>
            <Form.Control
              type="text"
              value={nombreSeccion}
              onChange={(e) => setNombreSeccion(e.target.value)}
              placeholder="Ingrese el nombre aquí"
              required
            />
          </Form.Group>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>Cancelar</Button>
            <Button variant="primary" type="submit">Guardar</Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export const EditSeccionModal = ({ show, handleClose, seccion, refreshSecciones }) => {
  const [nombreSeccion, setNombreSeccion] = useState('');

  useEffect(() => {
    if (show) {
      setNombreSeccion(seccion.nomSeccion); // Cargar datos en el modal
    }
  }, [show, seccion]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await handleEditSeccion(seccion.idSeccion, nombreSeccion);
    refreshSecciones();
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Editar Sección</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="editNombreSeccion" className='m-3 mt-0'>
            <Form.Label>Nombre de la Sección</Form.Label>
            <Form.Control
              type="text"
              value={nombreSeccion}
              onChange={(e) => setNombreSeccion(e.target.value)}
              required
            />
          </Form.Group>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>Cancelar</Button>
            <Button variant="primary" type="submit">Guardar Cambios</Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export const CategoriaModal = ({ show, handleClose, secciones, refreshCategorias }) => {
  const [state, setState] = useState({
    ...initialState,
    imagenTemporal: null,  // Almacenar la nueva imagen temporalmente para el cropper
  });

  const [imagenError, setImagenError] = useState(false);  // Estado para mostrar el error de imagen

  const resetState = () => {
    setState(initialState);
    setImagenError(false);  // Reiniciar el estado de error de imagen
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith('image/')) {
      convertToWebP(file, (webpFile) => {
        setState(prevState => ({
          ...prevState,
          imagenTemporal: webpFile,
          showCropper: true,
        }));
        setImagenError(false);  // Limpiar error de imagen al seleccionar una válida
      });
    } else {
      setImagenError(true);  // Mostrar error si el archivo no es una imagen válida
    }
  };

  const handleCropperSave = (croppedFile) => {
    convertToWebP(croppedFile, (webpFile) => {
      setState(prevState => ({
        ...prevState,
        imagenCategoria: webpFile,
        isCropped: true,
        showCropper: false,
        imagenTemporal: null,
      }));
      setImagenError(false);  // Limpiar error después de guardar la imagen recortada
    });
  };

  const handleCropperClose = () => {
    setState(prevState => ({
      ...prevState,
      showCropper: false,
      imagenTemporal: null,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { nombreCategoria, idSeccion, imagenCategoria, isCropped } = state;

    if (!imagenCategoria) {
      setImagenError(true);  // Mostrar error si no hay imagen
      return;
    }

    if (!isCropped && imagenCategoria) {
      alert('Por favor, recorta la imagen antes de guardar.');
      return;
    }

    await handleCategoriaSubmit(nombreCategoria, idSeccion.value, imagenCategoria);
    refreshCategorias();
    resetState();
    handleClose();
  };

  useEffect(() => {
    if (!show) resetState(); 
  }, [show]);

  const seccionOptions = secciones.map(seccion => ({
    value: seccion.idSeccion,
    label: seccion.nomSeccion,
  }));

  return (
    <>
      <Modal size='lg' show={show} onHide={() => { resetState(); handleClose(); }}>
        <Modal.Header closeButton>
          <Modal.Title>Nueva Categoría</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group className='m-3 mt-0'>
                  <Form.Label htmlFor='nombreCategoria'>Nombre de la Categoría</Form.Label>
                  <Form.Control
                    type="text"
                    value={state.nombreCategoria}
                    onChange={(e) => setState({ ...state, nombreCategoria: e.target.value })}
                    placeholder='Ingrese nombre aquí'
                    required
                  />
                </Form.Group>
                <Form.Group controlId="idSeccion" className='m-3'>
                  <Form.Label>Sección</Form.Label>
                  <Select
                    value={state.idSeccion}
                    onChange={(selectedOption) => setState({ ...state, idSeccion: selectedOption })}
                    options={seccionOptions}
                    placeholder="Seleccione una sección"
                    isClearable
                    required
                  />
                </Form.Group>
              </Col>
              <Col className="d-flex justify-content-center align-items-center">
                <Form.Group className='text-center'>
                  <Form.Label htmlFor='imagenCategoria'>Imagen de la Categoría</Form.Label>
                  <div className="position-relative" style={{ width: '200px', height: '200px' }}>
                    <div
                      className={`d-flex align-items-center justify-content-center overflow-hidden ${state.imagenCategoria && state.isCropped ? 'bg-light' : 'bg-secondary'}`}
                      style={{
                        width: '100%',
                        height: '100%',
                        border: state.imagenCategoria || state.imagenExistente ? 'none' : '2px dashed #007bff',
                        backgroundImage: state.imagenCategoria && state.isCropped ? `url(${URL.createObjectURL(state.imagenCategoria)})` : 'none',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                      }}
                    >
                      {!state.imagenCategoria ? (
                        <Button
                          variant="outline-primary"
                          className="position-absolute top-50 start-50 translate-middle rounded-circle"
                          style={{ width: '40px', height: '40px', padding: 0 }}
                          onClick={() => document.getElementById('fileInput').click()}
                        >
                          <Plus size={20} />
                        </Button>
                      ) : (
                        <Button
                          variant="light"
                          className="position-absolute top-50 start-50 translate-middle rounded-circle"
                          style={{ width: '40px', height: '40px', padding: 0 }}
                          onClick={() => document.getElementById('fileInput').click()}
                        >
                          <Pencil size={20} />
                        </Button>
                      )}
                      <Form.Control
                        id="fileInput"
                        type="file"
                        accept="image/*"
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                      />
                    </div>
                    {imagenError && (
                      <Form.Text className="text-danger">
                        Por favor, sube una imagen.
                      </Form.Text>
                    )}
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Modal.Footer className='mt-2'>
              <Button variant="secondary" onClick={() => { resetState(); handleClose(); }}>Cancelar</Button>
              <Button variant="primary" type="submit">Guardar</Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
      <ImageCropperModal
        show={state.showCropper}
        imageFile={state.imagenTemporal}  
        onSave={handleCropperSave}
        onClose={handleCropperClose}
        aspectRatio={1}
      />
    </>
  );
};

export const EditCategoriaModal = ({ show, handleClose, categoria, secciones, refreshCategorias }) => {
  const [state, setState] = useState({
    nombreCategoria: '',
    idSeccion: null,
    imagenCategoria: null,
    imagenExistente: null,
    isCropped: false,
    showCropper: false,
    imagenTemporal: null,  // Imagen temporal para el cropper
  });

  const resetState = () => setState({
    nombreCategoria: '',
    idSeccion: null,
    imagenCategoria: null,
    imagenExistente: null,
    isCropped: false,
    showCropper: false,
    imagenTemporal: null,
  });

  useEffect(() => {
    if (show && categoria && secciones.length > 0) {
      const seccionActual = secciones.find(seccion => seccion.idSeccion === categoria.idSeccion);
      const imagenExistente = categoria.imgCategoria || null;

      setState({
        nombreCategoria: categoria.nomCategoria,
        idSeccion: seccionActual ? { value: seccionActual.idSeccion, label: seccionActual.nomSeccion } : null,
        imagenCategoria: null,
        imagenExistente,
        isCropped: false,
        showCropper: false,
        imagenTemporal: null,
      });
    }
  }, [show, categoria, secciones]);

  useEffect(() => {
    if (!show) resetState();
  }, [show]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith('image/')) {
      convertToWebP(file, (webpFile) => {
        setState(prevState => ({
          ...prevState,
          imagenTemporal: webpFile,  // Almacenar la imagen temporalmente
          showCropper: true,
        }));
      });
    } else {
      alert('Por favor, selecciona una imagen válida.');
    }
  };

  const handleCropperSave = (croppedFile) => {
    convertToWebP(croppedFile, (webpFile) => {
      setState(prevState => ({
        ...prevState,
        imagenCategoria: webpFile,  // Guardar la imagen recortada
        isCropped: true,
        showCropper: false,
        imagenExistente: null,  // Reemplazar la imagen existente
        imagenTemporal: null,   // Limpiar la imagen temporal
      }));
    });
  };

  const handleCropperClose = () => {
    // Cerrar el cropper y descartar la imagen temporal sin afectar la imagen existente
    setState(prevState => ({
      ...prevState,
      showCropper: false,
      imagenTemporal: null,  // Descartar la imagen temporal
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { nombreCategoria, idSeccion, imagenCategoria, isCropped } = state;
    if (!isCropped && imagenCategoria) {
      alert('Por favor, recorte la imagen antes de guardar.');
      return;
    }
    await handleCategoriaUpdate(categoria.idCategoria, nombreCategoria, idSeccion?.value, imagenCategoria);
    refreshCategorias();
    resetState();
    handleClose();
  };

  const seccionOptions = secciones.map(seccion => ({
    value: seccion.idSeccion,
    label: seccion.nomSeccion,
  }));

  return (
    <>
      <Modal size="lg" show={show} onHide={() => { resetState(); handleClose(); }}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Categoría</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group className="m-3 mt-0">
                  <Form.Label htmlFor="editNombreCategoria">Nombre de la Categoría</Form.Label>
                  <Form.Control
                    type="text"
                    id="editNombreCategoria"
                    value={state.nombreCategoria}
                    onChange={(e) => setState({ ...state, nombreCategoria: e.target.value })}
                    required
                  />
                </Form.Group>
                <Form.Group className="m-3">
                  <Form.Label htmlFor='editIdSeccion'>Sección</Form.Label>
                  <Select
                    value={state.idSeccion}
                    onChange={(selectedOption) => setState({ ...state, idSeccion: selectedOption })}
                    options={seccionOptions}
                    placeholder="Seleccione una sección"
                    isClearable
                    required
                  />
                </Form.Group>
              </Col>
              <Col className="d-flex justify-content-center align-items-center">
                <Form.Group className="text-center">
                  <Form.Label htmlFor='editImagenCategoria'>Imagen de la Categoría</Form.Label>
                  <div className="position-relative" style={{ width: '210px', height: '210px' }}>
                    <div
                      className="d-flex align-items-center justify-content-center overflow-hidden"
                      style={{
                        width: '100%',
                        height: '100%',
                        position: 'relative',
                        backgroundColor: '#f8f9fa',
                      }}
                    >
                      {(state.imagenCategoria && state.isCropped) ? (
                        <img
                          src={URL.createObjectURL(state.imagenCategoria)}
                          alt="Imagen de la Categoría"
                          style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                        />
                      ) : state.imagenExistente ? (
                        <img
                          src={state.imagenExistente}
                          alt="Imagen de la Categoría"
                          style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }}
                        />
                      ) : (
                        <div
                          className="d-flex align-items-center justify-content-center"
                          style={{ width: '100%', height: '100%', background: '#e9ecef' }}
                        >
                          {/* Contenido para imagen vacía */}
                        </div>
                      )}
                      <Button
                        variant={!state.imagenCategoria && !state.imagenExistente ? "outline-primary" : "light"}
                        className="position-absolute top-50 start-50 translate-middle rounded-circle"
                        style={{ width: '40px', height: '40px', padding: 0 }}
                        onClick={() => document.getElementById('fileInput').click()}
                      >
                        {!state.imagenCategoria && !state.imagenExistente ? <Plus size={16} /> : <Pencil size={16} />}
                      </Button>
                      <Form.Control
                        id="fileInput"
                        type="file"
                        accept="image/*"
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                      />
                    </div>
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => { resetState(); handleClose(); }}>Cancelar</Button>
              <Button variant="primary" type="submit">Guardar Cambios</Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Mostrar el ImageCropperModal cuando showCropper es true */}
      <ImageCropperModal
        show={state.showCropper}  // Mostrar el modal cuando showCropper es true
        imageFile={state.imagenTemporal}  // Usar la imagen temporal en el cropper
        onSave={handleCropperSave}
        onClose={handleCropperClose}
        aspectRatio={1}
      />
    </>
  );
};