import React from 'react';
import { Card, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function InfoGeneralFactura({ cliente, idCliente, tipoFactura, deudaCliente, facturasRelacionadas }) {
    return (
        <Card>
            <Card.Header className="fw-bold text-black">Información General</Card.Header>
            <Table size="sm" className="shadow-none">
                <tbody>
                    <tr><th>Cliente</th><td><Link to={`/admin/Clientes/DetallesClientes/${idCliente}/`} className="text-decoration-none">{cliente}</Link></td></tr>
                    <tr><th>Deuda total cliente</th><td><Link to={`/admin/Clientes/DetallesClientes/${idCliente}/`} className="text-decoration-none text-danger">${deudaCliente.toLocaleString('es-ES')}</Link></td></tr>
                    <tr><th>Facturas relacionadas</th><td>{facturasRelacionadas}</td></tr>
                    <tr><th>Tipo de Factura</th><td>{tipoFactura}</td></tr>
                    <tr><th>Factura Electrónica</th><td>Si/No</td></tr>
                </tbody>
            </Table>
        </Card>
    );
}

export default InfoGeneralFactura;
