import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Table, Form, Button } from 'react-bootstrap';
import { BarChart, Bar, PieChart, Pie, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import APIserver from '../../../../api/axiosConfig';

const ReporteClientes = () => {
    const [fechaInicio, setFechaInicio] = useState('');
    const [fechaFin, setFechaFin] = useState('');
    const [filter, setFilter] = useState('mes');
    const [clientesRegistrados, setClientesRegistrados] = useState(0);
    const [totalClientesRegistrados, setTotalClientesRegistrados] = useState(0);
    const [clientesCompras, setClientesCompras] = useState(0);
    const [clientesActivos, setClientesActivos] = useState([]);
    const [clientesInactivos, setClientesInactivos] = useState([]);

    useEffect(() => {
        const today = new Date();
        // Fecha de inicio del mes (primer día)
        const primerDiaMes = new Date(today.getFullYear(), today.getMonth(), 1);
        const fechaInicioMes = primerDiaMes.toISOString().split('T')[0]; // Formato YYYY-MM-DD
        // Fecha de fin del mes (último día)
        const ultimoDiaMes = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        const fechaFinMes = ultimoDiaMes.toISOString().split('T')[0];
        setFechaInicio(fechaInicioMes);
        setFechaFin(fechaFinMes);
    }, []);

    const fetchClientesRegistrados = () => {
        APIserver.get(`/api/clientes/cards/registrados`)
            .then(response => {
                setClientesRegistrados(response.data.totalClientesRegistrados);
            })
            .catch(error => {
                console.error('Error al obtener clientes registrados:', error);
            });
    };

    const fetchClientesActivos = () => {
        APIserver.get(`/api/reportesClientes/clientes-activos`)
            .then(response => {
                setClientesActivos(response.data); // Accede al array correcto
            })
            .catch(error => {
                console.error('Error al obtener clientes activos:', error);
            });
    };

    const fechClientes = async () => {
        try {
            const [registradosRes, comprasRES, inactivosRes] = await Promise.all([
                APIserver.get('/api/reportesClientes/clientes-registrados', { params: { fechaInicio, fechaFin } }),
                APIserver.get('/api/reportesClientes/clientes-compras', { params: { fechaInicio, fechaFin } }),
                APIserver.get('/api/reportesClientes/clientes-inactivos', { params: { fechaInicio, fechaFin } }),
            ]);
            setTotalClientesRegistrados(registradosRes.data || 0);
            setClientesCompras(comprasRES.data || 0);
            setClientesInactivos(inactivosRes.data || 0);
        } catch (error) {
            console.error('Error al obtener clientes registrados:', error);
        }
    };

    useEffect(() => {
        if (fechaInicio && fechaFin) {
            fechClientes();
        }
    }, [fechaInicio, fechaFin]);

    useEffect(() => {
        fetchClientesActivos();
        fetchClientesRegistrados();
    }, []);

    const handleExport = async () => {
        try {
            const response = await APIserver.get('/api/reportesClientes/exportar-clientes', {
                params: { fechaInicio, fechaFin },
                responseType: 'arraybuffer', // Es importante para recibir el archivo como binario
            });

            // Crear un Blob a partir de los datos binarios recibidos
            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            // Crear un enlace temporal para descargar el archivo
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'clientes.xlsx'; // Nombre del archivo
            link.click(); // Simular un clic en el enlace
        } catch (error) {
            console.error('Error al exportar el archivo Excel:', error);
        }
    };

    const handleExportCartera = async () => {
        try {
            const response = await APIserver.get('/api/reportesClientes/exportar-cartera', {
                responseType: 'arraybuffer', // Es importante para recibir el archivo como binario
            });

            // Crear un Blob a partir de los datos binarios recibidos
            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            // Crear un enlace temporal para descargar el archivo
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'cartera_clientes.xlsx'; // Nombre del archivo
            link.click(); // Simular un clic en el enlace
        } catch (error) {
            console.error('Error al exportar la cartera de clientes:', error);
        }
    };

    const COLORS = ['#3459e6', '#2fb380', '#f4bd61', '#da292e'];

    // Función para formatear valores en COP
    const formatCOP = (value) => value?.toLocaleString('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    }) || '$0';

    const formatDate = (dateString) => {
        const date = new Date(dateString); // Convierte el string a un objeto Date
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Mes con 2 dígitos
        const day = date.getDate().toString().padStart(2, '0'); // Día con 2 dígitos
        return `${year}-${month}-${day}`; // Retorna en formato YYYY-MM-DD
    };
    return (
        <Container fluid className="py-3">
            {/* Filtros de Reporte */}
            <div className='d-flex flex-column flex-md-row align-items-center align-items-md-end justify-content-center mb-3 text-center'>
                <div className='d-flex flex-column me-md-3 mb-3 mb-md-0'>
                    <Form.Label>Desde</Form.Label>
                    <Form.Control
                        type='date'
                        value={fechaInicio}
                        onChange={(e) => {
                            const selectedDate = e.target.value;
                            if (new Date(selectedDate) <= new Date(fechaFin || new Date())) {
                                setFechaInicio(selectedDate);
                            }
                        }}
                        max={new Date().toISOString().split('T')[0]} // Fecha máxima: Hoy
                        style={{ minWidth: '200px' }}
                    />
                </div>
                <div className='d-flex flex-column me-md-3 mb-3 mb-md-0'>
                    <Form.Label>Hasta</Form.Label>
                    <Form.Control
                        type='date'
                        value={fechaFin}
                        onChange={(e) => {
                            const selectedDate = e.target.value;
                            if (new Date(selectedDate) >= new Date(fechaInicio) && new Date(selectedDate) <= new Date()) {
                                setFechaFin(selectedDate);
                            }
                        }}
                        max={new Date().toISOString().split('T')[0]} // Fecha máxima: Hoy
                        style={{ minWidth: '200px' }}
                    />
                </div>
                <div className='d-flex align-items-center justify-content-end mt-3 mt-md-0'>
                    <Button variant='success' className='me-2' onClick={handleExport}>
                        Exportar
                    </Button>

                </div>
                <div className='d-flex align-items-center justify-content-end mt-3 mt-md-0'>
                    <Button variant='success' onClick={handleExportCartera}>
                        Exportar Cartera
                    </Button>
                </div>
            </div>

            {/* Resumen de clientes Totales */}
            <Row className="d-flex flex-column flex-md-row align-items-center align-items-md-end justify-content-center mb-3 text-center">
                <Col md={4}>
                    <Card bg='primary' className='text-light'>
                        <Card.Body>
                            <Card.Title>Total Clientes</Card.Title>
                            <h4>{clientesRegistrados || 0}</h4>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card bg='success' className='text-light'>
                        <Card.Body>
                            <Card.Title>Clientes Registrados</Card.Title>
                            <h4>{totalClientesRegistrados || 0}</h4>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {/*Clientes con Más Compras (Gráfico de Barras) */}
            <Row className="mb-4">
                <Col md={6}>
                    <div className='d-flex flex-column flex-md-row align-items-center justify-content-between mb-2'>
                        <h5 className='fw-bold'>Clientes con Más Compras</h5>
                    </div>
                    <ResponsiveContainer width="100%" height={300}>
                        <BarChart data={clientesCompras}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="nombre" />
                            <YAxis />
                            <Tooltip content={({ payload }) => (
                                <div style={{ backgroundColor: '#fff', padding: '5px', border: '1px solid #ccc' }}>
                                    {payload.map((entry, index) => (
                                        <div key={`tooltip-${index}`} style={{ color: entry.color }}>
                                            <strong>{entry.name}:</strong> {formatCOP(entry.value)}
                                        </div>
                                    ))}
                                    <div><strong>Total Compra:</strong> {formatCOP(payload[0]?.payload.totalCompra)}</div>
                                </div>
                            )}
                                formatter={(value) => formatCOP(value)}
                            />
                            <Legend />
                            <Bar dataKey="facturasPendientes" fill="#f4bd61" name="Facturas Pendientes" />
                            <Bar dataKey="facturasPagadas" fill="#2fb380" name="Facturas Pagadas" />
                            <Bar dataKey="facturasVencidas" fill="#da292e" name="Facturas Vencidas" />
                            <Bar dataKey="nombre" fill="#2fb380" name="Nombre" />

                        </BarChart>
                    </ResponsiveContainer>
                </Col>

                {/* Clientes Activos  Gráfico de Pastel */}
                <Col md={6}>
                    <h5 className='fw-bold'>Clientes Activos</h5>
                    <ResponsiveContainer width="100%" height={300}>
                        <PieChart>
                            <Pie
                                data={clientesActivos}
                                dataKey="totalFacturas"
                                nameKey="nombre"
                                cx="50%"
                                cy="50%"
                                outerRadius={100}

                            >
                                {clientesActivos.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Pie>
                            <Tooltip />
                        </PieChart>
                    </ResponsiveContainer>
                </Col>
            </Row>

            {/* Clientes Inactivos */}
            <Row>
                <Col>
                    <div className='d-flex flex-column flex-md-row align-items-center justify-content-between mb-2'>
                        <h5 className='fw-bold'>Clientes Inactivos</h5>
                    </div>
                    <Table striped bordered hover responsive>
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>Última Compra</th>
                                <th>Ciudad</th>
                            </tr>
                        </thead>
                        <tbody>
                            {clientesInactivos.map((cliente, index) => (
                                <tr key={index}>
                                    <td>{cliente.nombre}</td>
                                    <td>{cliente.ultimaCompra ? formatDate(cliente.ultimaCompra) : 'No hay compras'}</td> {/* Formateamos la fecha */}
                                    <td>{cliente.ciudad}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    );
};

export default ReporteClientes;
