import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Button, Form } from 'react-bootstrap';
import { useCompanyInfo } from '../../../../hooks/useCompanyInfo';
import { CameraFill } from 'react-bootstrap-icons';
import ImageCropperModal from './ImageCropperModal';
import Swal from 'sweetalert2';  // Importar SweetAlert2
import Select from 'react-select';
import Departamentos from '../../../../utils/places-colombia';

function CompanyConfig() {
    const {
        companyData,
        setCompanyData,
        handleUpdateCompanyInfo,
        isLoading,
        error,
        updateLogoImage
    } = useCompanyInfo();
    const [backupCompanyData, setBackupCompanyData] = useState(companyData);
    const [isEditing, setIsEditing] = useState(false);

    const [hoverLogo, setHoverLogo] = useState(false);
    const [showLogoModal, setShowLogoModal] = useState(false);
    const [newLogoImage, setNewLogoImage] = useState(null);

    const [cityOptions, setCityOptions] = useState([]);

    useEffect(() => {
        if (isEditing && companyData.departamento) {
            const department = Departamentos.find(dep => dep.departamento === companyData.departamento);
            setCityOptions(department ? department.ciudades.map(city => ({ label: city, value: city })) : []);
        }
    }, [isEditing, companyData.departamento]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCompanyData({
            ...companyData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { logoEmpresa, ...companyDataWithoutLogo } = companyData;

        try {
            const response = await handleUpdateCompanyInfo(companyDataWithoutLogo);

            if (response.message === 'Información actualizada correctamente') {
                setIsEditing(false);

                setCompanyData(prevData => ({
                    ...prevData,
                    logoEmpresa: logoEmpresa
                }));

                Swal.fire({
                    icon: 'success',
                    title: 'Datos actualizados',
                    text: 'La información de la empresa ha sido actualizada correctamente.',
                    confirmButtonText: 'Aceptar',
                    iconColor: '#2fb380',
                    confirmButtonColor: "#2c4cc4"
                });

             } else {
                console.error('No se pudo actualizar la información:', response.error);

                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'No se pudo actualizar la información de la empresa.',
                    confirmButtonText: 'Aceptar',
                    iconColor: '#da292e',
                    confirmButtonColor: "#da292e"
                });
            }
        } catch (err) {
            console.error('Error al actualizar la información:', err);

            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Hubo un problema al actualizar la información de la empresa.',
                confirmButtonText: 'Aceptar',
                iconColor: '#da292e',
                confirmButtonColor: "#da292e"
            });
        }
    };

    const handleEdit = () => {
        setBackupCompanyData(companyData);
        setIsEditing(true);
    };

    const handleCancel = () => {
        setCompanyData(backupCompanyData);
        setIsEditing(false);
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setNewLogoImage(file);
        setShowLogoModal(true);
    };

    const handleImageSave = async (croppedImage) => {
        try {
            const newImageUrl = await updateLogoImage(croppedImage);
            setCompanyData((prevData) => ({
                ...prevData,
                logoEmpresa: `${newImageUrl}?t=${new Date().getTime()}`,
            }));
            setShowLogoModal(false);

            Swal.fire({
                icon: 'success',
                title: 'Logo actualizado',
                text: 'El logo de la empresa ha sido actualizado correctamente.',
                confirmButtonText: 'Aceptar',
                iconColor: '#2fb380',
                confirmButtonColor: "#2c4cc4"
            }).then(() => {
                // Recargar la página después de que el usuario haga clic en "Aceptar"
                window.location.reload();
            });
        } catch (err) {
            console.error('Error al actualizar la imagen:', err);

            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Hubo un problema al actualizar el logo de la empresa.',
                confirmButtonText: 'Aceptar',
                iconColor: '#da292e',
                confirmButtonColor: "#da292e"
            });
        }
    };

    const handleDepartmentChange = (selectedOption) => {
        const department = Departamentos.find(dep => dep.departamento === selectedOption.value);
        setCityOptions(department ? department.ciudades.map(city => ({ label: city, value: city })) : []);
        setCompanyData({
            ...companyData,
            departamento: selectedOption.value,
            ciudad: ''
        });
    };

    const handleCityChange = (selectedOption) => {
        setCompanyData({
            ...companyData,
            ciudad: selectedOption.value
        });
    };

    if (isLoading) return <p>Cargando datos...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div className='m-4'>
            <div className='d-flex flex-column flex-md-row align-items-center justify-content-between'>
                <p className='mb-2 mb-md-0 text-center'>
                    Información de la Empresa
                </p>
                <div className='d-flex mt-2 mt-md-0'>
                    <Button variant='primary' onClick={handleEdit}>
                        Editar
                    </Button>
                </div>
            </div>
            <Card className='mt-3 shadow-none'>
                <Card.Body>
                    <Row>
                        <Col md={4} className='d-flex align-items-center justify-content-center position-relative'>
                            <div
                                className='position-relative'
                                onMouseEnter={() => setHoverLogo(true)}
                                onMouseLeave={() => setHoverLogo(false)}
                            >
                                <img
                                    src={companyData.logoEmpresa
                                        ? `${companyData.logoEmpresa}`
                                        : "https://via.placeholder.com/150"}
                                    alt="Company Logo"
                                    className={`m-2 ${hoverLogo ? 'img-darken' : ''}`}
                                    width="200"
                                />
                                {hoverLogo && (
                                    <>
                                        <Button
                                            variant='link'
                                            className='edit-button position-absolute top-50 start-50 translate-middle'
                                            onClick={() => document.getElementById('logo-image-input').click()}
                                            title='Cambiar logo'
                                        >
                                            <CameraFill size={24} />
                                        </Button>
                                        <input
                                            type='file'
                                            id='logo-image-input'
                                            accept='image/*'
                                            style={{ display: 'none' }}
                                            onChange={handleImageChange}
                                        />
                                    </>
                                )}
                            </div>
                        </Col>
                        <Col md={8}>
                            <Form onSubmit={handleSubmit}>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group className='m-2'>
                                            <Form.Label>Nombre de la Empresa</Form.Label>
                                            <Form.Control
                                                type='text'
                                                name='nombreEmpresa'
                                                value={companyData.nombreEmpresa}
                                                onChange={handleChange}
                                                readOnly={!isEditing} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className='m-2'>
                                            <Form.Label>NIT</Form.Label>
                                            <Form.Control
                                                type='text'
                                                name='nit'
                                                value={companyData.nit}
                                                onChange={handleChange}
                                                readOnly={!isEditing}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group className='m-2'>
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                type='email'
                                                name='email'
                                                value={companyData.email}
                                                onChange={handleChange}
                                                readOnly={!isEditing}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className='m-2'>
                                            <Form.Label>Dirección</Form.Label>
                                            <Form.Control
                                                type='text'
                                                name='direccion'
                                                value={companyData.direccion}
                                                onChange={handleChange}
                                                readOnly={!isEditing}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group className='m-2'>
                                            <Form.Label>Ciudad</Form.Label>
                                            {isEditing ? (
                                                <Select
                                                    value={{ label: companyData.ciudad, value: companyData.ciudad }}
                                                    onChange={handleCityChange}
                                                    options={cityOptions}
                                                />
                                            ) : (
                                                <Form.Control
                                                    type='text'
                                                    name='ciudad'
                                                    value={companyData.ciudad}
                                                    readOnly
                                                />
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group className='m-2'>
                                            <Form.Label>Departamento</Form.Label>
                                            {isEditing ? (
                                                <Select
                                                    value={{ label: companyData.departamento, value: companyData.departamento }}
                                                    onChange={handleDepartmentChange}
                                                    options={Departamentos.map(dep => ({
                                                        label: dep.departamento, value: dep.departamento
                                                    }))}
                                                />
                                            ) : (
                                                <Form.Control
                                                    type='text'
                                                    name='departamento'
                                                    value={companyData.departamento}
                                                    readOnly
                                                />
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <Form.Group className='m-2'>
                                            <Form.Label>Teléfono</Form.Label>
                                            <Form.Control
                                                type='text'
                                                name='telefono'
                                                value={companyData.telefono}
                                                onChange={handleChange}
                                                readOnly={!isEditing}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                {isEditing && (
                                    <div className="d-flex justify-content-end mt-3">
                                        <Button variant='success' type='submit' className='me-2'>
                                            Guardar
                                        </Button>
                                        <Button variant='secondary' onClick={handleCancel}>
                                            Cancelar
                                        </Button>
                                    </div>
                                )}
                            </Form>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

            <ImageCropperModal
                show={showLogoModal}
                onHide={() => setShowLogoModal(false)}
                onSave={handleImageSave}
                onClose={() => setShowLogoModal(false)}
                aspectRatio={1}
                imageFile={newLogoImage}
            />
        </div>
    );
}

export default CompanyConfig;