import React, { useState, useEffect } from 'react';
import { Table, Pagination, Button, Form, ButtonGroup } from 'react-bootstrap';
import { Filter, EyeFill, PencilFill, TrashFill } from 'react-bootstrap-icons';
import FiltrosProveedor from './filtrosProveedor';
import EditProveedor from './editarProveedorModal';
import { useNavigate, Link } from 'react-router-dom';
import { handleDeleteProveedor } from '../Utils/AlertsProveedores';
import APIserver from '../../../../api/axiosConfig';

function TablaProveedor() {
    const navigate = useNavigate();
    const [showFilters, setShowFilters] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [editShow, setEditShow] = useState(false);
    const [selectedProveedor, setSelectedProveedor] = useState(null);
    const [proveedores, setProveedores] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [isFiltering, setIsFiltering] = useState(false);
    const [filters, setFilters] = useState({});

    useEffect(() => {
        fetchProveedores(currentPage, rowsPerPage, filters);
    }, [currentPage, rowsPerPage, filters]);

    const fetchProveedores = async (page, limit, filters = {}) => {
        try {
            const response = await APIserver.get(`/api/proveedores`, {
                params: {
                    page: currentPage,
                    limit: rowsPerPage,
                    ...filters,  // Incluye los filtros si se aplican
                },
            });

            setProveedores(response.data.proveedores);

            // Actualizar el número total de páginas
            if (Object.keys(filters).length === 0) {
                const totalCount = response.data.totalCount;
                setTotalPages(Math.ceil(totalCount / limit));
            } else {
                setTotalPages(1); // Solo una página si se están aplicando filtros
            }
        } catch (error) {
            console.error('Error al obtener los proveedores:', error);
        }
    };

    const toggleFilters = () => {
        setShowFilters(!showFilters);
    };

    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(parseInt(e.target.value));
        setCurrentPage(1);
    };

    const handleEditClose = () => setEditShow(false);

    const handleEditShow = (proveedor) => {
        setSelectedProveedor(proveedor.idProveedor); // Guarda solo el ID
        setEditShow(true);
    };

    const handleVerShow = (proveedor) => {
        setSelectedProveedor(proveedor); // Guarda el proveedor completo
        navigate(`/admin/Proveedores/DetallesProveedores/${proveedor.idProveedor}/`, {
            state: { proveedor }
        });
    };

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    const handleFilterChange = (filters) => {
        setFilters(filters);  // Actualizar filtros
        setCurrentPage(1);  // Volver a la primera página cuando se apliquen filtros
        fetchProveedores(1, rowsPerPage, filters); // Volver a cargar proveedores con filtros
    };

    const renderPaginationItems = () => {
        const items = [];
        const pageRangeDisplayed = 5;
        let startPage = Math.max(1, currentPage - Math.floor(pageRangeDisplayed / 2));
        let endPage = Math.min(totalPages, startPage + pageRangeDisplayed - 1);

        if (endPage - startPage < pageRangeDisplayed - 1) {
            startPage = Math.max(1, endPage - pageRangeDisplayed + 1);
        }

        if (startPage > 1) {
            items.push(<Pagination.First key="first" onClick={() => handlePageChange(1)} />);
        }

        if (currentPage > 1) {
            items.push(<Pagination.Prev key="prev" onClick={() => handlePageChange(currentPage - 1)} />);
        }

        for (let number = startPage; number <= endPage; number++) {
            items.push(
                <Pagination.Item key={number} active={number === currentPage} onClick={() => handlePageChange(number)}>
                    {number}
                </Pagination.Item>
            );
        }

        if (currentPage < totalPages) {
            items.push(<Pagination.Next key="next" onClick={() => handlePageChange(currentPage + 1)} />);
        }

        if (endPage < totalPages) {
            items.push(<Pagination.Last key="last" onClick={() => handlePageChange(totalPages)} />);
        }

        return items;
    };

    return (
        <div style={{ boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)" }}>
            <Table responsive className='text-center align-middle border'>
                <thead>
                    <tr>
                        <th colSpan={8}>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div className='d-flex flex-wrap'>
                                    <Button variant='link' size='sm' onClick={toggleFilters} title='Filtrar'>
                                        <Filter className='m-0' size={20} />
                                    </Button>
                                </div>
                                <div>
                                    <Form.Select
                                        className='ms-2'
                                        value={rowsPerPage}
                                        onChange={handleChangeRowsPerPage}
                                    >
                                        <option value='5'>5</option>
                                        <option value='10'>10</option>
                                        <option value='20'>20</option>
                                        <option value='50'>50</option>
                                    </Form.Select>
                                </div>
                            </div>
                            <div className={`filter-options ${showFilters ? 'show' : ''}`}>
                                <FiltrosProveedor onFilterChange={handleFilterChange} />
                            </div>
                        </th>
                    </tr>
                    <tr>
                        <th>Identificación</th>
                        <th>Nombre</th>
                        <th>Número</th>
                        <th>Razón Social</th>
                        <th>Ciudad</th>
                        <th>Departamento</th>
                        <th>País</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {proveedores.map(proveedor => (
                        <tr key={proveedor.idProveedor}>
                            <td>{proveedor.proveedorID}</td>
                            <td>
                                <Link to={`/admin/Proveedores/DetallesProveedores/${proveedor.idProveedor}/`} className='text-decoration-none'>
                                    {proveedor.nomProveedor}
                                </Link>
                            </td>
                            <td>{proveedor.numProveedor}</td>
                            <td>{proveedor.razonSocial}</td>
                            <td>{proveedor.ciudad}</td>
                            <td>{proveedor.departamento}</td>
                            <td>{proveedor.pais}</td>
                            <td>
                                <ButtonGroup aria-label='action-button'>
                                    <Button variant='link' className='text-primary' title='Ver proveedor' onClick={() => handleVerShow(proveedor)}>
                                        <EyeFill size={18} />
                                    </Button>
                                    <Button variant='link' className='text-warning' title='Editar proveedor' onClick={() => handleEditShow(proveedor)}>
                                        <PencilFill size={18} />
                                    </Button>
                                    <Button variant='link' className='text-danger' title='Eliminar proveedor' onClick={() => handleDeleteProveedor(proveedor.idProveedor, setProveedores, proveedores)}>
                                        <TrashFill size={18} />
                                    </Button>
                                </ButtonGroup>
                            </td>
                        </tr>
                    ))}
                </tbody>
                <tfoot>
                    {!isFiltering && (
                        <tr>
                            <td colSpan={8}>
                                <div className='d-flex justify-content-center align-items-center'>
                                    <Pagination>
                                        {renderPaginationItems()}
                                    </Pagination>
                                </div>
                            </td>
                        </tr>
                    )}
                </tfoot>
            </Table>

            {/* MODAL EDITAR Y VER */}
            <EditProveedor show={editShow} handleClose={handleEditClose} proveedorId={selectedProveedor} />
        </div>
    );
}

export default TablaProveedor;
