import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { API } from '../../../../api/URL_API';
import '../../styles/CategoriasUni.css';
import { Skeleton } from '@mui/material';

function CategoriasUni() {
    const [todasCategorias, setTodasCategorias] = useState([]); // Para todas las categorías
    const [categoriasPorSeccion, setCategoriasPorSeccion] = useState([]); // Para categorías filtradas por sección
    const [isLoading, setIsLoading] = useState(true); // Estado de carga para las categorías
    const { nomSeccion, idSucursal } = useParams(); // Captura nomSeccion e idSucursal desde los parámetros de la URL

    // Fetch para obtener todas las categorías
    useEffect(() => {
        setIsLoading(true);
        const url = nomSeccion 
            ? `${API}/api/mostrarCategorias/categorias/${nomSeccion}`  // Si se recibe el nombre de la sección, filtra por esa
            : `${API}/api/mostrarCategorias/categorias`;  // Si no, carga todas las categorías

        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                if (nomSeccion) {
                    setCategoriasPorSeccion(data); // Si se filtró por sección, asigna a categoriasPorSeccion
                } else {
                    setTodasCategorias(data); // Si no, asigna a todas las categorías
                }
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Error al cargar categorías:', error);
                setIsLoading(false);
            });
    }, [nomSeccion]); // Solo vuelve a ejecutar si `nomSeccion` cambia

    // Mostrar Skeleton mientras los datos se cargan
    if (isLoading) {
        return (
            <Container fluid className="text-center">
                <h2 className="text-light fst-italic mb-4 fw-bold title-seccion">Cargando Colecciones...</h2>
                <Row className="g-4">
                    {Array.from(new Array(8)).map((_, idx) => (
                        <Col key={idx} xs={6} sm={6} md={4} lg={3} className="d-flex justify-content-center">
                            <Card className="text-center h-100 custom-card" style={{ borderRadius: '15px' }}>
                                <Skeleton variant="rectangular" height={300} animation="wave" />
                                <Card.Body className="d-flex flex-column align-items-center">
                                    <Skeleton variant="text" height={40} width="60%" />
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
        );
    }

    return (
        <Container fluid className="text-center">
            <h2 className="text-light fst-italic mb-4 fw-bold title-seccion">{nomSeccion ? `Colecciones en ${nomSeccion}` : 'Colecciones'}</h2>
            <Row className="g-4">
                {(nomSeccion ? categoriasPorSeccion : todasCategorias).map((categoria, idx) => (
                    <Col key={idx} xs={6} sm={6} md={4} lg={3} className="d-flex justify-content-center">
                        <Card className="text-center h-100 custom-card" style={{ boxShadow: '0 4px 8px rgba(43, 35, 94, 0.3), 0 6px 20px rgba(43, 35, 94, 0.3)', borderRadius: '15px' }}>
                            <Link
                                to={`/Collections/${idSucursal}/Products/${categoria.nomCategoria}`}
                                className="text-decoration-none"
                            >
                                <div className="custom-image-container">
                                    <img
                                        src={`${categoria.imgCategoria}`}
                                        alt={categoria.nomCategoria}
                                        className="custom-image"
                                        onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src = 'https://via.placeholder.com/300';
                                        }}
                                    />
                                </div>
                                <Card.Body className="d-flex flex-column align-items-center justify-content-center">
                                    <Card.Text className="mt-1 text1-cr text-center">
                                        <h3 className="fw-bold d-none d-sm-block">{categoria.nomCategoria}</h3>
                                        <h5 className="fw-bold d-block d-sm-none">{categoria.nomCategoria}</h5>
                                    </Card.Text>
                                </Card.Body>
                            </Link>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    );
}

export default CategoriasUni;
