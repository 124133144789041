import React, { useState } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { Download, Upload, BoxSeamFill, NutFill, Palette2, BookmarkCheckFill } from 'react-bootstrap-icons';
import '../styles/tables.css';
import CrearProductoModal from './components/crearProductoModal';
import CardProducto from './components/cardProductos';
import TablaProdcutos from './components/tablaProductos';
import CustomToggle from '../styles/customToggle';
import { ColoresModal, TallasModal } from './components/atributosModal';

function AdminProductos() {
  //funciones modal
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showTallas, setShowTallas] = useState(false);

  const handleCloseTallas = () => setShowTallas(false);
  const handleShowTallas = () => setShowTallas(true);

  const [showColor, setShowColor] = useState(false);

  const handleCloseColor = () => setShowColor(false);
  const handleShowColor = () => setShowColor(true);

  return (
    <div className='mx-2 mt-3'>
      <div className='d-flex flex-column flex-md-row align-items-center justify-content-between'>
        <p className='mb-2 mb-md-0'>
          Administra tus productos, gestiona tallas y colores, e importa o exporta datos desde un solo lugar.
        </p>
        <div className='d-flex mt-2 mt-md-0'>
          <Button variant='primary' onClick={handleShow}>
            <BoxSeamFill /> Nuevo Producto
          </Button>
          <Dropdown>
            <Dropdown.Toggle as={CustomToggle} variant='secondary' className='ms-2'>
              <NutFill />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={handleShowTallas}><BookmarkCheckFill className='mx-1' /> Motivos</Dropdown.Item>
              <Dropdown.Item onClick={handleShowColor}><Palette2 className='mx-1' /> Colores</Dropdown.Item>
              <Dropdown.Item><Download className='text-success mx-1' /> Importar</Dropdown.Item>
              <Dropdown.Item><Upload className='text-danger mx-1' /> Exportar</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      {/* MODAL DE INGRESO DE PRODUCTOS */}
      <CrearProductoModal show={show} handleClose={handleClose} />

      <TallasModal showTallas={showTallas} handleCloseTallas={handleCloseTallas} />

      <ColoresModal showColor={showColor} handleCloseColor={handleCloseColor} />

      {/* CARDS PRODUCTOS */}
      <div className='mt-4'>
        <CardProducto />
      </div>

      {/* TABLA DE PRODUCTOS */}
      <div>
        <TablaProdcutos />
      </div>

    </div>
  );
}
export default AdminProductos;