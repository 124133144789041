import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Form } from 'react-bootstrap';
import APIserver from '../../../../api/axiosConfig';

function CardCompra() {
    const [totalCompras, setTotalCompras] = useState(0);
    const [totalMontoCompras, setTotalMontoCompras] = useState(0);
    const [comprasPagadas, setComprasPagadas] = useState(0);
    const [montoComprasPagadas, setMontoComprasPagadas] = useState(0);
    const [comprasPendientes, setComprasPendientes] = useState(0);
    const [montoComprasPendientes, setMontoComprasPendientes] = useState(0);
    const [comprasVencidas, setComprasVencidas] = useState(0);
    const [montoComprasVencidas, setMontoComprasVencidas] = useState(0);
    const [filter, setFilter] = useState('semana');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const responseTotal = await APIserver.get(`/api/comprasCard/total?filter=${filter}`);
                setTotalCompras(responseTotal.data.totalCompras);
    
                const responseTotalMonto = await APIserver.get(`/api/comprasCard/totalmonto?filter=${filter}`);
                setTotalMontoCompras(responseTotalMonto.data.totalMontoCompras || 0);
    
                const responsePagadas = await APIserver.get(`/api/comprasCard/pagadas?filter=${filter}`);
                setComprasPagadas(responsePagadas.data.totalPagadas);
    
                const responseMontoPagadas = await APIserver.get(`/api/comprasCard/pagadasmonto?filter=${filter}`);
                setMontoComprasPagadas(responseMontoPagadas.data.totalMontoPagadas || 0);
    
                const responsePendientes = await APIserver.get(`/api/comprasCard/pendientes?filter=${filter}`);
                setComprasPendientes(responsePendientes.data.totalPendientes);
    
                const responseMontoPendientes = await APIserver.get(`/api/comprasCard/pendientesmonto?filter=${filter}`);
                setMontoComprasPendientes(responseMontoPendientes.data.totalMontoPendientes || 0);
    
                const responseVencidas = await APIserver.get(`/api/comprasCard/vencidas?filter=${filter}`);
                setComprasVencidas(responseVencidas.data.totalVencidas);
    
                const responseMontoVencidas = await APIserver.get(`/api/comprasCard/vencidasmonto?filter=${filter}`);
                setMontoComprasVencidas(responseMontoVencidas.data.totalMontoVencidas || 0);
            } catch (error) {
                console.error('Error al obtener datos de compras:', error);
            }
        };
    
        fetchData();
    }, [filter]);

    const formatCurrency = (value) => {
        return value.toLocaleString('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 0 });
    };

    return (
        <div className='d-flex flex-column align-items-center'>
            <div className='d-flex justify-content-end w-100 mb-3 text-center text-md-center'>
                <Form.Group controlId="filterSelect" className="d-flex align-items-center">
                    <Form.Label className="me-2 mb-0">Filtrar:</Form.Label>
                    <Form.Control
                        as="select"
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                    >
                        <option value="dia">Día</option>
                        <option value="semana">Semana</option>
                        <option value="mes">Mes</option>
                        <option value="ano">Año</option>
                    </Form.Control>
                </Form.Group>
            </div>
            <Row className='text-center'>
                <Col xs={12} md={3} className='mb-4 d-flex justify-content-center'>
                    <Card bg='info' text='white' style={{ width: '18rem' }}>
                        <Card.Body>
                            <Card.Text>Compras: <b>{totalCompras}</b></Card.Text>
                            <Card.Text><b>{formatCurrency(totalMontoCompras)}</b></Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={3} className='mb-4 d-flex justify-content-center'>
                    <Card bg='success' text='white' style={{ width: '18rem' }}>
                        <Card.Body>
                            <Card.Text>Pagados: <b>{comprasPagadas}</b></Card.Text>
                            <Card.Text><b>{formatCurrency(montoComprasPagadas)}</b></Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={3} className='mb-4 d-flex justify-content-center'>
                    <Card bg='warning' text='white' style={{ width: '18rem' }}>
                        <Card.Body>
                            <Card.Text>Pendientes: <b>{comprasPendientes}</b></Card.Text>
                            <Card.Text><b>{formatCurrency(montoComprasPendientes)}</b></Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={3} className='mb-4 d-flex justify-content-center'>
                    <Card bg='danger' text='white' style={{ width: '18rem' }}>
                        <Card.Body>
                            <Card.Text>Vencidas: <b>{comprasVencidas}</b></Card.Text>
                            <Card.Text><b>{formatCurrency(montoComprasVencidas)}</b></Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default CardCompra;
