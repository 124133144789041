// Función para convertir números a letras en español
function convertirMontoALetras(monto) {
    // Define arrays y objetos para las conversiones
    const unidades = [
        '', 'uno', 'dos', 'tres', 'cuatro', 'cinco', 'seis', 'siete', 'ocho', 'nueve', 'diez',
        'once', 'doce', 'trece', 'catorce', 'quince', 'dieciséis', 'diecisiete', 'dieciocho', 'diecinueve', 'veinte'
    ];
    const decenas = [
        '', '', 'veinti', 'treinta', 'cuarenta', 'cincuenta', 'sesenta', 'setenta', 'ochenta', 'noventa'
    ];
    const centenas = [
        '', 'cien', 'doscientos', 'trescientos', 'cuatrocientos', 'quinientos', 'seiscientos', 'setecientos', 'ochocientos', 'novecientos'
    ];
    const miles = 'mil';

    // Convierte una parte entera a letras
    function enteroALetras(n) {
        if (n === 0) return 'cero';
        if (n < 21) return unidades[n];
        if (n < 100) {
            let unidad = n % 10;
            return `${decenas[Math.floor(n / 10)]}${unidad ? ' y ' + unidades[unidad] : ''}`;
        }
        if (n < 1000) {
            let decena = n % 100;
            return `${centenas[Math.floor(n / 100)]}${decena ? ' ' + enteroALetras(decena) : ''}`;
        }
        if (n < 2000) {
            let resto = n % 1000;
            return `mil${resto ? ' ' + enteroALetras(resto) : ''}`;
        }
        // Para números mayores a mil, como 2000, 3000, etc.
        const milLetras = Math.floor(n / 1000);
        const resto = n % 1000;
        return `${enteroALetras(milLetras)} mil${resto ? ' ' + enteroALetras(resto) : ''}`;
    }

    // Convierte monto a letras
    const [entero, decimal] = monto.toFixed(2).split('.');
    const enteroLetras = enteroALetras(parseInt(entero, 10));
    const decimalLetras = decimal === '00' ? '' : ` con ${decimal}/100`;

    return `${enteroLetras} pesos${decimalLetras}`;
}

export default convertirMontoALetras;
