import React, { useState } from 'react';
import { FormControl, Button, ButtonGroup } from 'react-bootstrap';
import { Search, XSquare } from 'react-bootstrap-icons';

function FiltrosProveedor({ onFilterChange }) {
    const [filters, setFilters] = useState({
        identificacion: '',
        nombre: '',
        razonSocial: '',
        celular: '',
        ciudad: '',
        departamento: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFilters({ ...filters, [name]: value });
    };

    const handleClearFilters = () => {
        setFilters({
            identificacion: '',
            nombre: '',
            razonSocial: '',
            celular: '',
            ciudad: '',
            departamento: ''
        });
        onFilterChange({});
    };

    const handleSearch = () => {
        onFilterChange(filters);
    };

    return (
        <div className='d-flex align-items-center m-3'>
            <FormControl
                name='identificacion'
                value={filters.identificacion}
                onChange={handleChange}
                placeholder='Busca por Identificación'
                className='me-2'
            />
            <FormControl
                name='nombre'
                value={filters.nombre}
                onChange={handleChange}
                placeholder='Busca por Nombre'
                className='me-2'
            />
            <FormControl
                name='celular'
                value={filters.celular}
                onChange={handleChange}
                placeholder='Busca por Celular'
                className='me-2'
            />
            <FormControl
                name='razonSocial'
                value={filters.razonSocial}
                onChange={handleChange}
                placeholder='Busca por Razon Social'
                className='me-2'
            />
            <FormControl
                name='ciudad'
                value={filters.ciudad}
                onChange={handleChange}
                placeholder='Busca por Ciudad'
                className='me-2'
            />
            <FormControl
                name='departamento'
                value={filters.departamento}
                onChange={handleChange}
                placeholder='Busca por Departamento'
                className='me-2'
            />
            <ButtonGroup>
                <Button variant='primary' title='Buscar' onClick={handleSearch}>
                    <Search />
                </Button>
                <Button variant='danger' title='Limpiar' onClick={handleClearFilters}>
                    <XSquare />
                </Button>
            </ButtonGroup>
        </div>
    );
}

export default FiltrosProveedor;
