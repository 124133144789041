import React, { useState } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import { Bank, Upload, NutFill } from 'react-bootstrap-icons';
import '../styles/tables.css';
import CustomToggle from '../styles/customToggle';
import CrearBancoModal from './componentes/crearBancoModal';
import TablaBancos from './componentes/tablaBancos';

function AdminBancos() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className='mx-2 mt-3'>
      <div className='d-flex flex-column flex-md-row align-items-center justify-content-between'>
        <p className='mb-2 mb-md-0 text-center'>
          Administra los ingresos y egresos en tus cuentas bancarias
        </p>
        <div className='d-flex mt-2 mt-md-0'>
          <Button variant='primary' onClick={handleShow}>
            <Bank /> Nuevo Banco
          </Button>
          <Dropdown>
            <Dropdown.Toggle as={CustomToggle} variant='secondary' className='ms-2'>
              <NutFill />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item><Upload className='text-danger mx-1' /> Exportar</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      {/* Modal para crear bancos */}
      <CrearBancoModal show={show} handleClose={handleClose} />
      {/* Tabla de Bancos */}
      <div className='mb-2 mt-4'>
        <TablaBancos />
      </div>
    </div>
  );
}

export default AdminBancos;
