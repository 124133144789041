import React, { useState, useEffect } from 'react';
import { Row, Card, Col } from 'react-bootstrap';
import APIserver from '../../../../api/axiosConfig';

function CardProveedor() {
    const [proveedoresRegistrados, setProveedoresRegistrados] = useState(0);
    const [proveedoresNuevosEsteMes, setProveedoresNuevosEsteMes] = useState(0);

    useEffect(() => {
        fetchProveedoresStats();
    }, []);

    const fetchProveedoresStats = () => {
        APIserver.get(`/api/proveedores/stats`)
            .then(response => {
                setProveedoresRegistrados(response.data.proveedoresRegistrados);
                setProveedoresNuevosEsteMes(response.data.proveedoresNuevosEsteMes);
            })
            .catch(error => {
                console.error('Error al obtener datos de proveedores:', error);
            });
    };

    return (
        <div className='d-flex justify-content-center'>
            <Row className='text-center'>
                <Col xs={12} md={6} className='mb-4 d-flex justify-content-center'>
                    <Card bg='primary' text='white' style={{ width: '18rem' }}>
                        <Card.Body>
                            <Card.Text>Proveedores Registrados: <b>{proveedoresRegistrados}</b></Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} md={6} className='mb-4 d-flex justify-content-center'>
                    <Card bg='success' text='white' style={{ width: '18rem' }}>
                        <Card.Body>
                            <Card.Text>Proveedores nuevos este mes: <b>{proveedoresNuevosEsteMes}</b></Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default CardProveedor;
